import numeral from 'numeral';

const numeralTextConverter = (val) => {
  if (val) {
    return numeral(val).value();
  }
  return null;
};

export default numeralTextConverter;
