/** @jsxImportSource @emotion/react */
import qs from 'query-string';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Fragment, useContext } from 'react';

import { LocaleContext } from '../../../../contexts/localeContext';
import ApplicantCard from './ApplicantCard/ApplicantCard';
import { Pagination } from '../../../general';
import {
  applicantContainerStyles,
  paginationContainerStyles,
  paginationStyles,
} from './ApplicantList.styles';

const ApplicantList = ({
  params,
  resumeData,
  pageInfo,
  setPageInfo,
  setInviteUserModalStatus,
  setRefetchStatus,
  setSearchTermResetStatus,
  setFilterApplicationStatus,
}) => {
  const { locale } = useContext(LocaleContext);
  const history = useHistory();
  const handlePageChange = (page) => {
    setPageInfo((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
    const stringifiedQueryParams = qs.stringify({
      ...params,
      page,
    }, {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    });
    window.scrollTo(0, 0);
    history.push({
      pathname: `/${locale}/resume`,
      search: stringifiedQueryParams,
    });
    setSearchTermResetStatus(true);
    setFilterApplicationStatus(false);
  };

  return (
    <Fragment>
      <div css={applicantContainerStyles}>
        {resumeData.map((d) => (
          <ApplicantCard
            key={d.id}
            data={d}
            setInviteUserModalStatus={setInviteUserModalStatus}
            setRefetchStatus={setRefetchStatus}
          />
        ))}
      </div>
      <div css={paginationContainerStyles}>
        <Pagination
          containerStyles={paginationStyles}
          pageSize={pageInfo.pageSize}
          totalCount={pageInfo.totalCount}
          currentPage={pageInfo.currentPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </Fragment>
  );
};

ApplicantList.propTypes = {
  resumeData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      role_preference: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  ).isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
  setInviteUserModalStatus: PropTypes.func.isRequired,
  setRefetchStatus: PropTypes.func.isRequired,
  setSearchTermResetStatus: PropTypes.func.isRequired,
  setFilterApplicationStatus: PropTypes.func.isRequired,
  params: PropTypes.shape({}).isRequired,
};

export default ApplicantList;
