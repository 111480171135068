import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import { ManageMembersSettings } from '../../../components/pages';
import { users } from '../../../api/v1/get/company';
import { LoadingContext } from '../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../reducers/loadingReducers';
import { LocaleContext } from '../../../contexts/localeContext';

const ManageMembers = () => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(LoadingContext);
  const [data, setData] = useState([]);
  const [isAddMemberModalShown, setAddMemberModalStatus] = useState(false);
  const [editMemberModalData, setEditMemberModalData] = useState({
    id: 0,
    status: false,
  });
  const [manageMemberModalData, setManageMemberModalData] = useState({
    id: 0,
    status: false,
  });
  const [currentUserIdToBeActivated, setCurrentUserIdToBeActivated] = useState(0);
  const [activeConfirmationModalKey, setActiveConfirmationModalKey] = useState(
    'deactivateMemberModal',
  );
  const [isConfirmationModalShown, setConfirmationModalStatus] = useState({
    activateMemberModal: false,
    deactivateMemberModal: false,
    deleteMemberModal: false,
  });

  useEffect(() => {
    const fetchCompanyMembers = async () => {
      const params = {
        limit: 9999,
      };

      try {
        dispatch(isLoading());
        const res = await users(params);

        setData(res.data.data.data);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchCompanyMembers();
  }, []);

  return (
    <Layout>
      <ManageMembersSettings
        data={data}
        setData={setData}
        isAddMemberModalShown={isAddMemberModalShown}
        setAddMemberModalStatus={setAddMemberModalStatus}
        editMemberModalData={editMemberModalData}
        setEditMemberModalData={setEditMemberModalData}
        manageMemberModalData={manageMemberModalData}
        setManageMemberModalData={setManageMemberModalData}
        currentUserIdToBeActivated={currentUserIdToBeActivated}
        setCurrentUserIdToBeActivated={setCurrentUserIdToBeActivated}
        activeConfirmationModalKey={activeConfirmationModalKey}
        setActiveConfirmationModalKey={setActiveConfirmationModalKey}
        isConfirmationModalShown={isConfirmationModalShown}
        setConfirmationModalStatus={setConfirmationModalStatus}
      />
    </Layout>
  );
};

export default ManageMembers;
