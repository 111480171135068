import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 415px;
  height: auto;
  padding: 20px 28px 40px 28px;
`;

export const closeButtonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const closeButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: unset;
  background-color: ${colors.TRANSPARENT};
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const closeIconStyles = css`
  font-size: 24px;
  color: ${colors.PRIMARY_BLACK};
`;

export const imageStyles = css`
  width: 160px;
  height: 160px;
`;

export const titleStyles = css`
  margin: 20px 0 0 0;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
`;

export const descriptionStyles = css`
  margin: 12px 0 0 0;
  text-align: center;
  font-size: 15px;
`;

export const buttonStyles = css`
  margin: 36px 0 0 0;
`;
