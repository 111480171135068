import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const cardStyles = css`
  width: 721px;
  min-height: 565px;
  padding: 32px;
`;

export const modalTitleAndCloseModalButtonStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const modalTitleStyles = css`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 24px;
  color: ${colors.PRIMARY_BLUE};
`;

export const modalCloseButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: unset;
  background-color: ${colors.TRANSPARENT};
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  i {
    font-size: 24px;
    color: ${colors.LIGHT_BLUE};
  }
`;

export const inputSearchStyles = css`
  width: 345px;
  margin: 16px 0 0 0;
`;

export const jobListContainerStyles = css`
  width: 100%;
  height: 381px;
  margin: 24px 0 0 0;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const jobListStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 0 0;
  padding: 0 0 8px 0;
  border-bottom: 1px solid rgba(29, 61, 113, 0.1);

  &:first-of-type {
    margin: 0;
  }
`;

export const titleAndLocationContainerStyles = css`
  width: 178px;
`;

export const jobTitleStyles = css`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
`;

export const locationContainerStyles = css`
  margin: 12px 0 0 0;
`;

export const iconAndLabelContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const labelStyles = css`
  margin: 0 0 0 5px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const deadlineContainerStyles = css`
  width: 160px;
`;

export const dateStyles = css`
  padding: 0 0 0 20px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.RED};
`;

export const selectJobButtonStyles = css`
  font-size: 14px;
`;
