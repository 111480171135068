import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const containerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 25px 0;
  border-bottom: 1px solid rgba(29, 61, 113, 0.15);
`;

export const leftContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const selectContainerStyles = css`
  width: 165px;

  select {
    border-color: rgba(29, 61, 113, 0.05);
    background-color: rgba(29, 61, 113, 0.05);
  }
`;

export const multipleCheckboxButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  padding: 0;
  border: 1px solid rgba(29, 61, 113, 0.05);
  border-radius: 6px;
  background-color: rgba(29, 61, 113, 0.05);
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const multipleCheckboxIconStyles = css`
  color: ${colors.LIGHT_BLUE};
`;

export const applicantCounterStyles = css`
  margin: 0 0 0 22px;
  text-transform: capitalize;
  font-size: 16px;
`;

export const optionsButtonStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 78px;
  height: 40px;
  margin: 0 0 0 48px;
  padding: 0 12px;
  border: 1px solid rgba(29, 61, 113, 0.05);
  border-radius: 6px;
  background-color: rgba(29, 61, 113, 0.05);
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const optionsIconStyles = css`
  color: ${colors.LIGHT_BLUE};
`;

export const checkboxDropdownContainerStyles = css`
  position: relative;
`;

export const checkboxDropdownStyles = (isDropdownShown) => css`
  display: ${isDropdownShown ? 'block' : 'none'};
  position: absolute;
  width: 250px;
  height: 500px;
  margin: 5px 0 0 0;
  padding: 10px 5px;
  left: 48px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.WHITE};
  z-index: 50;
  overflow: scroll;
`;

export const dropdownTitleStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  padding: 0 12px;
  font-weight: 500;
`;

export const buttonListStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border-radius: 5px;
  border: none;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;

export const dropdownDivider = css`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: rgba(29, 61, 113, 0.15);
`;

export const rejectButtonStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.TRANSPARENT};
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.DANGER_RED};
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;
