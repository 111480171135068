/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useState } from 'react';

import {
  InputWithIcon,
  ShortText,
  Checkbox,
  Button,
  Icon,
  Input,
} from '../../../general';
import {
  cardStyles,
  sectionStyles,
  sectionTitleStyles,
  searchInputStyles,
  inputStyles,
  advancedFilterSectionStyles,
  advancedFiltersTitleContainerStyles,
  hideButtonStyles,
  reactSelectContainerStyles,
  reactSelectStyles,
  accordionStyles,
  accordionButtonStyles,
  accordionIconStyles,
  accordionContentStyles,
  checkboxContainerStyles,
  checkboxStyles,
  minimumAndMaximumContainerStyles,
  labelStyles,
  inputAndLabelContainerStyles,
  minimumAndMaximumInputStyles,
  minimumAndMaximumLabelStyles,
  lastEducationYearInputStyles,
  applyAndResetButtonContainerStyles,
  resetButtonStyles,
} from './SearchAndFilter.styles';

const SearchAndFilter = ({
  filterData,
  setFilterData,
  searchTerm,
  setSearchTerm,
  selectOptionsData,
}) => {
  const [isAdvancedFilterShown, setAdvancedFilterStatus] = useState(true);
  const [accordionData, setAccordionData] = useState({
    jobRole: false,
    workExperience: false,
    educationLevel: false,
    lastEducation: false,
    location: false,
  });
  const [checkboxSearchData, setCheckboxSearchData] = useState({
    jobRole: '',
    province: '',
  });

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAdvancedFilterToggle = () => {
    setAdvancedFilterStatus((prevState) => !prevState);
  };

  const skillsValueParser = () => {
    let parsedValue = [];
    if (typeof (filterData.skills) === 'object') {
      parsedValue = filterData.skills.map((d) => ({
        label: d,
        value: d,
      }));
    } else if (typeof (filterData.skills) === 'string') {
      parsedValue = [{ label: filterData.skills, value: filterData.skills }];
    }
    return parsedValue;
  };

  const handleSkillsChange = (d) => {
    setFilterData((prevState) => {
      if (d) {
        const parsedArr = d.map(({ value }) => value);

        return {
          ...prevState,
          skills: parsedArr,
        };
      }

      return {
        ...prevState,
        skills: [],
      };
    });
  };

  const handleAccordion = (accordionName) => {
    setAccordionData((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  const handleCheckboxSearch = (e) => {
    setCheckboxSearchData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRolesChange = (e) => {
    if (e.target.checked) {
      setFilterData((prevState) => ({
        ...prevState,
        roles: [...prevState.roles, e.target.name],
      }));
    } else {
      setFilterData((prevState) => {
        const newArr = prevState.roles.filter((d) => d !== e.target.name);

        return {
          ...prevState,
          roles: newArr,
        };
      });
    }
  };

  const handleMinAndMaxValueChange = (e) => {
    setFilterData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEducationLevelChange = (e) => {
    if (e.target.checked) {
      setFilterData((prevState) => ({
        ...prevState,
        educationLevel: [...prevState.educationLevel, e.target.name],
      }));
    } else {
      setFilterData((prevState) => {
        const newArr = prevState.educationLevel.filter(
          (d) => d !== e.target.name,
        );

        return {
          ...prevState,
          educationLevel: newArr,
        };
      });
    }
  };

  const handleProvincesChange = (e) => {
    if (e.target.checked) {
      setFilterData((prevState) => ({
        ...prevState,
        provinces: [...prevState.provinces, e.target.name],
      }));
    } else {
      setFilterData((prevState) => {
        const newArr = prevState.provinces.filter(
          (d) => d !== e.target.name,
        );

        return {
          ...prevState,
          provinces: newArr,
        };
      });
    }
  };

  const handleResetFilter = () => {
    setFilterData({
      skills: [],
      roles: [],
      educationLevel: [],
      minWorkExperienceInYears: 0,
      minWorkExperienceInMonths: 0,
      maxWorkExperienceInYears: 0,
      maxWorkExperienceInMonths: 0,
      lastEducationMinYears: 0,
      lastEducationMaxYears: 0,
      provinces: [],
    });
  };

  return (
    <div css={cardStyles}>
      <form>
        <div css={sectionStyles}>
          <ShortText styles={sectionTitleStyles}>
            Search name, Team name, or Location
          </ShortText>
          <InputWithIcon
            type="text"
            containerStyles={searchInputStyles}
            inputStyles={inputStyles}
            name="searchTerm"
            iconName="search-line"
            value={searchTerm}
            onChange={handleSearchTerm}
            placeholder="search"
          />
        </div>
        <div css={sectionStyles}>
          <div css={advancedFiltersTitleContainerStyles}>
            <ShortText styles={sectionTitleStyles}>advanced filters</ShortText>
            <button
              type="button"
              css={hideButtonStyles}
              onClick={handleAdvancedFilterToggle}
            >
              {isAdvancedFilterShown ? 'hide' : 'show'}
            </button>
          </div>
          <div css={reactSelectContainerStyles}>
            <Select
              id="skills"
              instanceId="skills"
              css={reactSelectStyles}
              classNamePrefix="select"
              name="skills"
              options={selectOptionsData.skillsOptions}
              value={skillsValueParser()}
              onChange={handleSkillsChange}
              isSearchable
              placeholder="skills"
              isMulti
            />
          </div>
          <div css={advancedFilterSectionStyles(isAdvancedFilterShown)}>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('jobRole')}
              >
                Job Role
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.jobRole)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.jobRole)}>
                <InputWithIcon
                  type="text"
                  inputStyles={inputStyles}
                  name="jobRole"
                  value={checkboxSearchData.jobRole}
                  onChange={handleCheckboxSearch}
                  iconName="search-line"
                  placeholder="Job Role"
                />
                <div css={checkboxContainerStyles}>
                  {selectOptionsData.rolePreferenceList
                    .filter(({ label }) => label
                      .toLowerCase()
                      .includes(checkboxSearchData.jobRole))
                    .map(({ label, value }) => (
                      <Checkbox
                        key={label}
                        containerStyles={checkboxStyles}
                        checkboxLabel={label}
                        name={value}
                        onChange={handleRolesChange}
                        checked={filterData.roles.includes(value)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('workExperience')}
              >
                Years of Work Experience
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.workExperience)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.workExperience)}>
                <div css={minimumAndMaximumContainerStyles}>
                  <ShortText styles={labelStyles}>minimum</ShortText>
                  <div css={inputAndLabelContainerStyles}>
                    <Input
                      type="number"
                      containerStyles={minimumAndMaximumInputStyles}
                      name="minWorkExperienceInYears"
                      value={filterData.minWorkExperienceInYears}
                      onChange={handleMinAndMaxValueChange}
                      placeholder="Number of"
                      min="1"
                    />
                    <ShortText styles={minimumAndMaximumLabelStyles}>
                      years
                    </ShortText>
                  </div>
                  <div css={inputAndLabelContainerStyles}>
                    <Input
                      type="number"
                      containerStyles={minimumAndMaximumInputStyles}
                      name="minWorkExperienceInMonths"
                      value={filterData.minWorkExperienceInMonths}
                      onChange={handleMinAndMaxValueChange}
                      placeholder="Number of"
                      min="1"
                    />
                    <ShortText styles={minimumAndMaximumLabelStyles}>
                      months
                    </ShortText>
                  </div>
                </div>
                <div css={minimumAndMaximumContainerStyles}>
                  <ShortText styles={labelStyles}>maximum</ShortText>
                  <div css={inputAndLabelContainerStyles}>
                    <Input
                      type="number"
                      containerStyles={minimumAndMaximumInputStyles}
                      name="maxWorkExperienceInYears"
                      value={filterData.maxWorkExperienceInYears}
                      onChange={handleMinAndMaxValueChange}
                      placeholder="Number of"
                      min="1"
                    />
                    <ShortText styles={minimumAndMaximumLabelStyles}>
                      years
                    </ShortText>
                  </div>
                  <div css={inputAndLabelContainerStyles}>
                    <Input
                      type="number"
                      containerStyles={minimumAndMaximumInputStyles}
                      name="maxWorkExperienceInMonths"
                      value={filterData.maxWorkExperienceInMonths}
                      onChange={handleMinAndMaxValueChange}
                      placeholder="Number of"
                      min="1"
                    />
                    <ShortText styles={minimumAndMaximumLabelStyles}>
                      months
                    </ShortText>
                  </div>
                </div>
              </div>
            </div>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('educationLevel')}
              >
                Education Level
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.educationLevel)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.educationLevel)}>
                <div>
                  {selectOptionsData.educationLevelList.map(
                    ({ label, value }) => (
                      <Checkbox
                        key={label}
                        containerStyles={checkboxStyles}
                        checkboxLabel={label}
                        name={value}
                        onChange={handleEducationLevelChange}
                        checked={filterData.educationLevel.includes(value)}
                      />
                    ),
                  )}
                </div>
              </div>
            </div>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('lastEducation')}
              >
                Last Education Year
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.lastEducation)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.lastEducation)}>
                <Input
                  type="number"
                  containerStyles={lastEducationYearInputStyles}
                  label="minimum"
                  name="lastEducationMinYears"
                  value={filterData.lastEducationMinYears}
                  onChange={handleMinAndMaxValueChange}
                  placeholder="Number of"
                  min="1"
                />
                <Input
                  type="number"
                  containerStyles={lastEducationYearInputStyles}
                  label="maximum"
                  name="lastEducationMaxYears"
                  value={filterData.lastEducationMaxYears}
                  onChange={handleMinAndMaxValueChange}
                  placeholder="Number of"
                  min="1"
                />
              </div>
            </div>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('location')}
              >
                Location
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.location)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.location)}>
                <InputWithIcon
                  type="text"
                  inputStyles={inputStyles}
                  name="provinces"
                  value={checkboxSearchData.province}
                  onChange={handleCheckboxSearch}
                  iconName="search-line"
                  placeholder="location"
                />
                <div css={checkboxContainerStyles}>
                  {selectOptionsData.provinceList
                    .filter(({ label }) => label
                      .toLowerCase()
                      .includes(checkboxSearchData.province.toLowerCase()))
                    .map(({ label, value }) => (
                      <Checkbox
                        key={label}
                        containerStyles={checkboxStyles}
                        checkboxLabel={label}
                        name={value.toString()}
                        onChange={handleProvincesChange}
                        checked={filterData.provinces.includes(value.toString())}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={applyAndResetButtonContainerStyles}>
          <Button
            type="button"
            styles={resetButtonStyles}
            size="FULL"
            color="LIGHT_BLUE"
            onClick={handleResetFilter}
            inverted
          >
            reset filters
          </Button>
        </div>
      </form>
    </div>
  );
};

SearchAndFilter.propTypes = {
  filterData: PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    minWorkExperienceInYears: PropTypes.number,
    minWorkExperienceInMonths: PropTypes.number,
    maxWorkExperienceInYears: PropTypes.number,
    maxWorkExperienceInMonths: PropTypes.number,
    educationLevel: PropTypes.arrayOf(PropTypes.string),
    lastEducationMinYears: PropTypes.number,
    lastEducationMaxYears: PropTypes.number,
    provinces: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFilterData: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  selectOptionsData: PropTypes.shape({
    skillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    rolePreferenceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    educationLevelList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    provinceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default SearchAndFilter;
