import { useState, useContext } from 'react';

import Layout from '../../../components/layout/Layout';
import { ChangeEmailSettings } from '../../../components/pages';
import { changeEmailRequest } from '../../../api/v1/post';
import { LoadingContext } from '../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../reducers/loadingReducers';
import { isShow } from '../../../reducers/snackbarReducers';
import { SnackbarContext } from '../../../contexts/snackbarContext';

const ChangeEmail = () => {
  const { dispatch } = useContext(LoadingContext);
  const snackbarContext = useContext(SnackbarContext);
  const [formData, setFormData] = useState({
    currentEmail: '',
    newEmail: '',
  });
  const [changeEmailStatus, setChangeEmailStatus] = useState({
    isSubmitting: false,
    isSuccessModalShown: false,
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetForm = () => {
    setFormData({
      currentEmail: '',
      newEmail: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading());
    setChangeEmailStatus((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    try {
      await changeEmailRequest({
        email: formData.newEmail,
        redirect: 'company-portal',
      });

      handleResetForm();
      setChangeEmailStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isSuccessModalShown: true,
      }));
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to request change email.'));
      handleResetForm();
      setChangeEmailStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
    } finally {
      dispatch(isNotLoading());
    }
  };

  return (
    <Layout>
      <ChangeEmailSettings
        formData={formData}
        changeEmailStatus={changeEmailStatus}
        setChangeEmailStatus={setChangeEmailStatus}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default ChangeEmail;
