/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useContext } from 'react';
import { convertToHTML } from 'draft-convert';

import formValidator from '../formValidator';
import {
  Container,
  Button,
  LabelAndValue,
  ShortText,
} from '../../../general';
import { thousandsSeparator, numeralTextConverter } from '../../../../helpers/parser';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow, isNotShow } from '../../../../reducers/snackbarReducers';
import {
  contentStyles,
  bodyStyles,
  rowStyles,
  labelAndValueStyles,
  labelStyles,
  perksAndBenefitsContainerStyles,
  perksAndBenefitsStyles,
  perksAndBenefitsListStyles,
  footerStyles,
} from './JobSummary.styles';

const JobSummary = ({
  setCurrentStep,
  formData,
  optionsData,
  handleSubmit,
  isSubmitting,
}) => {
  const { dispatch } = useContext(SnackbarContext);

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };

  const requiredSkillsParser = (requiredSkills) => {
    const parsedRequiredSkills = requiredSkills.join(', ');

    return parsedRequiredSkills;
  };

  const textEditorParser = (editorState) => (
    ReactHtmlParser(convertToHTML(editorState.getCurrentContent()))
  );

  const handleConfirmAndPublish = () => {
    const isFormEligibleToSubmit = formValidator(formData, dispatch, isShow);

    if (isFormEligibleToSubmit) {
      handleSubmit();
      dispatch(isNotShow());
    }
  };

  return (
    <Container contentWidthType="NORMAL" contentStyles={contentStyles}>
      <div css={bodyStyles}>
        <div css={rowStyles}>
          <LabelAndValue
            styles={labelAndValueStyles}
            label="job title"
            value={formData.jobTitle}
          />
          <LabelAndValue
            styles={labelAndValueStyles}
            label="job function"
            value={optionsData.jobFunctionsOptions[formData.jobFunction]?.label}
          />
        </div>
        <div css={rowStyles}>
          <LabelAndValue
            styles={labelAndValueStyles}
            label="job level"
            value={optionsData.jobLevelsOptions[formData.jobLevel]?.label}
          />
          <LabelAndValue
            styles={labelAndValueStyles}
            label="employment type"
            value={
              optionsData.employmentTypesOptions[formData.employmentType]?.label
            }
          />
        </div>
        <div css={rowStyles}>
          <LabelAndValue
            styles={labelAndValueStyles}
            label="number of people you hiring"
            value={formData.numberOfPeople}
          />
          <LabelAndValue
            styles={labelAndValueStyles}
            label="deadline of application"
            value={formData.applicationDeadline}
          />
          <LabelAndValue
            styles={labelAndValueStyles}
            label="minimum education"
            value={
              optionsData.minimumEducationOptions[formData.minimumEducation]
                ?.label
            }
          />
        </div>
        {formData.requiredSkills.length !== 0 && (
          <div css={rowStyles}>
            <LabelAndValue
              styles={labelAndValueStyles}
              label="required skills"
              value={requiredSkillsParser(formData.requiredSkills)}
            />
          </div>
        )}
        <div css={rowStyles}>
          <LabelAndValue
            valueType="HTML"
            label="job description"
            value={textEditorParser(formData.jobDescription)}
          />
        </div>
        <div css={rowStyles}>
          <LabelAndValue
            valueType="HTML"
            label="qualification / requirements"
            value={textEditorParser(formData.qualificationAndRequirements)}
          />
        </div>
        <div css={rowStyles}>
          <LabelAndValue
            styles={labelAndValueStyles}
            label="country"
            value={optionsData.countryOptions[formData.country]?.label}
          />
          <LabelAndValue
            styles={labelAndValueStyles}
            label="region / province / state"
            value={optionsData.regionOptions[formData.region]?.label}
          />
          <LabelAndValue
            styles={labelAndValueStyles}
            label="city"
            value={optionsData.cityOptions[formData.city]?.label}
          />
        </div>
        {formData.isWorkFromHome && (
          <div css={rowStyles}>
            <LabelAndValue
              valueType="LONG"
              label="availability"
              value="this job can be done remotely (work from home)"
            />
          </div>
        )}
        {formData.isWillingToRelocate && (
          <div css={rowStyles}>
            <LabelAndValue
              valueType="LONG"
              label="relocation"
              value="accept candidates willing to relocate"
            />
          </div>
        )}
        {formData.currency && formData.salaryRangeFrom && (
          <div css={rowStyles}>
            <LabelAndValue
              label="expected salaries"
              value={`${formData.currency} ${thousandsSeparator(
                numeralTextConverter(formData.salaryRangeFrom),
              )} -  ${formData.currency} ${thousandsSeparator(
                numeralTextConverter(formData.salaryRangeUntil),
              )}`}
            />
          </div>
        )}
        {formData.perksAndBenefits.length !== 0 && (
          <div css={perksAndBenefitsContainerStyles}>
            <ShortText styles={labelStyles}>perks and benefit</ShortText>
            <ul css={perksAndBenefitsStyles}>
              {formData.perksAndBenefits.map((d) => (
                <li key={d.id} css={perksAndBenefitsListStyles}>
                  {d.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div css={footerStyles}>
        <Button
          type="button"
          size="S"
          color="LIGHT_BLUE"
          onClick={handlePreviousStep}
          inverted
        >
          back
        </Button>
        <Button
          type="button"
          size="XL"
          color="LIGHT_BLUE"
          onClick={handleConfirmAndPublish}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'submitting...' : 'confirm & publish now!'}
        </Button>
      </div>
    </Container>
  );
};

JobSummary.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    numberOfPeople: PropTypes.string,
    applicationDeadline: PropTypes.string,
    minimumEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
    jobDescription: PropTypes.shape({}),
    qualificationAndRequirements: PropTypes.shape({}),
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
    currency: PropTypes.string,
    salaryRangeFrom: PropTypes.string,
    salaryRangeUntil: PropTypes.string,
    perksAndBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  optionsData: PropTypes.shape({
    jobFunctionsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    jobLevelsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    employmentTypesOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    minimumEducationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    requiredSkillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    companyBenefitsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default JobSummary;
