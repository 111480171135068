/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';
import { Container, Text } from '../../general';

import {
  containerStyles,
  titleTextStyles,
  pointContainerStyles,
  pointTitleStyles,
  pointTextStyles,
  // contentContainerStyles,
  contentContainerTncStyles,
  // contentDescriptionStyles,
} from './Agreement.styles';
import { LocaleContext } from '../../../contexts/localeContext';
import { signNda } from '../../../api/v1/post';

const Agreement = ({ agreementId }) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const idn = locale === 'id';
  const location = useLocation();
  const url = location.pathname;

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleOnClick = async () => {
    await signNda(agreementId);

    history.push(`/${locale}/thankyou`);
  };

  const TnCs = !idn ? [
    'You, a Hiring Partner as defined below; and',
    'We, Hacktiv8, which are referred to individually as a/the “Party” and collectively as “Parties”.',
  ]
    : [
      'Anda, Hiring Partner sebagaimana didefinisikan di bawah ini; dan',
      'yang disebut secara individual sebagai “Pihak” dan secara bersama sebagai “Para Pihak”.',
    ];

  const by = !idn ? [
    'Applying to become a Hiring Partner',
    ' Becoming a Hiring Partner,',
    'Accessing our database, and/or',
    'Using our Services,',

  ]
    : [
      'Mendaftar untuk menjadi Hiring Partner',
      'Menjadi Hiring Partner,',
      'Mengakses database kami, dan/atau',
      'Menggunakan Layanan kami,',
    ];

  const SnK = !idn ? [
    'That these T&Cs constitute a legally binding agreement between you and Hacktiv8 effective as of the date of signing, and',
    'That you have carefully and thoroughly read, understood and accepted, and hence are bound by and subject to (applied to Hacktiv8 as well), all provisions of these T&Cs, and its changes and alterations.',

  ]
    : [
      'Bahwa S&K ini merupakan perjanjian yang mengikat secara hukum antara Anda dan Hacktiv8 serta berlaku sejak ditandatangani, dan',
      'Bahwa Anda telah membaca, memahami dan menerima dengan cermat dan sepenuhnya, dan oleh karena itu Anda terikat oleh dan tunduk (berlaku untuk Hacktiv8 juga) kepada semua ketentuan dari S&K ini, serta penggantian dan perubahannya.',
    ];

  const definedTerms = !idn ? [
    {
      title: '“Authorization”',
      desc: 'means any written approval, consent, license, permit, action, or other authorization from, or filling, notification with or to, any governmental body, creditor, or any other party.',
    },
    {
      title: '“Force Majeure”',
      desc: 'means any severe act, event or cause beyond the reasonable human’s control, including but not limited to fires, floods, earthquakes, typhoons, nationalization, war, riots, rebellions, disease outbreaks, labor strikes, changes in government regulations, sabotage, natural disasters, and other security problems, in accordance with applicable laws and regulations.',
    },
    {
      title: '“Graduates”',
      desc: 'means our student(s) who have fulfilled and passed our internal requirements and passed all learning processes (including examinations) in our institution whom you will recruit for employment. This definition applies for graduates with whom you have initiated contact as well as graduates who submit job applications to you independently.',
    },
    {
      title: '“Hiring Partner“',
      desc: 'means a company, organization, or entity in any other form that collaborates with Hacktiv8 to get priority in recruiting Hacktiv8 for such company, organization or entity by complying with the terms and conditions in these T&Cs.',
    },
    {
      title: '“Probation Period”',
      desc: 'means a mutually agreed upon (by you and Graduates) duration of time in which the Graduate’s ability to meet certain performance levels will be observed and assessed by you.',
    },
    {
      title: '“Recruitment Process”',
      desc: 'means all internal or external processes that you and/or your company carries out to entice, attract, shortlist, select and/or hire Graduates for employment purposes (permanent or otherwise). For the avoidance of doubt, Recruitment Process starts at the date when you are connected with our Graduates for the purpose of recruiting them for employment in your company or organization, either originating from you or through an independent job application by our Graduates, and ends:',
      array: [
        {
          title: 'a. ',
          desc: 'In the case you and a Graduate decide to enter into an employment relationship, at the date of signing of an employment contract (or other contract or documentation affecting employment relationship of the same) between you and the Graduate or the first day of work of a Graduate and/or organization (whichever is earlier), OR',
        },
        {
          title: 'b. ',
          desc: 'In the case where you and a Graduate decide not to enter into an employment relationship, at the date where a notification of refusal for employment (or other document, statement, or notification communicating the same nature) is sent and/or informed by you to the Graduate (and/or vice versa, from the Graduate to you), OR',
        },
        {
          title: 'c. ',
          desc: 'At the date that is 3 (three) months after the date when employment-related interview and/or test and/or examination of a Graduate is conducted by you for the purpose of this Recruitment Process.',
        },
      ],
      text: 'If the Hiring Partner and Graduate decide to enter into an employment relationship, all matters relating to the employment relationship between the Hiring Partner and the Graduate after the Recruitment Process ends becomes a private matter between the Hiring Partner and such Graduate, and Hacktiv8 will not interfere or be involved in such employment relationship.',
    },
    {
      title: '“Services”',
      desc: 'means the facilities we provide to you as our Hiring Partner for the purpose of employing our Graduates in your company or organization, which include:',
      array: [
        {
          title: 'a. ',
          desc: 'Access to Job Portal,',
        },
        {
          title: 'b. ',
          desc: 'Recommendations for "Graduates" names and information as displayed on the Job Portal,',
        },
        {
          title: 'c. ',
          desc: 'Invitations to Graduates related events, including Graduation Day, and',
        },
        {
          title: 'd. ',
          desc: 'Dedicated Account Manager.',
        },
      ],
    },
    {
      title: '“You”',
      desc: 'means you, Hacktiv8’s Hiring Partner based on these T&Cs.',
    },
  ]
    : [
      {
        title: '“Persetujuan”',
        desc: 'berarti setiap persetujuan tertulis, kesepakatan, lisensi, izin, tindakan, atau pemberian wewenang lain dari, atau pendaftaran, pemberitahuan pada atau kepada, setiap badan pemerintahan, kreditor, atau setiap pihak lain.',
      },
      {
        title: '“Keadaan Kahar“',
        desc: 'memiliki arti peristiwa atau kejadian di luar kekuasaan manusia, termasuk tetapi tidak terbatas pada kebakaran, banjir, gempa bumi, topan, perang, kerusuhan, pemberontakan, wabah penyakit, pemogokan buruh, perubahan peraturan pemerintah, sabotase, bencana alam, dan gangguan keamanan lainnya, sesuai peraturan perundang-undangan yang berlaku.',
      },
      {
        title: '“Lulusan“',
        desc: 'berarti murid-murid kami yang telah memenuhi dan lulus ketentuan internal kami dan proses pembelajaran (termasuk ujian-ujian) dari institusi kami yang akan Anda rekrut untuk bekerja. Definisi ini berlaku baik pada lulusan yang Anda kontak terlebih dahulu maupun lulusan yang mengajukan lamaran pekerjaan secara mandiri kepada Anda.',
      },
      {
        title: '“Hiring Partner“',
        desc: 'berarti perusahaan, organisasi, atau entitas dalam bentuk lainnya yang bekerjasama dengan Hacktiv8 untuk mendapatkan prioritas dalam merekrut Lulusan Hacktiv8 dengan menaati syarat dan ketentuan dalam S&K ini.',
      },
      {
        title: '“Masa Percobaan”',
        desc: 'berarti durasi waktu yang disepakati bersama (oleh Anda dan Lulusan) di mana kemampuan Lulusan untuk memenuhi tingkat kinerja tertentu akan diamati dan dinilai oleh Anda.',
      },
      {
        title: 'Proses Perekrutan”',
        desc: 'berarti semua proses internal dan eksternal yang Anda dan/atau perusahaan lakukan untuk menarik, membuat daftar pendek, memilih, dan mempekerjakan Lulusan untuk tujuan pekerjaan (permanen atau sebaliknya). Untuk menghindari keraguan, Proses Perekrutan dimulai pada tanggal di mana Anda terhubung dengan Lulusan kami untuk tujuan merekrut mereka untuk pekerjaan di perusahaan atau organisasi Anda, baik yang dimulai dari Anda atau dari Lulusan kami melalui lamaran pekerjaan secara mandiri, dan berakhir:',
        array: [
          {
            title: 'a. ',
            desc: 'Apabila Anda dan Lulusan memutuskan untuk masuk ke dalam hubungan kerja, pada tanggal penandatanganan kontrak kerja (atau kontrak atau dokumentasi lain yang memberlakukan hubungan kerja yang sama) antara Anda dan Lulusan atau hari kerja pertama Lulusan, yang mana saja yang terlebih dahulu, ATAU',
          },
          {
            title: 'b. ',
            desc: 'Apabila Anda dan Lulusan memutuskan untuk tidak masuk ke dalam hubungan kerja, pada tanggal di mana pemberitahuan penolakan pekerjaan (atau dokumen, pernyataan, yang mengkomunikasikan hal yang sifatnya yang sama) dikirim atau diberitahukan oleh Anda kepada Lulusan (dan/atau sebaliknya, oleh Lulusan kepada Anda) ATAU',
          },
          {
            title: 'c. ',
            desc: 'Pada tanggal yang jatuhnya 3 (tiga) bulan setelah tanggal ketika wawancara atau ujian atau pemeriksaan yang terkait pekerjaan atau pemeriksaan Lulusan dilakukan oleh Anda untuk tujuan Proses Perekrutan ini.',
          },
        ],
        text: 'Apabila Hiring Partner dan Lulusan memutuskan untuk masuk ke dalam hubungan kerja, maka segala hal terkait hubungan kerja antara Hiring Partner dan Lulusan setelah Proses Perekrutan berakhir merupakan ranah pribadi antara Hiring Partner dan Lulusan tersebut, dan Hacktiv8 tidak akan ikut campur maupun terlibat dalam hubungan kerja tersebut.',
      },
      {
        title: '“Layanan“',
        desc: 'berarti fasilitas yang kami sediakan kepada Anda sebagai Hiring Partner kami untuk tujuan mempekerjakan Lulusan kami di perusahaan atau organisasi Anda, yaitu di antaranya:',
        array: [
          {
            title: 'a. ',
            desc: 'Akses Job Portal,',
          },
          {
            title: 'b. ',
            desc: 'Rekomendasi nama dan informasi Lulusan yang ditampilkan pada Job Portal,',
          },
          {
            title: 'c. ',
            desc: 'Undangan event-event terkait Lulusan, di antaranya Graduation Day, dan',
          },
          {
            title: 'd. ',
            desc: 'Dedicated Account Manager',
          },
        ],
      },
      {
        title: '“Anda”',
        desc: 'berarti anda, Hiring Partner Hacktiv8 berdasarkan S&K ini.',
      },
    ];

  const scope = !idn ? [
    'We agree to provide our Services to you as our Hiring Partner, for the purpose of our Graduates’ employment in your company or organization.',
    'You agree to take into consideration the qualifications of, conduct interviews with, and employ Graduates at your company or organization in accordance with your internal hiring policy; you undertake to ensure that such internal hiring policy shall always conform with the provisions of these T&Cs with regards to the employment of our Graduates.',
  ] : [
    'Kami setuju untuk menyediakan Layanan kami kepada Anda sebagai Hiring Partner kami, untuk tujuan mempekerjakan Lulusan kami di perusahaan atau organisasi Anda.',
    'Anda setuju untuk mempertimbangkan kualifikasi, melakukan wawancara dengan, dan mempekerjakan Lulusan di perusahaan atau organisasi Anda sesuai dengan kebijakan perekrutan internal Anda; Anda sepakat untuk memastikan bahwa kebijakan perekrutan internal tersebut harus selalu mematuhi ketentuan-ketentuan dari S&K ini sehubungan dengan pekerjaan Lulusan kami.',
  ];

  const rno = !idn ? [
    {
      title: 'Hacktiv8’s Obligations:',
      array: [
        {
          title: 'a.',
          desc: 'To maintain the confidentiality of the Recruitment Process carried out by the Hiring Partner for Hacktiv8 Graduates, including the decisions and reasons for acceptance or refusal of Graduates during the course of such Recruitment Process.',
        },
        {
          title: 'b.',
          desc: 'To give Hiring Partner access to Hacktiv8’s database of Graduates after the Hiring Partner has passed Hacktiv8’s legal and administration procedure.',
        },
        {
          title: 'c.',
          desc: 'To provide the Hiring Partner, through Hacktiv8’s database in the Job Portal, a list of Graduates that, in Hacktiv8’s best judgment, are Graduates that are fitting or suitable for employment in Hiring Partner’s company or organisation.',
        },
        {
          title: 'd.',
          desc: 'To maintain the confidential information of Hiring Partner and Graduates, by way of not disclosing to other parties save for Hacktiv8’s own board of management (commissioners and directors), necessary employees (on a need-to-know basis only), as well as internal and/or external auditors and audit institutions/organizations without permission of the confidential information’s owner.',
        },
      ],

    },
    {
      title: 'Hacktiv8’s Rights:',
      array: [
        {
          title: 'a.',
          desc: 'To decide, based on Hacktiv8’s own best judgment, the suitability of Hiring Partners and the list of suitable Graduates to be informed to the Hiring Partner.',
        },
        {
          title: 'b.',
          desc: 'In the case where the Hiring Partner decides to give a formal employment offer to Graduates; to receive notification by the Hiring Partner of such offer no later than 14 (fourteen) calendar days after such formal employment offer is given to the Graduate.',
        },
        {
          title: 'c.',
          desc: 'In the case where the Hiring Partner decides to employ Graduates, Hacktiv8 may request a report of Graduate’s work performance at Hiring Partner’s company or organisation for the purpose of developing the quality of future Graduates.',
        },
        {
          title: 'd.',
          desc: 'To make changes or alterations to any provisions of these T&Cs. If the changes are material, Hacktiv8 shall give prior notice to the Hiring Partner at least 14 (fourteen) calendar days prior to the date on which such change or alteration is to take effect, which notice can be given through email. During this period, Hiring Partners may submit their opinions or objections to Hacktiv8 in writing. However, if during that time period there are no objections from the Hiring Partner, then the Hiring Partner is deemed to have known and is willing to comply with the provisions of the revised T&C.',
        },
        {
          title: 'e.',
          desc: 'To show the Hiring Partner’s company logo on Hacktiv8’s promotional materials, with Hiring Partner’s approval.',
        },
        {
          title: 'f.',
          desc: 'If there is any violation of the T&C by the Hiring Partner and/or if based on the objective assessment of Hacktiv8 the Hiring Partner no longer meets the qualifications or is indicated to be unable to comply with any provisions in these T&Cs, to issue warning(s) in writing to the Hiring Partner to immediately make improvements within reasonable time. If the Hiring Partner does not make improvements even though it has previously been given 2 (two) consecutive warnings, Hacktiv8 has the right to terminate its cooperation with Hiring Partner in accordance with the provisions of these T&Cs.',
        },
      ],

    },
    {
      title: 'Hiring Partner’s Obligations:',
      array: [
        {
          title: 'a.',
          desc: 'To maintain the confidentiality, by way of not disclosing to other party save for the Hiring Partner’s own board of management (commissioners and directors), and necessary employees (on a need-to-know basis only) of Graduates and other related information related to the Recruitment Process, including but not limited to Graduates’ biodata, CVs, interview and test results, identity details, and recruitment result, as well as any data and information relating to Hacktiv8’s Graduates database.',
        },
        {
          title: 'b.',
          desc: 'In the case where the Hiring Partner decides to give a formal employment offer to Graduates; to notify Hacktiv8 of such offer no later than 14 (fourteen) calendar days after such formal employment offer is given to the Graduate.',
        },
        {
          title: 'c.',
          desc: 'In the case where the Hiring Partner decides to offer employment, the Hiring Partner is obliged to provide salary to the Graduate as agreed with the Graduate and applicable legal provisions. Hacktiv8 recommends a gross monthly salary of minimum Rp10,000,000,00 (ten million Rupiah) to the Graduate of coding bootcamp, and minimum Rp8,000,000,00 (eight million Rupiah) to the Graduate of data science or digital marketing bootcamp.',
        },
        {
          title: 'd.',
          desc: 'In the case where the Hiring Partner decides to employ Graduates, Hacktiv8 may request information from the Hiring Partner regarding the employment status of the recruited Graduate (including the name of the recruited Graduate, position, and start date). The Hiring Partner may decide to provide such information to Hacktiv8 at the Hiring Partner’s discretion.',
        },
        {
          title: 'e.',
          desc: 'If there are any changes to the Hiring Partner’s company data (change of company name, etc.) or changes to correspondence data (PIC name, email, contact address, Job Portal user account, etc.), the Hiring Partner must immediately notify their Dedicated Account Manager of such changes to be delivered to Hacktiv8.',
        },
      ],

    },
    {
      title: 'Hiring Partner’s Rights:',
      array: [
        {
          title: 'a.',
          desc: 'To receive from Hacktiv8, through Hacktiv8’s database in the Job Portal, a list Graduates that are fitting or suitable for employment in Hiring Partner’s company or organization.',
        },
        {
          title: 'b.',
          desc: 'To have access to Hacktiv8’s database of Graduates after the Hiring Partner has passed Hacktiv8’s legal and administration procedure.',
        },
        {
          title: 'c.',
          desc: 'Subject to the provisions of these T&Cs, to employ its internal hiring policy during Recruitment Process and make independent decisions on the employment of the Graduates. For the avoidance of doubt, the Hiring Partner reserves the right to decide whether to recruit the Graduate it has selected or not, and Hacktiv8 will not interfere in the Recruitment Process.',
        },
      ],
    },
  ] : [
    {
      title: 'Kewajiban Hacktiv8:',
      array: [
        {
          title: 'a.',
          desc: 'Menjaga kerahasiaan atas Proses Perekrutan yang dilakukan oleh Hiring Partner atas Lulusan Hacktiv8, termasuk keputusan dan alasan penerimaan ataupun penolakan Lulusan, selama berlangsungnya Proses Perekrutan tersebut.',
        },
        {
          title: 'b.',
          desc: 'Memberikan kepada Hiring Partner akses ke database Lulusan setelah Hiring Partner lulus prosedur administrasi dan legal dari Hacktiv8.',
        },
        {
          title: 'c.',
          desc: 'Menyediakan kepada Hiring Partner, melalui database Hacktiv8 pada Job Portal, daftar Lulusan yang, menurut penilaian terbaik dari Hacktiv8, adalah Lulusan yang sesuai atau cocok untuk bekerja di perusahaan atau organisasi Hiring Partner',
        },
        {
          title: 'd.',
          desc: 'Menjaga kerahasiaan informasi rahasia Hiring Partner dan Lulusan, dengan cara tidak mengungkapkan kepada pihak lain kecuali jajaran manajemen Hacktiv8 sendiri (komisaris dan direksi), karyawan yang diperlukan (hanya berdasarkan kebutuhan), serta auditor dan lembaga/organisasi audit baik internal maupun eksternal tanpa izin dari pemilik informasi rahasia tersebut.',
        },
      ],

    },
    {
      title: 'Hak Hacktiv8:',
      array: [
        {
          title: 'a.',
          desc: 'Memutuskan, berdasarkan penilaian terbaik Hacktiv8 sendiri, kesesuaian Hiring Partner dan daftar Lulusan yang cocok untuk diinformasikan kepada Hiring Partner.',
        },
        {
          title: 'b.',
          desc: 'Apabila Hiring Partner memutuskan untuk memberikan penawaran kerja resmi kepada Lulusan; menerima pemberitahuan dari Hiring Partner mengenai hal tersebut selambat-lambatnya 14 (empat belas) hari kalender setelah penawaran kerja resmi tersebut diberikan kepada Lulusan.',
        },
        {
          title: 'c.',
          desc: 'Apabila Hiring Partner memutuskan untuk mempekerjakan Lulusan, Hacktiv8 dapat meminta laporan kinerja Lulusan pada perusahaan atau organisasi Hiring Partner untuk keperluan pengembangan kualitas Lulusan kedepannya',
        },
        {
          title: 'd.',
          desc: 'Membuat penggantian atau perubahan terhadap ketentuan apa pun dari S&K ini. Apabila perubahannya material, maka Hacktiv8 akan memberikan pemberitahuan sebelumnya kepada Hiring Partner setidaknya 14 (empat belas) hari kalender sebelum tanggal dimana penggantian atau perubahan tersebut berlaku, pemberitahuan yang mana dapat diberikan melalui email. Selama jangka waktu tersebut, Hiring Partner dapat menyampaikan pendapat atau keberatannya kepada Hacktiv8 secara tertulis. Namun, apabila selama jangka waktu tersebut tidak ada keberatan dari Hiring Partner, maka Hiring Partner dianggap telah mengetahui dan bersedia untuk menaati ketentuan S&K yang telah direvisi.',
        },
        {
          title: 'e.',
          desc: 'Menampilkan logo perusahaan Hiring Partner pada materi promosi Hacktiv8, dengan persetujuan Hiring Partner',
        },
        {
          title: 'f.',
          desc: 'Apabila terdapat pelanggaran S&K oleh Hiring Partner dan/atau apabila berdasarkan penilaian objektif Hacktiv8 Hiring Partner tidak lagi memenuhi kualifikasi atau terindikasi tidak mampu menaati ketentuan manapun dalam S&K ini, memberikan peringatan(-peringatan) tertulis kepada Hiring Partner untuk segera melakukan perbaikan dalam waktu yang wajar. Apabila Hiring Partner tidak melakukan perbaikan walaupun sebelumnya telah diberikan peringatan sebanyak 2 (dua) kali berturut-turut, Hacktiv8 berhak untuk mengakhiri kerjasama dengan Hiring Partner sesuai ketentuan dalam S&K ini.',
        },
      ],

    },
    {
      title: 'Kewajiban Hiring Partner:',
      array: [
        {
          title: 'a.',
          desc: 'Menjaga kerahasiaan, dengan cara tidak mengungkapkan kepada pihak lain kecuali jajaran manajemen Hiring Partner sendiri (komisaris dan direksi), dan karyawan yang diperlukan (hanya berdasarkan kebutuhan), mengenai Lulusan dan informasi terkait lainnya yang berkaitan dengan Proses Perekrutan, termasuk tetapi tidak terbatas pada, biodata, CV, hasil wawancara dan tes, rincian identitas, dan hasil rekrutmen Lulusan, serta data dan informasi apapun terkait dengan database Lulusan Hacktiv8.',
        },
        {
          title: 'b.',
          desc: 'Apabila Hiring Partner memutuskan untuk memberikan penawaran kerja resmi kepada Lulusan; memberitahukan hal tersebut kepada Hacktiv8 selambat-lambatnya 14 (empat belas) hari kalender setelah penawaran kerja resmi tersebut diberikan kepada Lulusan',
        },
        {
          title: 'c.',
          desc: 'Apabila Hiring Partner memutuskan untuk menawarkan pekerjaan, Hiring Partner wajib memberikan gaji kepada Lulusan sesuai kesepakatan dengan Lulusan dan ketentuan hukum yang berlaku. Hacktiv8 merekomendasikan nominal gaji bulanan kotor minimum sebesar Rp10.000.000,00 (sepuluh juta Rupiah) kepada Lulusan coding bootcamp, dan minimum sebesar Rp8.000.000,00 (delapan juta Rupiah) kepada Lulusan data science bootcamp atau digital marketing bootcamp.',
        },
        {
          title: 'd.',
          desc: 'Apabila Hiring Partner memutuskan untuk mempekerjakan Lulusan, Hacktiv8 dapat meminta informasi dari Hiring Partner mengenai status pekerjaan Lulusan yang direkrut (di antaranya nama Lulusan yang direkrut, jabatan, dan tanggal mulai bekerja). Hiring Partner dapat memutuskan untuk memberikan informasi tersebut kepada Hacktiv8 berdasarkan diskresi Hiring Partner.',
        },
        {
          title: 'e.',
          desc: 'Apabila terdapat perubahan apapun terhadap data perusahaan Hiring Partner (perubahan nama perusahaan, dsb.) atau perubahan data korespondensi (nama PIC, email, alamat kontak, user account Job Portal, dsb.), Hiring Partner wajib segera memberitahukan perubahan tersebut kepada Dedicated Account Manager untuk disampaikan kepada Hacktiv8.',
        },
      ],

    },
    {
      title: 'Hak Hiring Partner:',
      array: [
        {
          title: 'a.',
          desc: 'Menerima dari Hacktiv8, melalui database Hacktiv8 pada Job Portal, daftar Lulusan yang, menurut penilaian terbaik dari Hacktiv8, adalah Lulusan yang sesuai atau cocok untuk bekerja di perusahaan atau organisasi Hiring Partner.',
        },
        {
          title: 'b.',
          desc: 'Mendapatkan akses ke database Lulusan Hacktiv8 setelah Hiring Partner lulus prosedur administrasi dan legal dari Hacktiv8.',
        },
        {
          title: 'c.',
          desc: 'Dengan tunduk pada ketentuan S&K ini, menggunakan kebijakan perekrutan internalnya selama Proses Perekrutan dan membuat keputusan yang independen dalam pemberian pekerjaan terhadap Lulusan. Untuk mencegah kerancuan, Hiring Partner berhak untuk memutuskan apakah akan merekrut Lulusan yang telah ia seleksi atau tidak, dan Hacktiv8 tidak akan ikut campur dalam Proses Perekrutan.',
        },
      ],

    },
  ];

  const warranties = !idn ? [
    {
      title: 'Each Party represents and warrants to the other Party on and from the date of these T&Cs, that:',
      array: [
        {
          title: 'a.',
          desc: 'Each Party is a limited liability company or legal entity duly incorporated and validly existing under their country of incorporation which has valid Authorizations and the power to carry on its business as it is being conducted and to execute, deliver and perform all of its obligations under these T&Cs and have submitted and/or presented accurate documents and information about their company;',
        },
        {
          title: 'b.',
          desc: 'The person signing these T&Cs on Hiring Partner’s behalf is a private citizen who has the capacity and authority to represent Hiring Partner in signing these T&Cs, such as the President Director, Director, Director’s Proxy, or other job titles with the legal authority to represent Hiring Partner based on your company’s Article of Association; and',
        },
        {
          title: 'c.',
          desc: 'All Authorizations to execute and deliver, and for the performance of these T&Cs have been obtained or taken and remain valid.',
        },
      ],
    },
    {
      title: 'Hiring Partner represents and warrants to Hacktiv8 on and from the date of these T&Cs, that:',
      array: [
        {
          title: 'a.',
          desc: 'Hiring Partner will use the Service in good faith and only use the Service for the purposes of their company’s Recruitment Process;',
        },
        {
          title: 'b.',
          desc: 'Hiring Partner will not misuse the Service, including to obtain Hacktiv8’s confidential information (including but not limited to curriculum, syllabus, education systems, trade secrets, etc.) to be provided, disseminated and/or used for your own or other parties’ needs without prior consent from Hacktiv8;',
        },
        {
          title: 'c.',
          desc: 'If any of these Representations and Warranties is incorrect or defaulted, you agree that Hacktiv8 has the right to cancel or terminate these T&Cs unilaterally and stop providing the Service to you in reference to Article 6 of these T&C.',
        },
      ],

    },
  ] : [
    {
      title: 'Masing-masing Pihak menyatakan dan menjamin kepada Pihak lainnya pada dan sejak tanggal S&K ini, bahwa:',
      array: [
        {
          title: 'a.',
          desc: 'Masing-masing Pihak adalah perusahaan atau badan hukum yang didirikan secara sah menurut hukum negara pendiriannya dan mempunyai Persetujuan dan kuasa yang sah untuk menjalankan kegiatan usaha sebagaimana saat ini dijalankannya serta untuk menandatangani, menyerahkan dan melaksanakan seluruh kewajibannya berdasarkan S&K ini serta telah menyerahkan dan/atau menunjukkan dokumen dan informasi yang sebenar-benarnya mengenai perusahaannya;',
        },
        {
          title: 'b.',
          desc: 'Orang yang menandatangani S&K ini atas nama Hiring Partner adalah warga negara privat yang memiliki kapasitas dan kewenangan untuk mewakili Hiring Partner dalam menandatangani S&K ini, yaitu Direktur Utama, Direktur, Kuasa Direksi, atau jabatan lain yang secara hukum sah mewakili Hiring Partner berdasarkan Anggaran Dasar Perusahaan Anda; dan',
        },
        {
          title: 'c.',
          desc: 'Seluruh Persetujuan untuk menandatangani, menyerahkan dan untuk pelaksanaan S&K ini telah diperoleh atau didapatkan dan tetap berlaku.',
        },
      ],
    },
    {
      title: 'Hiring Partner menyatakan dan menjamin kepada Hacktiv8 pada dan sejak tanggal S&K ini, bahwa:',
      array: [
        {
          title: 'a.',
          desc: 'Hiring Partner akan menggunakan Layanan dengan itikad baik dan hanya menggunakan Layanan untuk keperluan Proses Perekrutan;',
        },
        {
          title: 'b.',
          desc: 'Hiring Partner tidak akan menyalahgunakan Layanan, di antaranya untuk mendapatkan informasi rahasia Hacktiv8 (termasuk namun tidak terbatas pada kurikulum, silabus, sistem pendidikan, rahasia dagang dan sebagainya) untuk diberikan, disebarluaskan dan/atau digunakan untuk keperluan Hiring Partner sendiri atau pihak lain tanpa persetujuan terlebih dahulu dari Hacktiv8;',
        },
        {
          title: 'c.',
          desc: 'Apabila terdapat Pernyataan dan Jaminan manapun yang tidak benar atau wanprestasi, maka Anda sepakat bahwa Hacktiv8 berhak membatalkan atau memutuskan S&K ini secara sepihak serta menghentikan pemberian Layanan kepada Anda dengan mengacu pada Pasal 6 S&K ini.',
        },
      ],

    },
  ];

  const fee = !idn ? [
    'Hacktiv8 will not impose, and the Hiring Partner will not be subject to, any fee arising from these T&Cs.',
    'Notwithstanding the provision of Article 3.2.d and Article 4.1 of these T&Cs, Hacktiv8 undertakes to give a 14 (fourteen) calendar days prior written notice in the case of alteration of provision of Article 4.1, which notification may be given by email. During this period, Hiring Partners may submit their opinions or objections to Hacktiv8 in writing. However, if during that time period there are no objections from the Hiring Partner, then the Hiring Partner is deemed to have known and is willing to comply with the provisions of the revised T&C.',
  ] : [
    'Hacktiv8 tidak akan memungut, dan Hiring Partner tidak akan dikenakan, biaya apa pun yang timbul dari S&K ini.',
    'Tanpa mengesampingkan ketentuan Pasal 3.2.d dan Pasal 4.1 dari S&K ini, Hacktiv8 sepakat untuk memberikan pemberitahuan tertulis 14 (empat belas) hari kalender sebelumnya, dalam hal perubahan ketentuan Pasal 4.1, yang dapat diberikan melalui email. Selama jangka waktu tersebut, Hiring Partner dapat menyampaikan pendapat atau keberatannya kepada Hacktiv8 secara tertulis. Namun, apabila selama jangka waktu tersebut tidak ada keberatan dari Hiring Partner, maka Hiring Partner dianggap telah mengetahui dan bersedia untuk menaati ketentuan S&K yang telah direvisi.',
  ];

  const rni = idn ? [
    'Informasi dan data Lulusan yang diberikan oleh Hacktiv8 kepada Hiring Partner telah diberikan apa adanya dan sepanjang pengetahuan Hacktiv8. Hiring Partner berkewajiban untuk melakukan pemeriksaan latar belakang sendiri atas Lulusan untuk keperluan perekrutan/pekerjaan Hiring Partner. Hiring Partner dengan ini menjamin dan membebaskan Hacktiv8 dari kerugian apapun, baik material maupun immaterial, biaya, kerusakan, atau klaim dalam bentuk apapun yang timbul dari ketidakabsahan dan/atau ketidakakuratan data Lulusan.',
    'Para Pihak sepakat jika masing-masing Pihak melakukan wanprestasi dan/atau dinyatakan lalai dalam menjalankan kewajibannya berdasarkan S&K ini, maka Pihak tersebut wajib memberikan penggantian kepada Pihak lain yang menerima kerugian, baik materiil atau immateriil, atas kelalaian yang dilakukannya, termasuk namun tidak terbatas pada, (i) klarifikasi dan pengakuan tanggung jawab atas kelalaian masing-masing Pihak; dan (ii) menyatakan pihak lain tidak bertanggung jawab atas kelalaian yang dilakukan salah satu Pihak dan (iii) batas akhir pemenuhan atau pemulihan maksimal 14 (empat belas) hari kalender setelah surat peringatan yang diberikan oleh Pihak yang menderita kerugian, jika tidak ada pemulihan yang dilakukan atau dipulihkan mengacu pada Pasal 6.2 S&K ini.',
  ] : [
    'The information and data of Graduates that are provided by Hacktiv8 to the Hiring Partner has been given as is and to the best of Hacktiv8’s knowledge. The Hiring Partner is obliged to conduct their own background checks on any of the Graduates for the Hiring Partner’s recruitment/employment purposes. The Hiring Partner hereby indemnifies and holds harmless Hacktiv8 from any loss, whether material or immaterial, cost, damage, or claim of any kind arising out of the invalidity and/or inaccuracy of the data of the Graduates.',
    'The Parties agree that if either Party is defaulting and/or otherwise deemed to make negligence in carrying out its obligations under these T&Cs, then the party concerned shall carry out the remedy to the other Party who suffered the loss, whether material or immaterial, for the negligence it committed, including but not limited to, (i) clarification and recognition of responsibility for the negligence of each Party; and (ii) declaring that the other party is not responsible for negligence committed by one of the Parties and (iii) the deadline of the fulfillment or remedies maximum 14 (fourteen) calendar days after warning letter given by Party who suffered loss, if there is no remedies perform or recover pursuant to Article 6.2 of these T&Cs.',
  ];

  const termination = !idn ? [
    'The Services under this T&Cs are provided by Hacktiv8 in its full discretion. Hacktiv8 hereby has the full right to terminate this T&Cs and/or cease to provide the Services to you, in full or in part, temporarily or permanently, at any time and for any reason, including any violation of the T&Cs by Hiring Partners and/or if, based on Hacktiv8’s objective assessment, the Hiring Partner is no longer qualified or indicated to be unable to comply with any provisions in these T&Cs, by giving written notice to Hiring Partner in advance. You may terminate this T&Cs by providing written notice to Hacktiv8 14 (fourteen) calendar days prior to the intended termination date.',
    'These T&Cs shall also be automatically terminated, in the case where either Party is declared to be bankrupt by the authorized court decision or in a state of suspension of debt payments or no longer able to enforce these T&Cs under the provisions of the applicable laws.',
    'In connection with these T&Cs, the Parties agree to waive/exempt the provisions of Article 1266 of the Civil Law Code, which requires the need for any decision of judicial bodies to terminate these T&Cs',
  ] : [
    'Layanan berdasarkan S&K ini disediakan oleh Hacktiv8 berdasarkan kebijakan penuhnya. Hacktiv8 dengan ini memiliki hak penuh untuk menghentikan S&K ini dan/atau berhenti menyediakan Layanan kepada Anda, baik seluruhnya atau sebagian, baik sementara atau permanen, kapanpun dan dengan alasan apapun, termasuk di antaranya apabila terdapat pelanggaran S&K oleh Hiring Partner dan/atau apabila berdasarkan penilaian objektif Hacktiv8 Hiring Partner tidak lagi memenuhi kualifikasi atau terindikasi tidak mampu menaati ketentuan manapun dalam S&K ini, dengan memberikan pemberitahuan tertulis kepada Hiring Partner terlebih dahulu. Anda dapat mengakhiri S&K ini dengan memberikan pemberitahuan tertulis kepada Hacktiv8 14 (empat belas) hari kalender sebelum tanggal pengakhiran.',
    'S&K juga akan secara otomatis berakhir, apabila salah satu Pihak dinyatakan pailit oleh putusan pengadilan yang berwenang atau berada dalam keadaan penundaan kewajiban pembayaran utang atau tidak lagi dapat melaksanakan S&K ini berdasarkan ketentuan hukum yang berlaku.',
    'Sehubungan dengan S&K ini, Para Pihak sepakat untuk mengesampingkan ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata yang mensyaratkan diperlukannya putusan badan peradilan apapun untuk mengakhiri S&K ini.',
  ];

  const nonSolication = idn ? [
    'Para Pihak sepakat untuk tidak, secara langsung atau tidak langsung, secara individu atau atas nama orang lain atau entitas lain apa pun, membantu atau mencoba untuk meminta atau membujuk karyawan Pihak lain atau afiliasinya, termasuk anggota tim dan/atau instrukturnya, untuk meninggalkan pekerjaan mereka dalam rangka untuk menerima pekerjaan baru dengan Pihak yang membujuk tersebut.',
    'Ketentuan sebagaimana disebutkan dalam Pasal 7.1 di atas tidak berlaku apabila instruktur dan/atau anggota tim sudah tidak lagi berstatus sebagai karyawan Hacktiv8 atau karyawan Hiring Partner selama lebih dari 1 (satu) tahun.',
  ] : [
    'The Parties agree not to, directly or indirectly, individually or on behalf of any person or entity, aid or endeavor to solicit or induce any of other Party or its affiliates’ employees, including team members and/or instructors, to leave their employment in order to accept new employment with the persuading Party.',
    'The provision referred to in Article 7.1 above does not apply if the instructor and/or the team member has ceased to be Hacktiv8 or Hiring Partner’s employee for more than 1 (one) year.',
  ];

  const forceMajeure = idn ? [
    'Para Pihak memahami bahwa dalam pelaksanaan S&K ini terdapat kemungkinan terjadinya kendala karena Keadaan Kahar, dan Para Pihak akan mengusahakan upaya terbaiknya untuk melanjutkan pelaksanaan hak dan kewajibannya sebisa mungkin dalam Keadaan Kahar.',
    'Pihak yang tidak dapat melaksanakan kewajibannya dikarenakan oleh peristiwa Keadaan Kahar, wajib memberitahukan Pihak lainnya secara tertulis, selambat-lambatnya 14 (empat belas) hari kalender terhitung sejak tanggal terjadinya peristiwa tersebut. Apabila dalam waktu 7 (tujuh) hari kalender sejak diterimanya pemberitahuan tersebut, Pihak yang menerima pemberitahuan tidak menanggapi, maka akan dianggap bahwa pihak tersebut telah menerima adanya Keadaan Kahar.',
    'Segala permasalahan yang timbul sebagai akibat dari terjadinya Keadaan Kahar akan diselesaikan secara musyawarah dan mufakat oleh Para Pihak.',
  ] : [
    'The Parties understand that in the implementation of these T&Cs there may be obstacles due to Force Majeure, and the Parties will make their best efforts to continue the implementation of their rights and obligations as much as possible in the event of Force Majeure.',
    'The Party that cannot fulfill its obligations due to a Force Majeure event, must notify the other Party in writing, no later than 14 (fourteen) calendar days after the occurrence of Force Majeure. If within 7 (seven) calendar days since such notice, the receiving Party doesn’t respond, then it will be assumed that that Party has accepted the Force Majeure.',
    'Any issues arising as a result of the Force Majeure will be resolved by deliberation and consensus by the Parties.',
  ];

  const dispute = idn ? [
    'Apabila terjadi perselisihan sehubungan dengan pelaksanaan S&K ini, maka Para Pihak akan menyelesaikannya dengan jalan musyawarah untuk mencapai mufakat. Apabila perselisihan tersebut tidak dapat diselesaikan dalam jangka waktu 30 (tiga puluh) hari kalender sejak munculnya perselisihan, maka Para Pihak sepakat untuk menyelesaikan perselisihan tersebut melalui Badan Arbitrase Nasional Indonesia (BANI) di Jakarta, menurut prosedur beracara yang berlaku di dalam Badan Arbitrase tersebut, dengan menggunakan hukum Indonesia.',
    'Ketika perselisihan timbul dan ketika perselisihan sedang berada dalam proses hukum, kecuali untuk hal yang disengketakan, Para Pihak wajib tetap memenuhi kewajibannya masing-masing dan tetap berhak untuk memperoleh haknya berdasarkan S&K ini.',
  ] : [
    'If there is a dispute regarding the implementation of this T&Cs, the Parties will resolve it amicably to reach consensus. If the dispute cannot be resolved within 30 (thirty) calendar days since the dispute arises, the Parties agree to settle the dispute through the Indonesian National Arbitration Board (BANI) in Jakarta, according to the procedural procedures applicable to the Arbitration Board, using Indonesian law.',
    'When any dispute occurs and when any dispute is under proceedings, except for the matters in dispute, the Parties should continue to fulfil their respective obligations and will be entitled to exercise their rights under these T&Cs.',
  ];

  const language = idn ? [
    'S&K ini dibuat dalam bahasa Inggris dan bahasa Indonesia.',
    'Dalam hal terdapat perbedaan atau ketidakkonsistenan antara versi bahasa Inggris dan bahasa Indonesia, maka versi bahasa Indonesia yang akan berlaku.',
  ] : [
    'These T&Cs are made in English and Indonesian.',
    'In the event of any discrepancies or inconsistencies between the English version and Indonesian version thereof, the Indonesian version shall prevail.',
  ];

  const miscellaneous = idn ? [
    'S&K ini diatur dengan dan ditafsirkan sesuai dengan hukum negara Republik Indonesia.',
    'Jika terdapat suatu satu ketentuan dari S&K ini yang dinyatakan tidak sah, tidak berlaku atau tidak dapat dilaksanakan, secara keseluruhan atau sebagian, berdasarkan aturan hukum yang berlaku, maka keabsahan dan keterlaksanaan ketentuan lain dalam S&K ini tidak akan terpengaruh karenanya.',
    'Para Pihak tidak dapat mengalihkan seluruh atau sebagian hak dan kewajibannya berdasarkan S&K ini tanpa persetujuan tertulis dari Pihak lainnya dan setiap usaha untuk melakukannya adalah batal demi hukum.',
    'S&K ini berlaku dan mengikat Para Pihak sejak tanggal S&K ini ditandatangani.',
    'Hal-hal yang lain yang belum diatur di dalam S&K ini akan diatur selanjutnya oleh Para Pihak melalui pertemuan dan/atau secara tertulis. Hal-hal tersebut yang kemudian disetujui oleh Para Pihak harus diberlakukan sebagai bagian yang integral dan tidak dapat dipisahkan dari S&K ini dan oleh karena itu merupakan sebuah perikatan yang mengikat.',
  ] : [
    'These T&Cs shall be governed by and construed in accordance with the laws of the Republic of Indonesia.',
    'If any provision in these T&Cs shall be held to be illegal, invalid or unenforceable, in whole or in part, under the applicable rule of law, the legality and enforceability of the remainder of these T&Cs shall not be affected.',
    'The Parties shall not assign in whole or in part of their rights and obligations under these T&Cs without prior written approval from the other Party and any attempt to do so shall be void.',
    'These T&Cs shall take effect and bind the Parties as of the date of signing.',
    'Other matters that have not been stipulated under these T&Cs will be further stipulated by the Parties through meetings and/or in written forms. Such matters that are later agreed upon by the Parties shall be treated as an integral and inseparable part of these T&Cs and as such acts as a binding.',
  ];

  return (
    <>
      <Container
        contentWidthType="NORMAL"
        containerStyles={containerStyles}
      >
        <Text styles={titleTextStyles}>
          {idn ? 'Syarat dan Ketentuan Hiring Partner Hacktiv8' : 'Hacktiv8 Hiring Partner Terms and Condition'}
        </Text>
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles}>{!idn ? 'Please firstly read the following thoroughly and carefully:' : 'Mohon terlebih dulu membaca perihal berikut secara menyeluruh dan berhati-hati:'}</Text>
          <Text styles={pointTextStyles} textTransform="uppercase">
            {idn ? 'Syarat dan Ketentuan Hiring Partner (“S&K”) ini menetapkan ketentuan-ketentuan yang wajib Anda taati dalam menggunakan Layanan kami (sebagaimana didefinisikan di bawah) merekrut Lulusan kami untuk dipekerjakan di perusahaan atau organisasi Anda. Untuk menjadi Hiring Partner, Anda wajib menyetujui S&K ini.' : 'These Hiring Partner Terms and Conditions (“T&Cs”) set out the terms you are obliged to comply with in using our Services (as defined below) in recruiting our Graduates for employment in your company or organization. To be a Hiring Partner, you must agree to these T&Cs.'}
          </Text>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            {idn ? 'Dengan menandatangani S&K ini, Anda setuju bahwa Anda telah membaca dan memahami S&K ini secara menyeluruh, dan sebagai syarat penggunaan Layanan oleh Anda, Anda setuju untuk terikat oleh S&K ini secara penuh dan tanpa syarat.' : 'By signing these T&Cs, you agree that you have thoroughly read and understood these T&Cs, and as a condition to your use of the Services, you agree to be bound by these T&Cs in full and without reservation.'}
          </Text>
        </div>
        <div css={pointContainerStyles}>
          <Text styles={pointTextStyles} textTransform="lowercase">
            {!idn ? 'Hacktiv8 is an informal education provider specializing in computer education. Hacktiv8, Hacktiv8.com website and its domain are entities, names and domains affiliated with and operated by us, PT Hacktivate Teknologi Indonesia, a limited liability company, duly established and validly existing under Indonesian law, having its registered office at Jl. Sultan Iskandar Muda No. 7 Arteri Pondok Indah, Kebayoran Lama, South Jakarta (“Hacktiv8”, “we”, “our”, or “us”) and any pages, links, features, content, database, and application services that Hacktiv8 has to offer are fully operated and owned by Hacktiv8. The Services under these T&Cs include any services performed by us and the content provided on the website, without restrictions.' : 'Hacktiv8 adalah penyelenggara pendidikan informal yang mengkhususkan diri dalam pendidikan komputer. Hacktiv8, situs web Hacktiv8.com dan domainnya adalah badan, nama dan domain yang terafiliasi dan dioperasikan oleh kami, PT Hacktivate Teknologi Indonesia, sebuah perseroan terbatas, yang didirikan dan secara sah ada berdasarkan hukum Indonesia, memiliki kantor terdaftar di Jl. Sultan Iskandar Muda No. 7, Arteri Pondok Indah, Kebayoran Lama, Jakarta Selatan (“Hacktiv8” atau “kami”) dan halaman, tautan, fitur, konten, database dan layanan aplikasi yang selanjutnya Hacktiv8 tawarkan dimiliki dan dijalankan sepenuhnya oleh Hacktiv8. Layanan berdasarkan S&K ini meliputi setiap layanan yang dilakukan oleh kami dan konten yang diberikan di situs web tersebut, tanpa batasan.'}
          </Text>
          <Text styles={pointTextStyles}>
            {idn ? 'S&K ini merupakan perjanjian yang mengikat secara hukum oleh dan antara:' : 'These T&Cs constitute a legally binding agreement by and between:'}
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {TnCs.map((e, i) => (
              <div key={e} style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <Text css={pointTextStyles}>
                  {i + 1}
                  .
                  {' '}
                </Text>
                <Text css={pointTextStyles}>

                  {e}
                </Text>
              </div>
            ))}
          </div>
          <br />
          <Text styles={pointTextStyles}>
            {idn ? 'Dengan:' : 'By:'}
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {by.map((e, i) => (
              <div key={e} style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <Text css={pointTextStyles}>
                  {i + 1}
                  .
                  {' '}
                </Text>
                <Text css={pointTextStyles}>

                  {e}
                </Text>
              </div>
            ))}
          </div>
          <br />
          <Text styles={pointTextStyles}>
            {idn ? 'Anda dianggap telah secara tegas mengakui dan menyetujui:' : 'You are deemed to expressly acknowledge and agree:'}
          </Text>
          <ol style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {SnK.map((e, i) => (
              <div key={e} style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                <Text css={pointTextStyles}>
                  {i + 1}
                  .
                  {' '}
                </Text>
                <Text css={pointTextStyles}>
                  {e}
                </Text>
              </div>
            ))}
          </ol>
        </div>
        <br />

        {/* 1 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            1.
            {' '}
            {' '}
            {!idn ? 'DEFINED TERMS' : 'ISTILAH YANG DIDEFINISIKAN'}
          </Text>
          {definedTerms.map(({
            title, desc, array, text,
          }, i) => (
            <div key={title} css={contentContainerTncStyles}>
              {(i === 2 && !idn) ? (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <Text styles={pointTextStyles}>
                    {i + 1}
                    .
                  </Text>
                  <Text styles={pointTextStyles}>
                    <b>{title}</b>
                    {' '}
                    or
                    {' '}
                    {' '}
                    <b>“Graduate”</b>
                    {' '}
                    {desc}

                  </Text>
                </div>
              ) : (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                    <Text styles={pointTextStyles}>
                      {i + 1}
                      .
                    </Text>
                    <Text styles={pointTextStyles}>
                      <b>{title}</b>
                      {' '}
                      {desc}
                    </Text>
                  </div>
                  {array && (
                    array.map(({ title: titleArr, desc: descArr }) => (
                      <div style={{
                        display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '8px',
                      }}
                      >
                        <Text styles={{ lineHeight: '20px', marginLeft: '18px' }} textTransform="lowercase">
                          {titleArr.toLocaleLowerCase()}
                          {' '}
                        </Text>
                        <Text>
                          {descArr}
                        </Text>
                      </div>
                    ))
                  )}
                  {text && (
                  <div style={{ marginLeft: '18px' }}>
                    <br />
                    <Text styles={pointTextStyles}>
                      {text}
                    </Text>
                  </div>
                  )}
                </>
              )}

            </div>
          ))}
        </div>

        {/* 2 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            2.
            {' '}
            {' '}
            {!idn ? 'SCOPE' : 'RUANG LINGKUP'}
          </Text>
          {scope.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .

              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 3 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            3.
            {' '}
            {' '}
            {!idn ? 'RIGHTS AND OBLIGATIONS' : 'HAK DAN KEWAJIBAN'}
          </Text>
          {rno.map(({ title, array }, i) => (
            <div key={title} css={contentContainerTncStyles}>

              <Text styles={pointTextStyles}>
                {i + 1}
                .
                {' '}
                {' '}
                {title}
                <br />
                {array.map(({ title: titleArr, desc }) => (
                  <div style={{
                    display: 'flex', flexDirection: 'row', lineHeight: '20px', marginLeft: '16px', marginTop: '8px', gap: '8px',
                  }}
                  >
                    <Text textTransform="lowercase">
                      {titleArr}
                    </Text>
                    <Text>
                      {' '}
                      {desc}
                      {' '}
                    </Text>
                  </div>
                ))}
              </Text>

            </div>
          ))}
        </div>

        {/* 4 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            4.
            {' '}
            {' '}
            {!idn ? 'SERVICE FEE' : 'BIAYA LAYANAN'}
          </Text>
          {fee.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .

              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 5 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            5.
            {' '}
            {' '}
            {!idn ? 'RELIANCE AND INDEMNIFICATION' : 'KETERGANTUNGAN DAN GANTI RUGI'}
          </Text>
          {rni.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 6 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            6.
            {' '}
            {' '}
            {!idn ? 'RELIANCE AND INDEMNIFICATION' : 'KETERGANTUNGAN DAN GANTI RUGI'}
          </Text>
          {termination.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 7 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            7.
            {' '}
            {' '}
            {!idn ? 'NON-SOLICITATION' : 'LARANGAN BUJUKAN'}
          </Text>
          {nonSolication.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 8 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            8.
            {' '}
            {' '}
            {!idn ? 'FORCE MAJEURE' : 'KEADAAN KAHAR'}
          </Text>
          {forceMajeure.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 9 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            9.
            {' '}
            {' '}
            {!idn ? 'REPRESENTATIONS AND WARRANTIES' : 'PERNYATAAN DAN JAMINAN'}
          </Text>
          {warranties.map(({ title, array }, i) => (
            <div key={title} css={contentContainerTncStyles}>

              <Text styles={pointTextStyles}>
                {i + 1}
                .
                {' '}
                {' '}
                {title}
                <br />
                {array.map(({ title: titleArr, desc }) => (
                  <div style={{
                    display: 'flex', flexDirection: 'row', lineHeight: '20px', marginLeft: '16px', marginTop: '8px', gap: '8px',
                  }}
                  >
                    <Text textTransform="lowercase">
                      {titleArr}
                    </Text>
                    <Text>
                      {' '}
                      {desc}
                      {' '}
                    </Text>
                  </div>
                ))}
              </Text>

            </div>
          ))}
        </div>

        {/* 10 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            10.
            {' '}
            {' '}
            {!idn ? 'DISPUTE SETTLEMENT' : 'PENYELESAIAN PERSELISIHAN'}
          </Text>
          {dispute.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 11 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            11.
            {' '}
            {' '}
            {!idn ? 'LANGUAGE' : 'BAHASA'}
          </Text>
          {language.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        {/* 12 */}
        <div css={pointContainerStyles}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            12.
            {' '}
            {' '}
            {!idn ? 'ENTIRE AGREEMENT' : 'KESELURUHAN PERJANJIAN'}
          </Text>
          <Text styles={{ marginLeft: '20px', lineHeight: '20px' }}>
            {idn ? 'S&K ini menggantikan seluruh kesepakatan, kesepahaman dan korespondensi sebelumnya yang telah dibuat oleh dan antara Para Pihak melalui seluruh hal yang sama sebagaimana dituangkan dalam S&K ini, baik dalam bentuk dokumen yang ditandatangani Para Pihak, hasil percakapan lisan, rapat, memorandum, surat dan lain-lain.' : 'These T&Cs supersede all prior agreements, understanding and correspondence that have been made by and between the Parties throughout the matters that are the same as set out in these T&Cs, whether in the form of document signed by the Parties, the results of oral conversations, meetings, memorandums and letters as well as others.'}
          </Text>
        </div>

        {/* 13 */}
        <div css={pointContainerStyles} style={{ marginTop: '24px' }}>
          <Text styles={pointTitleStyles} textTransform="uppercase">
            13.
            {' '}
            {' '}
            {!idn ? 'MISCELLANEOUS PROVISIONS' : 'KETENTUAN LAIN'}
          </Text>
          {miscellaneous.map((e, i) => (
            <div
              key={e}
              style={{
                lineHeight: '20px', marginLeft: '16px', display: 'flex', flexDirection: 'row', gap: '6px',
              }}
            >
              <Text styles={pointTextStyles}>
                {i + 1}
                .
              </Text>
              <Text styles={pointTextStyles}>
                {e}
              </Text>

            </div>
          ))}
        </div>

        <form style={{ display: 'flex', flexDirection: 'column', marginTop: '40px' }}>
          <div style={{
            marginBottom: '10px', display: 'flex', alignItems: 'start', gap: '10px',
          }}
          >
            <input
              type="checkbox"
              id="agreementCheckbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{
                cursor: 'pointer', width: '24px', height: '24px', marginTop: '4px',
              }}
            />
            {idn ? (
              <label htmlFor="agreementCheckbox">
                <Text styles={{ fontSize: '16px' }}>
                  Dengan mengirim formulir ini saya menyetujui
                  <a href={url} style={{ textDecoration: 'none', color: '#1D3D71' }}>  Syarat dan Ketentuan</a>
                  {' '}
                  untuk melakukan kolaborasi bisnis dengan Hacktiv8.
                </Text>

              </label>
            ) : (
              <label htmlFor="agreementCheckbox">
                <Text styles={{ fontSize: '16px' }}>
                  By sending this form I agree to the
                  <a href={url} style={{ textDecoration: 'none', color: '#1D3D71' }}> Terms and Conditions</a>
                  {' '}
                  for conducting Business Collaboration with Hacktiv8.
                </Text>

              </label>
            )}

          </div>
          <button
            onClick={handleOnClick}
            type="button"
            style={{
              width: '107px',
              backgroundColor: isChecked ? '#1D3D71' : 'grey',
              border: 'none',
              height: '48px',
              borderRadius: '10px',
              color: 'white',
              marginLeft: '24px',
              marginTop: '20px',
              cursor: isChecked ? 'pointer' : 'not-allowed',
            }}
            disabled={!isChecked}
          >
            {idn ? 'Setuju' : 'Agree'}
          </button>
        </form>

      </Container>
    </>
  );
};

Agreement.propTypes = {
  agreementId: PropTypes.string.isRequired,
};

export default Agreement;
