/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import StatusPills from './StatusPill/StatusPill';
import cardPlaceholder from '../../../../../assets/images/pages/job/job-card-placeholder.jpg';
import {
  Button,
  Icon,
  ShortText,
  Link,
} from '../../../../general';
import { LocaleContext } from '../../../../../contexts/localeContext';
import { roleEligibilityChecker } from '../../../../../helpers/localStorage';
import { humanDateSlashesParser } from '../../../../../helpers/parser';
import {
  cardStyles,
  leftCardContainerStyles,
  imageContainerStyles,
  imageStyles,
  detailsContainerStyles,
  jobTitleStyles,
  jobPostedStyles,
  iconAndApplicantContainerStyles,
  iconStyles,
  applicantStyles,
  statusAndChangeStatusContainerStyles,
  changeStatusButtonStyles,
  detailsButtonStyles,
} from './JobCard.styles';

const JobCard = ({ data, setChangeStatusConfirmationModalData }) => {
  const { locale } = useContext(LocaleContext);

  const handleChangeStatus = ({ id, name, status }) => {
    let type = '';

    if (status === 'open') type = 'closed';
    if (status === 'closed') type = 'open';

    setChangeStatusConfirmationModalData({
      id,
      type,
      name,
      status: true,
    });
  };

  return (
    <div css={cardStyles}>
      <div css={leftCardContainerStyles}>
        <div css={imageContainerStyles}>
          {data.company?.logo_url ? (
            <img css={imageStyles} src={data.company.logo_url} alt={data.name} />
          ) : (
            <img css={imageStyles} src={cardPlaceholder} alt="placeholder" />
          )}
        </div>
        <div css={detailsContainerStyles}>
          {
            data.last_status_at.open && (
            <div css={iconAndApplicantContainerStyles}>
              <ShortText styles={jobPostedStyles}>Job Posted at</ShortText>
              &nbsp;
              <ShortText styles={jobPostedStyles}>
                {humanDateSlashesParser(data.last_status_at.open)}
              </ShortText>
            </div>
            )
          }
          <ShortText styles={jobTitleStyles}>{data.name}</ShortText>
          <div css={iconAndApplicantContainerStyles}>
            <Icon name="user-line" styles={iconStyles} />
            <ShortText styles={applicantStyles}>
              {`${data.seekers_applied} applicants`}
            </ShortText>
          </div>
          <div css={statusAndChangeStatusContainerStyles}>
            <StatusPills status={data.status.toUpperCase()} />
            {data.status !== 'draft' && (
              <button
                type="button"
                css={changeStatusButtonStyles}
                onClick={() => handleChangeStatus(data)}
              >
                change status
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        {data.status === 'open' && (
          <>
            {roleEligibilityChecker() && (
              <Link to={`/${locale}/job/${data.id}/edit`}>
                <Button type="button" size="S" inverted>
                  edit
                </Button>
              </Link>
            )}
            <Link to={`/${locale}/job/${data.id}/applicants?filter=applied`}>
              <Button
                type="button"
                styles={detailsButtonStyles}
                size="M"
                color="LIGHT_BLUE"
              >
                see details
              </Button>
            </Link>
          </>
        )}
        {data.status === 'draft' && (
          roleEligibilityChecker() && (
            <Link to={`/${locale}/job/${data.id}/edit`}>
              <Button
                type="button"
                size="M"
                color="LIGHT_BLUE"
              >
                edit details
              </Button>
            </Link>
          )
        )}
        {data.status === 'closed' && (
          <>
            {roleEligibilityChecker() && (
              <Link to={`/${locale}/job/${data.id}/duplicate`}>
                <Button type="button" size="S" inverted>
                  duplicate
                </Button>
              </Link>
            )}
            <Link to={`/${locale}/job/${data.id}/applicants?filter=applied`}>
              <Button
                type="button"
                styles={detailsButtonStyles}
                size="M"
                color="LIGHT_BLUE"
              >
                see details
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

JobCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    company: PropTypes.shape({
      logo_url: PropTypes.string,
    }),
    name: PropTypes.string,
    seekers_applied: PropTypes.number,
    status: PropTypes.oneOf(['open', 'closed', 'draft']),
    last_status_at: PropTypes.shape({
      closed: PropTypes.instanceOf(Date),
      open: PropTypes.instanceOf(Date),
    }),
  }).isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
};

export default JobCard;
