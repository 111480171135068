import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  padding: 40px 0 80px;

  @media screen and (max-width: 1279px) {
    padding: 0 16px;
  }
`;

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 60px;
  border: 1px solid ${colors.GREY};
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  background: ${colors.WHITE};
  justify-content: flex-start;

  @media screen and (max-width: 1279px) {
    padding: 32px 32px 64px;
  }
`;

export const titleTextStyles = css`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  margin: 0 0 12px;

  @media screen and (max-width: 1279px) {
    font-weight: 500;
  }
`;

export const pointContainerStyles = css`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
`;

export const pointTitleStyles = css`
  color: ${colors.PRIMARY_BLUE};
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 12px;
`;

export const pointTextStyles = css`
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 10px;
  text-transform: uppercase;

  @media screen and (max-width: 1279px) {
    font-size: 14px;
  }
`;

export const horizontalLineStyles = css`
  height: 2px;
  border: 1px solid ${colors.GREY};
  margin: 0 0 12px;

  @media screen and (max-width: 1279px) {
    width: calc(100% + 64px);
  }
`;

export const contentContainerStyles = (index) => css`
  margin: ${index === 3 ? '12px 0 32px' : '12px 0'};

  @media screen and (max-width: 1279px) {
    display: ${index > 2 ? 'none' : 'block'};
  }

  p {
    color: ${colors.PRIMARY_BLUE};
    font-size: 14px;
    font-weight: 400;

    &:first-of-type {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      margin: 0 0 5px;

      @media screen and (max-width: 1279px) {
        font-size: 14px;
        margin: 0 0 10px;
      }
    }
  }
`;
