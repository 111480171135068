import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const logout = () => (
  axios.post(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/v2/auth/logout`,
    null,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default logout;
