import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const containerStyles = css`
  padding: 40px 0 100px 0;
`;

export const cardStyles = css`
  width: 100%;
  padding: 32px;
  border-radius: 12px;
  background-color: ${colors.WHITE};
`;

export const upperCardStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const imageStyles = css`
  width: 176px;
  height: 176px;
  border-radius: 12px;
  object-fit: cover;
`;

export const detailsContainerStyles = css`
  width: 248px;
  margin: 0 0 0 32px;
`;

export const nameStyles = css`
  font-weight: 700;
  text-transform: capitalize;
  font-size: 24px;
`;

export const iconAndLocationContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0 0 0;
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const locationStyles = css`
  margin: 0 0 0 5px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const phoneNumberStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 18px 0 0 0;
  font-size: 16px;
`;

export const phoneIconStyles = css`
  margin: 0 10px 0 0;
  color: ${colors.LIGHT_BLUE};
`;

export const socialMediaAndBadgeContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 22px 0 0 0;
`;

export const socialMediaContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const socialMediaLinkStyles = css`
  margin: 0 16px 0 0;

  &:last-of-type {
    margin: 0;
  }
`;

export const socialMediaIconStyles = css`
  font-size: 18px;
  color: ${colors.LIGHT_BLUE};
`;

export const badgeAndFinalScoreContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 25px;
`;

export const finalScoreContainerStyles = (color) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 24px;
  padding: 0 5px;
  border-radius: 5px;
  background: ${color};
`;

export const finalScoreStyles = css`
  color: ${colors.WHITE};
`;

export const badgeStyles = (color) => css`
  margin: 0 0 0 4px;
  background: ${color};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.1px;
`;

export const statusContainerStyles = css`
  width: 280px;
  margin: 0 0 0 90px;
`;

export const studentInfoRowStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &:last-of-type {
    margin: 20px 0 0 0;
  }
`;

export const studentInfoLabelAndValue = css`
  &:nth-of-type(2n + 1) {
    width: 150px;
  }
`;

export const labelAndValueContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-size: 12px;
  color: ${colors.DARKER_GREY};
`;

export const valueStyles = css`
  margin: 8px 0 0 0;
  text-transform: capitalize;
`;

export const skillsContainerStyles = css`
  margin: 32px 0 0 0;
`;

export const skillPillContainerStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 0 0 0;
`;

export const skillPillStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 22px;
  margin: 0 8px 8px 0;
  padding: 4px 8px;
  border-radius: 20px;
  border: 1px solid ${colors.PRIMARY_BLACK};

  &:last-of-type {
    margin: 0;
  }
`;

export const skillStyles = css`
  font-size: 12px;
`;

export const buttonContainerStyles = css`
  width: 250px;
  margin: 0 0 0 140px;
`;

export const applicationDropdownContainerStyles = css`
  position: relative;
`;

export const downArrowIconStyles = css`
  margin: 0 0 0 15px;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.WHITE};
`;

export const applicationButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 22px 0;
`;

export const applicationDropdownStyles = (isDropdownShown) => css`
  display: ${isDropdownShown ? 'block' : 'none'};
  position: absolute;
  width: 250px;
  height: 500px;
  margin: 5px 0 0 0;
  padding: 10px 5px;
  top: 50px;
  left: 0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.WHITE};
  z-index: 50;
  overflow: scroll;
`;

export const buttonListStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border-radius: 5px;
  border: none;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;

export const dropdownDivider = css`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: rgba(29, 61, 113, 0.15);
`;

export const rejectButtonStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.DANGER_RED};
  outline: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
    opacity: unset;
  }
`;

export const buttonLinkStyles = css`
  display: block;
  margin: 22px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const lowerCardStyles = css`
  margin: 24px 0 0 0;
`;

export const instructorRemarksCardContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 52px 0;
`;

export const instructorRemarksCardStyles = css`
  width: calc(100% - 12px);
  padding: 32px;
  border-radius: 12px;
  border: 1px solid ${colors.LIGHT_BLUE};
  background-color: ${colors.LIGHT_GREY};
  box-shadow: -12px 12px 0px 0 rgba(29, 61, 113, 1);
`;

export const remarkTitleStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 20px;
  color: ${colors.PRIMARY_BLUE};
`;

export const quillIconStyles = css`
  font-size: 30px;
  color: ${colors.PRIMARY_BLUE};
`;

export const remarkDescriptionStyles = css`
  margin: 16px 0 0 0;
  font-size: 16px;
  line-height: 24px;
`;

export const titleStyles = css`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 20px;
`;

export const professionalSummaryStyles = css`
  margin: 18px 0 0 0;
  font-size: 16px;
`;

export const workExperienceAndEducationContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
`;

export const columnStyles = css`
  width: calc(50% - 54px);
  margin: 0 0 40px;
`;
