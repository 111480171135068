import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useContext } from 'react';
import { Container, Text } from '../../general';
import {
  containerStyles,
} from '../Agreement/Agreement.styles';
import { LocaleContext } from '../../../contexts/localeContext';

const Error = () => {
  const { locale } = useContext(LocaleContext);
  const idn = locale === 'id';
  const history = useHistory();
  const styles = {
    textTitle: {
      color: '#1D3D71',
      fontWeight: '400px',
      fontSize: '18px',
      textAlign: 'center',
    },
  };
  return (
    <Container
      contentWidthType="NORMAL"
      containerStyles={containerStyles}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '40px',
        marginTop: '60px',
      }}
      >
        <Text textTransform="uppercase" styles={styles.textTitle}>
          {idn ? 'Maaf' : 'Sorry'}
          {' '}
          !
        </Text>
        {idn ? (
          <Text textTransform="" styles={{ ...styles.textTitle, marginTop: '-10px' }}>
            Halaman ini telah kedaluwarsa atau sudah pernah
            <br />
            {' '}
            diklik sebelumnya.
          </Text>
        ) : (
          <Text textTransform="" styles={{ ...styles.textTitle, marginTop: '-10px' }}>
            The page has either expired or has already been
            <br />
            {' '}
            clicked before.
          </Text>
        )}
        <img src="/image/Illustration.png" alt="" />
        <button
          onClick={() => history.push('/')}
          type="button"
          style={{
            backgroundColor: '#1E5BBD',
            width: '200px',
            height: '44px',
            border: 'none',
            borderRadius: '6px',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          Oke
        </button>
      </div>
    </Container>
  );
};

export default Error;
