import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const checkboxContainerStyles = css`
  width: 100%;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const checkboxLabelStyles = (label) => css`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${label ? '12px' : 0} 0 0 0;
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.LIGHT_BLUE};
  }
`;

export const checkboxStyles = css`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked {
    ~ span {
      background-color: ${colors.LIGHT_BLUE};

      &:after {
        display: inline-block;
      }
    }
  }
`;

export const checkmarkStyles = (checkboxLabel) => css`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: ${checkboxLabel ? '0 10px 0 0' : 0};
  border: 1.5px solid ${colors.LIGHT_BLUE};
  border-radius: 4px;
  background-color: ${colors.WHITE};

  &:after {
    display: none;
    position: absolute;
    top: 2px;
    left: 5px;
    content: '';
    width: 6px;
    height: 9px;
    border: 3px solid ${colors.WHITE};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
