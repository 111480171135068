/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Link, Icon } from '../../general';
import { LocaleContext } from '../../../contexts/localeContext';
import { profileAndCardSettings } from '../../../locale';
import { logout } from '../../../api/v1/post/auth';
import { clearCredentials } from '../../../helpers/localStorage';
import {
  cardStyles,
  leftCardContentStyles,
  listStyles,
  linkStyles,
  iconStyles,
  logoutButtonContainerStyles,
  logoutButtonStyles,
  rightCardContentStyles,
} from './ProfileAndSettingsCard.styles';

const ProfileAndSettingsCard = ({ rightCardContent }) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const [rightCardHeight, setRightCardHeight] = useState(0);
  const rightCardRef = useRef(null);

  const menuList = [
    {
      icon: 'user-line',
      label: profileAndCardSettings.userProfile[locale],
      path: `/${locale}/profile-and-settings/user-profile`,
    },
    {
      icon: 'lock-2-line',
      label: profileAndCardSettings.changePassword[locale],
      path: `/${locale}/profile-and-settings/change-password`,
    },
    {
      icon: 'mail-line',
      label: profileAndCardSettings.changeEmail[locale],
      path: `/${locale}/profile-and-settings/change-email`,
    },
    {
      icon: 'building-line',
      label: profileAndCardSettings.companyProfile[locale],
      path: `/${locale}/profile-and-settings/company-profile`,
    },
    {
      icon: 'group-line',
      label: profileAndCardSettings.manageMembers[locale],
      path: `/${locale}/profile-and-settings/manage-members`,
    },
  ];

  const handleLogout = () => {
    logout();
    history.push(`/${locale}/login`);
    clearCredentials();
  };

  useEffect(() => {
    setRightCardHeight(rightCardRef.current.clientHeight);
  }, []);

  return (
    <div css={cardStyles}>
      <div css={leftCardContentStyles(rightCardHeight)}>
        <ul>
          {menuList.map(({ icon, label, path }) => (
            <li key={label} css={listStyles}>
              <Link to={path} styles={linkStyles} activeClassName="isActive">
                <Icon name={icon} styles={iconStyles} />
                {label}
              </Link>
            </li>
          ))}
        </ul>
        <div css={logoutButtonContainerStyles}>
          <button type="button" css={logoutButtonStyles} onClick={handleLogout}>
            <Icon name="logout-box-r-line" styles={iconStyles} />
            {profileAndCardSettings.logout[locale]}
          </button>
        </div>
      </div>
      <div ref={rightCardRef} css={rightCardContentStyles}>
        {rightCardContent}
      </div>
    </div>
  );
};

ProfileAndSettingsCard.propTypes = {
  rightCardContent: PropTypes.node.isRequired,
};

export default ProfileAndSettingsCard;
