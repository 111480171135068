/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';

import { ShortText } from '../../../../general';
import { localeList } from '../../../../../configs';
import {
  passwordStrengthBarStyles,
  barContainerStyles,
  barStyles,
  activeBarStyles,
  statusStyles,
} from './PasswordStrengthBar.styles';

const PasswordStrengthBar = ({
  password,
  passwordStatusTranslation,
  locale,
}) => {
  const passwordScore = zxcvbn(password).score;

  const handleBarRender = () => {
    const barCount = 5;
    const passwordStrengthBar = new Array(barCount);

    if (password) {
      for (let i = 0; i <= passwordScore; i += 1) {
        passwordStrengthBar.push(
          <div key={`activeBar-${i}`} css={[barStyles, activeBarStyles]} />,
        );
      }

      if (passwordScore < 4) {
        const emptyBar = barCount - (passwordScore + 1);

        for (let j = 0; j < emptyBar; j += 1) {
          passwordStrengthBar.push(
            <div key={`emptyBar-${j}`} css={barStyles} />,
          );
        }
      }
    } else {
      for (let j = 0; j < barCount; j += 1) {
        passwordStrengthBar.push(<div key={`emptyBar-${j}`} css={barStyles} />);
      }
    }

    return passwordStrengthBar;
  };

  const handlePasswordStrengthStatus = () => {
    let passwordStrengthMeter = passwordStatusTranslation.empty[locale];

    if (password) {
      switch (passwordScore) {
        case 0:
          passwordStrengthMeter = passwordStatusTranslation.tooShort[locale];
          break;
        case 1:
          passwordStrengthMeter = passwordStatusTranslation.weak[locale];
          break;
        case 2:
          passwordStrengthMeter = passwordStatusTranslation.fair[locale];
          break;

        case 3:
          passwordStrengthMeter = passwordStatusTranslation.good[locale];
          break;

        case 4:
          passwordStrengthMeter = passwordStatusTranslation.strong[locale];
          break;

        default:
          passwordStrengthMeter = passwordStatusTranslation.empty[locale];
          break;
      }
    }

    return passwordStrengthMeter;
  };

  return (
    <div css={passwordStrengthBarStyles}>
      <div css={barContainerStyles}>{handleBarRender()}</div>
      <ShortText styles={statusStyles}>
        {handlePasswordStrengthStatus()}
      </ShortText>
    </div>
  );
};

PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
  passwordStatusTranslation: PropTypes.shape({
    empty: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    tooShort: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    weak: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    fair: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    good: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    strong: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  locale: PropTypes.oneOf(localeList).isRequired,
};

export default PasswordStrengthBar;
