import Layout from '../../components/layout/Layout';
import { Error404 } from '../../components/pages';

const Error404Page = () => (
  <Layout backgroundColor="LIGHT_GREY">
    <Error404 />
  </Layout>
);

export default Error404Page;
