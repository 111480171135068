/** @jsxImportSource @emotion/react */
import { useState, useContext, useRef } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import companyLogo from '../../../assets/svg/logo.svg';
// import indonesianFlag from '../../../assets/images/flags/indonesia.png';
// import unitedStatesFlag from '../../../assets/images/flags/united-states.png';
import { Link, Icon } from '../../general';
import { LocaleContext } from '../../../contexts/localeContext';
// import {
//   localeToEnglish,
//   localeToIndonesian,
// } from '../../../reducers/localeReducer';
import {
  getIntrospection,
  clearCredentials,
} from '../../../helpers/localStorage';
import { logout } from '../../../api/v1/post/auth';
import { useDetectOutsideClick } from '../../../hooks';

import {
  navbarStyles,
  navbarContentStyles,
  rightMenuStyles,
  listContainerStyles,
  listStyles,
  linkStyles,
  // notificationIconStyles,
  dividerStyles,
  // localeDropdownContainerStyles,
  // localeDropdownButtonStyles,
  // flagStyles,
  // languageStyles,
  arrowDownStyles,
  // localeDropdownStyles,
  // localeDropdownListStyles,
  // localeDropdownLanguageButtonStyles,
  profileDropdownContainerStyles,
  profileDropdownButtonStyles,
  profileDropdownStyles,
  dropdownLinkStyles,
  dropdownDivider,
  logoutButtonStyles,
} from './Header.styles';

const Header = () => {
  const history = useHistory();
  const localeDropdownRef = useRef();
  const profileDropdownRef = useRef();
  const { locale } = useContext(LocaleContext);
  const [isLocaleDropdownShow, setLocaleDropdownStatus] = useState(false);
  const [isProfileDropdownShow, setProfileDropdownStatus] = useState(false);

  useDetectOutsideClick(localeDropdownRef, () => {
    if (isLocaleDropdownShow) setLocaleDropdownStatus(false);
  });

  useDetectOutsideClick(profileDropdownRef, () => {
    if (isProfileDropdownShow) setProfileDropdownStatus(false);
  });

  // const handleLocaleDropdownToggle = () => {
  //   setLocaleDropdownStatus((prevState) => !prevState);
  // };

  // const handleLocaleChange = (lang) => {
  //   if (lang === 'en') {
  //     dispatch(localeToEnglish());
  //   }

  //   if (lang === 'id') {
  //     dispatch(localeToIndonesian());
  //   }

  //   setLocaleDropdownStatus(false);
  // };

  const handleProfileDropdownToggle = () => {
    setProfileDropdownStatus((prevState) => !prevState);
  };

  const handleLogout = () => {
    logout();
    history.push(`/${locale}/login`);
    clearCredentials();
  };

  return (
    <div css={navbarStyles}>
      <div css={navbarContentStyles}>
        <NavLink to={`/${locale}/job?filter=all`}>
          <img src={companyLogo} alt="hacktiv8-logo" />
        </NavLink>
        <div css={rightMenuStyles}>
          <ul css={listContainerStyles}>
            <li css={listStyles}>
              <Link
                to={`/${locale}/job?filter=all`}
                styles={linkStyles}
                activeClassName="isActive"
              >
                job
              </Link>
            </li>
            <li css={listStyles}>
              <Link
                to={`/${locale}/resume`}
                styles={linkStyles}
                activeClassName="isActive"
              >
                resume search
              </Link>
            </li>
            {/* <li css={listStyles}>
              <Link
                to={`/${locale}/notifications`}
                styles={linkStyles}
                isActive="isActive"
              >
                <Icon
                  name="notification-2-fill"
                  styles={notificationIconStyles}
                />
              </Link>
            </li> */}
          </ul>
          <div css={dividerStyles} />
          {/* TODO: Temporary disabled */}
          {/* <div css={localeDropdownContainerStyles}>
            <button
              type="button"
              css={localeDropdownButtonStyles}
              onClick={handleLocaleDropdownToggle}
            >
              <img
                src={locale === 'en' ? unitedStatesFlag : indonesianFlag}
                css={flagStyles}
                alt={locale === 'en' ? 'us-flag' : 'id-flag'}
              />
              <ShortText styles={languageStyles}>
                {locale === 'en' ? 'en' : 'id'}
              </ShortText>
              <Icon name="arrow-down-s-line" styles={arrowDownStyles} />
            </button>
            <nav
              ref={localeDropdownRef}
              css={localeDropdownStyles(isLocaleDropdownShow)}
            >
              <ul>
                <li css={localeDropdownListStyles}>
                  <button
                    type="button"
                    css={localeDropdownLanguageButtonStyles}
                    onClick={() => handleLocaleChange('en')}
                  >
                    <img
                      src={unitedStatesFlag}
                      css={flagStyles}
                      alt="us-flag"
                    />
                    english - US
                  </button>
                </li>
                <li css={localeDropdownListStyles}>
                  <button
                    type="button"
                    css={localeDropdownLanguageButtonStyles}
                    onClick={() => handleLocaleChange('id')}
                  >
                    <img src={indonesianFlag} css={flagStyles} alt="id-flag" />
                    indonesian
                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
          {/* <div css={dividerStyles} /> */}
          <div css={profileDropdownContainerStyles}>
            <button
              type="button"
              css={profileDropdownButtonStyles}
              onClick={handleProfileDropdownToggle}
            >
              {getIntrospection().user_name}
              <Icon name="arrow-down-s-line" styles={arrowDownStyles} />
            </button>
            <nav
              ref={profileDropdownRef}
              css={profileDropdownStyles(isProfileDropdownShow)}
            >
              <ul>
                <li>
                  <Link
                    to={`/${locale}/company-profile`}
                    styles={dropdownLinkStyles}
                  >
                    view company profile
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/favorite-candidates`}
                    styles={dropdownLinkStyles}
                  >
                    favorite candidates
                  </Link>
                </li>
                <div css={dropdownDivider} />
                <li>
                  <Link
                    to={`/${locale}/profile-and-settings/user-profile`}
                    styles={dropdownLinkStyles}
                  >
                    user profile
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/profile-and-settings/change-password`}
                    styles={dropdownLinkStyles}
                  >
                    change password
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/profile-and-settings/change-email`}
                    styles={dropdownLinkStyles}
                  >
                    change email address
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/profile-and-settings/company-profile`}
                    styles={dropdownLinkStyles}
                  >
                    edit company profile
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${locale}/profile-and-settings/manage-members`}
                    styles={dropdownLinkStyles}
                  >
                    manage member
                  </Link>
                </li>
                <div css={dropdownDivider} />
                <button
                  type="button"
                  css={logoutButtonStyles}
                  onClick={handleLogout}
                >
                  log out
                </button>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
