/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState } from 'react';

import { PlainCard } from '../../../../cards';
import {
  Modal,
  Heading,
  Icon,
  Input,
  Button,
} from '../../../../general';
import {
  cardStyles,
  headerStyles,
  titleStyles,
  closeButtonStyles,
  iconStyles,
  bodyStyles,
  footerStyles,
} from './AddNewPerksAndBenefitModal.styles';

const AddNewPerksAndBenefitModal = ({
  isModalShown,
  setModalStatus,
  addNewPerksAndBenefitCheckbox,
}) => {
  const [perksAndBenefit, setPerksAndBenefit] = useState('');

  const handleCloseModal = () => {
    setModalStatus(false);
  };

  const handleChange = (e) => {
    setPerksAndBenefit(e.target.value);
  };

  const handleSave = () => {
    addNewPerksAndBenefitCheckbox(perksAndBenefit);
    setPerksAndBenefit('');
    setModalStatus(false);
  };

  return (
    <Modal isModalShown={isModalShown}>
      <PlainCard styles={cardStyles}>
        <div css={headerStyles}>
          <Heading type="h2" styles={titleStyles}>
            add perks and benefit
          </Heading>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon styles={iconStyles} name="close-line" />
          </button>
        </div>
        <div css={bodyStyles}>
          <Input
            type="text"
            label="perks and benefit"
            name="perksAndBenefit"
            value={perksAndBenefit}
            onChange={handleChange}
            placeholder="new perks and benefit"
          />
        </div>
        <div css={footerStyles}>
          <Button
            type="button"
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSave}
          >
            save
          </Button>
        </div>
      </PlainCard>
    </Modal>
  );
};

AddNewPerksAndBenefitModal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  addNewPerksAndBenefitCheckbox: PropTypes.func.isRequired,
};

export default AddNewPerksAndBenefitModal;
