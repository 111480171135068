/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Container,
  Heading,
  Button,
  Icon,
  ShortText,
  LabelAndValue,
} from '../../general';
import { thousandsSeparator } from '../../../helpers/parser';
import {
  containerStyles,
  jobDataPaperStyles,
  bannerStyles,
  upperJobDataPaperStyles,
  companyLogoContainerStyles,
  companyLogoStyles,
  jobTitleStyles,
  upperJobDataPaperRowStyles,
  companyNameStyles,
  iconAndLocationNameContainerStyles,
  iconStyles,
  locationNameStyles,
  deadlineOfApplicationStyles,
  tagContainerStyles,
  tagStyles,
  salariesStyles,
  copyLinkAndFavoriteButtonContainerStyles,
  copyLinkButtonStyles,
  buttonIconStyles,
  lowerJobDataPaperStyles,
  rowStyles,
  labelStyles,
  seekerAvailabilitiesContainerStyles,
  seekerAvailabilitiesStyles,
  seekerAvailabilitiesListStyles,
  perksAndBenefitsContainerStyles,
  perksAndBenefitsStyles,
  perksAndBenefitsListStyles,
} from './JobDetailsPreview.styles';

const JobDetailsPreview = ({ data }) => {
  const { jobId } = useParams();
  const [copyToClipboardStatus, setCopyToClipboardStatus] = useState(false);

  const copyToClipboard = () => {
    const el = document.createElement('input');
    el.value = `${process.env.REACT_APP_SEEKER_APPS_DOMAIN_NAME}/jobs/${jobId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopyToClipboardStatus(true);
  };

  const skillsParser = () => {
    const parsedSkills = data.skills.map((d) => d.label).join(', ');

    return parsedSkills;
  };

  return (
    <Container contentWidthType="NORMAL" containerStyles={containerStyles}>
      <div css={jobDataPaperStyles}>
        <div css={bannerStyles(data.company?.banner_url)} />
        <div css={upperJobDataPaperStyles}>
          <div css={companyLogoContainerStyles}>
            <img
              src={data.company?.logo_url
                || '/images/pages/job-details/company-logo-placeholder.jpeg'}
              css={companyLogoStyles}
              alt={data.company?.name}
            />
          </div>
          <Heading type="h2" styles={jobTitleStyles}>
            {data.name}
          </Heading>
          <div css={upperJobDataPaperRowStyles(12)}>
            <div>
              <Heading type="h2" styles={companyNameStyles}>
                {data.company.name}
              </Heading>
              <div css={iconAndLocationNameContainerStyles}>
                <Icon name="map-pin-line" styles={iconStyles} />
                <ShortText styles={locationNameStyles}>
                  {`${data.city?.label}, ${data.country?.label}`}
                </ShortText>
              </div>
            </div>
            <LabelAndValue
              styles={deadlineOfApplicationStyles}
              label="deadline of application"
              value={moment(data.due_date).format('MMMM, Do YYYY')}
            />
          </div>
          <div css={upperJobDataPaperRowStyles(20)}>
            <div css={tagContainerStyles}>
              <div css={tagStyles}>
                <ShortText>{data.job_function?.label}</ShortText>
              </div>
              <div css={tagStyles}>
                <ShortText>{data.employment_type?.label}</ShortText>
              </div>
              <div css={tagStyles}>
                <ShortText>{data.job_level?.label}</ShortText>
              </div>
            </div>
            {data.salary_currency && data.salary_range_from && (
              <ShortText styles={salariesStyles}>
                {`${data.salary_currency} ${thousandsSeparator(
                  data.salary_range_from,
                )} - ${thousandsSeparator(data.salary_range_until)}`}
              </ShortText>
            )}
          </div>
          <div css={upperJobDataPaperRowStyles(32)}>
            <div css={copyLinkAndFavoriteButtonContainerStyles}>
              <Button
                type="button"
                styles={copyLinkButtonStyles}
                size="M"
                onClick={copyToClipboard}
                inverted
              >
                {copyToClipboardStatus ? (
                  'copied'
                ) : (
                  <>
                    <Icon styles={buttonIconStyles} name="link" />
                    &nbsp;copy link
                  </>
                )}
              </Button>
            </div>
            <Button
              type="button"
              size="L"
              color="PRIMARY_BLUE"
              disabled
            >
              apply now!
            </Button>
          </div>
        </div>
        <div css={lowerJobDataPaperStyles}>
          <div css={rowStyles}>
            <LabelAndValue label="minimum education" value={data.education_level?.label} />
          </div>
          {data.skills.length !== 0 && (
            <div css={rowStyles}>
              <LabelAndValue label="required skills" value={skillsParser()} />
            </div>
          )}
          <div css={rowStyles}>
            <LabelAndValue
              valueType="HTML"
              label="job description"
              value={ReactHtmlParser(data.description)}
            />
          </div>
          <div css={rowStyles}>
            <LabelAndValue
              valueType="HTML"
              label="qualification / requirements"
              value={ReactHtmlParser(data.qualification)}
            />
          </div>
          {data.seeker_availabilities.length !== 0 && (
            <div css={seekerAvailabilitiesContainerStyles}>
              <ShortText styles={labelStyles}>availability</ShortText>
              <ul css={seekerAvailabilitiesStyles}>
                {data.seeker_availabilities.map((d) => (
                  <li key={d.id} css={seekerAvailabilitiesListStyles}>
                    {d.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {data.benefits.length !== 0 && (
            <div css={perksAndBenefitsContainerStyles}>
              <ShortText styles={labelStyles}>perks and benefit</ShortText>
              <ul css={perksAndBenefitsStyles}>
                {data.benefits.map((d) => (
                  <li key={d.id} css={perksAndBenefitsListStyles}>
                    {d.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

JobDetailsPreview.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    application: PropTypes.shape({
      status: PropTypes.string,
    }),
    due_date: PropTypes.string,
    company: PropTypes.shape({
      banner_url: PropTypes.string,
      logo_url: PropTypes.string,
      name: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    name: PropTypes.string,
    job_function: PropTypes.shape({
      label: PropTypes.string,
    }),
    employment_type: PropTypes.shape({
      label: PropTypes.string,
    }),
    job_level: PropTypes.shape({
      label: PropTypes.string,
    }),
    education_level: PropTypes.shape({
      label: PropTypes.string,
    }),
    salary_currency: PropTypes.string,
    salary_range_from: PropTypes.number,
    salary_range_until: PropTypes.number,
    description: PropTypes.string,
    qualification: PropTypes.string,
    seeker_availabilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default JobDetailsPreview;
