export default {
  userProfile: {
    en: 'user profile',
    id: 'profil anggota',
  },
  changePassword: {
    en: 'change password',
    id: 'ubah kata sandi',
  },
  changeEmail: {
    en: 'change email',
    id: 'ubah email',
  },
  companyProfile: {
    en: 'company profile',
    id: 'profil perusahaan',
  },
  manageMembers: {
    en: 'manage members',
    id: 'kelola anggota',
  },
  logout: {
    en: 'logout',
    id: 'keluar',
  },
};
