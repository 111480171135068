import axios from 'axios';

const cities = (params) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/library/cities/search`,
    {
      params,
    },
  ));

export default cities;
