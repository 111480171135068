/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment, useState, useEffect } from 'react';

import ApplicantCard from './ApplicantCard/ApplicantCard';
import emptyStateBackgroundImage from '../../../../assets/images/pages/job-details/job-details-empty-state.png';
import { Pagination } from '../../../general';
import {
  applicantCardContainerStyles,
  paginationContainerStyles,
} from './ApplicantList.styles';

const ApplicantList = ({
  setRefetchStatus,
  applicantListData,
  selectedApplicantIds,
  setSelectedApplicantIds,
  handleUpdateJobApplications,
  pageInfo,
  setPageInfo,
}) => {
  const [isJobDetailsDataAvailable, setJobDetailsDataStatus] = useState(false);

  const handlePageChange = (page) => {
    setPageInfo((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  useEffect(() => {
    if (applicantListData.length !== 0) {
      setJobDetailsDataStatus(true);
    } else {
      setJobDetailsDataStatus(false);
    }
  }, [applicantListData]);

  return (
    <Fragment>
      <div
        css={applicantCardContainerStyles(
          !isJobDetailsDataAvailable,
          emptyStateBackgroundImage,
        )}
      >
        {applicantListData.map((d) => (
          <ApplicantCard
            key={d.id}
            data={d}
            setRefetchStatus={setRefetchStatus}
            selectedApplicantIds={selectedApplicantIds}
            setSelectedApplicantIds={setSelectedApplicantIds}
            handleUpdateJobApplications={handleUpdateJobApplications}
          />
        ))}
      </div>
      <Pagination
        containerStyles={paginationContainerStyles}
        pageSize={pageInfo.pageSize}
        totalCount={pageInfo.totalCount}
        currentPage={pageInfo.currentPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </Fragment>
  );
};

ApplicantList.propTypes = {
  setRefetchStatus: PropTypes.func.isRequired,
  applicantListData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          channel: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  selectedApplicantIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedApplicantIds: PropTypes.func.isRequired,
  handleUpdateJobApplications: PropTypes.func.isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
};

export default ApplicantList;
