/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import RightCardContent from './RightCardContent/RightCardContent';
import { Container } from '../../general';
import { ProfileAndSettingsCard } from '../../cards';

const UserProfileSettings = ({ formData, handleChange, handleSubmit }) => (
  <Container contentWidthType="NORMAL">
    <ProfileAndSettingsCard
      rightCardContent={(
        <RightCardContent
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
    />
  </Container>
);

UserProfileSettings.propTypes = {
  formData: PropTypes.shape({
    fullName: PropTypes.string,
    phoneNumber: PropTypes.string,
    isNotificationEnabled: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UserProfileSettings;
