import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';

import Layout from '../../../../components/layout/Layout';
import { EditJob } from '../../../../components/pages';
import { LocaleContext } from '../../../../contexts/localeContext';
import { useIsFirstRender } from '../../../../hooks';
import {
  jobDetails,
  jobFunctions,
  jobLevels,
  employmentTypes,
  educationDegree,
  skills,
  companyBenefits,
  allProvinces,
  cities,
  countries,
} from '../../../../api/v1/get';
import { updateJobDetails } from '../../../../api/v1/put/job';
import { updateCompanyJobStatus } from '../../../../api/v1/put';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow } from '../../../../reducers/snackbarReducers';
import { numeralTextConverter } from '../../../../helpers/parser';

const EditJobPage = () => {
  const history = useHistory();
  const snackbarContext = useContext(SnackbarContext);
  const isFirstRender = useIsFirstRender();
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    jobTitle: '',
    jobFunction: '',
    jobLevel: '',
    employmentType: '',
    numberOfPeople: '',
    applicationDeadline: '',
    minimumEducation: '',
    requiredSkills: [],
    jobDescription: EditorState.createEmpty(),
    qualificationAndRequirements: EditorState.createEmpty(),
    country: '',
    region: '',
    city: '',
    isWorkFromHome: false,
    isWillingToRelocate: false,
    currency: '',
    salaryRangeFrom: '',
    salaryRangeUntil: '',
    perksAndBenefits: [],
    countryLabel: '',
    regionLabel: '',
    cityLabel: '',
    status: '',
  });
  const [optionsData, setOptionsData] = useState({
    jobFunctionsOptions: [],
    jobLevelsOptions: [],
    employmentTypesOptions: [],
    minimumEducationOptions: [],
    requiredSkillsOptions: [],
    countryOptions: [],
    regionOptions: [],
    cityOptions: [],
    companyBenefitsOptions: [],
  });

  const getFormDataFromLocalStorage = () => {
    if (localStorage.getItem('editJobFormData')) {
      const editJobFormData = JSON.parse(
        localStorage.getItem('editJobFormData'),
      );

      setFormData({
        ...editJobFormData,
        jobDescription: EditorState.createWithContent(
          convertFromHTML(editJobFormData.jobDescription),
        ),
        qualificationAndRequirements: EditorState.createWithContent(
          convertFromHTML(editJobFormData.qualificationAndRequirements),
        ),
      });

      return true;
    }

    return false;
  };

  const handleDataToLocalStorage = () => {
    const indexFinder = (key) => {
      const index = optionsData[`${key}Options`].findIndex(
        ({ value }) => value === parseInt(formData[key], 10),
      );

      return index;
    };

    const parsedFormData = {
      ...formData,
      jobDescription: convertToHTML(
        formData.jobDescription.getCurrentContent(),
      ),
      qualificationAndRequirements: convertToHTML(
        formData.qualificationAndRequirements.getCurrentContent(),
      ),
    };
    const parsedLocationData = {
      city: optionsData.cityOptions[indexFinder('city')],
      region: optionsData.regionOptions[indexFinder('region')],
      country: optionsData.countryOptions[indexFinder('country')],
    };

    localStorage.setItem('editJobFormData', JSON.stringify(parsedFormData));
    localStorage.setItem('editJobOptionsData', JSON.stringify(optionsData));
    localStorage.setItem(
      'editJobLocationData',
      JSON.stringify(parsedLocationData),
    );
  };

  const handleParseFormData = () => {
    const seekerAvailabilities = [];

    if (formData.isWorkFromHome) {
      seekerAvailabilities.push('remote');
    }

    if (formData.isWillingToRelocate) {
      seekerAvailabilities.push('willing-to-relocate');
    }

    const parsedFormData = {
      name: formData.jobTitle || null,
      job_function: formData.jobFunction || null,
      job_level: formData.jobLevel || null,
      employment_type: formData.employmentType || null,
      slot: parseInt(formData.numberOfPeople, 10) || null,
      due_date: formData.applicationDeadline
        ? moment(formData.applicationDeadline).format()
        : null,
      minimum_education: formData.minimumEducation,
      skills: formData.requiredSkills,
      description: convertToHTML(formData.jobDescription.getCurrentContent()),
      qualification: convertToHTML(
        formData.qualificationAndRequirements.getCurrentContent(),
      ),
      city_id: parseInt(formData.city, 10),
      salary_currency: formData.currency || null,
      salary_range_from: numeralTextConverter(formData.salaryRangeFrom) || null,
      salary_range_until: numeralTextConverter(formData.salaryRangeUntil) || null,
      benefit_ids: formData.perksAndBenefits.map(({ id }) => id),
      seeker_availabilities: seekerAvailabilities,
    };

    return parsedFormData;
  };

  const handleSaveAsDraft = async () => {
    setIsSubmitting(true);

    try {
      if (formData.status === 'open') {
        await updateCompanyJobStatus(jobId, { status: 'draft' });
      }

      await updateJobDetails(jobId, handleParseFormData());
      localStorage.removeItem('editJobFormData');
      localStorage.removeItem('editJobOptionsData');
      localStorage.removeItem('editJobLocationData');
      history.push(`/${locale}/job?filter=all&activeModal=draft`);
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to save as draft.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (formData.status === 'draft') {
        await updateCompanyJobStatus(jobId, { status: 'open' });
      }
      await updateJobDetails(jobId, handleParseFormData());
      localStorage.removeItem('editJobDetailsData');
      localStorage.removeItem('editPerksAndBenefitsData');
      localStorage.removeItem('editJobLocationData');
      history.push(`/${locale}/job?filter=all&activeModal=create`);
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to update job.'));
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const countriesRes = await countries();
      const parsedCountriesRes = countriesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        countryOptions: parsedCountriesRes,
      }));
    };

    const fetchProvinces = async () => {
      const provincesRes = await allProvinces({ country_id: formData.country });
      const parsedProvincesRes = provincesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        regionOptions: parsedProvincesRes,
      }));
    };

    const fetchCities = async () => {
      const citiesRes = await cities({
        country_id: formData.country,
        province_id: formData.region,
      });
      const parsedCitiesRes = citiesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        cityOptions: parsedCitiesRes,
      }));
    };

    if (!isFirstRender) {
      fetchCountries();
      if (formData.country) fetchProvinces();
      if (formData.region) fetchCities();
    }
  }, [formData.country, formData.region]);

  useEffect(() => {
    const fetchJobDetails = async () => {
      const jobDetailsRes = await jobDetails(jobId);

      const parsedSkills = jobDetailsRes.data.data.skills.map((d) => d.id);

      const seekerAvailabilitiesParser = (seekerAvailabilities) => {
        const status = jobDetailsRes.data.data.seeker_availabilities.find(
          (d) => d.id === seekerAvailabilities,
        );

        if (status) return true;

        return false;
      };

      const parsedPerksAndBenefits = jobDetailsRes.data.data.benefits.map(
        (d) => ({
          id: d.id,
          name: d.label,
        }),
      );

      setFormData({
        jobTitle: jobDetailsRes.data.data.name,
        jobFunction: jobDetailsRes.data.data.job_function?.id || '',
        jobLevel: jobDetailsRes.data.data.job_level?.id || '',
        employmentType: jobDetailsRes.data.data.employment_type?.id || '',
        numberOfPeople: jobDetailsRes.data.data.slot?.toString(),
        applicationDeadline: moment(jobDetailsRes.data.data.due_date).format(
          'yyyy-MM-DD',
        ),
        minimumEducation: jobDetailsRes.data.data.minimum_education?.id || '',
        requiredSkills: parsedSkills,
        jobDescription: EditorState.createWithContent(
          convertFromHTML(jobDetailsRes.data.data.description),
        ),
        qualificationAndRequirements: EditorState.createWithContent(
          convertFromHTML(jobDetailsRes.data.data.qualification),
        ),
        country: jobDetailsRes.data.data.country?.id.toString() || '',
        region: jobDetailsRes.data.data.province?.id.toString() || '',
        city: jobDetailsRes.data.data.city?.id.toString() || '',
        isWorkFromHome: seekerAvailabilitiesParser('remote'),
        isWillingToRelocate: seekerAvailabilitiesParser('willing-to-relocate'),
        currency: jobDetailsRes.data.data.salary_currency || 'IDR',
        salaryRangeFrom: jobDetailsRes.data.data.salary_range_from?.toString(),
        salaryRangeUntil: jobDetailsRes.data.data.salary_range_until?.toString(),
        perksAndBenefits: parsedPerksAndBenefits,
        countryLabel: jobDetailsRes.data.data.country?.label,
        regionLabel: jobDetailsRes.data.data.province?.label,
        cityLabel: jobDetailsRes.data.data.city?.label,
        status: jobDetailsRes.data.data.status,
      });
    };

    const fetchOptions = async () => {
      const fetchJobFunctionsOptions = async () => {
        const jobFunctionsRes = await jobFunctions();
        const options = jobFunctionsRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchJobLevelsOptions = async () => {
        const jobLevelsRes = await jobLevels();
        const options = jobLevelsRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchEmploymentTypesOptions = async () => {
        const employmentTypesRes = await employmentTypes();
        const options = employmentTypesRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchEducationDegreeOptions = async () => {
        const educationDegreeRes = await educationDegree();
        const options = educationDegreeRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchSkillsOptions = async () => {
        const skillsRes = await skills();
        const options = skillsRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchCompanyBenefits = async () => {
        const companyBenefitsRes = await companyBenefits();
        const parsedCompanyBenefitsRes = companyBenefitsRes.data.data.map(
          ({ id, label }) => ({
            id,
            name: label,
          }),
        );

        return parsedCompanyBenefitsRes;
      };

      const optionsPromises = [
        fetchJobFunctionsOptions(),
        fetchJobLevelsOptions(),
        fetchEmploymentTypesOptions(),
        fetchEducationDegreeOptions(),
        fetchSkillsOptions(),
        fetchCompanyBenefits(),
      ];
      const [
        jobFunctionsOptions,
        jobLevelsOptions,
        employmentTypesOptions,
        minimumEducationOptions,
        requiredSkillsOptions,
        companyBenefitsOptions,
      ] = await Promise.all(optionsPromises);

      setOptionsData((prevState) => ({
        ...prevState,
        jobFunctionsOptions,
        jobLevelsOptions,
        employmentTypesOptions,
        minimumEducationOptions,
        requiredSkillsOptions,
        companyBenefitsOptions,
      }));
    };

    if (localStorage.getItem('pageSource') === 'edit-preview-page') {
      getFormDataFromLocalStorage();
      localStorage.removeItem('pageSource');
    } else {
      fetchJobDetails();
    }
    fetchOptions();
  }, []);

  return (
    <Layout isPaddingActive={false}>
      <EditJob
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formData={formData}
        setFormData={setFormData}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        handleDataToLocalStorage={handleDataToLocalStorage}
        handleSaveAsDraft={handleSaveAsDraft}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </Layout>
  );
};

export default EditJobPage;
