import { css } from '@emotion/react';

export const applicantCardContainerStyles = (isEmpty, backgroundImage) => css`
  min-height: 500px;
  margin: 25px 0 0 0;
  background-image: ${isEmpty ? `url(${backgroundImage})` : 'none'};
  background-repeat: no-repeat;
  background-size: 450px 318px;
  background-position: center;
`;

export const paginationContainerStyles = css`
  margin: 60px 0 0 0;
`;
