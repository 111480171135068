import Layout from '../../components/layout/Layout';
import { TermsAndCondition } from '../../components/pages';

const TermsAndConditionPage = () => (
  <Layout>
    <TermsAndCondition />
  </Layout>
);

export default TermsAndConditionPage;
