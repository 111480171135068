/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';

import { ShortText, Icon } from '../../../../general';
import {
  containerStyles,
  rowStyles,
  titleStyles,
  employmentTypeBadge,
  employmentType,
  nameAndLocationContainerStyles,
  nameStyles,
  iconAndLocationContainerStyles,
  iconStyles,
  locationStyles,
  dateStyles,
  listContainerStyles,
  singleListStyles,
} from './ExperienceList.styles';

const ExperienceList = ({ data, isEducation }) => {
  const handleSplitDescription = () => {
    let symbol = '•';
    let arr = null;
    if (data.description.includes('•') || data.description.includes('-')) {
      if (!data.description.includes('•') && data.description.includes('-')) {
        symbol = '-';
      }
      arr = data.description.split(symbol);
    } else {
      arr = [data.description];
    }
    return arr;
  };

  return (
    <div css={containerStyles}>
      <div css={rowStyles}>
        <ShortText styles={titleStyles}>
          {isEducation && data.level?.label && `${data.level?.label} in ${data.field_of_study?.label} `}
          {!isEducation && data.title}
        </ShortText>
        {!isEducation && (
          <div css={employmentTypeBadge}>
            <ShortText styles={employmentType}>
              {data.hour_commitment_type}
            </ShortText>
          </div>
        )}
      </div>
      <div css={rowStyles}>
        {data.city?.label && (
          <div css={nameAndLocationContainerStyles}>
            <ShortText styles={nameStyles}>{data.institution_name}</ShortText>
            <div css={iconAndLocationContainerStyles}>
              <Icon name="map-pin-line" styles={iconStyles} />
              <ShortText styles={locationStyles}>
                {`${data.city?.label}, ${data.country?.label}`}
              </ShortText>
            </div>
          </div>
        )}
        {data.date_from && (
          <ShortText styles={dateStyles}>
            {`${moment(data.date_from).format('MMM, YYYY')} - ${
              data.date_until
                ? moment(data.date_until).format('MMM, YYYY')
                : 'present'
            }`}
          </ShortText>
        )}
      </div>
      {!isEducation && (
        <ul css={listContainerStyles}>
          {handleSplitDescription().map((d, i) => (
            <li key={d} css={singleListStyles}>
              {i > 0 ? `-${d}` : d}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ExperienceList.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    hour_commitment_type: PropTypes.string,
    date_from: PropTypes.string,
    date_until: PropTypes.string,
    institution_name: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.shape({
      label: PropTypes.string,
    }),
    field_of_study: PropTypes.shape({
      label: PropTypes.string,
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    province: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
  isEducation: PropTypes.bool,
};

ExperienceList.defaultProps = {
  isEducation: false,
};

export default ExperienceList;
