import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const companyBenefit = (data) => (
  axios.post(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/benefits`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default companyBenefit;
