/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';

import StatusPills from './StatusPill/StatusPill';
import { Icon, ShortText } from '../../../../general';
import { roles } from '../../../../../configs';
import { useDetectOutsideClick } from '../../../../../hooks';
import {
  memberListStyles,
  avatarAndMemberDetailsContainerStyles,
  avatarStyles,
  imageStyles,
  groupIconStyles,
  nameAndStatusAndRoleContainerStyles,
  nameAndRoleWrapperStyles,
  nameStyles,
  roleStyles,
  dropdownContainerStyles,
  moreButtonStyles,
  moreIconStyles,
  dropdownStyles,
  dropdownListStyles,
  dropdownButtonStyles,
} from './MemberList.styles';

const MemberList = ({
  id,
  imageUrl,
  name,
  email,
  status,
  memberRole,
  setEditMemberModalData,
  setManageMemberModalData,
  setCurrentUserIdToBeActivated,
  setActiveConfirmationModalKey,
  setConfirmationModalStatus,
}) => {
  const dropdownRef = useRef();
  const [isDropdownShown, setDropdownStatus] = useState(false);

  useDetectOutsideClick(dropdownRef, () => {
    if (isDropdownShown) setDropdownStatus(false);
  });

  const handleDropdownToggle = () => {
    setDropdownStatus((prevState) => !prevState);
  };

  const handleOpenEditMemberModal = () => {
    setEditMemberModalData({
      id,
      status: true,
    });
  };

  const handleOpenDeactivateMemberModal = () => {
    setManageMemberModalData({
      id,
      name,
      email,
      status: true,
    });
  };

  const handleOpenActivateMemberModal = () => {
    setActiveConfirmationModalKey('activateMemberModal');
    setCurrentUserIdToBeActivated(id);
    setConfirmationModalStatus((prevState) => ({
      ...prevState,
      activateMemberModal: true,
    }));
  };

  return (
    <div css={memberListStyles}>
      <div css={avatarAndMemberDetailsContainerStyles}>
        <div css={avatarStyles}>
          {imageUrl ? (
            <img src={imageUrl} css={imageStyles} alt={name} />
          ) : (
            <Icon name="group-line" styles={groupIconStyles} />
          )}
        </div>
        <div css={nameAndStatusAndRoleContainerStyles}>
          <div css={nameAndRoleWrapperStyles}>
            <ShortText styles={nameStyles}>{name}</ShortText>
            <StatusPills status={status} />
          </div>
          <ShortText styles={roleStyles}>{memberRole.toLowerCase()}</ShortText>
        </div>
      </div>
      <div css={dropdownContainerStyles}>
        <button
          type="button"
          css={moreButtonStyles}
          onClick={handleDropdownToggle}
        >
          <Icon name="more-2-fill" styles={moreIconStyles} />
        </button>
        <nav ref={dropdownRef} css={dropdownStyles(isDropdownShown)}>
          <ul>
            <li css={dropdownListStyles}>
              <button
                type="button"
                css={dropdownButtonStyles}
                onClick={handleOpenEditMemberModal}
              >
                edit member
              </button>
            </li>
            {status === 'INACTIVE' ? (
              <li css={dropdownListStyles}>
                <button
                  type="button"
                  css={dropdownButtonStyles}
                  onClick={handleOpenActivateMemberModal}
                >
                  activate member
                </button>
              </li>
            ) : (
              <li css={dropdownListStyles}>
                <button
                  type="button"
                  css={dropdownButtonStyles}
                  onClick={handleOpenDeactivateMemberModal}
                >
                  deactivate member
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

MemberList.propTypes = {
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  status: PropTypes.string.isRequired,
  memberRole: PropTypes.PropTypes.oneOf(roles).isRequired,
  setEditMemberModalData: PropTypes.func.isRequired,
  setManageMemberModalData: PropTypes.func.isRequired,
  setCurrentUserIdToBeActivated: PropTypes.func.isRequired,
  setActiveConfirmationModalKey: PropTypes.func.isRequired,
  setConfirmationModalStatus: PropTypes.func.isRequired,
};

MemberList.defaultProps = {
  email: '',
  imageUrl: '',
};

export default MemberList;
