import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import { ChangePasswordSettings } from '../../../components/pages';
import { changePassword } from '../../../api/v1/put/auth';
import { LoadingContext } from '../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../reducers/loadingReducers';
import { isShow } from '../../../reducers/snackbarReducers';
import { LocaleContext } from '../../../contexts/localeContext';
import { SnackbarContext } from '../../../contexts/snackbarContext';

const ChangePassword = () => {
  const history = useHistory();
  const snackbarContext = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmationNewPassword: '',
  });
  const [changePasswordStatus, setChangePasswordStatus] = useState({
    isSubmitting: false,
    isSuccessModalShown: false,
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetForm = () => {
    setFormData({
      currentPassword: '',
      newPassword: '',
      confirmationNewPassword: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(isLoading());
    setChangePasswordStatus((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    try {
      const parsedData = {
        old_password: formData.currentPassword,
        new_password: formData.newPassword,
        retype_password: formData.confirmationNewPassword,
      };

      await changePassword(parsedData);
      handleResetForm();
      setChangePasswordStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isSuccessModalShown: true,
      }));
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to change password.'));
      handleResetForm();
      setChangePasswordStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
    } finally {
      dispatch(isNotLoading());
    }
  };

  const handleRedirectToLogout = async () => {
    await localStorage.removeItem('loginRes');
    await localStorage.removeItem('introspectionRes');
    history.push(`/${locale}/login`);
  };

  return (
    <Layout>
      <ChangePasswordSettings
        formData={formData}
        changePasswordStatus={changePasswordStatus}
        setChangePasswordStatus={setChangePasswordStatus}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleRedirectToLogout={handleRedirectToLogout}
      />
    </Layout>
  );
};

export default ChangePassword;
