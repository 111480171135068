import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const headingStyles = css`
  font-weight: 600;
  font-size: 20px;
  color: ${colors.PRIMARY_BLUE};
`;

export const formStyles = css`
  margin: 24px 0 0 0;
`;

export const inputContainerStyles = css`
  margin: 24px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const notificationContainerStyles = css`
  margin: 24px 0 0 0;
`;

export const submitButtonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 60px 0 0 0;
`;
