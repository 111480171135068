/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import RightCardContent from './RightCardContent/RightCardContent';
import SuccessModal from './SuccessModal/SuccessModal';
import { Container } from '../../general';
import { ProfileAndSettingsCard } from '../../cards';

const ChangeEmailSettings = ({
  formData,
  changeEmailStatus,
  setChangeEmailStatus,
  handleChange,
  handleSubmit,
}) => (
  <Container contentWidthType="NORMAL">
    <ProfileAndSettingsCard
      rightCardContent={(
        <RightCardContent
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
    />
    <SuccessModal
      changeEmailStatus={changeEmailStatus}
      setChangeEmailStatus={setChangeEmailStatus}
    />
  </Container>
);

ChangeEmailSettings.propTypes = {
  formData: PropTypes.shape({
    currentEmail: PropTypes.string,
    newEmail: PropTypes.string,
  }).isRequired,
  changeEmailStatus: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setChangeEmailStatus: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ChangeEmailSettings;
