import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const dropzoneContainerStyles = css`
  width: 100%;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const textStyles = css`
  text-align: center;
  font-size: 12px;
  color: ${colors.DARKER_GREY};
`;

export const previewContainerStyles = css`
  margin: 24px 0 0 0;
`;

export const imagePreviewContainerStyles = (width, height) => css`
  width: ${width}px;
  height: ${height}px;
  margin: 8px 0 0 0;
`;

export const imagePreviewStyles = css`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
