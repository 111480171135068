const defaultState = {
  type: 'FALSE',
  message: '',
};

export const reducer = (_, action) => {
  switch (action.type) {
    case 'TRUE':
      return action;
    case 'FALSE':
      return action;
    default:
      return defaultState;
  }
};

export const isShow = (status, message) => ({
  type: 'TRUE',
  status,
  message,
});

export const isNotShow = () => ({
  type: 'FALSE',
  status: '',
  message: '',
});
