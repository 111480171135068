import axios from 'axios';

import { getAccessToken } from '../../../helpers/localStorage';

const updateJobDetails = (jobId, data) => (
  axios.put(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/jobs/${jobId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default updateJobDetails;
