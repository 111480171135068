/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import {
  Modal,
  Icon,
  ShortText,
  Text,
} from '../../../general';
import { PlainCard } from '../../../cards';
import {
  cardStyles,
  loaderIconContainerStyles,
  loaderIconStyles,
  titleStyles,
  descriptionStyles,
} from './VerificationModal.styles';

const VerificationModal = ({ loginStatus }) => (
  <Modal isModalShown={loginStatus.isSubmitting}>
    <PlainCard styles={cardStyles}>
      <div css={loaderIconContainerStyles}>
        <Icon name="loader-5-line" styles={loaderIconStyles} />
      </div>
      <ShortText styles={titleStyles}>verifying</ShortText>
      <Text styles={descriptionStyles}>please wait a moment, we will verify your data.</Text>
    </PlainCard>
  </Modal>
);

VerificationModal.propTypes = {
  loginStatus: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isError: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string,
    }),
    isSuccess: PropTypes.bool,
    isDeactivatedAccountModalShown: PropTypes.bool,
  }).isRequired,
};

export default VerificationModal;
