/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';

import { ShortText, ExternalLink } from '../../../../general';
import {
  containerStyles,
  rowStyles,
  titleStyles,
  nameAndLocationContainerStyles,
  nameStyles,
  dateStyles,
  linkStyles,
} from './CertificateList.styles';

const CertificateList = ({ data }) => (
  <div css={containerStyles}>
    <div css={rowStyles}>
      <ShortText styles={titleStyles}>
        {data.title}
      </ShortText>
    </div>
    <div css={rowStyles}>
      <div css={nameAndLocationContainerStyles}>
        <ShortText styles={nameStyles}>{data.institution_name}</ShortText>
      </div>
      <ShortText styles={dateStyles}>
        {`${moment(data.date_from).format('MMM, YYYY')} - ${data.date_until
          ? moment(
            data.date_until,
          ).format('MMM, YYYY')
          : 'present'
        }`}
      </ShortText>
    </div>
    {data.link && (
      <div css={rowStyles}>
        <ExternalLink styles={linkStyles} href={data.link}>{data.link}</ExternalLink>
      </div>
    )}
  </div>
);

CertificateList.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    link: PropTypes.string,
    date_from: PropTypes.string,
    date_until: PropTypes.string,
    institution_name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default CertificateList;
