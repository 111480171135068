import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const passwordStrengthBarStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 0 0;
`;

export const barContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const barStyles = css`
  width: 40px;
  height: 6px;
  margin: 0 4px 0 0;
  border-radius: 3px;
  background-color: ${colors.GREY};

  &:last-of-type {
    margin: 0;
  }
`;

export const activeBarStyles = css`
  background-color: ${colors.LIGHT_BLUE};
`;

export const statusStyles = css`
  margin: 0 0 0 6px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;
