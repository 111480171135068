/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import RightCardContent from './RightCardContent/RightCardContent';
import { Container } from '../../general';
import { ProfileAndSettingsCard } from '../../cards';

const CompanyProfileSettings = ({
  formData,
  setFormData,
  optionsData,
  handleUpdate,
  isSubmitting,
}) => (
  <Container contentWidthType="NORMAL">
    <ProfileAndSettingsCard
      rightCardContent={(
        <RightCardContent
          formData={formData}
          setFormData={setFormData}
          optionsData={optionsData}
          handleUpdate={handleUpdate}
          isSubmitting={isSubmitting}
        />
      )}
    />
  </Container>
);

CompanyProfileSettings.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.shape({}),
    industry: PropTypes.string,
    company_size: PropTypes.string,
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
  optionsData: PropTypes.shape({
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default CompanyProfileSettings;
