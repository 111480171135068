/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useContext } from 'react';

import JobPostCard from './JobPostCard/JobPostCard';
import bannerPlaceholder from '../../../assets/images/pages/recruiter/company-profile/banner-placeholder.png';
import {
  Button,
  Container,
  Heading,
  Icon,
  LabelAndValue,
  Link,
  ShortText,
  Pagination,
} from '../../general';
import { LocaleContext } from '../../../contexts/localeContext';
import { roleEligibilityChecker } from '../../../helpers/localStorage';
import {
  companyInfoPaperStyles,
  bannerStyles,
  upperCompanyInfoPaperStyles,
  companyLogoContainerStyles,
  companyLogoStyles,
  companyNameStyles,
  locationAndButtonContainerStyles,
  iconAndLocationNameContainerStyles,
  iconStyles,
  locationNameStyles,
  lowerCompanyInfoPaperStyles,
  rowStyles,
  labelAndValueForRowContainerStyles,
  labelAndValueContainerStyles,
  companyJobListPaperStyles,
  titleAndSearchContainerStyles,
  jobListPaperTitleStyles,
  searchInputContainerStyles,
  searchIconStyles,
  searchInputStyles,
  jobPostCardContainerStyles,
} from './CompanyProfile.styles';

const CompanyProfile = ({
  companyProfileData,
  searchTerm,
  setSearchTerm,
  jobData,
  pageInfo,
  setPageInfo,
}) => {
  const { locale } = useContext(LocaleContext);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (page) => {
    setPageInfo((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  return (
    <Container contentWidthType="NORMAL">
      <div css={companyInfoPaperStyles}>
        <div
          css={bannerStyles(
            companyProfileData?.banner_url || bannerPlaceholder,
          )}
        />
        <div css={upperCompanyInfoPaperStyles}>
          <div css={companyLogoContainerStyles}>
            <img
              src={companyProfileData.logo_url}
              css={companyLogoStyles}
              alt={companyProfileData.name}
            />
          </div>
          <Heading type="h2" styles={companyNameStyles}>
            {companyProfileData.name}
          </Heading>
          <div css={locationAndButtonContainerStyles}>
            <div css={iconAndLocationNameContainerStyles}>
              <Icon name="map-pin-line" styles={iconStyles} />
              <ShortText styles={locationNameStyles}>
                {`${companyProfileData.city?.label}, ${companyProfileData.country?.label}`}
              </ShortText>
            </div>
            {roleEligibilityChecker() && (
              <Link to={`/${locale}/profile-and-settings/company-profile`}>
                <Button type="button" size="M" inverted>
                  edit profile
                </Button>
              </Link>
            )}
          </div>
        </div>
        <div css={lowerCompanyInfoPaperStyles}>
          <div css={rowStyles}>
            <LabelAndValue
              styles={labelAndValueForRowContainerStyles}
              label="company industry"
              value={companyProfileData.industry.label}
            />
            <LabelAndValue
              styles={labelAndValueForRowContainerStyles}
              label="organization size"
              value={companyProfileData.company_size.label.label}
            />
          </div>
          <div css={rowStyles}>
            <LabelAndValue
              styles={labelAndValueForRowContainerStyles}
              label="office address"
              value={companyProfileData.address}
            />
            <LabelAndValue
              styles={labelAndValueForRowContainerStyles}
              label="website URL"
              valueType="URL"
              value={companyProfileData.website}
            />
          </div>
          <LabelAndValue
            styles={labelAndValueContainerStyles}
            label="about company"
            valueType="HTML"
            value={ReactHtmlParser(companyProfileData.description)}
          />
        </div>
      </div>
      <div css={companyJobListPaperStyles}>
        <div css={titleAndSearchContainerStyles}>
          <Heading type="h2" styles={jobListPaperTitleStyles}>
            all jobs posted
          </Heading>
          <div css={searchInputContainerStyles}>
            <Icon name="search-line" styles={searchIconStyles} />
            <input
              type="text"
              css={searchInputStyles}
              name="jobSearchTerms"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search job name"
            />
          </div>
        </div>
        <div css={jobPostCardContainerStyles}>
          {jobData.map((d) => (
            <JobPostCard key={d.id} data={d} />
          ))}
        </div>
        <Pagination
          pageSize={pageInfo.pageSize}
          totalCount={pageInfo.totalCount}
          currentPage={pageInfo.currentPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </Container>
  );
};

CompanyProfile.propTypes = {
  companyProfileData: PropTypes.shape({
    banner_url: PropTypes.string,
    logo_url: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    company_size: PropTypes.shape({
      label: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
    industry: PropTypes.shape({ label: PropTypes.string }),
    address: PropTypes.string,
    website: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  jobData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.shape({ logo_url: PropTypes.string }),
      name: PropTypes.string,
      city: PropTypes.PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
      country: PropTypes.PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
      due_date: PropTypes.string,
    }),
  ).isRequired,
  pageInfo: PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
};

export default CompanyProfile;
