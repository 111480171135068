/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { linkStyles } from './ExternalLink.styles';

const ExternalLink = ({ href, styles, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    css={[linkStyles, styles]}
  >
    {children}
  </a>
);

ExternalLink.propTypes = {
  styles: PropTypes.shape({}),
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

ExternalLink.defaultProps = {
  styles: css``,
};

export default ExternalLink;
