import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const linkStyles = css`
  text-decoration: none;
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;
