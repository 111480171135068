/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';
import { useState, useEffect } from 'react';

import { PlainCard } from '../../../cards';
import {
  Modal,
  ShortText,
  InputWithIcon,
  Icon,
  Button,
} from '../../../general';
import { useDebounce } from '../../../../hooks';
import { jobs } from '../../../../api/v1/get/job';
import {
  cardStyles,
  modalTitleAndCloseModalButtonStyles,
  modalTitleStyles,
  modalCloseButtonStyles,
  inputSearchStyles,
  jobListContainerStyles,
  jobListStyles,
  titleAndLocationContainerStyles,
  jobTitleStyles,
  locationContainerStyles,
  iconAndLabelContainerStyles,
  iconStyles,
  labelStyles,
  deadlineContainerStyles,
  dateStyles,
  selectJobButtonStyles,
} from './InviteUserModal.styles';

const InviteUserModal = ({
  isInviteUserModalShown,
  setInviteUserModalStatus,
  setInvitationConfirmationModalStatus,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeJobData, setActiveJobData] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dateParser = (date) => {
    const parsedDate = moment(date).format('MMMM, Do YYYY');

    return parsedDate;
  };

  const handleCloseModal = () => {
    setInviteUserModalStatus({
      seekerId: 0,
      status: false,
    });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedJob = (jobId) => {
    setInviteUserModalStatus((prevState) => ({
      ...prevState,
      status: false,
    }));
    setInvitationConfirmationModalStatus({
      jobId,
      status: true,
    });
  };

  useEffect(() => {
    const fetchActiveJobs = async () => {
      const activeJobRes = await jobs({
        job_status: 'open',
        q: debouncedSearchTerm?.trim() || '',
        limit: 9999,
      });

      setActiveJobData(activeJobRes.data.data.jobs);
    };

    if (isInviteUserModalShown.status) fetchActiveJobs();
  }, [isInviteUserModalShown.status, debouncedSearchTerm]);

  return (
    <Modal isModalShown={isInviteUserModalShown.status}>
      <PlainCard styles={cardStyles}>
        <div css={modalTitleAndCloseModalButtonStyles}>
          <ShortText styles={modalTitleStyles}>select active job</ShortText>
          <button
            type="button"
            css={modalCloseButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-fill" />
          </button>
        </div>
        <InputWithIcon
          type="text"
          containerStyles={inputSearchStyles}
          iconName="search-line"
          name="jobName"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="job name"
        />
        <div css={jobListContainerStyles}>
          {activeJobData.map((d) => (
            <div key={d.id} css={jobListStyles}>
              <div css={titleAndLocationContainerStyles}>
                <ShortText styles={jobTitleStyles}>{d.name}</ShortText>
                <div
                  css={[locationContainerStyles, iconAndLabelContainerStyles]}
                >
                  <Icon name="map-pin-line" styles={iconStyles} />
                  <ShortText styles={labelStyles}>
                    {`${d.province}, ${d.country}`}
                  </ShortText>
                </div>
              </div>
              <div css={deadlineContainerStyles}>
                <div css={iconAndLabelContainerStyles}>
                  <Icon name="time-line" styles={iconStyles} />
                  <ShortText styles={labelStyles}>deadline:</ShortText>
                </div>
                <div>
                  <ShortText styles={dateStyles}>
                    {dateParser(d.due_date)}
                  </ShortText>
                </div>
              </div>
              <Button
                type="button"
                styles={selectJobButtonStyles}
                size="S"
                color="LIGHT_BLUE"
                onClick={() => handleSelectedJob(d.id)}
              >
                select job
              </Button>
            </div>
          ))}
        </div>
      </PlainCard>
    </Modal>
  );
};

InviteUserModal.propTypes = {
  isInviteUserModalShown: PropTypes.shape({
    seekerId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setInviteUserModalStatus: PropTypes.func.isRequired,
  setInvitationConfirmationModalStatus: PropTypes.func.isRequired,
};

export default InviteUserModal;
