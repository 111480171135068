/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import {
  Modal,
  Heading,
  Button,
  Icon,
  Text,
  ShortText,
  InputWithIcon,
} from '../../../general';
import { PlainCard } from '../../../cards';
import {
  cardStyles,
  cardHeaderStyles,
  titleStyles,
  closeButtonStyles,
  closeIconStyles,
  cardBodyStyles,
  labelStyles,
  inputStyles,
  descriptionStyles,
  emailHighlightStyles,
  buttonStyles,
  errorMessageHighlightStyles,
} from './ForgotPasswordModal.styles';

const ForgotPasswordModal = ({
  resetPasswordEmail,
  setResetPasswordEmail,
  forgotPasswordStatus,
  setForgotPasswordStatus,
  handleResetPasswordSubmit,
}) => {
  const handleCloseModal = () => {
    setResetPasswordEmail('');
    setForgotPasswordStatus({
      isModalShown: false,
      isSubmitting: false,
      isError: {
        status: false,
        message: '',
      },
      isSuccess: false,
    });
  };

  const handleResetPasswordEmailChange = (e) => {
    setResetPasswordEmail(e.target.value);
  };

  return (
    <Modal isModalShown={forgotPasswordStatus.isModalShown}>
      <PlainCard styles={cardStyles}>
        <div css={cardHeaderStyles}>
          <Heading type="h3" styles={titleStyles}>
            {forgotPasswordStatus.isSuccess
              ? 'please check your email'
              : 'forgot your password?'}
          </Heading>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <div css={cardBodyStyles}>
          {forgotPasswordStatus.isSuccess && (
            <>
              <Text styles={descriptionStyles}>
                link to reset password has been sent to&nbsp;
                <ShortText styles={emailHighlightStyles}>
                  {resetPasswordEmail}
                </ShortText>
              </Text>
              <Button type="button" styles={buttonStyles} size="FULL" onClick={handleCloseModal}>
                great!
              </Button>
            </>
          )}
          {forgotPasswordStatus.isError.status && (
            <Text styles={descriptionStyles}>
              Failed to reset password. Reason:&nbsp;
              <ShortText styles={errorMessageHighlightStyles}>
                {forgotPasswordStatus.isError.message}
              </ShortText>
            </Text>
          )}
          {!forgotPasswordStatus.isSuccess && !forgotPasswordStatus.isError.status && (
            <form onSubmit={handleResetPasswordSubmit}>
              <label css={labelStyles}>enter your email address</label>
              <InputWithIcon
                type="email"
                containerStyles={inputStyles}
                iconName="mail-line"
                name="resetPasswordEmail"
                value={resetPasswordEmail}
                onChange={handleResetPasswordEmailChange}
                placeholder="email"
              />
              <Button type="submit" styles={buttonStyles} size="FULL">
                {forgotPasswordStatus.isSubmitting ? 'submitting' : 'reset password'}
              </Button>
            </form>
          )}
        </div>
      </PlainCard>
    </Modal>
  );
};

ForgotPasswordModal.propTypes = {
  resetPasswordEmail: PropTypes.string.isRequired,
  setResetPasswordEmail: PropTypes.func.isRequired,
  forgotPasswordStatus: PropTypes.shape({
    isModalShown: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isError: PropTypes.shape({
      message: PropTypes.string,
      status: PropTypes.bool,
    }),
    isSuccess: PropTypes.bool,
  }).isRequired,
  setForgotPasswordStatus: PropTypes.func.isRequired,
  handleResetPasswordSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
