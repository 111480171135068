/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import modalImage from '../../../../assets/images/pages/change-email-settings/modal.png';
import {
  Modal,
  Heading,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { LocaleContext } from '../../../../contexts/localeContext';
import { changeEmail } from '../../../../locale';
import {
  cardStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonStyles,
} from './SuccessModal.styles';

const SuccessModal = ({ changeEmailStatus, setChangeEmailStatus }) => {
  const { locale } = useContext(LocaleContext);

  const handleCloseModal = () => {
    setChangeEmailStatus((prevState) => ({
      ...prevState,
      isSuccessModalShown: false,
    }));
  };

  return (
    <Modal isModalShown={changeEmailStatus.isSuccessModalShown}>
      <PlainCard styles={cardStyles}>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          {changeEmail.modal.title[locale]}
        </Heading>
        <Text styles={descriptionStyles}>
          {changeEmail.modal.description[locale]}
        </Text>
        <Button
          type="button"
          styles={buttonStyles}
          size="M"
          color="PRIMARY_BLUE"
          onClick={handleCloseModal}
        >
          {changeEmail.modal.button[locale]}
        </Button>
      </PlainCard>
    </Modal>
  );
};

SuccessModal.propTypes = {
  changeEmailStatus: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setChangeEmailStatus: PropTypes.func.isRequired,
};

export default SuccessModal;
