/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import companyLogo from '../../../assets/svg/logo.svg';
// import indonesianFlag from '../../../assets/images/flags/indonesia.png';
// import unitedStatesFlag from '../../../assets/images/flags/united-states.png';
import { LocaleContext } from '../../../contexts/localeContext';

import {
  navbarContentStyles,
  rightMenuStyles,
  listContainerStyles,
  listStylesTnc,
  linkStylesTnc,
  navbarStylesTnc,
  // notificationIconStyles,

} from './Header.styles';
import { localeToEnglish, localeToIndonesian } from '../../../reducers/localeReducer';

const HeaderTnc = () => {
  const { locale, dispatch } = useContext(LocaleContext);

  const handleLocaleChange = (lang) => {
    if (lang === 'en') {
      dispatch(localeToEnglish());
    } else if (lang === 'id') {
      dispatch(localeToIndonesian());
    }
  };

  return (
    <div css={navbarStylesTnc}>
      <div css={navbarContentStyles}>
        <NavLink to={`/${locale}/job?filter=all`}>
          <img src={companyLogo} alt="hacktiv8-logo" />
        </NavLink>
        <div css={rightMenuStyles}>
          <ul css={listContainerStyles} style={{ justifyContent: 'center', alignContent: 'center', gap: '5px' }}>
            <li css={listStylesTnc}>
              <button
                style={{ border: 'none', cursor: 'pointer' }}
                type="button"
                onClick={() => {
                  handleLocaleChange('id');
                }}
                css={linkStylesTnc(locale === 'id')}
                tabIndex={0}
              >
                IDN
              </button>
            </li>
            <li css={listStylesTnc}>
              <button
                style={{ border: 'none', cursor: 'pointer' }}
                type="button"
                onClick={() => {
                  handleLocaleChange('en');
                }}
                css={linkStylesTnc(locale === 'en')}
                tabIndex={0}
              >
                ENG
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderTnc;
