/** @jsxImportSource @emotion/react */
import { useContext } from 'react';

import { Button, Link } from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { useQuery } from '../../../../hooks';
import { roleEligibilityChecker } from '../../../../helpers/localStorage';
import {
  navbarStyles,
  navbarContentStyles,
  unorderedListStyles,
  listStyles,
  linkStyles,
} from './Navbar.styles';

const Navbar = () => {
  const query = useQuery();
  const { locale } = useContext(LocaleContext);

  const tabs = [
    {
      key: 'all',
      label: 'all jobs',
    },
    {
      key: 'open',
      label: 'open',
    },
    {
      key: 'closed',
      label: 'closed',
    },
    {
      key: 'draft',
      label: 'draft',
    },
  ];

  const activeTabChecker = (key) => {
    if (key === query.get('filter')) return true;

    return false;
  };

  return (
    <nav css={navbarStyles}>
      <div css={navbarContentStyles}>
        <ul css={unorderedListStyles}>
          {tabs.map(({ key, label }) => (
            <li key={key} css={listStyles(activeTabChecker(key))}>
              <Link to={`/${locale}/job?filter=${key}`} styles={linkStyles}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
        {roleEligibilityChecker() && (
          <Link to={`/${locale}/job/create`}>
            <Button type="button" size="L" color="LIGHT_BLUE">
              post a new job
            </Button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
