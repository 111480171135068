import { convertToHTML } from 'draft-convert';

const formValidator = (formData, dispatch, isShow) => {
  if (!formData.name) {
    dispatch(isShow('error', 'name field is required.'));

    return false;
  }

  if (convertToHTML(formData.description.getCurrentContent()) === '<p></p>') {
    dispatch(isShow('error', 'description field is required.'));

    return false;
  }

  if (!formData.industry) {
    dispatch(isShow('error', 'industry field is required.'));

    return false;
  }

  if (!formData.company_size) {
    dispatch(isShow('error', 'company size field is required.'));

    return false;
  }

  if (!formData.country) {
    dispatch(isShow('error', 'country field is required.'));

    return false;
  }

  if (!formData.region) {
    dispatch(isShow('error', 'region field is required.'));

    return false;
  }

  if (!formData.city) {
    dispatch(isShow('error', 'city field is required.'));

    return false;
  }

  if (!formData.address) {
    dispatch(isShow('error', 'address field is required.'));

    return false;
  }

  if (!formData.website) {
    dispatch(isShow('error', 'website URL field is required.'));

    return false;
  }

  // if (!formData.companyLogo) {
  //   dispatch(isShow('error', 'company logo is required.'));

  //   return false;
  // }

  return true;
};

export default formValidator;
