/** @jsxImportSource @emotion/react */
import { Container, Text } from '../../general';
import {
  containerStyles,
  contentStyles,
  titleTextStyles,
  pointContainerStyles,
  pointTitleStyles,
  pointTextStyles,
  horizontalLineStyles,
  contentContainerStyles,
} from './PrivacyPolicy.styles';

const PrivacyPolicy = () => {
  const contents = [
    {
      title: 'apa yang termasuk dalam kebijakan privasi ini?',
      description:
        'Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan dan menggunakan informasi dari pengguna website kami yang memasukkan data dan menggunakan layanan kami, termasuk Informasi Pribadi. Kebijakan privasi ini juga meliputi perlindungan kami terhadap informasi personal yang kami bagikan kepada partner bisnis kami, atau data yang berikan pada kami melalui website dari pihak ketiga. Kebijakan ini tidak berlaku pada website yang tidak kami miliki, atau perorangan yang tidak kami pekerjakan.',
    },
    {
      title: 'bagaimana kami menjaga data pribadi murid kami?',
      description:
        'Jika umur anda dibawah 18 tahun, anda hanya dapat menggunakan layanan dan Informasi Pribadi ini dengan izin orang tua atau wali anda. Kami mengumpulkan Informasi Pribadi dari murid kami untuk kebutuhan pembelajaran di Hacktiv8. Kami membutuhkan data sekolah dan kota asal, serta persetujuan orangtua sebelum kami mengumpulkan Informasi Pribadi murid kami.',
    },
    {
      title: 'informasi apa yang kami kumpulkan?',
      description:
        'Ketika anda menggunakan website kami, anda dapat memasukkan informasi pribadi anda berupa e-mail, nama, dan informasi lainnya. Informasi yang anda berikan selanjutnya kami kumpulkan untuk kebutuhan personalisasi anda demi kebutuhan layanan yang kami berikan dan untuk meningkatkan kualitas layanan kami.',
    },
    {
      title: 'bagaimana tentang cookies?',
      description:
        'Cookies adalah pengenal alfanumerik yang digunakan untuk memindahkan data dari browser ke hard drive komputer anda untuk membuat sistem kami mengenali browser yang anda gunakan dan halaman apa yang anda kunjungi. Cookies kami tidak mengumpulkan Informasi Pribadi atau Informasi Pribadi Murid dan kami tidak menggabungkan informasi umum yang kami dapatkan melalui cookies dengan data pribadi tersebut. Kebanyakan browser memiliki opsi untuk mematikan fitur cookie, yang akan mencegah browser anda untuk menerima cookie baru. Kami menyarankan anda untuk tetap mengaktifkan fitur cookie tersebut, untuk pengalaman anda yang lebih baik dalam menggunakan website kami.',
    },
    {
      title:
        'apakah kami akan membagikan informasi pribadi atau informasi pribadi murid yang kami terima?',
      description:
        'Informasi Pribadi dan Informasi Pribadi Murid adalah bagian penting dalam bisnis kami. Kami tidak menyewakan atau menjual data anda kepada siapapun. Perlu diperhatikan bahwa Informasi Pribadi hanya berlaku kepada pengguna yang bukan murid kami.',
    },
    {
      title: 'apakah saya memiliki pilihan lain?',
      description:
        'Seperti disebutkan sebelumnya, anda diperbolehkan untuk tidak memberikan informasi anda, walaupun informasi tersebut sebenarnya dibutuhkan untuk memanfaatkan layanan kami. Anda dapat menambah atau mengubah data anda. Ketika anda mengubah informasi anda, kami mungkin memiliki informasi anda yang belum diubah. Beberapa jenis komunikasi seperti pesan tidak dapat dihapus dan kami masih menyimpan data anda walaupun data tersebut sudah anda hapus. Jika anda tidak ingin mendapatkan e-mail dari kami dan ingin meminta penghapusan data anda, silakan hubungi kami di halo@hacktiv8.com. Perlu diperhatikan bahwa jika anda tidak ingin menerima pemberitahuan legal dari kami, seperti Kebijakan Privasi, pemberitahuan legal tersebut tetap berlaku bagi anda.',
    },
    {
      title: 'perubahan kebijakan privasi',
      description:
        'Kami dapat sewaktu-waktu melakukan perubahan terhadap Kebijakan Privasi kami. Penggunaan informasi yang kami kumpulkan sesuai dengan Kebijakan Privasi yang berlaku pada saat informasi tersebut digunakan. Jika kami membuat perubahan tentang bagaimana kami menggunakan Informasi Pribadi atau Informasi Pribadi Murid, kami akan mengabarkan anda melalui e-mail atau dengan memberikan pengumuman di website kami. Pengguna terikat dengan perubahan terhadap Kebijakan Privasi ketika yang bersangkutan menggunakan website setelah perubahan tersebut.',
    },
    {
      title: 'pertanyaan',
      description:
        'Jika anda memiliki pertanyaan seputar privasi di hacktiv8.com, anda dapat mengirimkan pesan dengan detail yang anda perlukan ke halo@hacktiv8.com. Kami akan segera menjawab setiap pertanyaan anda.',
    },
  ];

  return (
    <Container containerStyles={containerStyles} contentStyles={contentStyles}>
      <Text styles={titleTextStyles}>Privacy Policy</Text>
      <div css={pointContainerStyles}>
        <Text styles={pointTitleStyles}>Tanggal Efektif, 1 Mei 2016</Text>
        <Text styles={pointTextStyles}>
          hacktiv8 ( selanjutnya disebut dengan “kami” ) mengerti bahwa anda
          sangat peduli terhadap kerahasiaan informasi pribadi anda. dengan
          mengunjungi website kami di hacktiv8.com, aplikasi, nama domain, dan
          halaman web lainnya yang kami miliki, anda mengakui bahwa anda
          menerima kebijakan yang kami terapkan. dengan menggunakan website ini,
          perlu anda ketahui bahwa data anda ditransfer dan diproses di
          indonesia.
        </Text>
        <Text styles={pointTextStyles}>
          konten apapun yang anda berikan melalui website ini, sebagaimana
          didefinisikan pada halaman terms of service adalah risiko anda.
          walaupun kami telah melakukan pembatasan hak akses terhadap informasi
          yang anda masukkan, tidak ada keamanan yang sempurna sehingga kami
          tidak dapat menjamin bahwa konten yang anda berikan di website ini
          tidak akan dilihat oleh orang lain yang tidak berhak. oleh karena itu,
          kami tidak bertanggungjawab atas penipuan atau penyalahgunaan konten
          atau informasi yang anda berikan.
        </Text>
        <Text styles={pointTextStyles}>
          setiap penggunaan konten atau informasi yang tidak sesuai adalah
          pelanggaran terhadap terms of service kami dan dapat dilaporkan ke
          halo@hacktiv8.com.
        </Text>
      </div>
      <div css={horizontalLineStyles} />
      {contents.map(({ title, description }, index) => (
        <div key={title} css={contentContainerStyles(index)}>
          <Text>{title}</Text>
          <Text>{description}</Text>
        </div>
      ))}
    </Container>
  );
};

export default PrivacyPolicy;
