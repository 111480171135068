import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const cardStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.WHITE};
`;

export const leftCardContentStyles = (height) => css`
  width: 271px;
  height: calc(${height}px - 22px);
  padding: 32px 20px 0 32px;
  border-right: 1px solid rgba(29, 61, 113, 0.05);
`;

export const listStyles = css`
  margin: 10px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const linkStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 0 0 16px;
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};

  &.isActive {
    color: ${colors.LIGHT_BLUE};
    border-radius: 8px;
    background-color: rgba(30, 91, 189, 0.07);

    i {
      color: ${colors.LIGHT_BLUE};
    }
  }
`;

export const iconStyles = css`
  margin: 0 10px 0 0;
`;

export const logoutButtonContainerStyles = css`
  margin: 60px 0 0 0;
`;

export const logoutButtonStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 0 0 16px;
  border: unset;
  background-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const rightCardContentStyles = css`
  width: 577px;
  padding: 32px;
`;
