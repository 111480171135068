import HeaderTnc from '../../components/layout/Header/HeaderTnc';
import Success from '../../components/pages/SuccessPage/Success';

const SuccessPage = () => (
  <>
    <HeaderTnc />
    <Success />
  </>
);

export default SuccessPage;
