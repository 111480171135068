/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/react';

import Text from '../Text/Text';
import ShortText from '../ShortText/ShortText';
import {
  dropzoneContainerStyles,
  labelStyles,
  textStyles,
  previewContainerStyles,
  imagePreviewContainerStyles,
  imagePreviewStyles,
} from './ReactDropzone.styles';

const ReactDropzone = ({
  containerStyles,
  dropzoneStyles,
  label,
  children,
  imagePreviewWidth,
  imagePreviewHeight,
  // maxFileWidth,
  // maxFileHeight,
  maxFileSize,
  onChange,
  currentImage,
}) => {
  const [files, setFiles] = useState([]);

  const baseStyle = dropzoneStyles;

  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles);
    setFiles(
      acceptedFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
      })),
    );
  }, []);

  // const validator = (file) => {
  //   if (maxFileWidth && maxFileHeight) {
  //     if (file.width !== maxFileWidth || file.height !== maxFileHeight) {
  //       return {
  //         code: 'invalid-dimension',
  //         message: `image dimension is not ${file.width}x${file.height}px`,
  //       };
  //     }
  //   }

  //   return null;
  // };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // validator,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    maxSize: maxFileSize,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    [isDragActive],
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <div css={[dropzoneContainerStyles, containerStyles]}>
      <label htmlFor={label} css={labelStyles}>
        {label}
      </label>
      <div id={label} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text styles={textStyles}>drop the files here ...</Text>
        ) : (
          children
        )}
      </div>
      {files.length !== 0 && (
        <div css={previewContainerStyles}>
          <label htmlFor={`${label}-preview`} css={labelStyles}>
            {`${label} preview`}
          </label>
          <div
            id={`${label}-preview`}
            css={imagePreviewContainerStyles(imagePreviewWidth, imagePreviewHeight)}
          >
            <img
              src={files[0].preview}
              css={imagePreviewStyles}
              alt={`preview-of-${files[0].name}`}
            />
          </div>
        </div>
      )}
      {files.length === 0 && currentImage && (
        <div css={previewContainerStyles}>
          <ShortText styles={labelStyles}>
            current image
          </ShortText>
          <div css={imagePreviewContainerStyles(imagePreviewWidth, imagePreviewHeight)}>
            <img
              src={currentImage}
              css={imagePreviewStyles}
              alt={currentImage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ReactDropzone.propTypes = {
  containerStyles: PropTypes.shape({}),
  dropzoneStyles: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  imagePreviewWidth: PropTypes.number.isRequired,
  imagePreviewHeight: PropTypes.number.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  maxFileWidth: PropTypes.number,
  maxFileHeight: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  currentImage: PropTypes.string,
};

ReactDropzone.defaultProps = {
  containerStyles: css``,
  maxFileWidth: null,
  maxFileHeight: null,
  currentImage: '',
};

export default ReactDropzone;
