import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
`;

export const backgroundImageStyles = (backgroundImage) => css`
  width: 64.4%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const formContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35.6%;
  height: 100%;
  padding: 0 84px;
  background-color: ${colors.LIGHT_GREY};

  @media screen and (max-width: 1600px) {
    padding: 0 42px;
  }
`;

export const titleStyles = css`
  margin: 24px 0 0 0;
  text-align: center;
  font-weight: 700;
  font-size: 64px;
  color: ${colors.PRIMARY_BLUE};

  @media screen and (max-width: 1600px) {
    font-size: 42px;
  }
`;

export const descriptionStyles = css`
  max-width: 416px;
  margin: 42px 0 0 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: ${colors.PRIMARY_BLUE};

  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }
`;

export const formStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 344px;
  margin: 24px 0 0 0;
`;

export const formTitleStyles = css`
  text-align: center;
  text-transform: none;
  font-weight: 400;
  font-size: 18px;
`;

export const credentialInputContainerStyles = css`
  margin: 24px 0 0 0;
`;

export const errorMessageAndForgotPasswordContainerStyles = (isError) => css`
  display: flex;
  justify-content: ${isError ? 'space-between' : 'flex-end'};
  align-items: center;
  width: 100%;
  margin: 8px 0 0 0;
`;

export const errorMessageStyles = css`
  font-size: 12px;
  color: ${colors.DANGER_RED};
`;

export const forgotPasswordLinkStyles = css`
  border: unset;
  background-color: ${colors.TRANSPARENT};
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  outline: none;
  cursor: pointer;
`;

export const submitButtonStyles = css`
  margin: 40px 0 0 0;
`;
