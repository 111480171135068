import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = (isModalShow, status) => css`
  position: relative;
  display: ${isModalShow ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0;
  border-radius: 0;
  border: 1px solid ${status === 'success' ? colors.LIGHT_BLUE : colors.RED};
  background-color: ${status === 'success' ? colors.LIGHT_BLUE : colors.RED};
`;

export const contentStyles = css`
  position: relative;
`;

export const closeButtonStyles = css`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -12px 0 0 0;
  padding: 0;
  border: unset;
  background-color: ${colors.TRANSPARENT};
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const closeIconStyles = css`
  font-size: 24px;
  color: ${colors.WHITE};
`;

export const descriptionStyles = css`
  text-align: center;
  font-size: 16px;
  color: ${colors.WHITE};
`;
