/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PlainCard } from '../../../cards';
import {
  Button,
  Heading,
  Input,
  Modal,
  Select,
} from '../../../general';
import { users } from '../../../../api/v1/get/company';
import { rolePreferences } from '../../../../api/v1/get/user';
import { createSelfCompanyUser } from '../../../../api/v1/post/company';
import { LocaleContext } from '../../../../contexts/localeContext';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow } from '../../../../reducers/snackbarReducers';
import {
  cardStyles,
  titleStyles,
  formStyles,
  inputContainerStyles,
  buttonContainerStyles,
} from './AddMemberModal.styles';

const AddMemberModal = ({
  setData,
  isModalShown,
  setModalStatus,
}) => {
  const history = useHistory();
  const { dispatch } = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);
  const [isSubmitting, setSubmittingStatus] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    role: '',
  });

  useEffect(async () => {
    const { data } = await rolePreferences();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label, value: id,
    }));
    setRoleOptions(modifiedArr);
  }, []);

  const handleCloseModal = () => {
    setModalStatus(false);
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmittingStatus(true);
      const parsedData = {
        name: formData.fullName,
        email: formData.email,
        role: formData.role,
        redirect: 'company-portal',
      };

      await createSelfCompanyUser(parsedData);

      const res = await users({ limit: 9999 });

      setData(res.data.data.data);
      dispatch(isShow('success', 'successfully add new member.'));
    } catch (error) {
      dispatch(isShow('error', 'failed to add new member.'));

      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      setSubmittingStatus(false);
      setFormData({
        fullName: '',
        email: '',
        role: '',
      });
      setModalStatus(false);
    }
  };

  return (
    <Modal isModalShown={isModalShown}>
      <PlainCard styles={cardStyles}>
        <Heading type="h2" styles={titleStyles}>
          add member
        </Heading>
        <form css={formStyles} onSubmit={handleSubmit}>
          <Input
            type="text"
            containerStyles={inputContainerStyles}
            label="full name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="full name"
          />
          <Input
            type="email"
            containerStyles={inputContainerStyles}
            label="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="email"
          />
          <Select
            containerStyles={inputContainerStyles}
            label="role"
            name="role"
            options={roleOptions}
            value={formData.role}
            onChange={handleChange}
            placeholder="select role"
          />
          <div css={buttonContainerStyles}>
            <Button
              type="button"
              size="S"
              color="DARK_GREY"
              onClick={handleCloseModal}
              inverted
            >
              cancel
            </Button>
            <Button type="submit" size="M" color="LIGHT_BLUE">
              {isSubmitting ? 'loading...' : 'add member'}
            </Button>
          </div>
        </form>
      </PlainCard>
    </Modal>
  );
};

AddMemberModal.propTypes = {
  setData: PropTypes.func.isRequired,
  isModalShown: PropTypes.bool.isRequired,
  setModalStatus: PropTypes.func.isRequired,
};

export default AddMemberModal;
