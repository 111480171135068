/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import ShortText from '../ShortText/ShortText';
import {
  checkboxContainerStyles,
  labelStyles,
  checkboxLabelStyles,
  checkboxStyles,
  checkmarkStyles,
} from './Checkbox.styles';

const Checkbox = ({
  containerStyles,
  label,
  name,
  checked,
  onChange,
  checkboxLabel,
}) => (
  <div css={[checkboxContainerStyles, containerStyles]}>
    {label && <ShortText styles={labelStyles}>{label}</ShortText>}
    <label htmlFor={name} css={checkboxLabelStyles(label)}>
      <input
        id={name}
        type="checkbox"
        css={checkboxStyles}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span css={checkmarkStyles(checkboxLabel)} />
      {checkboxLabel}
    </label>
  </div>
);

Checkbox.propTypes = {
  containerStyles: PropTypes.shape({}),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  checkboxLabel: PropTypes.string,
};

Checkbox.defaultProps = {
  containerStyles: css``,
  label: '',
  checkboxLabel: '',
};

export default Checkbox;
