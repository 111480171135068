import { css } from '@emotion/react';

import { colors } from '../../../../../../configs';

export const statusPillStyles = (backgroundColor) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 20px;
  padding: 0 10px;
  border-radius: 32px;
  background-color: ${backgroundColor};
`;

export const statusStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 11px;
  color: ${colors.WHITE};
`;
