import axios from 'axios';

import { getAccessToken } from '../../../helpers/localStorage';

const companyBenefits = () => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/benefits`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default companyBenefits;
