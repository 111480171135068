export const handleMinWorkExperienceInYears = (year, month) => {
  const years = parseInt(year, 10) + parseInt(month, 10) / 12;

  return parseFloat(years.toFixed(1));
};

export const handleMaxWorkExperienceInYears = (year, month) => {
  const years = parseInt(year, 10) + parseInt(month, 10) / 12;

  return parseFloat(years.toFixed(1));
};
