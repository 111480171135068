import { css, keyframes } from '@emotion/react';

import { colors } from '../../../../configs';

export const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const loaderIconContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  animation: ${spin} 0.8s linear infinite;
`;

export const loaderIconStyles = css`
  font-size: 36px;
  color: ${colors.LIGHT_BLUE};
`;

export const titleStyles = css`
  margin: 14px 0 0 0;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
`;

export const descriptionStyles = css`
  max-width: 250px;
  margin: 12px 0 0 0;
  text-align: center;
  line-height: 22px;
  font-size: 15px;
`;
