import { useState, useEffect, useContext } from 'react';

import Layout from '../../../components/layout/Layout';
import { UserProfileSettings } from '../../../components/pages';
import { updateUser } from '../../../api/v1/put/user';
import { getIntrospection } from '../../../helpers/localStorage';
import { updateIntrospection } from '../../../helpers/api';
import { LoadingContext } from '../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../reducers/loadingReducers';
import { isShow } from '../../../reducers/snackbarReducers';
import { SnackbarContext } from '../../../contexts/snackbarContext';

const UserProfile = () => {
  const snackbarContext = useContext(SnackbarContext);
  const { dispatch } = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    isNotificationEnabled: false,
  });

  const handleChange = (e) => {
    if (e.target.name === 'isNotificationEnabled') {
      setFormData((prevState) => ({
        ...prevState,
        isNotificationEnabled: e.target.checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async () => {
    dispatch(isLoading());

    try {
      await updateUser({
        user_name: formData.fullName,
        phone_number: formData.phoneNumber,
      });
      await updateIntrospection();
      snackbarContext.dispatch(isShow('success', 'successfully update profile.'));
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to update profile.'));
    } finally {
      dispatch(isNotLoading());
    }
  };

  useEffect(() => {
    const data = getIntrospection();

    setFormData((prevState) => ({
      ...prevState,
      fullName: data.user_name,
      phoneNumber: data.phone_number ? data.phone_number : '',
    }));
  }, []);

  return (
    <Layout>
      <UserProfileSettings
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default UserProfile;
