import { Container } from '../../general';
import {
  containerStyles,
} from '../Agreement/Agreement.styles';

const ValidateLoadingPage = () => (
  <Container
    contentWidthType="NORMAL"
    containerStyles={containerStyles}
  >
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '40px',
      marginTop: '60px',
    }}
    >
      <img
        style={{
          maxWidth: '100px',
        }}
        src="/image/rolling-preloader.svg"
        alt=""
      />
    </div>
  </Container>
);

export default ValidateLoadingPage;
