/** @jsxImportSource @emotion/react */
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, Select, ShortText } from '../../../general';
import { useDetectOutsideClick } from '../../../../hooks';
import {
  containerStyles,
  leftContainerStyles,
  selectContainerStyles,
  multipleCheckboxButtonStyles,
  multipleCheckboxIconStyles,
  applicantCounterStyles,
  optionsButtonStyles,
  optionsIconStyles,
  checkboxDropdownContainerStyles,
  checkboxDropdownStyles,
  dropdownTitleStyles,
  buttonListStyles,
  dropdownDivider,
  rejectButtonStyles,
} from './SortAndOptions.styles';

const SortAndOptions = ({
  setRefetchStatus,
  applicantTotalCount,
  applicantListData,
  selectedApplicantId,
  setSelectedApplicantId,
  handleBulkUpdateJobApplications,
  sort,
  setSort,
}) => {
  const checkboxDropdownRef = useRef();
  const [isCheckboxDropdownShow, setCheckboxDropdownStatus] = useState(false);

  const applicationOptions = [
    {
      label: 'applicant',
      value: 'applicant',
    },
    {
      label: 'shortlisted',
      value: 'shortlisted',
    },
    {
      label: 'HR Interview',
      value: 'hr-interview',
    },
    {
      label: 'Technical Stage',
      value: 'technical-stage',
    },
    {
      label: 'offer',
      value: 'offer',
    },
    {
      label: 'hired',
      value: 'hired',
    },
  ];

  const applicationRejectedOptions = [
    {
      label: 'rejected - education',
      value: 'rejected-education',
    },
    {
      label: 'rejected - tech stack',
      value: 'rejected-tech-stack',
    },
    {
      label: 'rejected - candidate withdraw',
      value: 'rejected-candidate-withdraw',
    },
    {
      label: 'rejected - experience',
      value: 'rejected-experience',
    },
    {
      label: 'rejected - aptitude',
      value: 'rejected-aptitude',
    },
    {
      label: 'rejected - technical',
      value: 'rejected-technical',
    },
    {
      label: 'rejected - did not submit',
      value: 'rejected-did-not-submit',
    },
    {
      label: 'rejected - communication',
      value: 'rejected-communication',
    },
    {
      label: 'rejected - culture/personality',
      value: 'rejected-culture/personality',
    },
    {
      label: 'rejected - no response',
      value: 'rejected-no-response',
    },
    {
      label: 'rejected - salary',
      value: 'rejected-salary',
    },
  ];

  const sortOptions = [
    { label: 'A - Z', value: 'ASC' },
    { label: 'Z - A', value: 'DESC' },
  ];

  const handleBulkUpdate = (status) => {
    handleBulkUpdateJobApplications(status);
    setCheckboxDropdownStatus(false);
    setSelectedApplicantId([]);
    setRefetchStatus(true);
  };

  useDetectOutsideClick(checkboxDropdownRef, () => {
    if (isCheckboxDropdownShow) setCheckboxDropdownStatus(false);
  });

  const handleSelectedApplicantId = () => {
    if (!selectedApplicantId.length) {
      const applicantIds = applicantListData.map(({ id }) => id);

      setSelectedApplicantId(applicantIds);
    } else {
      setSelectedApplicantId([]);
    }
  };

  const handleCheckboxDropdownToggle = () => {
    setCheckboxDropdownStatus((prevState) => !prevState);
  };

  const handleSortChange = (e) => {
    setSort((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  return (
    <div css={containerStyles}>
      <div css={leftContainerStyles}>
        <button
          type="button"
          css={multipleCheckboxButtonStyles}
          onClick={handleSelectedApplicantId}
        >
          <Icon
            name={`${
              !selectedApplicantId.length
                ? 'checkbox-multiple-fill'
                : 'checkbox-indeterminate-fill'
            }`}
            styles={multipleCheckboxIconStyles}
          />
        </button>
        <ShortText styles={applicantCounterStyles}>
          {`all applicants (${applicantTotalCount})`}
        </ShortText>
        <div css={checkboxDropdownContainerStyles}>
          <button
            type="button"
            css={optionsButtonStyles}
            onClick={handleCheckboxDropdownToggle}
          >
            <Icon name="archive-fill" styles={optionsIconStyles} />
            <Icon name="arrow-down-s-line" styles={optionsIconStyles} />
          </button>
          <nav
            ref={checkboxDropdownRef}
            css={checkboxDropdownStyles(isCheckboxDropdownShow)}
          >
            <ShortText styles={dropdownTitleStyles}>Proceed All to:</ShortText>
            <div css={dropdownDivider} />
            <ul>
              {applicationOptions.map(({ label, value }) => (
                <li key={label}>
                  <button
                    type="button"
                    css={buttonListStyles}
                    onClick={() => handleBulkUpdate(value)}
                  >
                    {label}
                  </button>
                </li>
              ))}
              <div css={dropdownDivider} />
              {applicationRejectedOptions.map(({ label, value }) => (
                <button
                  key={value}
                  type="button"
                  css={rejectButtonStyles}
                  onClick={() => handleBulkUpdate(value)}
                >
                  {label}
                </button>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      <div>
        <Select
          containerStyles={selectContainerStyles}
          name="sortBy"
          options={sortOptions}
          value={sort.value}
          onChange={handleSortChange}
          placeholder="sort by"
        />
      </div>
    </div>
  );
};

SortAndOptions.propTypes = {
  setRefetchStatus: PropTypes.func.isRequired,
  applicantTotalCount: PropTypes.number.isRequired,
  applicantListData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          channel: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  selectedApplicantId: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedApplicantId: PropTypes.func.isRequired,
  handleBulkUpdateJobApplications: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOf(['ASC', 'DESC']),
  }).isRequired,
  setSort: PropTypes.func.isRequired,
};

export default SortAndOptions;
