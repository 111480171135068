/** @jsxImportSource @emotion/react */
import { Container, ShortText, Text } from '../../general';
import {
  contentStyles,
  titleStyles,
  subtitleStyles,
} from './Error500.styles';

const Error500 = () => (
  <Container
    contentWidthType="FULL"
    contentStyles={contentStyles}
  >
    <ShortText styles={titleStyles}>internal server error</ShortText>
    <Text styles={subtitleStyles}>
      please contact administrator to report this issue.
    </Text>
  </Container>
);

export default Error500;
