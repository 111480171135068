/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import downCaret from '../../../assets/svg/down-caret.svg';
import {
  customSelectContainerStyles,
  labelStyles,
  selectStyles,
} from './Select.styles';

const Select = ({
  containerStyles,
  label,
  options,
  name,
  value,
  onChange,
  placeholder,
  useIndexAsValue,
  required,
  ...rest
}) => (
  <div css={[customSelectContainerStyles, containerStyles]}>
    {label && (
      <label htmlFor={label} css={labelStyles}>
        {label}
      </label>
    )}
    <select
      id={label}
      css={selectStyles(label, downCaret)}
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
    >
      <option value="">
        {placeholder}
      </option>
      {options.map((d, index) => (
        <option key={d.value} value={useIndexAsValue ? index : d.value}>
          {d.label}
        </option>
      ))}
    </select>
  </div>
);

Select.propTypes = {
  containerStyles: PropTypes.shape({}),
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  useIndexAsValue: PropTypes.bool,
  required: PropTypes.bool,
};

Select.defaultProps = {
  containerStyles: css``,
  label: '',
  useIndexAsValue: false,
  required: false,
};

export default Select;
