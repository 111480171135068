import { css } from '@emotion/react';

export const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 415px;
  height: auto;
  padding: 35px 48px;
`;

export const imageStyles = css`
  width: 160px;
  height: 160px;
`;

export const titleStyles = css`
  margin: 16px 0 0 0;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
`;

export const descriptionStyles = css`
  margin: 12px 0 0 0;
  text-align: center;
  font-size: 15px;
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0 0 0;
`;

export const buttonStyles = css`
  margin: 0 0 0 20px;
`;
