import axios from 'axios';

import { getAccessToken } from '../../../helpers/localStorage';

const changeEmailRequest = (data) => (
  axios.post(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/v2/auth/email/change-request`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default changeEmailRequest;
