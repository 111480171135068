/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState } from 'react';

import { PlainCard } from '../../../cards';
import {
  Button,
  Heading,
  Modal,
  ShortText,
} from '../../../general';
import {
  cardStyles,
  titleStyles,
  fullNameAndEmailContainerStyles,
  fullNameStyles,
  emailStyles,
  formStyles,
  inputContainerStyles,
  radioButtonLabelStyles,
  radioButtonInputStyles,
  checkmarkStyles,
  radioButtonDescriptionStyles,
  highlightedTextStyles,
  buttonContainerStyles,
} from './ManageMemberModal.styles';

const ManageMemberModal = ({
  manageMemberModalData,
  setManageMemberModalData,
  setActiveConfirmationModalKey,
  setConfirmationModalStatus,
}) => {
  const [formData, setFormData] = useState({
    manageStatus: 'deactivateMember',
  });

  const handleCloseModal = () => {
    setManageMemberModalData({
      id: 0,
      status: false,
    });
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.manageStatus === 'deactivateMember') {
      setActiveConfirmationModalKey('deactivateMemberModal');
      setConfirmationModalStatus((prevState) => ({
        ...prevState,
        deactivateMemberModal: true,
      }));
    }

    if (formData.manageStatus === 'deleteMember') {
      setActiveConfirmationModalKey('deleteMemberModal');
      setConfirmationModalStatus((prevState) => ({
        ...prevState,
        deleteMemberModal: true,
      }));
    }

    setManageMemberModalData({
      id: manageMemberModalData.id,
      status: false,
    });
    setFormData({ manageStatus: 'deactivateMember' });
  };

  return (
    <Modal isModalShown={manageMemberModalData.status}>
      <PlainCard styles={cardStyles}>
        <Heading type="h2" styles={titleStyles}>
          manage member
        </Heading>
        <div css={fullNameAndEmailContainerStyles}>
          <ShortText styles={fullNameStyles}>{manageMemberModalData.name}</ShortText>
          <ShortText styles={emailStyles}>{manageMemberModalData.email}</ShortText>
        </div>
        <form css={formStyles} onSubmit={handleSubmit}>
          <div css={inputContainerStyles}>
            <label htmlFor="deactivateMember" css={radioButtonLabelStyles}>
              <input
                id="deactivateMember"
                type="radio"
                css={radioButtonInputStyles}
                name="manageStatus"
                value="deactivateMember"
                onChange={handleChange}
                checked={formData.manageStatus === 'deactivateMember'}
              />
              <span css={checkmarkStyles} />
              deactivate
            </label>
            <label
              htmlFor="deactivateMember"
              css={radioButtonDescriptionStyles}
            >
              The account will no longer to process job, but still available in
              your teams, You can set your team to fully function by&nbsp;
              <ShortText styles={highlightedTextStyles}>activated members</ShortText>
            </label>
          </div>
          <div css={inputContainerStyles}>
            <label htmlFor="deleteMember" css={radioButtonLabelStyles}>
              <input
                id="deleteMember"
                type="radio"
                css={radioButtonInputStyles}
                name="manageStatus"
                value="deleteMember"
                onChange={handleChange}
                checked={formData.manageStatus === 'deleteMember'}
              />
              <span css={checkmarkStyles} />
              delete
            </label>
            <label htmlFor="deleteMember" css={radioButtonDescriptionStyles}>
              The account will no longer available, and all the data in the
              account will be permanently deleted.
            </label>
          </div>
          <div css={buttonContainerStyles}>
            <Button
              type="button"
              size="S"
              color="DARK_GREY"
              onClick={handleCloseModal}
              inverted
            >
              cancel
            </Button>
            <Button type="submit" size="M" color="LIGHT_BLUE">
              confirm
            </Button>
          </div>
        </form>
      </PlainCard>
    </Modal>
  );
};

ManageMemberModal.propTypes = {
  manageMemberModalData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.bool,
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  setManageMemberModalData: PropTypes.func.isRequired,
  setActiveConfirmationModalKey: PropTypes.func.isRequired,
  setConfirmationModalStatus: PropTypes.func.isRequired,
};

export default ManageMemberModal;
