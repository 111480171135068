/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Container,
  Icon,
  Link,
  ShortText,
} from '../../../general';
import { useQuery } from '../../../../hooks';
import {
  containerStyles,
  contentStyles,
  linkListStyles,
  listStyles,
  linkStyles,
  iconStyles,
  nameAndValueStyles,
} from './NavbarFilter.styles';

const NavbarFilter = ({ applicantSummaryData }) => {
  const query = useQuery();
  const { pathname } = useLocation();
  const [filterListData, setFilterListData] = useState([]);

  const filterListIconAndName = {
    applied: {
      icon: 'mail-unread-fill',
      name: 'applicant',
    },
    shortlisted: {
      icon: 'sort-desc',
      name: 'shortlisted',
    },
    'technical-stage': {
      icon: 'draft-fill',
      name: 'technical stage',
    },
    'hr-interview': {
      icon: 'phone-fill',
      name: 'HR Interview',
    },
    offer: {
      icon: 'file-list-3-line',
      name: 'offer',
    },
    hired: {
      icon: 'checkbox-circle-fill',
      name: 'hired',
    },
    rejected: {
      icon: 'close-circle-fill',
      name: 'rejected',
    },
  };

  const activeTabChecker = (key) => {
    if (key === query.get('filter')) return true;

    return false;
  };

  useEffect(() => {
    const parsedApplicantSummaryData = applicantSummaryData.map(
      ({ status, total }) => ({
        key: status,
        value: total,
      }),
    );

    setFilterListData(() => [
      ...parsedApplicantSummaryData,
    ]);
  }, [applicantSummaryData]);

  return (
    <Container
      contentWidthType="FULL"
      containerStyles={containerStyles}
      contentStyles={contentStyles}
    >
      <ul css={linkListStyles}>
        {filterListData.map(({ key, value }) => (
          <li key={key} css={listStyles}>
            <Link
              to={`${pathname}?filter=${key}`}
              styles={linkStyles(activeTabChecker(key))}
            >
              <Icon
                styles={iconStyles}
                name={filterListIconAndName}
              />
              <ShortText styles={nameAndValueStyles}>
                <b>{`(${value})`}</b>
                {filterListIconAndName[key]?.name}
              </ShortText>
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  );
};

NavbarFilter.propTypes = {
  applicantSummaryData: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      total: PropTypes.number,
    }),
  ).isRequired,
};

export default NavbarFilter;
