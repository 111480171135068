import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 280px);
`;
export const titleStyles = css`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 36px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const subtitleStyles = css`
  margin: 16px 0 0 0;
  font-size: 18px;
  color: ${colors.PRIMARY_BLACK};
`;
