import numeral from 'numeral';

const millionsSeparator = (val) => {
  if (val) {
    return numeral(val).format('0,0');
  }
  return '';
};

export default millionsSeparator;
