/** @jsxImportSource @emotion/react */
import { useContext, useEffect } from 'react';

import Container from '../Container/Container';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { SnackbarContext } from '../../../contexts/snackbarContext';
import { isNotShow } from '../../../reducers/snackbarReducers';
import { useIsFirstRender } from '../../../hooks';
import {
  containerStyles,
  contentStyles,
  closeButtonStyles,
  closeIconStyles,
  descriptionStyles,
} from './Snackbar.styles';

const ErrorModal = () => {
  const isFirstRender = useIsFirstRender();
  const { isShow, dispatch } = useContext(SnackbarContext);

  const handleCloseModal = () => {
    dispatch(isNotShow());
  };

  useEffect(() => {
    if (!isFirstRender) window.scroll(0, 0);
    if (isShow.type === 'TRUE') {
      setTimeout(() => dispatch(isNotShow()), 10000);
    }
  }, [isShow.type]);

  return (
    <Container
      containerStyles={containerStyles(isShow.type === 'TRUE', isShow.status)}
      contentStyles={contentStyles}
      contentWidthType="FULL"
    >
      <button type="button" css={closeButtonStyles} onClick={handleCloseModal}>
        <Icon name="close-line" styles={closeIconStyles} />
      </button>
      <Text styles={descriptionStyles}>{isShow.message}</Text>
    </Container>
  );
};

export default ErrorModal;
