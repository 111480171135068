import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const users = (params) => (
  axios.get(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/v2/companies/users`,
    {
      params,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default users;
