/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import PasswordStrengthBar from './PasswordStrengthBar/PasswordStrengthBar';
import { Heading, Button, InputPassword } from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { changePassword } from '../../../../locale';
import {
  headingStyles,
  formStyles,
  inputContainerStyles,
  labelStyles,
  inputStyles,
  submitButtonContainerStyles,
} from './RightCardContent.styles';

const RightCardContent = ({
  formData,
  handleChange,
  handleSubmit,
}) => {
  const { locale } = useContext(LocaleContext);

  const handleSubmitButtonDisabledStatus = () => {
    if (
      formData.newPassword !== formData.confirmationNewPassword
      || !formData.currentPassword
      || !formData.newPassword
      || !formData.confirmationNewPassword
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <Heading type="h3" styles={headingStyles}>
        {changePassword.title[locale]}
      </Heading>
      <form css={formStyles}>
        <div css={inputContainerStyles}>
          <label htmlFor="currentPassword" css={labelStyles}>
            {changePassword.currentPassword[locale]}
          </label>
          <InputPassword
            containerStyles={inputStyles}
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            placeholder={changePassword.currentPassword[locale]}
          />
        </div>
        <div css={inputContainerStyles}>
          <label htmlFor="newPassword" css={labelStyles}>
            {changePassword.newPassword[locale]}
          </label>
          <InputPassword
            containerStyles={inputStyles}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder={changePassword.newPassword[locale]}
          />
          <PasswordStrengthBar
            password={formData.newPassword}
            passwordStatusTranslation={changePassword.passwordStatus}
            locale={locale}
          />
        </div>
        <div css={inputContainerStyles}>
          <label htmlFor="confirmationNewPassword" css={labelStyles}>
            {changePassword.confirmationNewPassword[locale]}
          </label>
          <InputPassword
            containerStyles={inputStyles}
            name="confirmationNewPassword"
            value={formData.confirmationNewPassword}
            onChange={handleChange}
            placeholder={changePassword.confirmationNewPassword[locale]}
          />
        </div>
        <div css={submitButtonContainerStyles}>
          <Button
            type="submit"
            size="L"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
            disabled={handleSubmitButtonDisabledStatus()}
          >
            {changePassword.submitButton[locale]}
          </Button>
        </div>
      </form>
    </div>
  );
};

RightCardContent.propTypes = {
  formData: PropTypes.shape({
    currentPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmationNewPassword: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RightCardContent;
