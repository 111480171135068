import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const cardStyles = css`
  width: 680px;
  height: auto;
  padding: 32px 32px 40px 32px;
`;

export const headerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const titleStyles = css`
  font-weight: 600;
  color: ${colors.PRIMARY_BLUE};
`;

export const closeButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: ${colors.TRANSPARENT};
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const iconStyles = css`
  font-size: 18px;
  color: ${colors.LIGHT_BLUE};
`;

export const bodyStyles = css`
  margin: 24px 0 0 0;
`;

export const footerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 36px 0 0 0;
`;
