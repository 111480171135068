import { css } from '@emotion/react';
import { colors } from '../../../../configs';

export const cardStyles = css`
  width: 720px;
  height: auto;
  padding: 32px;
`;

export const titleStyles = css`
  font-weight: 600;
  font-size: 24px;
  color: ${colors.PRIMARY_BLUE};
`;

export const fullNameAndEmailContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 12px 20px;
  border: 2px solid ${colors.LIGHT_BLUE};
  border-radius: 8px;
`;

export const fullNameStyles = css`
  text-transform: capitalize;
  font-weight: 600;
`;

export const emailStyles = css`
  margin: 8px 0 0 0;
`;

export const formStyles = css`
  margin: 24px 0 0 0;
`;

export const inputContainerStyles = css`
  margin: 24px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const radioButtonLabelStyles = css`
  display: block;
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

export const radioButtonInputStyles = css`
  margin: 0 18px 0 0;
  opacity: 0;

  &:checked {
    ~ span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.PRIMARY_BLUE};

      &:before {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: ${colors.WHITE};
      }

      &:after {
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${colors.PRIMARY_BLUE};
      }
    }
  }
`;

export const checkmarkStyles = css`
  position: absolute;
  top: 1px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: ${colors.DARK_GREY};
  border-radius: 50%;

  &:after {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${colors.GREY};
  }
`;

export const radioButtonDescriptionStyles = css`
  display: block;
  width: 100%;
  padding: 12px 0 0 31px;
  font-size: 14px;
  cursor: pointer;
`;

export const highlightedTextStyles = css`
  font-weight: 600;
  color: ${colors.PRIMARY_BLUE};
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 0 0;
`;
