import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const containerStyles = css`
  width: 100%;
  margin: 18px 0 0 0;
  padding: 0 0 24px 0;
  border-bottom: 1px solid ${colors.GREY};
`;

export const rowStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const titleStyles = css`
  text-transform: capitalize;
  font-size: 18px;
`;

export const nameAndLocationContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const nameStyles = css`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
  color: ${colors.LIGHT_BLUE};
`;

export const dateStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
`;

export const linkStyles = css`
  font-weight: 600;
  color: ${colors.LIGHT_BLUE};
  font-size: 14px;
`;
