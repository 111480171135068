/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import modalImage from '../../../../assets/images/pages/manage-member-settings/modal.png';
import {
  Modal,
  Heading,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { LocaleContext } from '../../../../contexts/localeContext';
import { users } from '../../../../api/v1/get/company';
import { updateUserById } from '../../../../api/v1/put/companies';
import { deleteUserById } from '../../../../api/v1/delete/companies';
import {
  cardStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonContainerStyles,
  buttonStyles,
} from './ConfirmationModal.styles';

const ConfirmationModal = ({
  setData,
  manageMemberModalData,
  currentUserIdToBeActivated,
  activeConfirmationModalKey,
  isConfirmationModalShown,
  setConfirmationModalStatus,
  text,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const [isSubmitting, setSubmittingStatus] = useState(false);

  const handleCloseModal = () => {
    setConfirmationModalStatus((prevState) => ({
      ...prevState,
      [activeConfirmationModalKey]: false,
    }));
  };

  const handleSubmit = async () => {
    try {
      setSubmittingStatus(true);

      if (activeConfirmationModalKey === 'activateMemberModal') {
        await updateUserById(currentUserIdToBeActivated, {
          status: 'active',
        });
      }

      if (activeConfirmationModalKey === 'deactivateMemberModal') {
        await updateUserById(manageMemberModalData.id, { status: 'inactive' });
      }

      if (activeConfirmationModalKey === 'deleteMemberModal') {
        await deleteUserById(manageMemberModalData.id);
      }

      const res = await users({ limit: 9999 });

      setData(res.data.data.data);
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      setSubmittingStatus(false);
      handleCloseModal();
    }
  };

  return (
    <Modal isModalShown={isConfirmationModalShown[activeConfirmationModalKey]}>
      <PlainCard styles={cardStyles}>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          {activeConfirmationModalKey
            && text[activeConfirmationModalKey].title[locale]}
        </Heading>
        <Text styles={descriptionStyles}>
          {activeConfirmationModalKey
            && text[activeConfirmationModalKey].description[locale]}
        </Text>
        <div css={buttonContainerStyles}>
          <Button type="button" size="S" onClick={handleCloseModal} inverted>
            {activeConfirmationModalKey
              && text[activeConfirmationModalKey].cancelButton[locale]}
          </Button>
          <Button
            type="button"
            styles={buttonStyles}
            size="L"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
          >
            {isSubmitting ? 'loading...' : activeConfirmationModalKey
              && text[activeConfirmationModalKey].continueButton[locale]}
          </Button>
        </div>
      </PlainCard>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  setData: PropTypes.func.isRequired,
  manageMemberModalData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  currentUserIdToBeActivated: PropTypes.number.isRequired,
  activeConfirmationModalKey: PropTypes.string.isRequired,
  isConfirmationModalShown: PropTypes.shape({
    activateMemberModal: PropTypes.bool,
    deactivateMemberModal: PropTypes.bool,
    deleteMemberModal: PropTypes.bool,
  }).isRequired,
  setConfirmationModalStatus: PropTypes.func.isRequired,
  text: PropTypes.shape({
    title: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    description: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    cancelButton: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
    continueButton: PropTypes.shape({
      en: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default ConfirmationModal;
