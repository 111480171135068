/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import bannerPlaceholder from '../../../../assets/images/pages/recruiter/company-profile/banner-placeholder.png';
import { thousandsSeparator } from '../../../../helpers/parser';
import {
  Container,
  Heading,
  Button,
  Icon,
  ShortText,
  LabelAndValue,
} from '../../../general';
import {
  containerStyles,
  jobDataPaperStyles,
  bannerStyles,
  upperJobDataPaperStyles,
  companyLogoContainerStyles,
  companyLogoStyles,
  jobTitleStyles,
  upperJobDataPaperRowStyles,
  companyNameStyles,
  iconAndLocationNameContainerStyles,
  iconStyles,
  locationNameStyles,
  deadlineOfApplicationStyles,
  tagContainerStyles,
  tagStyles,
  salariesStyles,
  copyLinkAndFavoriteButtonContainerStyles,
  copyLinkButtonStyles,
  favoriteButtonStyles,
  buttonIconStyles,
  applyNowButtonStyles,
  lowerJobDataPaperStyles,
  rowStyles,
  labelStyles,
  perksAndBenefitsContainerStyles,
  perksAndBenefitsStyles,
  perksAndBenefitsListStyles,
} from './Details.styles';

const Details = ({ data, optionsData, companyData }) => {
  const valueParser = (key, value) => {
    const index = optionsData[`${key}Options`].findIndex(
      (d) => d.value === value,
    );

    if (index !== -1) {
      return optionsData[`${key}Options`][index].label;
    }

    return '';
  };

  const requiredSkillsParser = (requiredSkills) => {
    let parsedRequiredSkills = [];

    if (requiredSkills) {
      parsedRequiredSkills = requiredSkills.join(', ');
    }

    return parsedRequiredSkills;
  };

  return (
    <Container contentWidthType="NORMAL" containerStyles={containerStyles}>
      <div css={jobDataPaperStyles}>
        <div css={bannerStyles(companyData.bannerUrl || bannerPlaceholder)} />
        <div css={upperJobDataPaperStyles}>
          <div css={companyLogoContainerStyles}>
            <img
              src={companyData.logoUrl}
              css={companyLogoStyles}
              alt={companyData.name}
            />
          </div>
          <Heading type="h2" styles={jobTitleStyles}>
            {data.jobTitle}
          </Heading>
          <div css={upperJobDataPaperRowStyles(18)}>
            <div>
              <div css={iconAndLocationNameContainerStyles}>
                <Icon name="map-pin-line" styles={iconStyles} />
                <ShortText styles={locationNameStyles}>
                  {`${companyData.city?.label}, ${companyData.country?.label}`}
                </ShortText>
              </div>
              <Heading type="h2" styles={companyNameStyles}>
                {companyData.name}
              </Heading>
            </div>
            <LabelAndValue
              styles={deadlineOfApplicationStyles}
              label="deadline of application"
              value={data.applicationDeadline}
            />
          </div>
          <div css={upperJobDataPaperRowStyles(25)}>
            <div css={tagContainerStyles}>
              <div css={tagStyles}>
                <ShortText>
                  {valueParser('jobFunctions', data.jobFunction)}
                </ShortText>
              </div>
              <div css={tagStyles}>
                <ShortText>
                  {valueParser('employmentTypes', data.employmentType)}
                </ShortText>
              </div>
              <div css={tagStyles}>
                <ShortText>{valueParser('jobLevels', data.jobLevel)}</ShortText>
              </div>
            </div>
            {data.currency && data.salaryRangeFrom && (
              <ShortText styles={salariesStyles}>
                {`${data.currency} ${thousandsSeparator(
                  data.salaryRangeFrom,
                )} -  ${data.currency} ${thousandsSeparator(
                  data.salaryRangeUntil,
                )}`}
              </ShortText>
            )}
          </div>
          <div css={upperJobDataPaperRowStyles(32)}>
            <div css={copyLinkAndFavoriteButtonContainerStyles}>
              <Button
                type="button"
                styles={copyLinkButtonStyles}
                size="M"
                inverted
                color="GREY"
              >
                <Icon styles={buttonIconStyles} name="link" />
                &nbsp;copy link
              </Button>
              <Button
                type="button"
                styles={favoriteButtonStyles}
                size="S"
                color="GREY"
                inverted
              >
                <Icon styles={buttonIconStyles} name="heart-line" />
              </Button>
            </div>
            <Button
              type="button"
              styles={applyNowButtonStyles}
              size="L"
              disabled
            >
              apply now!
            </Button>
          </div>
        </div>
        <div css={lowerJobDataPaperStyles}>
          <div css={rowStyles}>
            <LabelAndValue
              label="minimum education"
              value={valueParser('minimumEducation', data.minimumEducation)}
            />
          </div>
          {data.requiredSkills.length !== 0 && (
            <div css={rowStyles}>
              <LabelAndValue
                label="required skills"
                value={requiredSkillsParser(data.requiredSkills)}
              />
            </div>
          )}
          <div css={rowStyles}>
            <LabelAndValue
              valueType="HTML"
              label="job description"
              value={ReactHtmlParser(data.jobDescription)}
            />
          </div>
          <div css={rowStyles}>
            <LabelAndValue
              valueType="HTML"
              label="qualification / requirements"
              value={ReactHtmlParser(data.qualificationAndRequirements)}
            />
          </div>
          {data.isWorkFromHome && (
            <div css={rowStyles}>
              <LabelAndValue
                valueType="LONG"
                label="availability"
                value="this job can be done remotely (work from home)"
              />
            </div>
          )}
          {data.isWillingToRelocate && (
            <div css={rowStyles}>
              <LabelAndValue
                valueType="LONG"
                label="relocation"
                value="accept candidates willing to relocate"
              />
            </div>
          )}
          {data.perksAndBenefits.length !== 0 && (
            <div css={perksAndBenefitsContainerStyles}>
              <ShortText styles={labelStyles}>perks and benefit</ShortText>
              <ul css={perksAndBenefitsStyles}>
                {data.perksAndBenefits.map((d) => (
                  <li key={d.id} css={perksAndBenefitsListStyles}>
                    {d.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

Details.propTypes = {
  data: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    numberOfPeople: PropTypes.string,
    applicationDeadline: PropTypes.string,
    minimumEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
    jobDescription: PropTypes.string,
    qualificationAndRequirements: PropTypes.string,
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
    currency: PropTypes.string,
    salaryRangeFrom: PropTypes.string,
    salaryRangeUntil: PropTypes.string,
    perksAndBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  optionsData: PropTypes.shape({
    jobFunctionsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    jobLevelsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    employmentTypesOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    minimumEducationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    requiredSkillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    companyBenefitsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  companyData: PropTypes.shape({
    bannerUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default Details;
