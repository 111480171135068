import { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { jobDetails } from '../../../../api/v1/get';

import Layout from '../../../../components/layout/Layout';
import { JobDetailsPreview } from '../../../../components/pages';
import { LoadingContext } from '../../../../contexts/loadingContext';
import { LocaleContext } from '../../../../contexts/localeContext';
import { isLoading, isNotLoading } from '../../../../reducers/loadingReducers';

const JobDetailsPreviewPage = () => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const [data, setData] = useState({
    company: {
      banner_url: '',
      logo_url: '',
      name: '',
    },
    name: '',
    country: {
      id: 0,
      label: '',
    },
    city: {
      id: 0,
      label: '',
    },
    due_date: '',
    job_function: {
      id: '',
      label: '',
    },
    employment_type: {
      id: '',
      label: '',
    },
    job_level: {
      id: '',
      label: '',
    },
    salary_currency: '',
    salary_range_from: 0,
    salary_range_until: 0,
    minimum_education: {
      id: '',
      label: '',
    },
    skills: [],
    description: '<p></p>',
    qualification: '<p></p>',
    seeker_availabilities: [],
    benefits: [],
  });
  const { dispatch } = useContext(LoadingContext);

  const { jobId } = useParams();

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        dispatch(isLoading());
        const jobDetailsRes = await jobDetails(jobId);
        setData(jobDetailsRes.data.data);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchJobDetails();
  }, []);

  return (
    <Layout>
      <JobDetailsPreview data={data} />
    </Layout>
  );
};

export default JobDetailsPreviewPage;
