/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import {
  Modal,
  Icon,
  Heading,
  Text,
  ShortText,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import {
  cardStyles,
  closeButtonContainerStyles,
  closeButtonStyles,
  closeIconStyles,
  backgroundImageStyles,
  titleStyles,
  descriptionStyles,
  emailHighlightStyles,
  linkStyles,
} from './DeactivatedAccountModal.styles';
import backgroundImage from '../../../../assets/images/pages/login/deactivated-account-modal-background.png';

const DeactivatedAccountModal = ({ loginStatus, setLoginStatus }) => {
  const handleCloseModal = () => {
    setLoginStatus((prevState) => ({
      ...prevState,
      isDeactivatedAccountModalShown: false,
    }));
  };

  return (
    <Modal isModalShown={loginStatus.isDeactivatedAccountModalShown}>
      <PlainCard styles={cardStyles}>
        <div css={closeButtonContainerStyles}>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <div css={backgroundImageStyles(backgroundImage)} />
        <Heading type="h3" styles={titleStyles}>
          access denied!
        </Heading>
        <Text styles={descriptionStyles}>
          Your account has been deactivated. Please contact your Admin at&nbsp;
          <ShortText styles={emailHighlightStyles}>
            admin@hacktiv8.com
          </ShortText>
          &nbsp;to get your access back.
        </Text>
        <a
          href="mailto:admin@hacktiv8.com"
          target="_blank"
          rel="noopener noreferrer"
          css={linkStyles}
        >
          <Button type="button" size="L" color="LIGHT_BLUE">
            contact admin
          </Button>
        </a>
      </PlainCard>
    </Modal>
  );
};

DeactivatedAccountModal.propTypes = {
  loginStatus: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isError: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string,
    }),
    isSuccess: PropTypes.bool,
    isDeactivatedAccountModalShown: PropTypes.bool,
  }).isRequired,
  setLoginStatus: PropTypes.func.isRequired,
};

export default DeactivatedAccountModal;
