import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';

import { reducer } from '../reducers/loadingReducers';

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, dispatch] = useReducer(reducer, false);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        dispatch,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
