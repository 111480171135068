import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  margin: 40px 0 0 0;
`;

export const jobDataPaperStyles = css`
  border-radius: 8px;
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export const bannerStyles = (backgroundImage) => css`
  width: 100%;
  height: 250px;
  background-color: ${colors.BABY_BLUE};
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const upperJobDataPaperStyles = css`
  position: relative;
  padding: 60px 72px 32px 72px;
  border-bottom: 1px solid rgba(29, 61, 113, 0.05);
`;

export const companyLogoContainerStyles = css`
  position: absolute;
  top: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid ${colors.LIGHT_BLUE};
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export const companyLogoStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
`;

export const jobTitleStyles = css`
  font-weight: 600;
  font-size: 22px;
`;

export const upperJobDataPaperRowStyles = (marginTop) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${marginTop}px 0 0 0;
`;

export const companyNameStyles = css`
  text-decoration: underline;
  font-weight: 600;
  font-size: 13px;
  color: ${colors.LIGHT_BLUE};
`;

export const iconAndLocationNameContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0 0 0;
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const locationNameStyles = css`
  margin: 0 0 0 8px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const deadlineOfApplicationStyles = css`
  span {
    text-transform: capitalize;
    text-align: right;
  }
`;

export const tagContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const tagStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  padding: 6px 12px;
  border-radius: 12px;

  &:nth-of-type(1) {
    background-color: rgba(30, 91, 189, 0.12);

    span {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 11px;
      color: ${colors.LIGHT_BLUE};
    }
  }

  &:nth-of-type(2) {
    background-color: rgba(245, 149, 46, 0.12);

    span {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 11px;
      color: ${colors.PRIMARY_YELLOWISH_ORANGE};
    }
  }

  &:nth-of-type(3) {
    background-color: rgba(29, 61, 113, 0.12);

    span {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 11px;
      color: ${colors.PRIMARY_BLUE};
    }
  }

  &:last-of-type {
    margin: 0;
  }
`;

export const salariesStyles = css`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const copyLinkAndFavoriteButtonContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const copyLinkButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const buttonIconStyles = css`
  font-size: 16px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const lowerJobDataPaperStyles = css`
  padding: 36px 72px 60px 72px;
`;

export const rowStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 32px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  color: ${colors.DARK_GREY};
`;

export const seekerAvailabilitiesContainerStyles = css`
  margin: 32px 0 0 0;
`;

export const seekerAvailabilitiesStyles = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 160px;
  margin: 8px 0 0 0;
  list-style: none;
`;

export const seekerAvailabilitiesListStyles = css`
  margin: 0 0 14px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};

  &:last-of-type {
    margin: 0;
  }
`;

export const perksAndBenefitsContainerStyles = css`
  margin: 32px 0 0 0;
`;

export const perksAndBenefitsStyles = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 160px;
  margin: 8px 0 0 0;
  list-style: none;
`;

export const perksAndBenefitsListStyles = css`
  margin: 0 0 14px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};

  &:last-of-type {
    margin: 0;
  }

  &:before {
    content: '\\2022';
    margin: 0 11px 0 0;
    font-weight: bold;
    color: ${colors.LIGHT_BLUE};
  }
`;
