import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const applicantDetails = (applicantId) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/available-seekers/${applicantId}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default applicantDetails;
