import axios from 'axios';

import { getAccessToken } from '../../../helpers/localStorage';

const jobs = (params) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/jobs`,
    {
      params,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default jobs;
