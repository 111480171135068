const defaultState = false;

export const reducer = (_, action) => {
  switch (action.type) {
    case 'TRUE':
      return true;
    case 'FALSE':
      return false;
    default:
      return defaultState;
  }
};

export const isLoading = () => ({
  type: 'TRUE',
});

export const isNotLoading = () => ({
  type: 'FALSE',
});
