/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import jobPostPlaceholder from '../../../../assets/images/pages/recruiter/company-profile/job-post-placeholder.png';
import {
  Heading,
  Icon,
  ShortText,
  Button,
} from '../../../general';
import { dateParser } from '../../../../helpers/parser';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  cardStyles,
  jobInfoContainerStyles,
  imageContainerStyles,
  imageStyles,
  descriptionStyles,
  jobTitleStyles,
  iconAndLocationNameContainerStyles,
  iconStyles,
  locationNameStyles,
  iconAndDeadlineContainerStyles,
  deadlineLabelAndValueContainerStyles,
  deadlineLabelStyles,
  deadlineValueStyles,
} from './JobPostCard.styles';

const JobPostCard = ({ data }) => {
  const { locale } = useContext(LocaleContext);

  return (
    <div css={cardStyles}>
      <div css={jobInfoContainerStyles}>
        <div css={imageContainerStyles}>
          <img
            src={data.company.logo_url || jobPostPlaceholder}
            css={imageStyles}
            alt={data.name}
          />
        </div>
        <div css={descriptionStyles}>
          <Heading type="h3" styles={jobTitleStyles}>
            {data.name}
          </Heading>
          <div css={iconAndLocationNameContainerStyles}>
            <Icon name="map-pin-line" styles={iconStyles} />
            <ShortText styles={locationNameStyles}>
              {`${data.city?.label}, ${data.country?.label}`}
            </ShortText>
          </div>
          <div css={iconAndDeadlineContainerStyles}>
            <Icon name="time-line" styles={iconStyles} />
            <div css={deadlineLabelAndValueContainerStyles}>
              <ShortText styles={deadlineLabelStyles}>deadline:</ShortText>
              <ShortText styles={deadlineValueStyles}>
                {dateParser(data.due_date)}
              </ShortText>
            </div>
          </div>
        </div>
      </div>
      <NavLink to={`/${locale}/job/${data.id}/preview`}>
        <Button type="button" size="M" color="LIGHT_BLUE">
          see details
        </Button>
      </NavLink>
    </div>
  );
};

JobPostCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    company: PropTypes.shape({
      logo_url: PropTypes.string,
    }),
    name: PropTypes.string,
    city: PropTypes.PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    due_date: PropTypes.string,
  }).isRequired,
};

export default JobPostCard;
