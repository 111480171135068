import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const headingStyles = css`
  font-weight: 600;
  font-size: 20px;
  color: ${colors.PRIMARY_BLUE};
`;

export const memberListContainerStyles = css`
min-height: 146px;
  margin: 25px 0 0 0;
`;

export const addMemberButtonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 160px 0 0 0;
`;
