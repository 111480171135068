/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import MemberList from './MemberList/MemberList';
import { Button, Heading } from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { manageMembers } from '../../../../locale';
import { roleEligibilityChecker } from '../../../../helpers/localStorage';
import {
  headingStyles,
  memberListContainerStyles,
  addMemberButtonContainerStyles,
} from './RightCardContent.styles';

const RightCardContent = ({
  data,
  setAddMemberModalStatus,
  setEditMemberModalData,
  setManageMemberModalData,
  setCurrentUserIdToBeActivated,
  setActiveConfirmationModalKey,
  setConfirmationModalStatus,
}) => {
  const { locale } = useContext(LocaleContext);

  const handleAddMemberModalOpen = () => {
    setAddMemberModalStatus(true);
  };

  return (
    <div>
      <Heading type="h3" styles={headingStyles}>
        {manageMembers.title[locale]}
      </Heading>
      <div css={memberListContainerStyles}>
        {data.map((d) => (
          <MemberList
            key={d.id}
            id={d.id}
            name={d.name}
            email={d.email}
            status={d.status.toUpperCase()}
            memberRole={d.role.toUpperCase()}
            setEditMemberModalData={setEditMemberModalData}
            setManageMemberModalData={setManageMemberModalData}
            setCurrentUserIdToBeActivated={setCurrentUserIdToBeActivated}
            setActiveConfirmationModalKey={setActiveConfirmationModalKey}
            setConfirmationModalStatus={setConfirmationModalStatus}
          />
        ))}
      </div>
      {roleEligibilityChecker() && (
        <div css={addMemberButtonContainerStyles}>
          <Button
            type="button"
            size="M"
            onClick={handleAddMemberModalOpen}
            inverted
          >
            {manageMembers.addMemberButton[locale]}
          </Button>
        </div>
      )}
    </div>
  );
};

RightCardContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company_name: PropTypes.string,
      company_type: PropTypes.string,
      department: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
      status: PropTypes.oneOf(['active', 'inactive', 'pending']),
    }),
  ).isRequired,
  setAddMemberModalStatus: PropTypes.func.isRequired,
  setEditMemberModalData: PropTypes.func.isRequired,
  setManageMemberModalData: PropTypes.func.isRequired,
  setCurrentUserIdToBeActivated: PropTypes.func.isRequired,
  setActiveConfirmationModalKey: PropTypes.func.isRequired,
  setConfirmationModalStatus: PropTypes.func.isRequired,
};

export default RightCardContent;
