/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

import SearchAndFilter from './SearchAndFilter/SearchAndFilter';
import ApplicantList from './ApplicantList/ApplicantList';
import InviteUserModal from './InviteUserModal/InviteUserModal';
import InvitationConfirmationModal from './InvitationConfirmationModal/InvitationConfirmationModal';
import SuccessInvitationModal from './SuccessInvitationModal/SuccessInvitationModal';
import { Container, ShortText, Button } from '../../general';
import { selfCompany } from '../../../api/v1/get';
import {
  containerStyles,
  titleContainerStyles,
  titleStyles,
  filterAndApplicantListContainerStyles,
  applicantListContainerStyles,
  buttonStyles,
} from './Resume.styles';

const Resume = ({
  params,
  resumeData,
  filterData,
  setFilterData,
  searchTerm,
  setSearchTerm,
  setSearchTermResetStatus,
  selectOptionsData,
  pageInfo,
  setPageInfo,
  isInviteUserModalShown,
  setInviteUserModalStatus,
  isInvitationConfirmationModalShown,
  setInvitationConfirmationModalStatus,
  isSuccessInvitationModalShown,
  setSuccessInvitationModalStatus,
  setRefetchStatus,
  setFilterApplicationStatus,
  setPaginationResetStatus,
  setSearchFilterStatus,
  isSearchAndFilterReset,
}) => {
  const [profile, setProfile] = useState({});
  const handleFilterProgram = (val) => {
    setFilterData((prevState) => ({
      ...prevState,
      source: val,
    }));
  };

  useEffect(async () => {
    const { data } = await selfCompany();

    setProfile(data.data);
  }, []);

  return (
    <Fragment>
      <Container contentWidthType="FULL" containerStyles={containerStyles}>
        <div css={titleContainerStyles}>
          <Button
            type="button"
            size="M"
            styles={buttonStyles(filterData.source === '')}
            onClick={() => handleFilterProgram('')}
          >
            <ShortText styles={titleStyles}>all candidates</ShortText>
          </Button>
          {
            profile.has_kmi_nda && (
              <Button
                type="button"
                size="M"
                styles={buttonStyles(filterData.source === 'kmi')}
                onClick={() => handleFilterProgram('kmi')}
              >
                <ShortText styles={titleStyles}>kampus merdeka</ShortText>
              </Button>
            )
          }
        </div>
        <div css={filterAndApplicantListContainerStyles}>
          <SearchAndFilter
            filterData={filterData}
            setFilterData={setFilterData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setPageInfo={setPageInfo}
            setSearchTermResetStatus={setSearchTermResetStatus}
            selectOptionsData={selectOptionsData}
            setFilterApplicationStatus={setFilterApplicationStatus}
            setPaginationResetStatus={setPaginationResetStatus}
            isSearchAndFilterReset={isSearchAndFilterReset}
            setSearchFilterStatus={setSearchFilterStatus}
          />
          <div css={applicantListContainerStyles}>
            <ApplicantList
              params={params}
              resumeData={resumeData}
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              setInviteUserModalStatus={setInviteUserModalStatus}
              setRefetchStatus={setRefetchStatus}
              setSearchTermResetStatus={setSearchTermResetStatus}
              setFilterApplicationStatus={setFilterApplicationStatus}
            />
          </div>
        </div>
        <InviteUserModal
          isInviteUserModalShown={isInviteUserModalShown}
          setInviteUserModalStatus={setInviteUserModalStatus}
          setInvitationConfirmationModalStatus={
            setInvitationConfirmationModalStatus
          }
        />
        <InvitationConfirmationModal
          isInviteUserModalShown={isInviteUserModalShown}
          isInvitationConfirmationModalShown={isInvitationConfirmationModalShown}
          setInvitationConfirmationModalStatus={
            setInvitationConfirmationModalStatus
          }
          setSuccessInvitationModalStatus={setSuccessInvitationModalStatus}
        />
        <SuccessInvitationModal
          isSuccessInvitationModalShown={isSuccessInvitationModalShown}
          setSuccessInvitationModalStatus={setSuccessInvitationModalStatus}
        />
      </Container>
    </Fragment>
  );
};

Resume.propTypes = {
  params: PropTypes.shape({}).isRequired,
  resumeData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      role_preference: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  ).isRequired,
  filterData: PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    minWorkExperienceInYears: PropTypes.number,
    minWorkExperienceInMonths: PropTypes.number,
    maxWorkExperienceInYears: PropTypes.number,
    maxWorkExperienceInMonths: PropTypes.number,
    educationLevel: PropTypes.arrayOf(PropTypes.string),
    lastEducationMinYears: PropTypes.number,
    lastEducationMaxYears: PropTypes.number,
    provinces: PropTypes.arrayOf(PropTypes.string),
    distinctions: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
  }).isRequired,
  setFilterData: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSearchTermResetStatus: PropTypes.func.isRequired,
  selectOptionsData: PropTypes.shape({
    skillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    rolePreferenceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    educationLevelList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    provinceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
    distinctionsList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }).isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
  isInviteUserModalShown: PropTypes.shape({
    seekerId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setInviteUserModalStatus: PropTypes.func.isRequired,
  isInvitationConfirmationModalShown: PropTypes.shape({
    jobId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setInvitationConfirmationModalStatus: PropTypes.func.isRequired,
  isSuccessInvitationModalShown: PropTypes.bool.isRequired,
  setSuccessInvitationModalStatus: PropTypes.func.isRequired,
  setRefetchStatus: PropTypes.func.isRequired,
  setFilterApplicationStatus: PropTypes.func.isRequired,
  setPaginationResetStatus: PropTypes.func.isRequired,
  setSearchFilterStatus: PropTypes.func.isRequired,
  isSearchAndFilterReset: PropTypes.bool.isRequired,
};

export default Resume;
