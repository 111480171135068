import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  padding: 32px 0 90px 0;
`;

export const titleContainerStyles = css`
  padding: 0 0 15px 0;
  border-bottom: 1px solid rgba(29, 61, 113, 0.05);
`;

export const buttonStyles = (isActive) => css`
  background: ${colors.TRANSPARENT};
  border-width: 0 0 3px;
  border-radius: 0;
  border-color: ${isActive ? colors.PRIMARY_ORANGE : colors.TRANSPARENT};
  margin: 0 15px 0 0;
  width: unset;
`;

export const titleStyles = css`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 24px;
  color: ${colors.PRIMARY_BLUE};
`;

export const filterAndApplicantListContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 24px 0 0 0;
`;

export const applicantListContainerStyles = css`
  width: 992px;
  margin: 0 0 0 53px;
`;
