/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import * as css from './StepMap.styles';
import { Container, ShortText, Icon } from '../../../general';

const StepMap = ({ currentStep }) => {
  const [isActive, setIsActive] = useState({
    firstStep: {
      status: false,
      isNumber: true,
    },
    secondStep: {
      status: false,
      isNumber: true,
    },
    firstProgressBar: 0,
  });

  useEffect(() => {
    if (currentStep === 1) {
      setIsActive({
        firstStep: {
          status: true,
          isNumber: true,
        },
        secondStep: {
          status: false,
          isNumber: true,
        },
        firstProgressBar: 50,
      });
    }

    if (currentStep === 2) {
      setIsActive({
        firstStep: {
          status: true,
          isNumber: false,
        },
        secondStep: {
          status: true,
          isNumber: true,
        },
        firstProgressBar: 100,
      });
    }
  }, [currentStep]);

  return (
    <Container
      contentWidthType="NORMAL"
      containerStyles={css.containerStyles}
      contentStyles={css.contentStyles}
    >
      <div css={css.stepContainerStyles}>
        <div css={css.numberAndStepNameContainerStyles}>
          <div
            css={css.numberContainerStyles(
              isActive.firstStep.status,
              isActive.firstStep.isNumber,
            )}
          >
            {isActive.firstStep.isNumber ? (
              <ShortText styles={css.numberStyles(isActive.firstStep.status)}>
                1
              </ShortText>
            ) : (
              <Icon name="check-line" styles={css.checkIconStyles} />
            )}
          </div>
          <ShortText styles={css.stepNameStyles}>job details</ShortText>
        </div>
        <progress
          css={css.progressBarStyles}
          value={isActive.firstProgressBar}
          max={100}
        />
        <div css={css.numberAndStepNameContainerStyles}>
          <div
            css={css.numberContainerStyles(
              isActive.secondStep.status,
              isActive.secondStep.isNumber,
            )}
          >
            {isActive.secondStep.isNumber ? (
              <ShortText styles={css.numberStyles(isActive.secondStep.status)}>
                2
              </ShortText>
            ) : (
              <Icon name="check-line" styles={css.checkIconStyles} />
            )}
          </div>
          <ShortText styles={css.stepNameStyles}>job summary</ShortText>
        </div>
      </div>
    </Container>
  );
};

StepMap.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default StepMap;
