import { css } from '@emotion/react';

import { colors, contentWidth } from '../../../../configs';

export const navbarStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  background-color: ${colors.WHITE};
`;

export const navbarContentStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${contentWidth.FULL}px;
  height: 100%;
`;

export const unorderedListStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const listStyles = (isActive) => css`
  width: 70px;
  height: 100%;
  margin: 0 32px 0 0;
  border-bottom: 4px solid
    ${isActive ? colors.PRIMARY_YELLOWISH_ORANGE : colors.TRANSPARENT};
  transition: all 0.2s ease-in-out;

  &:last-of-type {
    margin: 0;
  }
`;

export const linkStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  color: ${colors.PRIMARY_BLACK};
`;
