/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { PlainCard } from '../../../cards';
import {
  Button,
  Heading,
  Input,
  Modal,
  Select,
} from '../../../general';
import { userById, users } from '../../../../api/v1/get/company';
import { rolePreferences } from '../../../../api/v1/get/user';
import { updateUserById } from '../../../../api/v1/put/companies';
import {
  cardStyles,
  titleStyles,
  formStyles,
  inputContainerStyles,
  buttonContainerStyles,
} from './EditMemberModal.styles';
import { LocaleContext } from '../../../../contexts/localeContext';

const EditMemberModal = ({
  setData,
  editMemberModalData,
  setEditMemberModalData,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const [roleOptions, setRoleOptions] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    role: '',
  });

  useEffect(async () => {
    const { data } = await rolePreferences();
    const modifiedArr = data.data.map(({ id, label }) => ({
      label, value: id,
    }));
    setRoleOptions(modifiedArr);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = editMemberModalData.id;
        const userRes = await userById(userId);

        setFormData({
          fullName: userRes.data.data.name,
          email: userRes.data.data.email,
          role: userRes.data.data.role,
        });
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      }
    };

    if (editMemberModalData.status) fetchUserData();
  }, [editMemberModalData.status]);

  const handleCloseModal = () => {
    setEditMemberModalData({ id: 0, status: false });
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const parsedData = {
        name: formData.fullName,
        email: formData.email,
        role: formData.role,
      };

      await updateUserById(editMemberModalData.id, parsedData);

      const res = await users({ limit: 9999 });

      setData(res.data.data.data);
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      setEditMemberModalData(false);
    }
  };

  return (
    <Modal isModalShown={editMemberModalData.status}>
      <PlainCard styles={cardStyles}>
        <Heading type="h2" styles={titleStyles}>
          edit member
        </Heading>
        <form css={formStyles} onSubmit={handleSubmit}>
          <Input
            type="text"
            containerStyles={inputContainerStyles}
            label="full name"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="full name"
          />
          <Input
            type="email"
            containerStyles={inputContainerStyles}
            label="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="email"
          />
          <Select
            containerStyles={inputContainerStyles}
            label="role"
            name="role"
            options={roleOptions}
            value={formData.role}
            onChange={handleChange}
            placeholder="select role"
          />
          <div css={buttonContainerStyles}>
            <Button
              type="button"
              size="S"
              color="DARK_GREY"
              onClick={handleCloseModal}
              inverted
            >
              cancel
            </Button>
            <Button type="submit" size="M" color="LIGHT_BLUE">
              update
            </Button>
          </div>
        </form>
      </PlainCard>
    </Modal>
  );
};

EditMemberModal.propTypes = {
  setData: PropTypes.func.isRequired,
  editMemberModalData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setEditMemberModalData: PropTypes.func.isRequired,
};

export default EditMemberModal;
