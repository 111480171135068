/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import modalImage from '../../../../assets/images/pages/change-password-settings/modal.png';
import {
  Modal,
  Heading,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { LocaleContext } from '../../../../contexts/localeContext';
import { changePassword } from '../../../../locale';
import {
  cardStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonStyles,
} from './SuccessModal.styles';

const SuccessModal = ({
  changePasswordStatus,
  setChangePasswordStatus,
  handleRedirectToLogout,
}) => {
  const { locale } = useContext(LocaleContext);

  const handleCloseModal = () => {
    setChangePasswordStatus((prevState) => ({
      ...prevState,
      isSuccessModalShown: false,
    }));
    handleRedirectToLogout();
  };

  return (
    <Modal isModalShown={changePasswordStatus.isSuccessModalShown}>
      <PlainCard styles={cardStyles}>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          {changePassword.modal.title[locale]}
        </Heading>
        <Text styles={descriptionStyles}>
          {changePassword.modal.description[locale]}
        </Text>
        <Button
          type="button"
          styles={buttonStyles}
          size="M"
          color="PRIMARY_BLUE"
          onClick={handleCloseModal}
        >
          {changePassword.modal.button[locale]}
        </Button>
      </PlainCard>
    </Modal>
  );
};

SuccessModal.propTypes = {
  changePasswordStatus: PropTypes.shape({
    isPasswordMatch: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setChangePasswordStatus: PropTypes.func.isRequired,
  handleRedirectToLogout: PropTypes.func.isRequired,
};

export default SuccessModal;
