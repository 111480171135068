/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from '../Icon/Icon';
import { usePagination } from '../../../hooks';
import {
  paginationContainerStyles,
  buttonStyles,
  iconStyles,
  numberButtonStyles,
} from './Pagination.styles';

const Pagination = ({
  containerStyles,
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  if (currentPage === 0) {
    return null;
  }

  return (
    <ul css={[paginationContainerStyles, containerStyles]}>
      <li>
        <button
          type="button"
          css={buttonStyles}
          onClick={onPrevious}
          disabled={currentPage === 1}
        >
          <Icon
            name="arrow-left-s-line"
            styles={iconStyles(currentPage === 1)}
          />
          prev
        </button>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === '...') {
          return <li key={index}>&#8230;</li>;
        }

        return (
          <li key={index}>
            <button
              type="button"
              css={numberButtonStyles(pageNumber === currentPage)}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}
      <li>
        <button
          type="button"
          css={buttonStyles}
          onClick={onNext}
          disabled={currentPage === lastPage}
        >
          next
          <Icon
            name="arrow-right-s-line"
            styles={iconStyles(currentPage === lastPage)}
          />
        </button>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  containerStyles: PropTypes.shape({}),
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  containerStyles: css``,
  siblingCount: 1,
};

export default Pagination;
