import { css } from '@emotion/react';

import { colors, contentWidth } from '../../../configs';

export const footerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: ${colors.PRIMARY_BLACK};
`;

export const footerContentStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${contentWidth.FULL}px;
`;

export const descriptionStyles = css`
  text-transform: capitalize;
  font-size: 12px;
  color: ${colors.WHITE};
`;

export const linkStyles = css`
  margin: 0 24px 0 0;
  text-transform: capitalize;
  font-size: 12px;

  &:last-of-type {
    margin: 0;
  }
`;
