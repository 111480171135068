import axios from 'axios';

const distinctions = (params) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/library/distinctions`,
    {
      params,
    },
  ));

export default distinctions;
