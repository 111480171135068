import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const containerStyles = css`
  width: 100%;
  margin: 18px 0 0 0;
  padding: 0 0 12px 0;
  border-bottom: 1px solid ${colors.GREY};
`;

export const rowStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const titleStyles = css`
  text-transform: capitalize;
  font-size: 18px;
`;

export const employmentTypeBadge = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border-radius: 12px;
  background-color: rgba(245, 148, 46, 0.15);
`;

export const employmentType = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 11px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const nameAndLocationContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const nameStyles = css`
  width: 150px;;
  text-transform: capitalize;
  line-height: 1.3;
  font-weight: 600;
  font-size: 13px;
  color: ${colors.LIGHT_BLUE};
`;

export const iconAndLocationContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 25px;
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const locationStyles = css`
  width: 175px;
  margin: 0 0 0 5px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const dateStyles = css`
  width: 150px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
`;

export const listContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
`;

export const singleListStyles = css`
  margin: 0 0 8px;
  display: block;
`;
