import { css } from '@emotion/react';

export const applicantContainerStyles = css`
  min-height: 500px;
`;

export const paginationContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 0 0;
`;

export const paginationStyles = css`
  margin: 0;
`;
