/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from '../Icon/Icon';
import {
  inputContainerStyles,
  iconStyles,
  primaryInputStyles,
} from './InputWithIcon.styles';

const InputWithIcon = ({
  containerStyles,
  inputStyles,
  type,
  iconName,
  name,
  value,
  onChange,
  placeholder,
  readOnly,
  required,
  isError,
}) => (
  <div css={[inputContainerStyles, containerStyles]}>
    <Icon name={iconName} styles={iconStyles(isError)} />
    <input
      type={type}
      css={[primaryInputStyles, inputStyles]}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
    />
  </div>
);

InputWithIcon.propTypes = {
  type: PropTypes.oneOf(['date', 'email', 'password', 'text']).isRequired,
  iconName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  containerStyles: PropTypes.shape({}),
  inputStyles: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isError: PropTypes.bool,
};

InputWithIcon.defaultProps = {
  containerStyles: css``,
  inputStyles: css``,
  readOnly: false,
  required: false,
  isError: false,
};

export default InputWithIcon;
