import axios from 'axios';

const skills = (params) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/library/skills/search/all`,
    {
      params,
    },
  ));

export default skills;
