import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const containerStyles = css`
  width: 100%;
  margin: 18px 0 0 0;
  padding: 0 0 24px 0;
  border-bottom: 1px solid ${colors.GREY};
`;

export const rowStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const titleStyles = css`
  text-transform: capitalize;
  font-size: 18px;
`;

export const employmentType = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 11px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
`;

export const nameAndLocationContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const nameStyles = css`
  max-width: 400px;
  font-weight: 600;
  font-size: 13px;
  color: ${colors.LIGHT_BLUE};
`;

export const linkStyles = css`
  display: flex;
  align-items: center;

  i {
    font-size: 18px;
    color: ${colors.LIGHT_BLUE};
    margin: 0 20px 0 0;
  }

  span {
    font-weight: 600;
    color: ${colors.LIGHT_BLUE};
    font-size: 14px;
  }
`;

export const dateStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
`;

export const descriptionRowStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 16px 0 0 0;
`;

export const labelStyles = css`
  margin: 0 0 4px 0;
  text-transform: capitalize;
  font-weight: 700;
`;
