import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const memberListStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 16px 0 0 0;
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${colors.GREY};

  &:first-of-type {
    margin: 0;
  }
`;

export const avatarAndMemberDetailsContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const avatarStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: ${colors.GREY};
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export const groupIconStyles = css`
  font-size: 21px;
  color: ${colors.DARK_GREY};
`;

export const nameAndStatusAndRoleContainerStyles = css`
  margin: 0 0 0 14px;
`;

export const nameAndRoleWrapperStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const nameStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
`;

export const roleStyles = css`
  margin: 8px 0 0 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const dropdownContainerStyles = css`
  position: relative;
`;

export const moreButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${colors.DARKER_GREY};
  border-radius: 6px;
  background-color: ${colors.WHITE};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const moreIconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const dropdownStyles = (isDropdownShown) => css`
  position: absolute;
  display: ${isDropdownShown ? 'block' : 'none'};
  width: 170px;
  margin: 5px 0 0 0;
  right: 0;
  padding: 5px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: ${colors.WHITE};
  z-index: 50;
`;

export const dropdownListStyles = css`
  &:last-of-type {
    margin: 5px 0 0 0;
  }
`;

export const dropdownButtonStyles = css`
  width: 100%;
  height: 36px;
  padding: 10px 13px;
  text-align: left;
  border: unset;
  border-radius: 5px;
  background-color: ${colors.WHITE};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${colors.LIGHT_BLUE};
    color: ${colors.WHITE};
  }
`;
