/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Heading, Link, ShortText } from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  navbarStyles,
  navbarContentStyles,
  titleStyles,
  breadcrumbsContainerStyles,
  linkStyles,
  currentPageNameStyles,
} from './Navbar.styles';

const Navbar = () => {
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);

  return (
    <nav css={navbarStyles}>
      <div css={navbarContentStyles}>
        <Heading type="h3" styles={titleStyles}>
          applicant details
        </Heading>
        <div css={breadcrumbsContainerStyles}>
          {jobId === 'resume' ? (
            <ShortText styles={linkStyles}>
              applicant list
            </ShortText>
          ) : (
            <Link to={`/${locale}/job/${jobId}/applicants?filter=applied`} styles={linkStyles}>
              applicant list
            </Link>
          )}
          <ShortText styles={currentPageNameStyles}>
            &nbsp;/ applicant details
          </ShortText>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
