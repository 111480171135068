import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const containerStyles = css`
  margin: 40px 0 0 0;
`;

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 124px;
  padding: 24px 0 26px 0;
  border-bottom: 1px solid rgba(29, 61, 113, 0.05);
  border-radius: 8px 8px 0 0;
  background-color: ${colors.WHITE};
`;

export const stepContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 535px;
`;

export const numberAndStepNameContainerStyles = css`
  position: relative;
`;

export const numberContainerStyles = (isActive, isNumber) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  ${isNumber
    ? `border: 1px solid ${isActive ? colors.PRIMARY_BLUE : colors.GREY};`
    : `border: 1px solid ${colors.PRIMARY_BLUE};`}
  border-radius: 50%;
  ${isNumber
    ? `background-color: ${isActive ? colors.PRIMARY_BLUE : colors.GREY};`
    : `background-color: ${colors.WHITE};`}
`;

export const numberStyles = (isActive) => css`
  font-weight: 700;
  color: ${isActive ? colors.WHITE : colors.DARK_GREY};
`;

export const checkIconStyles = css`
  font-size: 14px;
  color: ${colors.PRIMARY_BLUE};
`;

export const stepNameStyles = css`
  position: absolute;
  left: 50%;
  width: 135px;
  margin: 22px 0 0 -67.5px;
  white-space: nowrap;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
`;

export const progressBarStyles = css`
  &[value] {
    width: 160px;
    height: 4px;
    margin: 0 12px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    background-color: ${colors.GREY};
  }

  &[value]::-webkit-progress-bar {
    border-radius: 2px;
    background-color: ${colors.GREY};
  }

  &[value]::-webkit-progress-value {
    border-radius: 2px;
    background-color: ${colors.PRIMARY_BLUE};
  }

  &[value]::-moz-progress-bar {
    border-radius: 2px;
    background-color: ${colors.PRIMARY_BLUE};
  }
`;
