/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { ShortText } from '../../../../../general';
import { colors } from '../../../../../../configs';
import { statusPillStyles, statusStyles } from './StatusPill.styles';

const backgroundColorSwitch = (status) => {
  switch (status) {
    case 'OPEN':
      return colors.LIGHT_BLUE;
    case 'CLOSED':
      return colors.PRIMARY_YELLOWISH_ORANGE;
    case 'DRAFT':
      return colors.GREY;
    default:
      return colors.LIGHT_BLUE;
  }
};

const StatusPills = ({ status }) => (
  <div css={statusPillStyles(backgroundColorSwitch(status))}>
    <ShortText styles={statusStyles}>
      {status.toLowerCase()}
    </ShortText>
  </div>
);

StatusPills.propTypes = {
  status: PropTypes.oneOf(['OPEN', 'CLOSED', 'DRAFT']).isRequired,
};

export default StatusPills;
