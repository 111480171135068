export default {
  title: {
    en: 'edit profile',
    id: 'ubah profil',
  },
  fullName: {
    en: 'full name',
    id: 'nama lengkap',
  },
  phoneNumber: {
    en: 'phone number',
    id: 'nomor telepon',
  },
  notificationLabel: {
    en: 'notification',
    id: 'notifikasi',
  },
  notificationCheckboxLabel: {
    en: 'turn on email notification',
    id: 'aktifkan notifikasi email',
  },
  submitButton: {
    en: 'update profile',
    id: 'perbarui profil',
  },
};
