import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { FavoriteCandidates } from '../../components/pages';
import { favoriteCandidates } from '../../api/v1/get/company';
import { LocaleContext } from '../../contexts/localeContext';
import { LoadingContext } from '../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import {
  skills,
  rolePreferences,
  educationDegree,
  provinces,
} from '../../api/v1/get';
import { useIsFirstRender, useDebounce } from '../../hooks';
import {
  handleMinWorkExperienceInYears,
  handleMaxWorkExperienceInYears,
} from '../../helpers/general/handleYearExperience';

const FavoriteCandidatesPage = () => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const isFirstRender = useIsFirstRender();
  const { dispatch } = useContext(LoadingContext);
  const [favoriteCandidatesData, setFavoriteCandidatesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterData, setFilterData] = useState({
    skills: [],
    roles: [],
    educationLevel: [],
    minWorkExperienceInYears: 0,
    minWorkExperienceInMonths: 0,
    maxWorkExperienceInYears: 0,
    maxWorkExperienceInMonths: 0,
    lastEducationMinYears: 0,
    lastEducationMaxYears: 0,
    provinces: [],
  });
  const [selectOptionsData, setSelectOptionsData] = useState({
    skillsOptions: [],
    rolePreferenceList: [],
    educationLevelList: [],
    provinceList: [],
  });
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    totalCount: 10,
    currentPage: 1,
  });
  const [isInviteUserModalShown, setInviteUserModalStatus] = useState({
    seekerId: 0,
    status: false,
  });
  const [
    isInvitationConfirmationModalShown,
    setInvitationConfirmationModalStatus,
  ] = useState({
    jobId: 0,
    status: false,
  });
  const [
    isSuccessInvitationModalShown,
    setSuccessInvitationModalStatus,
  ] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const debouncedFilterData = useDebounce(filterData, 1000);

  const params = {
    q: debouncedSearchTerm,
    filter_skills: debouncedFilterData.skills,
    filter_roles: debouncedFilterData.roles,
    filter_min_years_of_work_experience:
      handleMinWorkExperienceInYears(filterData.minWorkExperienceInYears,
        filterData.minWorkExperienceInMonths) === 0.0
        ? null
        : handleMinWorkExperienceInYears(filterData.minWorkExperienceInYears,
          filterData.minWorkExperienceInMonths),
    filter_max_years_of_work_experience:
      handleMaxWorkExperienceInYears(filterData.maxWorkExperienceInYears,
        filterData.maxWorkExperienceInMonths) === 0.0
        ? null
        : handleMaxWorkExperienceInYears(filterData.maxWorkExperienceInYears,
          filterData.maxWorkExperienceInMonths),
    filter_education_degrees: debouncedFilterData.educationLevel,
    filter_min_education_year:
      debouncedFilterData.lastEducationMinYears === 0
        ? null
        : debouncedFilterData.lastEducationMinYears,
    filter_max_education_year:
      debouncedFilterData.lastEducationMaxYears === 0
        ? null
        : debouncedFilterData.lastEducationMaxYears,
    filter_province_ids: debouncedFilterData.provinces,
    limit: pageInfo.pageSize,
    page: pageInfo.currentPage,
  };

  const fetchSkillsOptions = async () => {
    const skillsRes = await skills();
    const options = skillsRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return options;
  };

  const fetchRolePreferences = async () => {
    const rolePreferencesRes = await rolePreferences();
    const list = rolePreferencesRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchEducationDegree = async () => {
    const educationDegreeRes = await educationDegree();
    const list = educationDegreeRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchProvinces = async () => {
    const queryParams = { country_id: 1 };
    const provincesRes = await provinces(queryParams);
    const list = provincesRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(isLoading());

        const promises = [
          fetchRolePreferences(),
          fetchEducationDegree(),
          fetchSkillsOptions(),
          fetchProvinces(),
          favoriteCandidates(params),
        ];
        const [
          rolePreferenceList,
          educationLevelList,
          skillsOptions,
          provinceList,
          favoriteCandidatesRes,
        ] = await Promise.all(promises);

        setSelectOptionsData((prevState) => ({
          ...prevState,
          rolePreferenceList,
          educationLevelList,
          skillsOptions,
          provinceList,
        }));
        setFavoriteCandidatesData(favoriteCandidatesRes.data.data.seekers);
        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: favoriteCandidatesRes.data.data.total_data,
        }));
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(isLoading());

        const favoriteCandidatesRes = await favoriteCandidates(params);

        setFavoriteCandidatesData(favoriteCandidatesRes.data.data.seekers);
        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: favoriteCandidatesRes.data.data.total_data,
        }));
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    if (!isFirstRender) {
      fetchData();
    }
  }, [debouncedSearchTerm, debouncedFilterData, pageInfo.currentPage]);

  return (
    <Layout isPaddingActive={false} backgroundColor="LIGHT_GREY">
      <FavoriteCandidates
        favoriteCandidatesData={favoriteCandidatesData}
        setFavoriteCandidatesData={setFavoriteCandidatesData}
        filterData={filterData}
        setFilterData={setFilterData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectOptionsData={selectOptionsData}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        isInviteUserModalShown={isInviteUserModalShown}
        setInviteUserModalStatus={setInviteUserModalStatus}
        isInvitationConfirmationModalShown={isInvitationConfirmationModalShown}
        setInvitationConfirmationModalStatus={setInvitationConfirmationModalStatus}
        isSuccessInvitationModalShown={isSuccessInvitationModalShown}
        setSuccessInvitationModalStatus={setSuccessInvitationModalStatus}
      />
    </Layout>
  );
};

export default FavoriteCandidatesPage;
