import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const inviteSeekerToCompanyJob = (jobId, data) => (
  axios.post(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/jobs/${jobId}/applications/invitations`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default inviteSeekerToCompanyJob;
