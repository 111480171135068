import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const cardStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 0 0;
  padding: 0 0 24px 0px;
  border-bottom: 1px solid ${colors.GREY};

  &:first-of-type {
    margin: 0;
  }
`;

export const jobInfoContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const imageContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 110px;
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
  background-color: rgba(215, 215, 215, 0.3);
  overflow: hidden;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const descriptionStyles = css`
  margin: 0 0 0 24px;
`;

export const jobTitleStyles = css`
  font-weight: 600;
  font-size: 18px;
`;

export const iconAndLocationNameContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 13px 0 0 0;
`;

export const iconStyles = css`
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const locationNameStyles = css`
  margin: 0 0 0 8px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const iconAndDeadlineContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0 0 0;
`;

export const deadlineLabelAndValueContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 8px;
`;

export const deadlineLabelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const deadlineValueStyles = css`
  margin: 4px 0 0 0;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.PRIMARY_ORANGE};
`;
