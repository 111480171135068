export default [
  'IDR',
  'USD',
  'SGD',
  'EUR',
  'AUD',
  'HKD',
  'NZD',
  'RUB',
  'THB',
  'TWD',
];
