import axios from 'axios';

import { getAccessToken } from '../../../helpers/localStorage';

const selfCompany = () => (
  axios.get(
    `${process.env.REACT_APP_USER_API_ENDPOINT}/v2/companies/profile`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default selfCompany;
