/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from '../Icon/Icon';
import {
  inputContainerStyles,
  labelStyles,
  iconAndInputContainerStyles,
  iconStyles,
  inputStyles,
} from './Input.styles';

const Input = (
  {
    containerStyles,
    label,
    icon,
    type,
    name,
    value,
    onChange,
    placeholder,
    readOnly,
    required,
    ...rest
  },
) => (
  <div css={[inputContainerStyles, containerStyles]}>
    {label && (
      <label htmlFor={label} css={labelStyles}>
        {label}
      </label>
    )}
    <div css={iconAndInputContainerStyles}>
      {icon && <Icon styles={iconStyles} name="search-line" />}
      <input
        id={label}
        type={type}
        css={inputStyles(label, icon)}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        {...rest}
      />
    </div>
  </div>
);

Input.propTypes = {
  containerStyles: PropTypes.shape({}),
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['date', 'email', 'number', 'password', 'text'])
    .isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

Input.defaultProps = {
  containerStyles: css``,
  label: '',
  icon: '',
  placeholder: '',
  readOnly: false,
  required: false,
};

export default Input;
