import queryString from 'query-string';
import { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { Resume } from '../../components/pages';
import { resumes } from '../../api/v1/get/company';
import { LocaleContext } from '../../contexts/localeContext';
import { LoadingContext } from '../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import {
  skills,
  rolePreferences,
  educationDegree,
  provinces,
  programOptions,
  distinctions,
} from '../../api/v1/get';
import { useIsFirstRender, useDebounce } from '../../hooks';
import {
  handleMinWorkExperienceInYears,
  handleMaxWorkExperienceInYears,
} from '../../helpers/general/handleYearExperience';

const ResumePage = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const isFirstRender = useIsFirstRender();
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(LoadingContext);
  const [isRefetch, setRefetchStatus] = useState(false);
  const [resumeData, setResumeData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(parsedQueryString?.q);
  const [isSearchTermReset, setSearchTermResetStatus] = useState(false);
  const [isPaginationReset, setPaginationResetStatus] = useState(false);
  const [isSearchAndFilterReset, setSearchFilterStatus] = useState(false);

  const undefinedChecker = (val) => {
    if (val !== undefined && val.length !== 0) return true;

    return false;
  };

  const handleParsedQueryString = (key) => queryString
    .parse(`${key}=${parsedQueryString[key]}`, { arrayFormat: 'comma' })[key];

  const handleStringToArray = (val) => {
    if (val) {
      if (typeof (val) === 'object') {
        return val;
      }
      return [val];
    }
    return null;
  };

  const [filterData, setFilterData] = useState({
    skills: undefinedChecker(parsedQueryString.filter_skills)
      ? handleStringToArray(handleParsedQueryString('filter_skills'))
      : [],
    roles: undefinedChecker(parsedQueryString.filter_roles)
      ? handleStringToArray(handleParsedQueryString('filter_roles'))
      : [],
    educationLevel: undefinedChecker(parsedQueryString.filter_education_degrees)
      ? handleStringToArray(handleParsedQueryString('filter_education_degrees'))
      : [],
    minWorkExperienceInYears:
      parsedQueryString?.filter_min_years_of_work_experience?.split('.')[0] || 0,
    minWorkExperienceInMonths:
      parsedQueryString?.filter_min_years_of_work_experience?.split('.')[1] || 0,
    maxWorkExperienceInYears:
      parsedQueryString?.filter_max_years_of_work_experience?.split('.')[0] || 0,
    maxWorkExperienceInMonths:
      parsedQueryString?.filter_max_years_of_work_experience?.split('.')[1] || 0,
    lastEducationMinYears: parsedQueryString?.filter_min_education_year || 0,
    lastEducationMaxYears: parsedQueryString?.filter_max_education_year || 0,
    provinces: undefinedChecker(parsedQueryString.filter_province_ids)
      ? handleStringToArray(handleParsedQueryString('filter_province_ids'))
      : [],
    programs: undefinedChecker(parsedQueryString.filter_programs)
      ? handleStringToArray(handleParsedQueryString('filter_programs'))
      : [],
    distinctions: undefinedChecker(parsedQueryString.filter_distinctions)
      ? handleStringToArray(handleParsedQueryString('filter_distinctions'))
      : [],
    source: parsedQueryString?.source || '',
  });
  const [isFilterApplied, setFilterApplicationStatus] = useState(false);
  const [selectOptionsData, setSelectOptionsData] = useState({
    skillsOptions: [],
    rolePreferenceList: [],
    educationLevelList: [],
    provinceList: [],
    programList: [],
    distinctionsList: [],
  });
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    totalCount: 0,
    currentPage: Number.isNaN(+parsedQueryString.page) ? 1 : parseInt(parsedQueryString?.page, 10),
  });
  const [isInviteUserModalShown, setInviteUserModalStatus] = useState({
    seekerId: 0,
    status: false,
  });
  const [
    isInvitationConfirmationModalShown,
    setInvitationConfirmationModalStatus,
  ] = useState({
    jobId: 0,
    status: false,
  });
  const [isSuccessInvitationModalShown, setSuccessInvitationModalStatus] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500) || '';

  const params = {
    q: searchTerm?.trim() || '',
    filter_skills: filterData.skills,
    filter_roles: filterData.roles,
    filter_min_years_of_work_experience:
      handleMinWorkExperienceInYears(
        filterData.minWorkExperienceInYears,
        filterData.minWorkExperienceInMonths,
      ) === 0.0
        ? null
        : handleMinWorkExperienceInYears(
          filterData.minWorkExperienceInYears,
          filterData.minWorkExperienceInMonths,
        ),
    filter_max_years_of_work_experience:
      handleMaxWorkExperienceInYears(
        filterData.maxWorkExperienceInYears,
        filterData.maxWorkExperienceInMonths,
      ) === 0.0
        ? null
        : handleMaxWorkExperienceInYears(
          filterData.maxWorkExperienceInYears,
          filterData.maxWorkExperienceInMonths,
        ),
    filter_education_degrees: filterData.educationLevel,
    filter_min_education_year:
      filterData.lastEducationMinYears === 0
        ? null
        : filterData.lastEducationMinYears,
    filter_max_education_year:
      filterData.lastEducationMaxYears === 0
        ? null
        : filterData.lastEducationMaxYears,
    filter_province_ids: filterData.provinces,
    filter_programs: filterData.programs,
    filter_distinctions: filterData.distinctions,
    limit: pageInfo.pageSize < 10 ? 10 : pageInfo.pageSize,
    page: parsedQueryString.page ? +parsedQueryString.page : +pageInfo.currentPage || 1,
    source: filterData.source,
  };

  const fetchSkillsOptions = async () => {
    const skillsRes = await skills();
    const options = skillsRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return options;
  };

  const fetchDistinctions = async () => {
    const distinctionRes = await distinctions();
    const options = distinctionRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return options;
  };

  const fetchRolePreferences = async () => {
    const rolePreferencesRes = await rolePreferences();
    const list = rolePreferencesRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchEducationDegree = async () => {
    const educationDegreeRes = await educationDegree();
    const list = educationDegreeRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchProvinces = async () => {
    const queryParams = { country_id: 1 };
    const provincesRes = await provinces(queryParams);
    const list = provincesRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchPrograms = async () => {
    const programRes = await programOptions();
    const list = programRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const handleNanPage = (p) => {
    if (Number.isNaN(+p)) return 1;
    return +p;
  };

  const fetchData = async (currPage = 1, isQueryString = false) => {
    let qsPage = isQueryString;
    if (parsedQueryString.page) qsPage = true;
    try {
      dispatch(isLoading());
      const resumesRes = await resumes(params);

      setResumeData(resumesRes.data.data.seekers);
      setPageInfo((prevState) => ({
        ...prevState,
        currentPage: qsPage ? handleNanPage(+parsedQueryString.page) : currPage,
        totalCount: resumesRes.data.data.total_data,
      }));
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response?.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      dispatch(isNotLoading());

      if (isSearchTermReset) setSearchTermResetStatus(false);
      if (isFilterApplied) setFilterApplicationStatus(false);
      if (isPaginationReset) setPaginationResetStatus(false);
      if (isRefetch) setRefetchStatus(false);
    }
  };

  useEffect(() => {
    if (!isFirstRender && !isPaginationReset && !isFilterApplied && isSearchTermReset) {
      fetchData(parsedQueryString.page, true);
    }
  }, [parsedQueryString.page]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        dispatch(isLoading());

        const promises = [
          fetchRolePreferences(),
          fetchEducationDegree(),
          fetchSkillsOptions(),
          fetchProvinces(),
          fetchPrograms(),
          fetchDistinctions(),
        ];
        const [
          rolePreferenceList,
          educationLevelList,
          skillsOptions,
          provinceList,
          programList,
          distinctionsList,
        ] = await Promise.all(promises);

        setSelectOptionsData((prevState) => ({
          ...prevState,
          rolePreferenceList,
          educationLevelList,
          skillsOptions,
          provinceList,
          programList,
          distinctionsList,
        }));
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response?.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchFilterOptions();
    fetchData();
    window.scrollTo(0, 0);

    if (!isFirstRender && pageInfo.currentPage > 0) {
      const stringifiedQueryParams = queryString.stringify(params, {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      });
      fetchData();
      history.push({
        pathname: `/${locale}/resume`,
        search: stringifiedQueryParams,
      });
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender && !isSearchTermReset && !isSearchAndFilterReset) {
      const stringifiedQueryParams = queryString.stringify(params, {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      });
      fetchData();
      setSearchFilterStatus(false);
      history.push({
        pathname: `/${locale}/resume`,
        search: stringifiedQueryParams,
      });
      window.scrollTo(0, 0);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!isFirstRender) {
      const stringifiedQueryParams = queryString.stringify(params, {
        arrayFormat: 'comma',
        skipNull: true,
        skipEmptyString: true,
      });

      if (isFilterApplied) {
        fetchData();
        history.push({
          pathname: `/${locale}/resume`,
          search: stringifiedQueryParams,
        });
      }

      if (isRefetch) {
        fetchData();
        history.push({
          pathname: `/${locale}/resume`,
          search: stringifiedQueryParams,
        });
      }

      window.scrollTo(0, 0);
    }
  }, [isFilterApplied, isRefetch]);

  return (
    <Layout isPaddingActive={false} backgroundColor="LIGHT_GREY">
      <Resume
        resumeData={resumeData}
        params={params}
        filterData={filterData}
        setFilterData={setFilterData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSearchTermResetStatus={setSearchTermResetStatus}
        selectOptionsData={selectOptionsData}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        isInviteUserModalShown={isInviteUserModalShown}
        setInviteUserModalStatus={setInviteUserModalStatus}
        isInvitationConfirmationModalShown={isInvitationConfirmationModalShown}
        setInvitationConfirmationModalStatus={
          setInvitationConfirmationModalStatus
        }
        isSuccessInvitationModalShown={isSuccessInvitationModalShown}
        setSuccessInvitationModalStatus={setSuccessInvitationModalStatus}
        setRefetchStatus={setRefetchStatus}
        setFilterApplicationStatus={setFilterApplicationStatus}
        setPaginationResetStatus={setPaginationResetStatus}
        isSearchAndFilterReset={isSearchAndFilterReset}
        setSearchFilterStatus={setSearchFilterStatus}
      />
    </Layout>
  );
};

export default ResumePage;
