import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const containerStyles = css`
  padding: 0 0 90px 0;
  background-color: ${colors.LIGHT_GREY};
`;

export const contentStyles = (isEmpty, backgroundImage) => css`
  min-height: calc(100vh - 220px);
  background-image: ${isEmpty ? `url(${backgroundImage})` : 'none'};
  background-repeat: no-repeat;
  background-size: 450px 318px;
  background-position: center;
`;

export const searchAndTitleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 24px 0;
  border-bottom: 1px solid rgba(29, 61, 113, 0.15);
`;

export const titleStyles = css`
  color: ${colors.PRIMARY_BLUE};
`;

export const selectAndInputContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const selectSortContainerStyles = css`
  width: 150px;
`;

export const inputSearchContainerStyles = css`
  width: 345px;
  margin: 0 0 0 16px;
`;

export const jobCardContainerStyles = css`
  min-height: calc(100vh - 450px);
  margin: 36px 0 0 0;
`;

export const paginationStyles = css`
  margin: 65px 0 0 0;
`;
