/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import modalImage from '../../../../assets/images/pages/favorite-candidates/confirmation.png';
import {
  Modal,
  ShortText,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { inviteSeekerToCompanyJob } from '../../../../api/v1/post/job';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  cardStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonContainerStyles,
  continueButtonStyles,
} from './InvitationConfirmationModal.styles';

const InvitationConfirmationModal = ({
  isInviteUserModalShown,
  isInvitationConfirmationModalShown,
  setInvitationConfirmationModalStatus,
  setSuccessInvitationModalStatus,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const [isSubmitting, setSubmittingStatus] = useState(false);

  const handleCloseModal = () => {
    setInvitationConfirmationModalStatus({
      seekerId: 0,
      status: false,
    });
  };

  const handleSubmit = async () => {
    const seekerId = {
      seeker_ids: [isInviteUserModalShown.seekerId],
    };

    try {
      setSubmittingStatus(true);
      await inviteSeekerToCompanyJob(
        isInvitationConfirmationModalShown.jobId,
        seekerId,
      );
      setInvitationConfirmationModalStatus({
        seekerId: 0,
        status: false,
      });
      setSuccessInvitationModalStatus(true);
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      setSubmittingStatus(false);
    }
  };

  return (
    <Modal isModalShown={isInvitationConfirmationModalShown.status}>
      <PlainCard styles={cardStyles}>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <ShortText type="h3" styles={titleStyles}>
          job invitation confirmation
        </ShortText>
        <Text styles={descriptionStyles}>
          are you sure want to invite selected member as&nbsp;
          <strong>job title</strong>
          &nbsp;to your selected active job?
        </Text>
        <div>
          <Button
            type="button"
            styles={buttonContainerStyles}
            size="S"
            onClick={handleCloseModal}
            inverted
          >
            cancel
          </Button>
          <Button
            type="button"
            styles={continueButtonStyles}
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
          >
            {isSubmitting ? 'sending...' : 'yes, continue'}
          </Button>
        </div>
      </PlainCard>
    </Modal>
  );
};

InvitationConfirmationModal.propTypes = {
  isInviteUserModalShown: PropTypes.shape({
    seekerId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  isInvitationConfirmationModalShown: PropTypes.shape({
    jobId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setInvitationConfirmationModalStatus: PropTypes.func.isRequired,
  setSuccessInvitationModalStatus: PropTypes.func.isRequired,
};

export default InvitationConfirmationModal;
