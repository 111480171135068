import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../../../components/layout/Layout';
import { ApplicantDetails } from '../../../../../components/pages';
import { applicantDetails } from '../../../../../api/v1/get/company';

const ApplicantDetailsPage = () => {
  const { applicantId } = useParams();

  const [applicantData, setApplicantData] = useState({
    id: 0,
    profile_image_url: '',
    name: '',
    address: '',
    summary: '',
    city: {
      id: 0,
      label: '',
    },
    country: {
      id: 0,
      label: '',
    },
    contacts: [],
    interests: [],
    skills: [],
    last_batch: {},
    batches: [],
    resume: {
      url: '',
    },
    last_hacktiv_course_track_record: {
      score: 0,
      description: '',
    },
    track_records: {
      work_experience: [],
      formal_education: [],
      informal_education: [],
      project: [],
    },
  });

  useEffect(() => {
    const fetchApplicantDetails = async () => {
      const applicantDetailsRes = await applicantDetails(applicantId);

      setApplicantData(applicantDetailsRes.data.data);
    };

    fetchApplicantDetails();
  }, []);

  return (
    <Layout isPaddingActive={false} backgroundColor="LIGHT_GREY">
      <ApplicantDetails applicantData={applicantData} />
    </Layout>
  );
};

export default ApplicantDetailsPage;
