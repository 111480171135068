import { css } from '@emotion/react';

export const cardStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 415px;
  height: auto;
  padding: 20px 28px 40px 28px;
`;

export const imageStyles = css`
  width: 160px;
  height: 160px;
`;

export const titleStyles = css`
  margin: 20px 0 0 0;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
`;

export const descriptionStyles = css`
  margin: 12px 0 0 0;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
`;

export const deadlineExtensionInputContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px 0 0 0;
`;

export const buttonContainerStyles = css`
  margin: 40px 0 0 0;
`;

export const continueButtonStyles = css`
  margin: 0 0 0 20px;
`;
