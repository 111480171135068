/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  textAreaContainerStyles,
  labelStyles,
  textAreaStyles,
} from './TextArea.styles';

const TextArea = ({
  containerStyles,
  label,
  rows,
  name,
  value,
  onChange,
  placeholder,
  readOnly,
  required,
}) => (
  <div css={[textAreaContainerStyles, containerStyles]}>
    <label htmlFor={label} css={labelStyles}>
      {label}
    </label>
    <textarea
      id={label}
      css={textAreaStyles}
      rows={rows}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
    />
  </div>
);

TextArea.propTypes = {
  containerStyles: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  containerStyles: css``,
  rows: 2,
  readOnly: false,
  required: false,
};

export default TextArea;
