/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import Navbar from './Navbar/Navbar';
import Details from './Details/Details';
import { containerStyles } from './JobDetails.styles';

const JobDetails = ({ data }) => (
  <div css={containerStyles}>
    <Navbar />
    <Details data={data} />
  </div>
);

JobDetails.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.shape({
      banner_url: PropTypes.string,
      name: PropTypes.string,
      logo_url: PropTypes.string,
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    province: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    minimum_education: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    job_function: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    job_level: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    employment_type: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    salary_currency: PropTypes.string,
    salary_range_from: PropTypes.number,
    salary_range_until: PropTypes.number,
    due_date: PropTypes.string,
    qualification: PropTypes.string,
    description: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    seeker_availabilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default JobDetails;
