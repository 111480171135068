/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import SearchAndFilter from './SearchAndFilter/SearchAndFilter';
import ApplicantList from './ApplicantList/ApplicantList';
import InviteUserModal from './InviteUserModal/InviteUserModal';
import InvitationConfirmationModal from './InvitationConfirmationModal/InvitationConfirmationModal';
import SuccessInvitationModal from './SuccessInvitationModal/SuccessInvitationModal';
import { Container, ShortText } from '../../general';
import {
  containerStyles,
  titleContainerStyles,
  titleStyles,
  filterAndApplicantListContainerStyles,
  applicantListContainerStyles,
} from './FavoriteCandidates.styles';

const FavoriteCandidates = ({
  favoriteCandidatesData,
  setFavoriteCandidatesData,
  filterData,
  setFilterData,
  searchTerm,
  setSearchTerm,
  selectOptionsData,
  pageInfo,
  setPageInfo,
  isInviteUserModalShown,
  setInviteUserModalStatus,
  isInvitationConfirmationModalShown,
  setInvitationConfirmationModalStatus,
  isSuccessInvitationModalShown,
  setSuccessInvitationModalStatus,
}) => (
  <Container contentWidthType="FULL" containerStyles={containerStyles}>
    <div css={titleContainerStyles}>
      <ShortText styles={titleStyles}>all candidates</ShortText>
    </div>
    <div css={filterAndApplicantListContainerStyles}>
      <SearchAndFilter
        filterData={filterData}
        setFilterData={setFilterData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectOptionsData={selectOptionsData}
      />
      <div css={applicantListContainerStyles}>
        <ApplicantList
          favoriteCandidatesData={favoriteCandidatesData}
          setFavoriteCandidatesData={setFavoriteCandidatesData}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          setInviteUserModalStatus={setInviteUserModalStatus}
        />
      </div>
    </div>
    <InviteUserModal
      isInviteUserModalShown={isInviteUserModalShown}
      setInviteUserModalStatus={setInviteUserModalStatus}
      setInvitationConfirmationModalStatus={
        setInvitationConfirmationModalStatus
      }
    />
    <InvitationConfirmationModal
      isInviteUserModalShown={isInviteUserModalShown}
      isInvitationConfirmationModalShown={isInvitationConfirmationModalShown}
      setInvitationConfirmationModalStatus={
        setInvitationConfirmationModalStatus
      }
      setSuccessInvitationModalStatus={setSuccessInvitationModalStatus}
    />
    <SuccessInvitationModal
      isSuccessInvitationModalShown={isSuccessInvitationModalShown}
      setSuccessInvitationModalStatus={setSuccessInvitationModalStatus}
    />
  </Container>
);

FavoriteCandidates.propTypes = {
  favoriteCandidatesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      role_preference: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  ).isRequired,
  setFavoriteCandidatesData: PropTypes.func.isRequired,
  filterData: PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    minWorkExperienceInYears: PropTypes.number,
    minWorkExperienceInMonths: PropTypes.number,
    maxWorkExperienceInYears: PropTypes.number,
    maxWorkExperienceInMonths: PropTypes.number,
    educationLevel: PropTypes.arrayOf(PropTypes.string),
    lastEducationMinYears: PropTypes.number,
    lastEducationMaxYears: PropTypes.number,
    provinces: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFilterData: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  selectOptionsData: PropTypes.shape({
    skillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    rolePreferenceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    educationLevelList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    provinceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }).isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
  isInviteUserModalShown: PropTypes.shape({
    seekerId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setInviteUserModalStatus: PropTypes.func.isRequired,
  isInvitationConfirmationModalShown: PropTypes.shape({
    jobId: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setInvitationConfirmationModalStatus: PropTypes.func.isRequired,
  isSuccessInvitationModalShown: PropTypes.bool.isRequired,
  setSuccessInvitationModalStatus: PropTypes.func.isRequired,
};

export default FavoriteCandidates;
