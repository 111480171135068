/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Navbar from './Navbar/Navbar';
import Details from './Details/Details';

const ApplicantDetails = ({ applicantData }) => (
  <Fragment>
    <Navbar />
    <Details applicantData={applicantData} />
  </Fragment>
);

ApplicantDetails.propTypes = {
  applicantData: PropTypes.shape({
    id: PropTypes.number,
    profile_image_url: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    summary: PropTypes.string,
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        channel: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    interests: PropTypes.arrayOf(PropTypes.shape({})),
    resume: PropTypes.shape({
      url: PropTypes.string,
    }),
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    last_batch: PropTypes.shape({
      program_name: PropTypes.string,
      graduation_time: PropTypes.string,
    }),
    batches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        program_name: PropTypes.string,
        graduation_time: PropTypes.string,
      }),
    ),
    last_hacktiv_course_track_record: PropTypes.shape({
      score: PropTypes.number,
      description: PropTypes.string,
    }),
    track_records: PropTypes.shape({
      work_experience: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          institution_name: PropTypes.string,
          description: PropTypes.string,
          city: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          province: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          country: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
      formal_education: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          institution_name: PropTypes.string,
          field_of_study: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
          }),
          description: PropTypes.string,
          city: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          province: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          country: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
      informal_education: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          institution_name: PropTypes.string,
          field_of_study: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
          }),
          description: PropTypes.string,
          city: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          province: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
          country: PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
          }),
        }),
      ),
      project: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          title: PropTypes.string,
          date_from: PropTypes.string,
          date_until: PropTypes.string,
          description: PropTypes.string,
          attachment_url: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
};

export default ApplicantDetails;
