import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../../../components/layout/Layout';
import { LoadingContext } from '../../../../contexts/loadingContext';
import { CreateJobPreview } from '../../../../components/pages';
import { selfCompany } from '../../../../api/v1/get/company';
import { isLoading, isNotLoading } from '../../../../reducers/loadingReducers';

const CreateJobPreviewPage = () => {
  const history = useHistory();
  const { locale } = useHistory();
  const { dispatch } = useContext(LoadingContext);
  const [data, setData] = useState({
    jobTitle: '',
    jobFunction: '',
    jobLevel: '',
    employmentType: '',
    numberOfPeople: '',
    applicationDeadline: '',
    minimumEducation: '',
    requiredSkills: [],
    jobDescription: '<p></p>',
    qualificationAndRequirements: '<p></p>',
    country: '',
    region: '',
    city: '',
    isWorkFromHome: false,
    isWillingToRelocate: false,
    currency: '',
    salaryRangeFrom: '',
    salaryRangeUntil: '',
    perksAndBenefits: [],
  });
  const [optionsData, setOptionsData] = useState({
    jobFunctionsOptions: [],
    jobLevelsOptions: [],
    employmentTypesOptions: [],
    minimumEducationOptions: [],
    requiredSkillsOptions: [],
    countryOptions: [],
    regionOptions: [],
    cityOptions: [],
    companyBenefitsOptions: [],
  });
  const [companyData, setCompanyData] = useState({
    bannerUrl: '',
    logoUrl: '',
    name: '',
    region: '',
    city: '',
    country: '',
  });

  useEffect(() => {
    if (
      localStorage.getItem('createJobFormData') !== null
      && localStorage.getItem('createJobOptionsData') !== null
    ) {
      const createJobFormData = JSON.parse(
        localStorage.getItem('createJobFormData'),
      );
      const createJobOptionsData = JSON.parse(
        localStorage.getItem('createJobOptionsData'),
      );

      setData(createJobFormData);
      setOptionsData(createJobOptionsData);
    }

    const fetchCompany = async () => {
      try {
        dispatch(isLoading());

        const selfCompanyRes = await selfCompany();

        setCompanyData({
          bannerUrl: selfCompanyRes.data.data.banner_url,
          logoUrl: selfCompanyRes.data.data.logo_url,
          name: selfCompanyRes.data.data.name,
          region: selfCompanyRes.data.data.region,
          city: selfCompanyRes.data.data.city,
          country: selfCompanyRes.data.data.country,
        });
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchCompany();
  }, []);

  return (
    <Layout isPaddingActive={false}>
      <CreateJobPreview
        data={data}
        optionsData={optionsData}
        companyData={companyData}
      />
    </Layout>
  );
};

export default CreateJobPreviewPage;
