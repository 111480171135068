/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Agreement, ValidateLoadingPage } from '../../components/pages';
import HeaderTnc from '../../components/layout/Header/HeaderTnc';
import Error from '../../components/pages/ErrorPage/Error';
import { validateAgreementId } from '../../api/v1/get';

const AgreementPage = () => {
  const { agreementId } = useParams();
  const [isValid, setIsValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    const fetchValidateAgreementId = async () => {
      try {
        setIsValidating(true);
        const { data } = await validateAgreementId(agreementId);

        setIsValid(data.data.isValid);
      } catch (error) {
        setIsValid(false);
      } finally {
        setIsValidating(false);
      }
    };

    fetchValidateAgreementId();
  }, [agreementId]);

  return (
    <>
      <HeaderTnc />
      {isValidating ? (
        <ValidateLoadingPage />
      ) : (
        isValid ? <Agreement agreementId={agreementId} /> : <Error />
      )}
    </>
  );
};

export default AgreementPage;
