/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Navbar from './Navbar/Navbar';
import List from './List/List';
import DraftSuccessModal from './DraftSuccessModal/DraftSuccessModal';
import CreateSuccessModal from './CreateSuccessModal/CreateSuccessModal';
import ChangeStatusConfirmationModal from './ChangeStatusConfirmationModal/ChangeStatusConfirmationModal';
import ChangeStatusSuccessModal from './ChangeStatusSuccessModal/ChangeStatusSuccessModal';

const Job = ({
  jobData,
  sortValue,
  setSortValue,
  searchTerm,
  setSearchTerm,
  pageInfo,
  setPageInfo,
  isCreateSuccessModalShown,
  setCreateSuccessModalStatus,
  isDraftSuccessModalShown,
  setDraftSuccessModalStatus,
  changeStatusConfirmationModalData,
  setChangeStatusConfirmationModalData,
  changeStatusSuccessModalData,
  setChangeStatusSuccessModalData,
  setRefetchStatus,
}) => (
  <Fragment>
    <Navbar />
    <List
      jobData={jobData}
      sortValue={sortValue}
      setSortValue={setSortValue}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      setChangeStatusConfirmationModalData={setChangeStatusConfirmationModalData}
      pageInfo={pageInfo}
      setPageInfo={setPageInfo}
    />
    <CreateSuccessModal
      isCreateSuccessModalShown={isCreateSuccessModalShown}
      setCreateSuccessModalStatus={setCreateSuccessModalStatus}
    />
    <DraftSuccessModal
      isDraftSuccessModalShown={isDraftSuccessModalShown}
      setDraftSuccessModalStatus={setDraftSuccessModalStatus}
    />
    <ChangeStatusConfirmationModal
      changeStatusConfirmationModalData={changeStatusConfirmationModalData}
      setChangeStatusConfirmationModalData={setChangeStatusConfirmationModalData}
      setChangeStatusSuccessModalData={setChangeStatusSuccessModalData}
    />
    <ChangeStatusSuccessModal
      changeStatusSuccessModalData={changeStatusSuccessModalData}
      setChangeStatusSuccessModalData={setChangeStatusSuccessModalData}
      setRefetchStatus={setRefetchStatus}
    />
  </Fragment>
);

Job.propTypes = {
  jobData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      banner_url: PropTypes.string,
      name: PropTypes.string,
      seekers_applied: PropTypes.number,
      status: PropTypes.oneOf(['open', 'closed', 'draft']),
    }),
  ).isRequired,
  sortValue: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  setSortValue: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
  isCreateSuccessModalShown: PropTypes.bool.isRequired,
  setCreateSuccessModalStatus: PropTypes.func.isRequired,
  isDraftSuccessModalShown: PropTypes.bool.isRequired,
  setDraftSuccessModalStatus: PropTypes.func.isRequired,
  changeStatusConfirmationModalData: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
  changeStatusSuccessModalData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
  setChangeStatusSuccessModalData: PropTypes.func.isRequired,
  setRefetchStatus: PropTypes.func.isRequired,
};

export default Job;
