const defaultLocale = 'en';

export const reducer = (_, action) => {
  switch (action.type) {
    case 'ENGLISH':
      return 'en';
    case 'INDONESIAN':
      return 'id';
    default:
      return defaultLocale;
  }
};

export const localeToEnglish = () => ({
  type: 'ENGLISH',
});

export const localeToIndonesian = () => ({
  type: 'INDONESIAN',
});
