/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import modalImage from '../../../../assets/images/pages/job/success-draft.png';
import {
  Modal,
  Icon,
  Heading,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import {
  cardStyles,
  closeButtonContainerStyles,
  closeButtonStyles,
  closeIconStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonStyles,
} from './ChangeStatusSuccessModal.styles';

const ChangeStatusSuccessModal = ({
  changeStatusSuccessModalData,
  setChangeStatusSuccessModalData,
  setRefetchStatus,
}) => {
  const handleCloseModal = () => {
    setChangeStatusSuccessModalData({
      id: 0,
      name: '',
      status: false,
    });
  };

  const handleContinueModal = () => {
    setChangeStatusSuccessModalData({
      id: 0,
      name: '',
      status: false,
    });
    setRefetchStatus(true);
  };

  return (
    <Modal isModalShown={changeStatusSuccessModalData.status}>
      <PlainCard styles={cardStyles}>
        <div css={closeButtonContainerStyles}>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          job status has been changed
        </Heading>
        <Text styles={descriptionStyles}>
          {`Thank you, your existing ${changeStatusSuccessModalData.name} Job has been changed. You can check the information details from jobs page`}
        </Text>
        <Button
          type="button"
          styles={buttonStyles}
          size="L"
          color="LIGHT_BLUE"
          onClick={handleContinueModal}
        >
          ok
        </Button>
      </PlainCard>
    </Modal>
  );
};

ChangeStatusSuccessModal.propTypes = {
  changeStatusSuccessModalData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
  setChangeStatusSuccessModalData: PropTypes.func.isRequired,
  setRefetchStatus: PropTypes.func.isRequired,
};

export default ChangeStatusSuccessModal;
