/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import React, {
  useRef,
  useContext,
  useLayoutEffect,
  memo,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Snackbar, LoadingModal } from '../general';
import { LocaleContext } from '../../contexts/localeContext';
import { bodyStyles } from './Layout.styles';

const Layout = ({
  isHeaderShown,
  isPaddingActive,
  backgroundColor,
  children,
}) => {
  const firstUpdate = useRef(true);
  const history = useHistory();
  const { pathname } = useLocation();
  const { locale } = useContext(LocaleContext);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      const parsedPathname = pathname.replace(/^.+?[/]/, '');
      const queryString = history.location.search;

      history.push(`/${locale}/${parsedPathname}${queryString}`);
    }
  }, [locale]);

  return (
    <React.Fragment>
      {isHeaderShown && <Header />}
      <Snackbar />
      <div css={bodyStyles(isHeaderShown, isPaddingActive, backgroundColor)}>
        {children}
        <LoadingModal />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default memo(Layout);

Layout.propTypes = {
  isHeaderShown: PropTypes.bool,
  isPaddingActive: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  isHeaderShown: true,
  backgroundColor: 'BACKGROUND_GREY',
  isPaddingActive: true,
};
