import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const cardStyles = css`
  width: 234px;
  padding: 15px 10px 20px 10px;
  border-radius: 10px;
  background-color: ${colors.WHITE};
`;

export const sectionStyles = css`
  margin: 25px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const sectionTitleStyles = css`
  font-weight: 500;
  text-transform: capitalize;
`;

export const searchInputStyles = css`
  margin: 15px 0 0 0;
`;

export const inputStyles = css`
  height: 36px;
  padding: 0 49px 0 40px;
  font-size: 14px;
`;

export const advancedFilterSectionStyles = (isShown) => css`
  display: ${isShown ? 'block' : 'none'};
`;

export const advancedFiltersTitleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const hideButtonStyles = css`
  background-color: ${colors.TRANSPARENT};
  border: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 11px;
  color: ${colors.LIGHT_BLUE};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const reactSelectContainerStyles = css`
  width: 100%;
  margin: 15px 0 0 0;
`;

export const reactSelectStyles = css`
  width: 100%;

  .select__control {
    padding: 5px 0;
    border: 1px solid ${colors.GREY};
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: ${colors.LIGHT_BLUE};
    }

    .select__indicator-separator {
      background-color: ${colors.GREY};
    }

    .select__indicator {
      color: ${colors.LIGHT_BLUE};
    }

    .select__value-container {
      .select__placeholder {
        padding: 0 10px;
        text-transform: capitalize;
        font-size: 14px;
        color: ${colors.GREY};
      }
    }
  }
`;

export const accordionStyles = css`
  margin: 20px 0 0 0;
`;

export const accordionButtonStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  border-radius: 8px;
  border: none;
  background-color: rgba(29, 61, 113, 0.05);
  letter-spacing: -0.3px;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  cursor: pointer;
`;

export const accordionIconStyles = (isShow) => css`
  font-size: 20px;
  color: ${colors.LIGHT_BLUE};
  transform: ${isShow ? 'rotate(-180deg)' : 'rotate(0)'};
  transition: all 0.2s ease-in-out;
`;

export const accordionContentStyles = (isShow) => css`
  width: 100%;
  height: ${isShow ? 'auto' : 0};
  padding: ${isShow ? '16px 0 0 0' : 0};
  transition: all 0.2s ease-in-out;
  overflow: hidden;
`;

export const checkboxContainerStyles = css`
  height: 170px;
  margin: 12px 0 0 0;
  overflow-y: scroll;
`;

export const checkboxStyles = css`
  margin: 12px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const minimumAndMaximumContainerStyles = css`
  margin: 16px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const labelStyles = css`
  text-transform: capitalize;
`;

export const inputAndLabelContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0 0 0;
`;

export const minimumAndMaximumInputStyles = css`
  width: 135px;

  input {
    height: 36px;

    &:placeholder {
      text-transform: normal;
    }
  }
`;

export const minimumAndMaximumLabelStyles = css`
  margin: 0 0 0 8px;
  text-transform: capitalize;
`;

export const applyAndResetButtonContainerStyles = css`
  margin: 30px 0 0 0;
`;

export const resetButtonStyles = css`
  border-color: ${colors.TRANSPARENT};
`;
