export default {
  title: {
    en: 'manage members',
    id: 'kelola anggota',
  },
  addMemberButton: {
    en: 'add member',
    id: 'tambah anggota',
  },
  activateMemberModal: {
    title: {
      en: 'activate member',
      id: 'mengaktifkan anggota',
    },
    description: {
      en: 'Are you sure to activate your member?',
      id: 'Apakah anda yakin untuk mengaktifkan member ini?',
    },
    cancelButton: {
      en: 'cancel',
      id: 'batal',
    },
    continueButton: {
      en: 'yes, continue',
      id: 'ya, lanjutkan',
    },
  },
  deactivateMemberModal: {
    title: {
      en: 'deactivate member',
      id: 'menonaktifkan anggota',
    },
    description: {
      en: 'Are you sure to deactive your member’s account? Because they will no longer do any recruitment process in this job portal.',
      id: 'Apakah anda yakin untuk menonaktifkan akun anggota ini? Karena anggota tersebut tidak dapat lagi untuk melakukan proses rekruitmen apapun di aplikasi ini.',
    },
    cancelButton: {
      en: 'cancel',
      id: 'batal',
    },
    continueButton: {
      en: 'yes, continue',
      id: 'ya, lanjutkan',
    },
  },
  deleteMemberModal: {
    title: {
      en: 'delete member',
      id: 'hapus anggota',
    },
    description: {
      en: 'Are you sure you want to delete your member account? Because they will not be able to access job portal page.',
      id: 'Apakah anda yakin untuk menghapus akun anggota ini? Karena anggota tersebut tidak dapat mengakses aplikasi ini.',
    },
    cancelButton: {
      en: 'cancel',
      id: 'batal',
    },
    continueButton: {
      en: 'yes, continue',
      id: 'ya, lanjutkan',
    },
  },
};
