import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { CompanyProfile } from '../../components/pages';
import { jobs, selfCompany } from '../../api/v1/get';
import { LoadingContext } from '../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../reducers/loadingReducers';
import { useDebounce, useIsFirstRender } from '../../hooks';
import { LocaleContext } from '../../contexts/localeContext';

const CompanyProfilePage = () => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const isFirstRender = useIsFirstRender();
  const { dispatch } = useContext(LoadingContext);
  const [companyProfileData, setCompanyProfileData] = useState({
    banner_url: '',
    logo_url: '',
    name: '',
    city: '',
    country: '',
    industry: '',
    company_size: {
      label: {
        label: '',
      },
    },
    description: '<p></p>',
    address: '',
    website: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [jobData, setJobData] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        dispatch(isLoading());

        const companyProfileRes = await selfCompany();
        const jobsRes = await jobs({
          job_status: 'open',
          limit: pageInfo.pageSize,
          page: pageInfo.currentPage,
        });

        setCompanyProfileData(companyProfileRes.data.data);
        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: jobsRes.data.data.total_data,
        }));
        setJobData(jobsRes.data.data.jobs);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchCompanyProfile();
  }, []);

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        dispatch(isLoading());

        const jobsRes = await jobs({
          job_status: 'open',
          q: debouncedSearchTerm?.trim() || '',
          limit: pageInfo.pageSize,
          page: pageInfo.currentPage,
        });

        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: jobsRes.data.data.total_data,
        }));
        setJobData(jobsRes.data.data.jobs);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    if (!isFirstRender && debouncedSearchTerm.length !== 0) fetchCompanyProfile();
  }, [debouncedSearchTerm]);

  return (
    <Layout>
      <CompanyProfile
        companyProfileData={companyProfileData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        jobData={jobData}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
      />
    </Layout>
  );
};

export default CompanyProfilePage;
