/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState } from 'react';

import modalImage from '../../../../assets/images/pages/job/change-status-confirmation.png';
import {
  Modal,
  Heading,
  Text,
  Button,
  Input,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { updateCompanyJobStatus } from '../../../../api/v1/put';
import { dateParserDatabaseFormat } from '../../../../helpers/parser';
import {
  cardStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  deadlineExtensionInputContainerStyles,
  buttonContainerStyles,
  continueButtonStyles,
} from './ChangeStatusConfirmationModal.styles';

const ChangeStatusConfirmationModal = ({
  changeStatusConfirmationModalData,
  setChangeStatusConfirmationModalData,
  setChangeStatusSuccessModalData,
}) => {
  const [deadlineExtension, setDeadlineExtension] = useState('');

  const handleCloseModal = () => {
    setChangeStatusConfirmationModalData({
      id: 0,
      type: '',
      name: '',
      status: false,
    });
  };

  const handleDeadlineExtension = (e) => {
    setDeadlineExtension(e.target.value);
  };

  const handleSubmit = async () => {
    const data = {
      status: changeStatusConfirmationModalData.type,
    };

    if (changeStatusConfirmationModalData.type === 'open') {
      data.due_date = dateParserDatabaseFormat(deadlineExtension);
    }

    await updateCompanyJobStatus(changeStatusConfirmationModalData.id, data);

    setChangeStatusConfirmationModalData({
      id: 0,
      type: '',
      name: '',
      status: false,
    });
    setChangeStatusSuccessModalData({
      id: changeStatusConfirmationModalData.id,
      name: changeStatusConfirmationModalData.name,
      status: true,
    });
  };

  return (
    <Modal isModalShown={changeStatusConfirmationModalData.status}>
      <PlainCard styles={cardStyles}>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          status change confirmation
        </Heading>
        <Text styles={descriptionStyles}>
          are you sure you want to make changes to your existing job status
          from&nbsp;
          <strong>
            {changeStatusConfirmationModalData.type === 'open'
              ? 'close to open'
              : 'open to close'}
          </strong>
          &nbsp;for&nbsp;
          {changeStatusConfirmationModalData.name}
          listing?
        </Text>
        {changeStatusConfirmationModalData.type === 'open' && (
          <Input
            type="date"
            containerStyles={deadlineExtensionInputContainerStyles}
            label="deadline extension"
            name="deadlineExtension"
            value={deadlineExtension}
            onChange={handleDeadlineExtension}
          />
        )}
        <div>
          <Button
            type="button"
            styles={buttonContainerStyles}
            size="S"
            onClick={handleCloseModal}
            inverted
          >
            cancel
          </Button>
          <Button
            type="button"
            styles={continueButtonStyles}
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
          >
            yes, continue
          </Button>
        </div>
      </PlainCard>
    </Modal>
  );
};

ChangeStatusConfirmationModal.propTypes = {
  changeStatusConfirmationModalData: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
  setChangeStatusSuccessModalData: PropTypes.func.isRequired,
};

export default ChangeStatusConfirmationModal;
