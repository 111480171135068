import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const cardStyles = css`
  width: 425px;
  height: auto;
  padding: 0;
`;

export const cardHeaderStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  border-bottom: 1px solid ${colors.GREY};
`;

export const titleStyles = css`
  font-weight: 500;
  font-size: 18px;
`;

export const closeButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: unset;
  background-color: ${colors.TRANSPARENT};
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const closeIconStyles = css`
  font-size: 24px;
  color: ${colors.PRIMARY_BLUE};
`;

export const cardBodyStyles = css`
  padding: 24px 40px;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
`;

export const inputStyles = css`
  margin: 8px 0 0 0;
`;

export const descriptionStyles = css`
  text-align: justify;
  font-size: 18px;
`;

export const emailHighlightStyles = css`
  font-size: 18px;
  font-weight: 500;
`;
export const buttonStyles = css`
  margin: 32px 0 0 0;
`;

export const errorMessageHighlightStyles = css`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.DANGER_RED};
`;
