/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Link, ShortText } from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  navbarStyles,
  navbarContentStyles,
  jobContainerStyles,
  jobNameStyles,
  closeJobButtonStyles,
} from './Navbar.styles';

const Navbar = ({ jobDetailsData, setChangeStatusConfirmationModalData }) => {
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);

  const handleCloseJob = () => {
    setChangeStatusConfirmationModalData({
      id: jobId,
      status: true,
    });
  };

  return (
    <nav css={navbarStyles}>
      <div css={navbarContentStyles}>
        <div css={jobContainerStyles}>
          <ShortText styles={jobNameStyles}>{jobDetailsData.name}</ShortText>
        </div>
        <div>
          {jobDetailsData.status === 'open' && (
            <Button
              type="button"
              styles={closeJobButtonStyles}
              size="M"
              color="DANGER_RED"
              onClick={handleCloseJob}
              inverted
            >
              close this job
            </Button>
          )}
          <Link to={`/${locale}/job/${jobId}`}>
            <Button type="button" size="L" color="LIGHT_BLUE">
              view job details
            </Button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  jobDetailsData: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
};

export default Navbar;
