/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { LocaleContext } from '../../../contexts/localeContext';
import { footerMenu } from '../../../configs';
import { Text, Link } from '../../general';
import {
  footerStyles,
  footerContentStyles,
  descriptionStyles,
  linkStyles,
} from './Footer.styles';

const Footer = () => {
  const { locale } = useContext(LocaleContext);

  const getCurrentYear = () => {
    const date = new Date();
    const year = date.getFullYear();

    return year;
  };

  return (
    <div css={footerStyles}>
      <div css={footerContentStyles}>
        <Text styles={descriptionStyles}>
          &copy;
          {` ${getCurrentYear()} hacktiv8. all rights reserved.`}
        </Text>
        <div>
          {footerMenu.map(({ label, path }) => (
            <Link key={label} to={`/${locale}${path}`} styles={linkStyles}>
              {label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
