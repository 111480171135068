/** @jsxImportSource @emotion/react */
import { useContext } from 'react';

import notFoundImage from '../../../assets/images/pages/404/404.png';
import { LocaleContext } from '../../../contexts/localeContext';
import {
  Container,
  Text,
  Link,
  Button,
} from '../../general';
import {
  containerStyles,
  contentStyles,
  imageStyles,
  descriptionContainerStyles,
  titleStyles,
  subtitleStyles,
  buttonStyles,
} from './Error404.styles';

const Error404 = () => {
  const { locale } = useContext(LocaleContext);

  return (
    <Container
      contentWidthType="FULL"
      containerStyles={containerStyles}
      contentStyles={contentStyles}
    >
      <img css={imageStyles} src={notFoundImage} alt="error-404" />
      <div css={descriptionContainerStyles}>
        <Text styles={titleStyles}>Page not found!</Text>
        <Text styles={subtitleStyles}>
          Sorry, we couldn&apos;t find the page you’re looking for.
        </Text>
        <Link to={`/${locale}`}>
          <Button type="button" styles={buttonStyles} size="M">
            back to home
          </Button>
        </Link>
      </div>
    </Container>
  );
};

export default Error404;
