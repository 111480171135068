import PropTypes from 'prop-types';
import { createContext, useReducer, useMemo } from 'react';

import { reducer } from '../reducers/snackbarReducers';

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [isShow, dispatch] = useReducer(reducer, {
    type: 'FALSE',
    status: '',
    message: '',
  });

  const memoizedValue = useMemo(() => ({
    isShow,
    dispatch,
  }));

  return (
    <SnackbarContext.Provider value={memoizedValue}>
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
