import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const containerStyles = css`
  margin: 40px 0 0 0;
`;

export const contentStyles = css`
  height: 85px;
  background-color: ${colors.WHITE};
`;

export const linkListStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const listStyles = css`
  width: 182px;
  height: 100%;
`;

export const linkStyles = (isActive) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-bottom: 4px solid
    ${isActive ? colors.PRIMARY_YELLOWISH_ORANGE : colors.WHITE};
  transition: all 0.2s ease-in-out;
`;

export const iconStyles = css`
  font-size: 18px;
  color: ${colors.LIGHT_BLUE};
`;

export const nameAndValueStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 0 0 12px;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
`;
