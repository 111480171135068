/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import modalImage from '../../../../assets/images/pages/favorite-candidates/success-invitation.png';
import {
  Modal,
  Icon,
  ShortText,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import {
  cardStyles,
  closeButtonContainerStyles,
  closeButtonStyles,
  closeIconStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonStyles,
} from './SuccessInvitationModal.styles';

const SuccessInvitationModal = ({
  isSuccessInvitationModalShown,
  setSuccessInvitationModalStatus,
}) => {
  const handleCloseModal = () => {
    setSuccessInvitationModalStatus(false);
  };

  return (
    <Modal isModalShown={isSuccessInvitationModalShown}>
      <PlainCard styles={cardStyles}>
        <div css={closeButtonContainerStyles}>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <ShortText type="h3" styles={titleStyles}>
          invitation has been sent
        </ShortText>
        <Text styles={descriptionStyles}>
          thank you, the invitation has been sent successfully. Please wait for
          approval from the student regarding the invitation status.
        </Text>
        <Button
          type="button"
          styles={buttonStyles}
          size="L"
          color="LIGHT_BLUE"
          onClick={handleCloseModal}
        >
          complete
        </Button>
      </PlainCard>
    </Modal>
  );
};

SuccessInvitationModal.propTypes = {
  isSuccessInvitationModalShown: PropTypes.bool.isRequired,
  setSuccessInvitationModalStatus: PropTypes.func.isRequired,
};

export default SuccessInvitationModal;
