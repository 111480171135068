/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { Heading, Button } from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  navbarStyles,
  navbarContentStyles,
  titleStyles,
} from './Navbar.styles';

const Navbar = () => {
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);

  return (
    <nav css={navbarStyles}>
      <div css={navbarContentStyles}>
        <Heading type="h3" styles={titleStyles}>
          job details
        </Heading>
        <div>
          <NavLink to={`/${locale}/job/${jobId}/edit`}>
            <Button type="button" size="M" inverted>
              edit details
            </Button>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
