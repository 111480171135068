import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const headingStyles = css`
  font-weight: 600;
  font-size: 20px;
  color: ${colors.PRIMARY_BLUE};
`;

export const inputStyles = css`
  margin: 24px 0 0 0;
`;

export const textEditorStyles = css`
  margin: 24px 0 0 0;
`;

export const formRowStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0 0;
`;

export const halfContainerWidthStyles = css`
  width: calc(50% - 10px);
`;

export const selectStyles = css`
  width: 157px;
`;

export const dropzoneContainerStyles = css`
  margin: 24px 0 0 0;
`;

export const companyLogoDropzoneStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 130,
  height: 130,
  marginTop: 8,
  borderWidth: 1,
  borderRadius: 5,
  borderColor: colors.GREY,
  borderStyle: 'solid',
  backgroundColor: colors.WHITE,
  outline: 'none',
  cursor: 'pointer',
};

export const companyBannerDropzoneStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 140,
  marginTop: 8,
  borderWidth: 1,
  borderRadius: 5,
  borderColor: colors.GREY,
  borderStyle: 'solid',
  backgroundColor: colors.WHITE,
  outline: 'none',
  cursor: 'pointer',
};

export const dropzoneIconStyles = css`
  font-size: 20px;
  color: ${colors.DARKER_GREY};
`;

export const dropzoneTextStyles = css`
  text-align: center;
  font-size: 12px;
  color: ${colors.DARKER_GREY};
`;

export const submitButtonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0 0 0;
`;
