import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const userById = (userId) => (
  axios.get(`${process.env.REACT_APP_USER_API_ENDPOINT}/v2/companies/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }));

export default userById;
