export default {
  title: {
    en: 'find the best candidate your company needs among Hacktiv8 graduates!',
    id: 'temukan kandidat terbaik yang dibutuhkan perusahaanmu dari lulusan hacktiv8',
  },
  description: {
    en: 'Login to Post New Job',
    id: 'Masuk Untuk Memposting Job Baru',
  },
  forgotPassword: {
    en: 'forgot password',
    id: 'lupa password',
  },
  loginButton: {
    en: 'login',
    id: 'masuk',
  },
};
