import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  padding: 40px 0 80px;

  @media screen and (max-width: 1279px) {
    padding: 0 16px;
  }
`;

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 60px;
  border: 1px solid ${colors.GREY};
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  background: ${colors.WHITE};
  justify-content: flex-start;

  @media screen and (max-width: 1279px) {
    padding: 32px 32px 46px;
  }
`;

export const titleTextStyles = css`
  font-weight: 600;
  font-size: 40px;
  line-height: 38px;
  letter-spacing: -0.01em;
  margin: 0 0 12px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;

  @media screen and (max-width: 1279px) {
    font-weight: 500;
    font-size: 28px;
  }
`;

export const pointContainerStyles = css`
  margin: 12px 0;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    @media screen and (max-width: 1279px) {
      display: none;
    }
  }
`;

export const pointTitleStyles = css`
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 12px;

  @media screen and (max-width: 1279px) {
    font-size: 16px;
    margin: 0 0 14px;
  }
`;

export const pointTextStyles = css`
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
`;

export const contentContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;

  &:last-of-type {
    margin: 0;
  }
`;

export const contentContainerTncStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 12px 12px;
`;

export const contentTitleStyles = css`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px;
  color: ${colors.PRIMARY_BLACK};
`;

export const contentDescriptionStyles = css`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.PRIMARY_BLACK};

  @media screen and (max-width: 1279px) {
    margin: 0 0 8px;
  }
`;
