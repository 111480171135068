import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
`;

export const backgroundImageStyles = (backgroundImage) => css`
  width: 64.4%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const formContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35.6%;
  height: 100%;
  padding: 0 84px;
  background-color: ${colors.LIGHT_GREY};
`;

export const titleStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 41px 0 0 0;
  text-align: center;
  text-transform: unset;
  font-weight: 600;
  font-size: 30px;
  color: ${colors.PRIMARY_BLUE};
`;

export const iconStyles = css`
  font-size: 30px;
  color: ${colors.PRIMARY_BLUE};
`;

export const formStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 344px;
  margin: 24px 0 0 0;
`;

export const formTitleStyles = css`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
`;

export const credentialInputContainerStyles = css`
  margin: 24px 0 0 0;
`;

export const errorMessageContainerStyles = css`
  width: 100%;
  margin: 8px 0 0 0;
`;

export const errorMessageStyles = css`
  text-transform: capitalize;
  font-size: 12px;
  color: ${colors.DANGER_RED};
`;

export const submitButtonStyles = css`
  margin: 40px 0 0 0;
`;
