import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const textAreaContainerStyles = css`
  width: 100%;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const textAreaStyles = css`
  width: 100%;
  height: auto;
  margin: 8px 0 0 0;
  padding: 12px 15px;
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
  background-color: ${colors.BACKGROUND_WHITE};
  font-size: 14px;
  color: ${colors.BLACK};
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::placeholder {
    text-transform: capitalize;
    color: ${colors.GREY};
  }

  &:focus {
    border-color: ${colors.LIGHT_BLUE};
  }

  &:read-only {
    background-color: ${colors.GREY};
    cursor: not-allowed;

    &::placeholder {
      color: ${colors.WHITE};
    }
  }
`;
