import { useContext, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import { JobDetails } from '../../../components/pages';
import { jobDetails } from '../../../api/v1/get';
import { LoadingContext } from '../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../reducers/loadingReducers';
import { LocaleContext } from '../../../contexts/localeContext';

const JobDetailsPage = () => {
  const history = useHistory();
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(LoadingContext);
  const [data, setData] = useState({
    name: '',
    company: {
      banner_url: '',
      name: '',
      logo_url: '',
    },
    city: {
      id: 0,
      label: '',
    },
    province: {
      id: 0,
      label: '',
    },
    country: {
      id: 0,
      label: '',
    },
    minimum_education: {
      id: '',
      label: '',
    },
    job_function: {
      id: '',
      label: '',
    },
    job_level: {
      id: '',
      label: '',
    },
    employment_type: {
      id: '',
      label: '',
    },
    salary_currency: '',
    salary_range_from: 0,
    salary_range_until: 0,
    due_date: '',
    qualification: '<p></p>',
    description: '<p></p>',
    skills: [],
    benefits: [],
    seeker_availabilities: [],
  });

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        dispatch(isLoading());

        const jobDetailsRes = await jobDetails(jobId);

        setData(jobDetailsRes.data.data);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchJobDetails();
  }, []);

  return (
    <Layout isPaddingActive={false} backgroundColor="LIGHT_GREY">
      <JobDetails data={data} />
    </Layout>
  );
};

export default JobDetailsPage;
