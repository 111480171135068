import { css } from '@emotion/react';
import { colors } from '../../../../configs';

export const cardStyles = css`
  width: 720px;
  height: auto;
  padding: 32px;
`;

export const titleStyles = css`
  font-weight: 600;
  font-size: 24px;
  color: ${colors.PRIMARY_BLUE};
`;

export const formStyles = css`
  margin: 24px 0 0 0;
`;

export const inputContainerStyles = css`
  margin: 24px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 0 0;
`;
