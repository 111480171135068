import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const companyInfoPaperStyles = css`
  border-radius: 8px;
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export const bannerStyles = (backgroundImage) => css`
  width: 100%;
  height: 250px;
  background-color: ${colors.BABY_BLUE};
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const upperCompanyInfoPaperStyles = css`
  position: relative;
  padding: 60px 72px 20px 72px;
  border-bottom: 1px solid rgba(29, 61, 113, 0.05);
`;

export const companyLogoContainerStyles = css`
  position: absolute;
  top: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid ${colors.LIGHT_BLUE};
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export const companyLogoStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
`;

export const companyNameStyles = css`
  font-weight: 600;
  font-size: 22px;
`;

export const locationAndButtonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const iconAndLocationNameContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const iconStyles = css`
  font-size: 12px;
  color: ${colors.DARK_GREY};
`;

export const locationNameStyles = css`
  margin: 0 0 0 8px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const lowerCompanyInfoPaperStyles = css`
  padding: 36px 72px 60px 72px;
`;

export const rowStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 32px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const labelAndValueForRowContainerStyles = css`
  width: 343px;

  &:last-of-type {
    width: calc(100% - 433px);
    margin: 0 0 0 90px;
  }
`;

export const labelAndValueContainerStyles = css`
  margin: 32px 0 0 0;
`;

export const companyJobListPaperStyles = css`
  margin: 24px 0 0 0;
  padding: 0 0 42px 0;
  border-radius: 8px;
  background-color: ${colors.WHITE};
  overflow: hidden;
`;

export const titleAndSearchContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 18px 72px;
  background-color: rgba(30, 91, 189, 0.07);
`;

export const jobListPaperTitleStyles = css`
  font-size: 22px;
  color: ${colors.PRIMARY_BLUE};
`;

export const searchInputContainerStyles = css`
  position: relative;
`;

export const searchIconStyles = css`
  position: absolute;
  top: 50%;
  left: 16px;
  margin: -8.5px 0 0 0;
  font-size: 17px;
  color: ${colors.LIGHT_BLUE};
`;

export const searchInputStyles = css`
  width: 200px;
  height: 45px;
  padding: 0 15px 0 42px;
  border-radius: 6px;
  border: 1px solid ${colors.LIGHT_BLUE};

  &::placeholder {
    color: ${colors.DARKER_GREY};
  }
`;

export const jobPostCardContainerStyles = css`
  padding: 36px 72px 24px 72px;
`;
