import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const cardContainerStyles = css`
  width: 992px;
`;

export const cardStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 254px;
  margin: 0 0 24px 0;
  padding: 20px 24px 20px 14px;
  border-radius: 8px;
  background-color: ${colors.WHITE};
`;

export const imageStyles = css`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;
`;

export const rightCardStyles = css`
  width: 100%;
  padding: 0 0 0 12px;
`;

export const nameLocationAndFinalScoreContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const nameAndLocationContainerStyles = css`
  width: 330px;
`;

export const nameStyles = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  overflow: hidden;
`;

export const iconAndLocationContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0 0 0;
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const locationStyles = css`
  margin: 0 0 0 5px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const locationAndJobExperienceSeparatorStyles = css`
  width: 1px;
  height: 18px;
  margin: 0 12px;
  background-color: ${colors.DARK_GREY};
`;

export const jobExperienceStyles = css`
  font-weight: 500;
  font-size: 13px;
  color: ${colors.DARK_GREY};
`;

export const badgeAndFinalScoreContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin: 0 0 0 24px;
`;

export const finalScoreContainerStyles = (color) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 24px;
  padding: 0 5px;
  border-radius: 5px;
  background: ${color};
`;

export const finalScoreStyles = css`
  color: ${colors.WHITE};
`;

export const badgeStyles = (color) => css`
  display: inline-block;
  margin: 2px 0 0 0;
  background: ${color};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${colors.TRANSPARENT};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.1px;
`;

export const skillsContainerStyles = css`
  margin: 20px 0 0 0;
`;

export const jobNameStyles = css`
  text-transform: capitalize;
  font-weight: 600;
`;

export const skillPillsContainerStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0 0 0;
`;

export const skillPillStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 22px;
  margin: 0 8px 0 0;
  padding: 4px 8px;
  border-radius: 20px;
  border: 1px solid ${colors.PRIMARY_BLACK};
`;

export const skillNameStyles = css`
  font-size: 12px;
`;

export const workExperienceAndButtonContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 0 0;
`;

export const educationlabelAndValueContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 16px 0 0 0;
`;

export const workExperiencelabelAndValueContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const labelStyles = css`
  display: block;
  width: 65px;
  text-transform: capitalize;
  font-weight: 600;
`;

export const workExperienceContainerStyles = css`
  margin: 0 0 0 20px;
`;

export const valueContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
`;

export const workExperienceValueStyles = css`
  justify-content: flex-start;
  margin: 8px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const valueStyles = css`
  font-weight: 500;
`;

export const dotIconStyles = css`
  margin: 0 6px;
  font-size: 4px;
  color: ${colors.GREY};
`;

export const dateValueStyles = css`
  font-weight: 500;
  color: ${colors.DARK_GREY};
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const inviteUserButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
`;

export const addUserIconStyles = css`
  font-size: 18px;
  color: ${colors.WHITE};
`;

export const favoriteButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: 0 0 0 12px;
  padding: 0;
  border: none;
  border-radius: 6px;
  background-color: rgba(245, 148, 46, 0.2);
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const heartIconStyles = css`
  color: ${colors.RED};
`;
