/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ShortText from '../ShortText/ShortText';
import { editorStyles, labelStyles } from './TextEditor.styles';

const TextEditor = ({
  styles,
  label,
  value,
  onChange,
}) => (
  <div css={[editorStyles, styles]}>
    <ShortText styles={labelStyles}>{label}</ShortText>
    <Editor
      editorState={value}
      onEditorStateChange={onChange}
      wrapperClassName="customContainer"
      toolbarClassName="customToolbar"
      editorClassName="customInput"
      toolbar={{
        options: ['inline', 'textAlign', 'list'],
        inline: { options: ['bold', 'italic', 'underline'] },
      }}
    />
  </div>
);

TextEditor.propTypes = {
  styles: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

TextEditor.defaultProps = {
  styles: css``,
};

export default TextEditor;
