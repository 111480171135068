/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import modalImage from '../../../../assets/images/pages/job/change-status-confirmation.png';
import {
  Modal,
  Heading,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { updateCompanyJobStatus } from '../../../../api/v1/put';
import {
  cardStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonContainerStyles,
  continueButtonStyles,
} from './ChangeStatusConfirmationModal.styles';

const ChangeStatusConfirmationModal = ({
  changeStatusConfirmationModalData,
  setChangeStatusConfirmationModalData,
  setChangeStatusSuccessModalData,
}) => {
  const handleCloseModal = () => {
    setChangeStatusConfirmationModalData({
      id: 0,
      status: false,
    });
  };

  const handleSubmit = async () => {
    await updateCompanyJobStatus(changeStatusConfirmationModalData.id, {
      status: 'closed',
    });

    setChangeStatusConfirmationModalData({
      id: 0,
      status: false,
    });
    setChangeStatusSuccessModalData(true);
  };

  return (
    <Modal isModalShown={changeStatusConfirmationModalData.status}>
      <PlainCard styles={cardStyles}>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          status change confirmation
        </Heading>
        <Text styles={descriptionStyles}>
          are you sure you want to make changes to your existing job status
          from&nbsp;
          <strong>open to close</strong>
          &nbsp;for&nbsp;
          {changeStatusConfirmationModalData.name}
          listing?
        </Text>
        <div>
          <Button
            type="button"
            styles={buttonContainerStyles}
            size="S"
            onClick={handleCloseModal}
            inverted
          >
            cancel
          </Button>
          <Button
            type="button"
            styles={continueButtonStyles}
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
          >
            yes, continue
          </Button>
        </div>
      </PlainCard>
    </Modal>
  );
};

ChangeStatusConfirmationModal.propTypes = {
  changeStatusConfirmationModalData: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.bool,
  }).isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
  setChangeStatusSuccessModalData: PropTypes.func.isRequired,
};

export default ChangeStatusConfirmationModal;
