/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';

import { ShortText, ExternalLink, Icon } from '../../../../general';
import {
  containerStyles,
  rowStyles,
  titleStyles,
  nameAndLocationContainerStyles,
  nameStyles,
  dateStyles,
  linkStyles,
  descriptionRowStyles,
  labelStyles,
} from './ProjectList.styles';

const ProjectList = ({ data }) => (
  <div css={containerStyles}>
    <div css={rowStyles}>
      <ShortText styles={titleStyles}>{data.title}</ShortText>
    </div>
    <div css={rowStyles}>
      <div css={nameAndLocationContainerStyles}>
        <ExternalLink href={data.link} styles={nameStyles}>
          Click Here
        </ExternalLink>
      </div>
      <ShortText styles={dateStyles}>
        {`${moment(data.date_from).format('MMM, YYYY')} - ${
          data.date_until
            ? moment(data.date_until).format('MMM, YYYY')
            : 'present'
        }`}
      </ShortText>
    </div>
    {data.attachment_url && (
      <div css={rowStyles}>
        <ExternalLink styles={linkStyles} href={data.attachment_url}>
          <Icon name="attachment-line" />
          <ShortText>
            Attachment
          </ShortText>
        </ExternalLink>
      </div>
    )}
    <div css={descriptionRowStyles}>
      <ShortText styles={labelStyles}>project description:</ShortText>
      <ShortText>{data.description}</ShortText>
    </div>
  </div>
);

ProjectList.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    date_from: PropTypes.string,
    date_until: PropTypes.string,
    attachment_url: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ProjectList;
