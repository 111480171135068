/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import formValidator from '../formValidator';
import {
  Heading,
  Input,
  TextEditor,
  Select,
  TextArea,
  ReactDropzone,
  Icon,
  Text,
  Button,
} from '../../../general';
import { roleEligibilityChecker } from '../../../../helpers/localStorage';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow } from '../../../../reducers/snackbarReducers';
import {
  headingStyles,
  inputStyles,
  textEditorStyles,
  formRowStyles,
  halfContainerWidthStyles,
  selectStyles,
  dropzoneContainerStyles,
  companyLogoDropzoneStyles,
  companyBannerDropzoneStyles,
  dropzoneIconStyles,
  dropzoneTextStyles,
  submitButtonContainerStyles,
} from './RightCardContent.styles';

const RightCardContent = ({
  formData,
  setFormData,
  optionsData,
  handleUpdate,
  isSubmitting,
}) => {
  const { dispatch } = useContext(SnackbarContext);

  const companySizeOptions = [
    {
      label: '1-9 employees',
      value: 'size-1',
    },
    {
      label: '10-49 employees',
      value: 'size-2',
    },
    {
      label: '50-99 employees',
      value: 'size-3',
    },
    {
      label: '100-249 employees',
      value: 'size-4',
    },
    {
      label: '250 above employees',
      value: 'size-5',
    },
  ];

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDescriptionChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      description: d,
    }));
  };

  const handleCompanyLogoChange = (files) => {
    setFormData((prevState) => ({
      ...prevState,
      companyLogo: files[0],
    }));
  };

  const handleCompanyBannerChange = (files) => {
    setFormData((prevState) => ({
      ...prevState,
      companyBanner: files[0],
    }));
  };

  const handleUpdateCompanyProfile = () => {
    const isFormEligible = formValidator(formData, dispatch, isShow);

    if (isFormEligible) handleUpdate();
  };

  return (
    <div>
      <Heading type="h3" styles={headingStyles}>
        edit company profile
      </Heading>
      <Input
        type="text"
        containerStyles={inputStyles}
        label="company name*"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="company name"
        disabled
      />
      <TextEditor
        styles={textEditorStyles}
        label="description*"
        value={formData.description}
        onChange={handleDescriptionChange}
      />
      <div css={formRowStyles}>
        <Select
          containerStyles={halfContainerWidthStyles}
          label="company industry*"
          name="industry"
          options={optionsData.industryOptions}
          value={formData.industry}
          onChange={handleChange}
          placeholder="company industry"
        />
        <Select
          containerStyles={halfContainerWidthStyles}
          label="company size*"
          name="company_size"
          options={companySizeOptions}
          value={formData.company_size}
          onChange={handleChange}
          placeholder="organization size"
        />
      </div>
      <div css={formRowStyles}>
        <Select
          containerStyles={selectStyles}
          label="country*"
          name="country"
          options={optionsData.countryOptions}
          value={formData.country}
          onChange={handleChange}
          placeholder="select country"
        />
        <Select
          containerStyles={selectStyles}
          label="region/province/state*"
          name="region"
          options={optionsData.regionOptions}
          value={formData.region}
          onChange={handleChange}
          placeholder="select region"
        />
        <Select
          containerStyles={selectStyles}
          label="city*"
          name="city"
          options={optionsData.cityOptions}
          value={formData.city}
          onChange={handleChange}
          placeholder="select city"
        />
      </div>
      <TextArea
        containerStyles={inputStyles}
        label="office address*"
        name="address"
        value={formData.address}
        onChange={handleChange}
        placeholder="office address"
      />
      <Input
        type="text"
        containerStyles={inputStyles}
        label="website URL*"
        name="website"
        value={formData.website}
        onChange={handleChange}
        placeholder="website URL"
      />
      <ReactDropzone
        containerStyles={dropzoneContainerStyles}
        dropzoneStyles={companyLogoDropzoneStyles}
        label="company logo*"
        imagePreviewWidth={130}
        imagePreviewHeight={130}
        maxFileSize={2097152}
        onChange={handleCompanyLogoChange}
        currentImage={formData.logo_url}
      >
        <Icon name="image-line" styles={dropzoneIconStyles} />
        <Text styles={dropzoneTextStyles}>Max. 2 MB (90x90px)</Text>
        <Text styles={dropzoneTextStyles}>in JPEG or PNG</Text>
        <Text styles={dropzoneTextStyles}>format.</Text>
      </ReactDropzone>
      <ReactDropzone
        containerStyles={dropzoneContainerStyles}
        dropzoneStyles={companyBannerDropzoneStyles}
        label="company banner (optional)"
        imagePreviewWidth={513}
        imagePreviewHeight={138}
        maxFileSize={2097152}
        onChange={handleCompanyBannerChange}
        currentImage={formData.banner_url}
      >
        <Icon name="image-line" styles={dropzoneIconStyles} />
        <Text styles={dropzoneTextStyles}>
          Max. 2 MB (900x300px) in JPEG or PNG format.
        </Text>
      </ReactDropzone>
      {roleEligibilityChecker() && (
        <div css={submitButtonContainerStyles}>
          <Button
            type="button"
            size="XL"
            color="LIGHT_BLUE"
            onClick={handleUpdateCompanyProfile}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'submitting...' : 'update company profile'}
          </Button>
        </div>
      )}
    </div>
  );
};

RightCardContent.propTypes = {
  formData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.shape({}),
    industry: PropTypes.string,
    company_size: PropTypes.string,
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    website: PropTypes.string,
    logo_url: PropTypes.string,
    banner_url: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  optionsData: PropTypes.shape({
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    industryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default RightCardContent;
