export default [
  {
    label: 'terms & condition',
    path: '/terms-and-condition',
  },
  {
    label: 'privacy policy',
    path: '/privacy-policy',
  },
];
