import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { Login } from '../../components/pages';
import { LocaleContext } from '../../contexts/localeContext';
import { login, introspection, resetPasswordRequest } from '../../api/v1/post/auth';

const LoginPage = () => {
  const { locale } = useContext(LocaleContext);
  const history = useHistory();
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState({
    isModalShown: false,
    isSubmitting: false,
    isError: {
      status: false,
      message: '',
    },
    isSuccess: false,
  });
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loginStatus, setLoginStatus] = useState({
    isSubmitting: false,
    isError: {
      status: false,
      message: '',
    },
    isSuccess: false,
    isDeactivatedAccountModalShown: false,
  });

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    setForgotPasswordStatus((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    try {
      await resetPasswordRequest({
        email: resetPasswordEmail,
        redirect: 'company-portal',
      });

      setForgotPasswordStatus((prevState) => ({
        ...prevState,
        isSuccess: true,
      }));
    } catch (error) {
      setForgotPasswordStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isError: {
          status: true,
          message: error.response.data.message,
        },
      }));
    }
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginStatus((prevState) => ({
      ...prevState,
      isSubmitting: true,
      isError: {
        status: false,
        message: '',
      },
    }));

    const parsedData = {
      username: formData.email,
      password: formData.password,
      grant_type: 'password',
    };

    try {
      const loginRes = await login(parsedData);
      const introspectionRes = await introspection(
        loginRes.data.data.access_token,
      );

      setLoginStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
      localStorage.setItem(
        'loginRes',
        JSON.stringify(loginRes.data.data),
      );
      localStorage.setItem(
        'introspectionRes',
        JSON.stringify(introspectionRes.data.data),
      );
      history.push(`/${locale}/job?filter=all`);
    } catch (error) {
      setLoginStatus((prevState) => ({
        ...prevState,
        isSubmitting: false,
        isError: {
          status: true,
          message: error.response.data.message,
        },
      }));
    }
  };

  return (
    <Layout isHeaderShown={false} isPaddingActive={false}>
      <Login
        resetPasswordEmail={resetPasswordEmail}
        setResetPasswordEmail={setResetPasswordEmail}
        forgotPasswordStatus={forgotPasswordStatus}
        setForgotPasswordStatus={setForgotPasswordStatus}
        handleResetPasswordSubmit={handleResetPasswordSubmit}
        formData={formData}
        loginStatus={loginStatus}
        setLoginStatus={setLoginStatus} // only here
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default LoginPage;
