const formValidator = (formData, dispatch, isShow) => {
  if (!formData.fullName) {
    dispatch(isShow('error', 'name field is required.'));

    return false;
  }

  if (!formData.phoneNumber) {
    dispatch(isShow('error', 'phone number field is required.'));

    return false;
  }

  return true;
};

export default formValidator;
