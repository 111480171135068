/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import formValidator from '../formValidator';
import {
  Button,
  Heading,
  Link,
  ShortText,
} from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow, isNotShow } from '../../../../reducers/snackbarReducers';
import {
  navbarStyles,
  navbarContentStyles,
  titleStyles,
  breadcrumbsContainerStyles,
  linkStyles,
  currentPageNameStyles,
} from './Navbar.styles';

const Navbar = ({ formData, handleDataToLocalStorage }) => {
  const { jobId } = useParams();
  const history = useHistory();
  const { dispatch } = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);

  const handleConfirmAndPublish = () => {
    const isFormEligibleToSubmit = formValidator(formData, dispatch, isShow);

    if (isFormEligibleToSubmit) {
      handleDataToLocalStorage();
      dispatch(isNotShow());
      history.push(`/${locale}/job/${jobId}/duplicate/preview`);
    }
  };

  return (
    <nav css={navbarStyles}>
      <div css={navbarContentStyles}>
        <div>
          <Heading type="h3" styles={titleStyles}>
            duplicate job
          </Heading>
          <div css={breadcrumbsContainerStyles}>
            <Link to={`/${locale}/job`} styles={linkStyles}>
              jobs
            </Link>
            <ShortText styles={currentPageNameStyles}>
              &nbsp;/ duplicate job
            </ShortText>
          </div>
        </div>
        <Button
          type="button"
          size="L"
          color="LIGHT_BLUE"
          onClick={handleConfirmAndPublish}
        >
          preview
        </Button>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  formData: PropTypes.shape({}).isRequired,
  handleDataToLocalStorage: PropTypes.func.isRequired,
};

export default Navbar;
