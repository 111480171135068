/** @jsxImportSource @emotion/react */
import { Container, Text } from '../../general';

import {
  containerStyles,
  contentStyles,
  titleTextStyles,
  pointContainerStyles,
  pointTitleStyles,
  pointTextStyles,
  contentContainerStyles,
  contentTitleStyles,
  contentDescriptionStyles,
} from './TermsAndCondition.styles';

const TermsAndCondition = () => {
  const webContentTerms = [
    {
      title: 'Mengenai Situs Web',
      description:
        'Situs web ini dioperasikan oleh Hacktiv8. Di seluruh situs ini, istilah “kami” merujuk kepada Hacktiv8. Situs web ini dan domainnya adalah nama dan domain yang terafiliasi oleh Hacktiv8, dan halaman, tautan, fitur, konten, dan layanan yang selanjutnya ditawarkan oleh Hacktiv8 dimiliki dan dijalankan sepenuhnya oleh Hacktiv8. Hacktiv8 menawarkan situs web ini, termasuk semua informasi dan layanan yang tersedia di situs web ini kepada Anda sebagai pengguna, dengan syarat bahwa Anda telah menyetujui semua syarat, ketentuan, kebijakan, dan pemberitahuan yang dinyatakan dalam Syarat dan Ketentuan Layanan ini.',
    },
    {
      title: 'Tautan Pihak Ketiga',
      description:
        'Konten dan layanan tertentu yang tersedia di situs web ini dapat merujuk kepada situs web pihak ketiga. Tautan pihak ketiga di situs ini dapat mengarahkan Anda ke situs web pihak ketiga yang tidak berafiliasi dengan kami. Kami tidak bertanggung jawab untuk memeriksa atau mengevaluasi konten atau keakuratan, dan kami tidak menjamin dan tidak akan memiliki kewajiban atau tanggung jawab atas materi atau situs web pihak ketiga, atau atas materi, produk, atau layanan lain yang diberikan oleh pihak ketiga. Kami tidak bertanggung jawab atas segala kerusakan atau kerugian yang timbul terkait dengan pembelian atau penggunaan barang, layanan, sumber daya, konten, atau transaksi lainnya yang dibuat sehubungan dengan situs web pihak ketiga mana pun. Harap membaca dan meninjau dengan cermat kebijakan dan praktik pihak ketiga dan pastikan Anda memahaminya sebelum Anda melakukan transaksi apa pun. Keluhan, klaim, kekhawatiran, atau pertanyaan tentang produk pihak ketiga harus diarahkan secara langsung ke pihak ketiga.',
    },
    {
      title: 'Akurasi, Kelengkapan dan Kekinian Informasi',
      description:
        'Sewaktu-waktu mungkin terdapat informasi di situs kami atau dalam Syarat dan Ketentuan Layanan yang mengandung kesalahan pengetikan, ketidakakuratan, atau ketidak lengkapan yang mungkin terkait dengan deskripsi, harga, promosi, penawaran, dan ketersediaan. Kami tidak bertanggung jawab jika informasi yang tersedia di situs ini tidak akurat, lengkap atau terkini. Materi di situs ini disediakan hanya untuk informasi umum dan tidak boleh diandalkan atau digunakan sebagai satu-satunya dasar untuk membuat keputusan tanpa berkonsultasi dengan sumber informasi yang lebih utama, lebih akurat, lebih lengkap atau lebih terkini. Ketergantungan pada materi di situs web ini adalah risiko Anda sendiri. Kami berhak untuk sewaktu-waktu memperbaiki kesalahan, ketidakakuratan atau ketidak lengkapan, dan untuk mengubah atau memperbarui informasi atau membatalkan pesanan jika informasi apa pun dalam layanan atau situs web terkait tidak akurat.',
    },
  ];

  const webUserTerms = [
    {
      title: 'ID Pengguna dan Kata Sandi',
      description:
        'Jika Hacktiv8 memberi Anda ID pengguna dan kata sandi untuk memungkinkan Anda mengakses area khusus situs web ini atau konten atau layanan lain, Anda harus memastikan bahwa ID pengguna dan kata sandi dijaga kerahasiaannya. Anda sendiri akan bertanggung jawab atas kata sandi dan keamanan ID pengguna Anda.',
    },
    {
      title: 'informasi murid',
      description:
        'Hacktiv8 perlu mengumpulkan data pribadi murid untuk untuk kebutuhan kegiatan belajar mengajar. Hacktiv8 membutuhkan data sekolah murid sebelumnya, tempat tinggal, dan izin orangtua atau wali. Jika Anda adalah calon murid kami, Anda tidak perlu mengirimkan informasi pribadi di luar yang kami minta untuk kebutuhan pendaftaran. Jika kami mendapatkan sebuah informasi yang tidak mendapatkan izin orangtua atau wali bagi murid di bawah umur, maka kami akan menghapus informasi tersebut.',
    },
    {
      title: 'Penggunaan yang Diperbolehkan',
      description:
        'Situs web ini beserta kontennya ditujukan untuk memberikan informasi kepada pengguna. Anda dapat melihat, mengunduh untuk tujuan penyimpanan di memori singgahan (cache) saja, dan mencetak halaman, file, atau konten lain dari situs web ini untuk penggunaan pribadi Anda sendiri dan untuk hal yang bersifat non-komersil, dengan tunduk pada batasan yang ditetapkan di bawah ini dan di bagian lain dalam Syarat dan Ketentuan Layanan ini . Anda tidak diperbolehkan untuk menyimpan konten situs web ini selain untuk keperluan pribadi yang bersifat non-komersil tanpa izin terlebih dulu dari Hacktiv8.',
    },
    {
      title: 'Biaya Penggunaan Situs Web',
      description:
        'Akses ke website kami bersifat gratis, namun kedepannya kami dapat menawarkan fitur khusus atau layanan yang akan mengenakan biaya bagi penggunaannya. Karena itu, Hacktiv8 berhak menerima pembayaran untuk fitur khusus atau layanan tersebut. Jika Anda berminat untuk mengaksesnya, maka Anda wajib membayar biaya untuk layanan tersebut.',
    },
  ];

  return (
    <Container
      contentWidthType="FULL"
      containerStyles={containerStyles}
      contentStyles={contentStyles}
    >
      <Text styles={titleTextStyles}>Terms & Conditions</Text>
      <div css={pointContainerStyles}>
        <Text styles={pointTitleStyles}>Tanggal Efektif, 10 Des 2019</Text>
        <Text styles={pointTextStyles} textTransform="uppercase">
          syarat dan ketentuan ini mengatur penggunaan situs web ini oleh anda.
          harap membaca syarat dan ketentuan layanan ini dengan cermat sebelum
          mengakses atau menggunakan situs web kami.
        </Text>
        <Text styles={pointTextStyles} textTransform="uppercase">
          dengan mengunjungi situs web ini, anda setuju bahwa anda telah membaca
          secara seksama dan menyepakati syarat dan ketentuan ini secara penuh
          dan tanpa syarat. jika anda tidak setuju dengan syarat dan ketentuan
          ini , baik secara keseluruhan atau sebagian, anda tidak boleh
          menggunakan situs web ini.
        </Text>
      </div>
      <div css={pointContainerStyles}>
        <Text styles={pointTitleStyles}>A. PERNYATAAN CUKUP USIA</Text>
        <Text styles={pointTextStyles} textTransform="lowercase">
          Anda wajib berusia minimal 18 (delapan belas) tahun untuk dapat
          menggunakan situs web ini. Dengan menggunakan situs web ini dan dengan
          menyetujui syarat dan ketentuan ini, Anda menjamin dan menyatakan
          bahwa Anda setidaknya berusia 18 tahun. Jika Anda berusia di bawah 18
          (delapan belas) tahun, orang tua atau wali Anda wajib membaca Syarat
          dan Ketentuan Layanan ini dan menyetujui penggunaan situs web ini oleh
          Anda. Jika Anda tidak mendapatkan izin dari orang tua atau wali Anda,
          Anda tidak diperbolehkan untuk melakukan pendaftaran dan menggunakan
          situs web ini.
        </Text>
      </div>
      <div css={pointContainerStyles}>
        <Text styles={pointTitleStyles}>B. KONTEN SITUS WEB</Text>
        {webContentTerms.map(({ title, description }) => (
          <div key={title} css={contentContainerStyles}>
            <Text styles={contentTitleStyles}>{title}</Text>
            <Text styles={contentDescriptionStyles}>{description}</Text>
          </div>
        ))}
      </div>
      <div css={pointContainerStyles}>
        <Text styles={pointTitleStyles}>C. PENGGUNAAN SITUS WEB</Text>
        {webUserTerms.map(({ title, description }) => (
          <div key={title} css={contentContainerStyles}>
            <Text styles={contentTitleStyles}>{title}</Text>
            <Text styles={contentDescriptionStyles}>{description}</Text>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default TermsAndCondition;
