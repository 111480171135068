import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const containerStyles = css`
  padding: 80px 0;
  
  @media screen and (max-width: 1279px) {
    padding: 0 32px;
  }
`;

export const contentStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const imageStyles = css`
  @media screen and (min-width: 320px) and (max-width: 413px) {
    width: 275px;
    height: 250px;
  }

  @media screen and (min-width: 414px) and (max-width: 1279px) {
    width: 325px;
    height: 300px;
  }
`;

export const descriptionContainerStyles = css`
  margin: 0 0 0 90px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1279px) {
    margin: 10px 0 0;
    align-items: center;
  }
`;

export const titleStyles = css`
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  margin: 0 0 8px;

  @media screen and (max-width: 1279px) {
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 18px;
  }
`;

export const subtitleStyles = css`
  font-size: 18px;
  line-height: 21px;
  color: ${colors.PRIMARY_BLACK};
  margin: 0 0 8px;

  @media screen and (max-width: 1279px) {
    text-align: center;
    font-size: 14px;
    margin: 0 0 18px;
  }
`;
