import axios from 'axios';

import { getAccessToken } from '../../../../helpers/localStorage';

const setFavoriteSeeker = (data) => (
  axios.post(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/seekers/favorites`,
    data,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default setFavoriteSeeker;
