import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const editorStyles = css`
  width: 100%;

  .customContainer {
    margin: 8px 0 0 0;
    border: 1px solid ${colors.GREY};
    border-radius: 5px;
    overflow: hidden;
  }

  .customToolbar {
    margin: 0;
    border: none;
    background-color: ${colors.GREY};

    .rdw-option-wrapper {
      border-color: ${colors.GREY};
      background-color: ${colors.GREY};

      &:hover {
        box-shadow: unset;
        border-color: ${colors.LIGHT_BLUE};
      }
    }

    .rdw-option-active {
      box-shadow: unset;
      border-color: ${colors.LIGHT_BLUE};
    }
  }

  .customInput {
    min-height: 175px;
    padding: 0 10px;
    font-size: 14px;

    .public-DraftStyleDefault-block {
      margin: 10px 0;
    }
  }
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
`;
