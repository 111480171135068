/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { LocaleContext } from '../../../../contexts/localeContext';
import bannerPlaceholder from '../../../../assets/images/pages/recruiter/company-profile/banner-placeholder.png';
import { dateParser, thousandsSeparator } from '../../../../helpers/parser';
import {
  Container,
  Heading,
  Button,
  Icon,
  ShortText,
  LabelAndValue,
} from '../../../general';
import {
  containerStyles,
  jobDataPaperStyles,
  bannerStyles,
  upperJobDataPaperStyles,
  companyLogoContainerStyles,
  companyLogoStyles,
  jobTitleStyles,
  upperJobDataPaperRowStyles,
  companyNameStyles,
  iconAndLocationNameContainerStyles,
  iconStyles,
  locationNameStyles,
  deadlineOfApplicationStyles,
  tagContainerStyles,
  tagStyles,
  salariesStyles,
  copyLinkAndFavoriteButtonContainerStyles,
  copyLinkButtonStyles,
  favoriteButtonStyles,
  buttonIconStyles,
  applyNowButtonStyles,
  lowerJobDataPaperStyles,
  rowStyles,
  labelStyles,
  perksAndBenefitsContainerStyles,
  perksAndBenefitsStyles,
  perksAndBenefitsListStyles,
} from './Details.styles';

const Details = ({ data }) => {
  const { locale } = useContext(LocaleContext);
  const skillsParser = (requiredSkills) => {
    const parsedRequiredSkills = requiredSkills.map((d) => d.label);

    return parsedRequiredSkills.join(', ');
  };

  return (
    <Container contentWidthType="NORMAL" containerStyles={containerStyles}>
      <div css={jobDataPaperStyles}>
        <div
          css={bannerStyles(data.company?.banner_url || bannerPlaceholder)}
        />
        <div css={upperJobDataPaperStyles}>
          <div css={companyLogoContainerStyles}>
            <img
              src={data.company?.logo_url}
              css={companyLogoStyles}
              alt={data.company?.name}
            />
          </div>
          <Heading type="h2" styles={jobTitleStyles}>
            {data.name}
          </Heading>
          <div css={upperJobDataPaperRowStyles(18)}>
            <div>
              <div css={iconAndLocationNameContainerStyles}>
                <Icon name="map-pin-line" styles={iconStyles} />
                <ShortText styles={locationNameStyles}>
                  {`${data.city?.label}, ${data.country?.label}`}
                </ShortText>
              </div>
              <NavLink to={`/${locale}/company-profile`}>
                <Heading type="h2" styles={companyNameStyles}>
                  {data.company?.name}
                </Heading>
              </NavLink>
            </div>
            <LabelAndValue
              styles={deadlineOfApplicationStyles}
              label="deadline of application"
              value={dateParser(data.due_date)}
            />
          </div>
          <div css={upperJobDataPaperRowStyles(25)}>
            <div css={tagContainerStyles}>
              <div css={tagStyles}>
                <ShortText>{data.job_function?.label}</ShortText>
              </div>
              <div css={tagStyles}>
                <ShortText>{data.employment_type?.label}</ShortText>
              </div>
              <div css={tagStyles}>
                <ShortText>{data.job_level?.label}</ShortText>
              </div>
            </div>
            {data.salary_currency && data.salary_range_from && (
              <ShortText styles={salariesStyles}>
                {`${data.salary_currency} ${thousandsSeparator(
                  data.salary_range_from,
                )} -  ${data.salary_currency} ${thousandsSeparator(
                  data.salary_range_until,
                )}`}
              </ShortText>
            )}
          </div>
          <div css={upperJobDataPaperRowStyles(32)}>
            <div css={copyLinkAndFavoriteButtonContainerStyles}>
              <Button
                type="button"
                styles={copyLinkButtonStyles}
                size="M"
                inverted
                color="GREY"
              >
                <Icon styles={buttonIconStyles} name="link" />
                &nbsp;copy link
              </Button>
              <Button
                type="button"
                styles={favoriteButtonStyles}
                size="S"
                color="GREY"
                inverted
              >
                <Icon styles={buttonIconStyles} name="heart-line" />
              </Button>
            </div>
            <Button
              type="button"
              styles={applyNowButtonStyles}
              size="L"
              disabled
            >
              apply now!
            </Button>
          </div>
        </div>
        <div css={lowerJobDataPaperStyles}>
          <div css={rowStyles}>
            <LabelAndValue
              label="minimum education"
              value={data.minimum_education?.label}
            />
          </div>
          {data.skills.length !== 0 && (
            <div css={rowStyles}>
              <LabelAndValue
                label="required skills"
                value={skillsParser(data.skills)}
              />
            </div>
          )}
          <div css={rowStyles}>
            <LabelAndValue
              valueType="HTML"
              label="job description"
              value={ReactHtmlParser(data.description)}
            />
          </div>
          <div css={rowStyles}>
            <LabelAndValue
              valueType="HTML"
              label="qualification / requirements"
              value={ReactHtmlParser(data.qualification)}
            />
          </div>
          {data.seeker_availabilities.length !== 0 && (
            data.seeker_availabilities.map((d) => (
              <div key={d.id} css={rowStyles}>
                <LabelAndValue
                  valueType="LONG"
                  label={d.id === 'remote' ? 'availability' : 'relocation'}
                  value={
                    d.id === 'remote'
                      ? 'this job can be done remotely (work from home)'
                      : 'accept candidates willing to relocate'
                  }
                />
              </div>
            ))
          )}
          {data.benefits.length !== 0 && (
            <div css={perksAndBenefitsContainerStyles}>
              <ShortText styles={labelStyles}>perks and benefit</ShortText>
              <ul css={perksAndBenefitsStyles}>
                {data.benefits.map((d) => (
                  <li key={d.id} css={perksAndBenefitsListStyles}>
                    {d.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

Details.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    company: PropTypes.shape({
      banner_url: PropTypes.string,
      name: PropTypes.string,
      logo_url: PropTypes.string,
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    province: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    minimum_education: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    job_function: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    job_level: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    employment_type: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    salary_currency: PropTypes.string,
    salary_range_from: PropTypes.number,
    salary_range_until: PropTypes.number,
    due_date: PropTypes.string,
    qualification: PropTypes.string,
    description: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      }),
    ),
    seeker_availabilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default Details;
