/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import Navbar from './Navbar/Navbar';
import StepMap from './StepMap/StepMap';
import JobDetailsForm from './JobDetailsForm/JobDetailsForm';
// import SalaryAndBenefitsForm from './SalaryAndBenefitsForm/SalaryAndBenefitsForm';
import JobSummary from './JobSummary/JobSummary';
import { containerStyles } from './CreateJob.styles';

const CreateJob = ({
  currentStep,
  setCurrentStep,
  formData,
  setFormData,
  optionsData,
  setOptionsData,
  handleDataToLocalStorage,
  handleSaveAsDraft,
  handleSubmit,
  isSubmitting,
}) => (
  <div css={containerStyles}>
    <Navbar
      formData={formData}
      handleDataToLocalStorage={handleDataToLocalStorage}
    />
    <StepMap currentStep={currentStep} />
    {currentStep === 1 && (
      <JobDetailsForm
        setCurrentStep={setCurrentStep}
        formData={formData}
        setFormData={setFormData}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        handleDataToLocalStorage={handleDataToLocalStorage}
        handleSaveAsDraft={handleSaveAsDraft}
      />
    )}
    {/* {currentStep === 2 && (
      <SalaryAndBenefitsForm
        setCurrentStep={setCurrentStep}
        formData={formData}
        setFormData={setFormData}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        handleDataToLocalStorage={handleDataToLocalStorage}
        handleSaveAsDraft={handleSaveAsDraft}
      />
    )} */}
    {currentStep === 2 && (
      <JobSummary
        setCurrentStep={setCurrentStep}
        formData={formData}
        optionsData={optionsData}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    )}
  </div>
);

CreateJob.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    numberOfPeople: PropTypes.string,
    applicationDeadline: PropTypes.string,
    minimumEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
    jobDescription: PropTypes.shape({}),
    qualificationAndRequirements: PropTypes.shape({}),
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
    currency: PropTypes.string,
    salaryRangeFrom: PropTypes.string,
    salaryRangeUntil: PropTypes.string,
    perksAndBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  optionsData: PropTypes.shape({
    jobFunctionsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    jobLevelsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    employmentTypesOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    minimumEducationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    requiredSkillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    companyBenefitsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  setOptionsData: PropTypes.func.isRequired,
  handleDataToLocalStorage: PropTypes.func.isRequired,
  handleSaveAsDraft: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default CreateJob;
