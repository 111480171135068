const badgeChecker = (score) => {
  if (score >= 100) {
    return {
      badge: 'award',
      color: 'linear-gradient(75.19deg, #E12179 -8.71%, #311996 108.25%)',
    };
  }

  if (score >= 90 && score < 100) {
    return {
      badge: 'honors',
      color: 'linear-gradient(219.89deg, #40a4ff -2.35%, #3e62bd 72.83%)',
    };
  }

  if (score >= 80 && score < 90) {
    return {
      badge: 'graduate',
      color: '#f39222',
    };
  }

  if (score >= 65 && score < 80) {
    return {
      badge: 'participant',
      color: 'linear-gradient(80.89deg, #999999 -8.71%, #E5E5E5 200.83%)',
    };
  }

  return null;
};

export default badgeChecker;
