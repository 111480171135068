import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { useContext, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { LocaleContext } from './contexts/localeContext';
import { ScrollToTop } from './helpers/general';
import { ProtectedRoute, routeConfig } from './route';

const App = () => {
  const location = useLocation();
  const { locale } = useContext(LocaleContext);

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Redirect to={`/${locale}/job?filter=all`} />
        </Route>
        {routeConfig.map(({ path, component, isProtected }) => {
          if (isProtected) {
            return (
              <ProtectedRoute
                key={path}
                exact
                path={path}
                component={component}
              />
            );
          }

          return <Route key={path} exact path={path} component={component} />;
        })}
      </Switch>
    </>
  );
};

export default App;
