const yearsToMonthsParser = (years) => {
  const durationInMonths = Math.floor(years * 12);

  let date = '';

  if (durationInMonths === 0) {
    date = '-';
  } else if (durationInMonths >= 12 && durationInMonths % 12 !== 0) {
    date = `${Math.floor(durationInMonths / 12)} yrs ${
      durationInMonths % 12
    } mos`;
  } else if (durationInMonths >= 12 && durationInMonths % 12 === 0) {
    date = `${Math.floor(durationInMonths / 12)} yrs`;
  } else {
    date = `${durationInMonths % 12} mos`;
  }

  return date;
};

export default yearsToMonthsParser;
