import { useContext, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Layout from '../../../../components/layout/Layout';
import { Applicants } from '../../../../components/pages';
import {
  applicationSummary,
  applications,
  skills,
  rolePreferences,
  educationDegree,
  provinces,
  jobDetails,
} from '../../../../api/v1/get';
import { updateJobApplications } from '../../../../api/v1/put/companies';
import { LocaleContext } from '../../../../contexts/localeContext';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { LoadingContext } from '../../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../../reducers/loadingReducers';
import { isShow } from '../../../../reducers/snackbarReducers';
import { useIsFirstRender, useQuery, useDebounce } from '../../../../hooks';
import {
  handleMinWorkExperienceInYears,
  handleMaxWorkExperienceInYears,
} from '../../../../helpers/general/handleYearExperience';

const ApplicantsPage = () => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const snackbarContext = useContext(SnackbarContext);
  const isFirstRender = useIsFirstRender();
  const query = useQuery();
  const { jobId } = useParams();
  const { dispatch } = useContext(LoadingContext);
  const [isRefetch, setRefetchStatus] = useState(false);
  const [jobDetailsData, setJobDetailsData] = useState({
    name: '',
    status: '',
  });
  const [applicantSummaryData, setApplicantSummaryData] = useState([]);
  const [applicantTotalCount, setApplicantTotalCount] = useState(0);
  const [applicantListData, setApplicantListData] = useState([]);
  const [selectedApplicantIds, setSelectedApplicantIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterData, setFilterData] = useState({
    skills: [],
    roles: [],
    educationLevel: [],
    minWorkExperienceInYears: 0,
    minWorkExperienceInMonths: 0,
    maxWorkExperienceInYears: 0,
    maxWorkExperienceInMonths: 0,
    lastEducationMinYears: 0,
    lastEducationMaxYears: 0,
    provinces: [],
  });
  const [selectOptionsData, setSelectOptionsData] = useState({
    skillsOptions: [],
    rolePreferenceList: [],
    educationLevelList: [],
    provinceList: [],
  });
  const [sort, setSort] = useState({
    key: 'name',
    value: 'DESC',
  });
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    totalCount: 10,
    currentPage: 1,
  });
  const [changeStatusConfirmationModalData, setChangeStatusConfirmationModalData] = useState({
    id: 0,
    status: false,
  });
  const [changeStatusSuccessModalData, setChangeStatusSuccessModalData] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const debouncedFilterData = useDebounce(filterData, 1000);

  const params = {
    q: debouncedSearchTerm?.trim() || '',
    status: query.get('filter'),
    sort_key: sort.key,
    sort_value: sort.value,
    filter_skills: debouncedFilterData.skills,
    filter_roles: debouncedFilterData.roles,
    filter_min_years_of_work_experience:
      handleMinWorkExperienceInYears(filterData.minWorkExperienceInYears,
        filterData.minWorkExperienceInMonths) === 0.0
        ? null
        : handleMinWorkExperienceInYears(filterData.minWorkExperienceInYears,
          filterData.minWorkExperienceInMonths),
    filter_max_years_of_work_experience:
      handleMaxWorkExperienceInYears(filterData.maxWorkExperienceInYears,
        filterData.maxWorkExperienceInMonths) === 0.0
        ? null
        : handleMaxWorkExperienceInYears(filterData.maxWorkExperienceInYears,
          filterData.maxWorkExperienceInMonths),
    filter_education_degrees: debouncedFilterData.educationLevel,
    filter_min_education_year:
      debouncedFilterData.lastEducationMinYears === 0
        ? null
        : debouncedFilterData.lastEducationMinYears,
    filter_max_education_year:
      debouncedFilterData.lastEducationMaxYears === 0
        ? null
        : debouncedFilterData.lastEducationMaxYears,
    filter_province_ids: debouncedFilterData.provinces,
    limit: pageInfo.pageSize,
    page: pageInfo.currentPage,
  };

  const fetchSkillsOptions = async () => {
    const skillsRes = await skills();
    const options = skillsRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return options;
  };

  const fetchRolePreferences = async () => {
    const rolePreferencesRes = await rolePreferences();
    const list = rolePreferencesRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchEducationDegree = async () => {
    const educationDegreeRes = await educationDegree();
    const list = educationDegreeRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const fetchProvinces = async () => {
    const queryParams = { country_id: 1 };
    const provincesRes = await provinces(queryParams);
    const list = provincesRes.data.data.map(({ id, label }) => ({
      label,
      value: id,
    }));

    return list;
  };

  const handleBulkUpdateJobApplications = async (status) => {
    const jobApplicationsData = {
      seeker_ids: selectedApplicantIds,
      to_status: status,
    };

    try {
      dispatch(isLoading());
      await updateJobApplications(jobId, jobApplicationsData);
      history.push({
        pathname: `/${locale}/job/${jobId}/applicants`,
        search: `?filter=${status}`,
      });
      snackbarContext.dispatch(isShow('success', 'Successfully change applicant status.'));
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }

      if (error.response.data.message === 'to_status not valid') {
        snackbarContext.dispatch(isShow('error', 'Application status cannot be moved to applicant.'));
      }
    } finally {
      dispatch(isNotLoading());
    }
  };

  const handleUpdateJobApplications = async (seekerId, status) => {
    const jobApplicationsData = {
      seeker_ids: [seekerId],
      to_status: status,
    };

    try {
      dispatch(isLoading());
      await updateJobApplications(jobId, jobApplicationsData);
      history.push({
        pathname: `/${locale}/job/${jobId}/applicants`,
        search: `?filter=${status}`,
      });
      snackbarContext.dispatch(isShow('success', 'Successfully change applicant status.'));
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }

      if (error.response.data.message === 'to_status not valid') {
        snackbarContext
          .dispatch(isShow('error', 'Application status cannot be moved to applicant.'));
      }
    } finally {
      dispatch(isNotLoading());
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(isLoading());

        const promises = [
          fetchRolePreferences(),
          fetchEducationDegree(),
          fetchSkillsOptions(),
          fetchProvinces(),
          jobDetails(jobId),
          applicationSummary(jobId),
          applications(jobId, params),
        ];
        const [
          rolePreferenceList,
          educationLevelList,
          skillsOptions,
          provinceList,
          jobDetailsRes,
          applicationSummaryRes,
          applicationsRes,
        ] = await Promise.all(promises);

        setSelectOptionsData((prevState) => ({
          ...prevState,
          rolePreferenceList,
          educationLevelList,
          skillsOptions,
          provinceList,
        }));
        setJobDetailsData({
          name: jobDetailsRes.data.data?.name,
          status: jobDetailsRes.data.data.status,
        });
        setApplicantSummaryData(applicationSummaryRes.data.data);
        setApplicantTotalCount(applicationsRes.data.data.total_data);
        setApplicantListData(applicationsRes.data.data.seekers);
        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: applicationsRes.data.data.total_data,
        }));
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response?.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    if (isFirstRender || isRefetch) fetchData();
    setRefetchStatus(false);
  }, [isRefetch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(isLoading());

        const applicationsRes = await applications(jobId, params);

        setApplicantTotalCount(applicationsRes.data.data.total_data);
        setApplicantListData(applicationsRes.data.data.seekers);
        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: applicationsRes.data.data.total_data,
        }));
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    if (!isFirstRender) {
      fetchData();
    }
  }, [
    query.get('filter'),
    debouncedSearchTerm,
    debouncedFilterData,
    sort,
    pageInfo.currentPage,
  ]);

  return (
    <Layout isPaddingActive={false} backgroundColor="LIGHT_GREY">
      <Applicants
        setRefetchStatus={setRefetchStatus}
        jobDetailsData={jobDetailsData}
        applicantSummaryData={applicantSummaryData}
        applicantTotalCount={applicantTotalCount}
        applicantListData={applicantListData}
        selectedApplicantIds={selectedApplicantIds}
        setSelectedApplicantIds={setSelectedApplicantIds}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filterData={filterData}
        setFilterData={setFilterData}
        sort={sort}
        setSort={setSort}
        selectOptionsData={selectOptionsData}
        handleBulkUpdateJobApplications={handleBulkUpdateJobApplications}
        handleUpdateJobApplications={handleUpdateJobApplications}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        changeStatusConfirmationModalData={changeStatusConfirmationModalData}
        setChangeStatusConfirmationModalData={setChangeStatusConfirmationModalData}
        changeStatusSuccessModalData={changeStatusSuccessModalData}
        setChangeStatusSuccessModalData={setChangeStatusSuccessModalData}
      />
    </Layout>
  );
};

export default ApplicantsPage;
