import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { v4 as uuidv4 } from 'uuid';

import Layout from '../../../components/layout/Layout';
import { CompanyProfileSettings } from '../../../components/pages';
import { htmlToDraftJs } from '../../../helpers/parser';
import { selfCompany } from '../../../api/v1/get/company';
import { updateCompany } from '../../../api/v1/put/companies';
import { LocaleContext } from '../../../contexts/localeContext';
import { LoadingContext } from '../../../contexts/loadingContext';
import { isLoading, isNotLoading } from '../../../reducers/loadingReducers';
import { uploadFileToS3 } from '../../../helpers/aws';
import {
  countries, allProvinces, cities, companyIndustries,
} from '../../../api/v1/get';
import { isShow } from '../../../reducers/snackbarReducers';
import { SnackbarContext } from '../../../contexts/snackbarContext';

const CompanyProfileSettingsPage = () => {
  const history = useHistory();
  const snackbarContext = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(LoadingContext);
  const [isSubmitting, setSubmitStatus] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: EditorState.createEmpty(),
    industry: '',
    company_size: '',
    country: '',
    region: '',
    city: '',
    address: '',
    website: '',
    companyLogo: null,
    companyBanner: null,
  });
  const [optionsData, setOptionsData] = useState({
    countryOptions: [],
    regionOptions: [],
    cityOptions: [],
    industryOptions: [],
  });

  const handleUpdate = async () => {
    dispatch(isLoading());
    setSubmitStatus(true);

    try {
      let companyLogoObjectURL = '';
      let companyBannerObjectURL = '';

      if (formData.companyLogo) {
        companyLogoObjectURL = await uploadFileToS3(
          new File([formData.companyLogo], `${uuidv4()}_h8_${formData.companyLogo.name}`),
          'company-logo',
        );
      }

      if (formData.companyBanner) {
        companyBannerObjectURL = await uploadFileToS3(
          new File([formData.companyBanner], `${uuidv4()}_h8_${formData.companyBanner.name}`),
          'company-banner',
        );
      }

      const parsedFormData = {
        name: formData.name,
        description: convertToHTML(formData.description.getCurrentContent()),
        industry: formData.industry,
        company_size: formData.company_size,
        city_id: parseInt(formData.city, 10),
        address: formData.address,
        website: formData.website,
        logo_url: companyLogoObjectURL || formData.logo_url,
        banner_url: companyBannerObjectURL || formData.banner_url,
      };

      await updateCompany(parsedFormData);
      snackbarContext.dispatch(isShow('success', 'successfully update company profile.'));
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to update company profile.'));

      if (error.response.status === 401 || error.response.status === 403) {
        history.replace(`/${locale}/login`);
      }

      if (error.response.status >= 500) {
        history.replace(`/${locale}/error-500`);
      }
    } finally {
      dispatch(isNotLoading());
      setSubmitStatus(false);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      const countriesRes = await countries();
      const parsedCountriesRes = countriesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        countryOptions: parsedCountriesRes,
      }));
    };

    const fetchProvinces = async () => {
      const provincesRes = await allProvinces({ country_id: formData.country });
      const parsedProvincesRes = provincesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        regionOptions: parsedProvincesRes,
      }));
    };

    const fetchCities = async () => {
      const citiesRes = await cities({
        country_id: formData.country,
        province_id: formData.region,
      });
      const parsedCitiesRes = citiesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        cityOptions: parsedCitiesRes,
      }));
    };

    fetchCountries();
    if (formData.country) fetchProvinces();
    if (formData.region) fetchCities();
  }, [formData.country, formData.region]);

  useEffect(() => {
    const fetchCompanyIndustries = async () => {
      const industryRes = await companyIndustries();
      const parsedIndustriesRes = industryRes.data.data.map(({ id, label }) => ({
        label,
        value: id,
      }));
      setOptionsData((prevState) => ({
        ...prevState,
        industryOptions: parsedIndustriesRes,
      }));
    };

    const fetchCompanyData = async () => {
      try {
        dispatch(isLoading());

        const companyDataRes = await selfCompany();
        const parsedCompany = {
          name: companyDataRes.data.data.name,
          description: htmlToDraftJs(
            companyDataRes.data.data.description
              ? companyDataRes.data.data.description
              : '<p></p>',
          ),
          industry: companyDataRes.data.data.industry?.id,
          company_size: companyDataRes.data.data.company_size.id,
          country: companyDataRes.data.data.country?.id,
          region: companyDataRes.data.data.province?.id,
          city: companyDataRes.data.data.city?.id,
          address: companyDataRes.data.data.address,
          website: companyDataRes.data.data.website,
          logo_url: companyDataRes.data.data.logo_url,
          banner_url: companyDataRes.data.data.banner_url,
        };

        setFormData(parsedCompany);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };
    fetchCompanyData();
    fetchCompanyIndustries();
  }, []);

  return (
    <Layout>
      <CompanyProfileSettings
        formData={formData}
        setFormData={setFormData}
        handleUpdate={handleUpdate}
        optionsData={optionsData}
        isSubmitting={isSubmitting}
      />
    </Layout>
  );
};

export default CompanyProfileSettingsPage;
