/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import emptyStateBackgroundImage from '../../../../assets/images/pages/job/job-list-empty-state.png';
import {
  Container,
  Heading,
  Select,
  Input,
  Pagination,
} from '../../../general';
import JobCard from './JobCard/JobCard';
import {
  containerStyles,
  contentStyles,
  searchAndTitleContainerStyles,
  titleStyles,
  selectAndInputContainerStyles,
  selectSortContainerStyles,
  inputSearchContainerStyles,
  jobCardContainerStyles,
  paginationStyles,
} from './List.styles';

const List = ({
  jobData,
  pageInfo,
  sortValue,
  setSortValue,
  searchTerm,
  setSearchTerm,
  setChangeStatusConfirmationModalData,
  setPageInfo,
}) => {
  const [isJobDataAvailable, setJobDataStatus] = useState(false);

  const sortOptions = [
    {
      label: 'latest jobs',
      value: 'DESC',
    },
    {
      label: 'oldest jobs',
      value: 'ASC',
    },
  ];

  const handlePageChange = (page) => {
    setPageInfo((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (jobData.length !== 0) {
      setJobDataStatus(true);
    } else {
      setJobDataStatus(false);
    }
  }, [jobData]);

  return (
    <Container
      contentWidthType="FULL"
      containerStyles={containerStyles}
      contentStyles={contentStyles(
        !isJobDataAvailable,
        emptyStateBackgroundImage,
      )}
    >
      <div css={searchAndTitleContainerStyles}>
        <Heading type="h2" styles={titleStyles}>
          all jobs posted
        </Heading>
        <div css={selectAndInputContainerStyles}>
          <Select
            containerStyles={selectSortContainerStyles}
            name="sortBy"
            options={sortOptions}
            value={sortValue}
            onChange={handleSortChange}
            placeholder="sort by"
          />
          <Input
            type="text"
            containerStyles={inputSearchContainerStyles}
            icon="search-line"
            name="searchTerm"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="search job"
          />
        </div>
      </div>
      <div css={jobCardContainerStyles}>
        {jobData.map((d) => (
          <JobCard
            key={d.id}
            data={d}
            setChangeStatusConfirmationModalData={
              setChangeStatusConfirmationModalData
            }
          />
        ))}
      </div>
      <Pagination
        containerStyles={paginationStyles}
        pageSize={pageInfo.pageSize}
        totalCount={pageInfo.totalCount}
        currentPage={pageInfo.currentPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </Container>
  );
};

List.propTypes = {
  jobData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      banner_url: PropTypes.string,
      name: PropTypes.string,
      seekers_applied: PropTypes.number,
      status: PropTypes.oneOf(['open', 'closed', 'draft']),
    }),
  ).isRequired,
  sortValue: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  setSortValue: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
  pageInfo: PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
};

export default List;
