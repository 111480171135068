import { css } from '@emotion/react';

export const containerStyles = css`
  margin: 36px 0 0 0;
  padding: 0 0 90px 0;
`;

export const contentStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const sortAndApplicantListContainerStyles = css`
  width: 992px;
  margin: 0 0 0 53px;
`;
