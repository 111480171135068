/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import formValidator from '../formValidator';
import {
  Heading,
  Input,
  Checkbox,
  Button,
} from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow, isNotShow } from '../../../../reducers/snackbarReducers';
import { userProfile } from '../../../../locale';
import {
  headingStyles,
  formStyles,
  inputContainerStyles,
  notificationContainerStyles,
  submitButtonContainerStyles,
} from './RightCardContent.styles';

const RightCardContent = ({ formData, handleChange, handleSubmit }) => {
  const { dispatch } = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);

  const handleSubmitUserProfile = () => {
    const isEligibleToSubmit = formValidator(formData, dispatch, isShow);

    if (isEligibleToSubmit) {
      handleSubmit();
      dispatch(isNotShow());
    }
  };

  return (
    <div>
      <Heading type="h3" styles={headingStyles}>
        {userProfile.title[locale]}
      </Heading>
      <form css={formStyles}>
        <Input
          type="text"
          containerStyles={inputContainerStyles}
          label={userProfile.fullName[locale]}
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          placeholder={userProfile.fullName[locale]}
        />
        <Input
          type="text"
          containerStyles={inputContainerStyles}
          label={userProfile.phoneNumber[locale]}
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder={userProfile.phoneNumber[locale]}
        />
        <Checkbox
          containerStyles={notificationContainerStyles}
          name="isNotificationEnabled"
          label={userProfile.notificationLabel[locale]}
          checkboxLabel={userProfile.notificationCheckboxLabel[locale]}
          checked={formData.isNotificationEnabled}
          onChange={handleChange}
        />
        <div css={submitButtonContainerStyles}>
          <Button
            type="button"
            size="M"
            color="LIGHT_BLUE"
            onClick={handleSubmitUserProfile}
          >
            {userProfile.submitButton[locale]}
          </Button>
        </div>
      </form>
    </div>
  );
};

RightCardContent.propTypes = {
  formData: PropTypes.shape({
    fullName: PropTypes.string,
    phoneNumber: PropTypes.string,
    isNotificationEnabled: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RightCardContent;
