/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react';

import { linkStyles } from './Link.styles';

const Link = ({
  to,
  styles,
  activeClassName,
  children,
}) => (
  <NavLink
    to={to}
    activeClassName={activeClassName}
    css={[linkStyles, styles]}
  >
    {children}
  </NavLink>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  activeClassName: null,
  styles: css``,
};

export default Link;
