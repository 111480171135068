import { css } from '@emotion/react';

import { colors } from '../../../../../configs';

export const cardStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 150px;
  margin: 24px 0 0 0;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.WHITE};

  &:first-of-type {
    margin: 0;
  }
`;

export const leftCardContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const imageContainerStyles = css`
  width: 120px;
  height: 110px;
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
`;

export const detailsContainerStyles = css`
  margin: 0 0 0 20px;
`;

export const jobTitleStyles = css`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
`;

export const jobPostedStyles = css`
  color: ${colors.DARK_GREY};
  height: 24px;
`;

export const iconAndApplicantContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 14px 0 0 0;
`;

export const iconStyles = css`
  font-size: 14px;
  color: ${colors.DARK_GREY};
`;

export const applicantStyles = css`
  color: ${colors.DARK_GREY};
`;

export const statusAndChangeStatusContainerStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 0 0;
`;

export const changeStatusButtonStyles = css`
  margin: 0 0 0 12px;
  padding: 0;
  background-color: ${colors.TRANSPARENT};
  border: none;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.PRIMARY_BLUE};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const detailsButtonStyles = css`
  margin: 0 0 0 20px;
`;
