/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Heading,
  Link,
  ShortText,
  Button,
} from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { createJob } from '../../../../api/v1/post';
import {
  navbarStyles,
  navbarContentStyles,
  titleStyles,
  breadcrumbsContainerStyles,
  linkStyles,
  nonLinkBreadcrumb,
  publishButtonStyles,
} from './Navbar.styles';

const Navbar = ({ data }) => {
  const history = useHistory();
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);

  const handleRedirectToDuplicatePage = () => {
    history.push(`/${locale}/job/${jobId}/duplicate`);
    localStorage.setItem('pageSource', 'duplicate-preview-page');
  };

  const handleParseData = (status) => {
    const seekerAvailabilities = [];

    if (data.isWorkFromHome) {
      seekerAvailabilities.push('remote');
    }

    if (data.isWillingToRelocate) {
      seekerAvailabilities.push('willing-to-relocate');
    }

    const parsedData = {
      status,
      name: data.jobTitle || null,
      job_function: data.jobFunction || null,
      job_level: data.jobLevel || null,
      employment_type: data.employmentType || null,
      slot: parseInt(data.numberOfPeople, 10) || null,
      due_date: data.applicationDeadline
        ? moment(data.applicationDeadline).format()
        : null,
      minimum_education: data.minimumEducation,
      skills: data.requiredSkills,
      description: data.jobDescription,
      qualification: data.qualificationAndRequirements,
      city_id: parseInt(data.city, 10),
      salary_currency: data.currency || null,
      salary_range_from: parseInt(data.salaryRangeFrom, 10) || null,
      salary_range_until: parseInt(data.salaryRangeUntil, 10) || null,
      benefit_ids: data.perksAndBenefits.map(({ id }) => id),
      seeker_availabilities: seekerAvailabilities,
    };

    return parsedData;
  };

  const handleSubmit = async () => {
    await createJob(handleParseData('open'));
    localStorage.removeItem('duplicateJobFormData');
    localStorage.removeItem('duplicateJobOptionsData');
    localStorage.removeItem('duplicateJobLocationData');
    history.push(`/${locale}/job?filter=all&activeModal=create`);
  };

  return (
    <nav css={navbarStyles}>
      <div css={navbarContentStyles}>
        <div>
          <Heading type="h3" styles={titleStyles}>
            duplicate job preview
          </Heading>
          <div css={breadcrumbsContainerStyles}>
            <Link to={`/${locale}/job`} styles={linkStyles}>
              jobs
            </Link>
            <ShortText styles={nonLinkBreadcrumb}>&nbsp;/&nbsp;</ShortText>
            <Link to={`/${locale}/job/${jobId}/duplicate`} styles={linkStyles}>
              duplicate job
            </Link>
            <ShortText styles={nonLinkBreadcrumb}>&nbsp;/ preview</ShortText>
          </div>
        </div>
        <div>
          <Button type="button" size="M" onClick={handleRedirectToDuplicatePage} inverted>
            edit data
          </Button>
          <Button
            type="button"
            styles={publishButtonStyles}
            size="XL"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
          >
            confirm & publish now!
          </Button>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  data: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    numberOfPeople: PropTypes.string,
    applicationDeadline: PropTypes.string,
    minimumEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
    jobDescription: PropTypes.string,
    qualificationAndRequirements: PropTypes.string,
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
    currency: PropTypes.string,
    salaryRangeFrom: PropTypes.string,
    salaryRangeUntil: PropTypes.string,
    perksAndBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default Navbar;
