import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';

import Layout from '../../../components/layout/Layout';
import { CreateJob } from '../../../components/pages';
import { LocaleContext } from '../../../contexts/localeContext';
import { useIsFirstRender } from '../../../hooks';
import {
  jobFunctions,
  jobLevels,
  employmentTypes,
  educationDegree,
  skills,
  companyBenefits,
  allProvinces,
  cities,
  countries,
} from '../../../api/v1/get';
import { createJob } from '../../../api/v1/post/job';
import { SnackbarContext } from '../../../contexts/snackbarContext';
import { isShow } from '../../../reducers/snackbarReducers';
import { numeralTextConverter } from '../../../helpers/parser';

const CreateJobPage = () => {
  const history = useHistory();
  const snackbarContext = useContext(SnackbarContext);
  const { locale } = useContext(LocaleContext);
  const isFirstRender = useIsFirstRender();
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setSubmitStatus] = useState(false);
  const [formData, setFormData] = useState({
    jobTitle: '',
    jobFunction: '',
    jobLevel: '',
    employmentType: '',
    numberOfPeople: '',
    applicationDeadline: '',
    minimumEducation: '',
    requiredSkills: [],
    jobDescription: EditorState.createEmpty(),
    qualificationAndRequirements: EditorState.createEmpty(),
    country: '',
    region: '',
    city: '',
    isWorkFromHome: false,
    isWillingToRelocate: false,
    currency: 'IDR',
    salaryRangeFrom: '',
    salaryRangeUntil: '',
    perksAndBenefits: [],
  });
  const [optionsData, setOptionsData] = useState({
    jobFunctionsOptions: [],
    jobLevelsOptions: [],
    employmentTypesOptions: [],
    minimumEducationOptions: [],
    requiredSkillsOptions: [],
    countryOptions: [],
    regionOptions: [],
    cityOptions: [],
    companyBenefitsOptions: [],
  });

  const getFormDataFromLocalStorage = () => {
    if (localStorage.getItem('createJobFormData')) {
      const createJobFormData = JSON.parse(
        localStorage.getItem('createJobFormData'),
      );

      setFormData({
        ...createJobFormData,
        jobDescription: EditorState.createWithContent(
          convertFromHTML(createJobFormData.jobDescription),
        ),
        qualificationAndRequirements: EditorState.createWithContent(
          convertFromHTML(createJobFormData.qualificationAndRequirements),
        ),
      });

      return true;
    }

    return false;
  };

  const handleDataToLocalStorage = () => {
    const parsedFormData = {
      ...formData,
      jobDescription: convertToHTML(
        formData.jobDescription.getCurrentContent(),
      ),
      qualificationAndRequirements: convertToHTML(
        formData.qualificationAndRequirements.getCurrentContent(),
      ),
    };
    const parsedLocationData = {
      city: optionsData.cityOptions[formData.city],
      region: optionsData.regionOptions[formData.region],
      country: optionsData.countryOptions[formData.country],
    };

    localStorage.setItem('createJobFormData', JSON.stringify(parsedFormData));
    localStorage.setItem('createJobOptionsData', JSON.stringify(optionsData));
    localStorage.setItem('createJobLocationData', JSON.stringify(parsedLocationData));
  };

  const handleParseFormData = (status) => {
    const seekerAvailabilities = [];

    if (formData.isWorkFromHome) {
      seekerAvailabilities.push('remote');
    }

    if (formData.isWillingToRelocate) {
      seekerAvailabilities.push('willing-to-relocate');
    }

    const parsedFormData = {
      status,
      name: formData.jobTitle || null,
      job_function:
        optionsData.jobFunctionsOptions[formData.jobFunction]?.value || null,
      job_level: optionsData.jobLevelsOptions[formData.jobLevel]?.value || null,
      employment_type:
        optionsData.employmentTypesOptions[formData.employmentType]?.value || null,
      slot: parseInt(formData.numberOfPeople, 10) || null,
      due_date: formData.applicationDeadline
        ? moment(formData.applicationDeadline).format()
        : null,
      minimum_education:
        optionsData.minimumEducationOptions[formData.minimumEducation]?.value || null,
      skills: formData.requiredSkills,
      description: convertToHTML(formData.jobDescription.getCurrentContent()),
      qualification: convertToHTML(
        formData.qualificationAndRequirements.getCurrentContent(),
      ),
      city_id: optionsData.cityOptions[formData.city]?.value,
      salary_currency: formData.currency || null,
      salary_range_from: numeralTextConverter(formData.salaryRangeFrom) || null,
      salary_range_until: numeralTextConverter(formData.salaryRangeUntil) || null,
      benefit_ids: formData.perksAndBenefits.map(({ id }) => id),
      seeker_availabilities: seekerAvailabilities,
    };

    return parsedFormData;
  };

  const handleSaveAsDraft = async () => {
    setSubmitStatus(true);

    try {
      await createJob(handleParseFormData('draft'));
      localStorage.removeItem('createJobFormData');
      localStorage.removeItem('createJobOptionsData');
      localStorage.removeItem('createJobLocationData');
      history.push(`/${locale}/job?filter=all&activeModal=draft`);
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to save as draft.'));
    } finally {
      setSubmitStatus(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitStatus(true);
    try {
      await createJob(handleParseFormData('open'));
      localStorage.removeItem('createJobFormData');
      localStorage.removeItem('createJobOptionsData');
      localStorage.removeItem('createJobLocationData');
      history.push(`/${locale}/job?filter=all&activeModal=create`);
    } catch (error) {
      snackbarContext.dispatch(isShow('error', 'failed to create new job.'));
    } finally {
      setSubmitStatus(false);
    }
  };

  useEffect(() => {
    const locationData = JSON.parse(
      localStorage.getItem('createJobLocationData'),
    );
    let countryId = 1;
    let provinceId = 0;

    if (isFirstRender && locationData) {
      countryId = locationData.country?.value || 1;
      provinceId = locationData.region?.value || '';
    }

    if (!isFirstRender && (formData.country || formData.region)) {
      countryId = optionsData.countryOptions[formData.country]?.value;
      provinceId = optionsData.regionOptions[formData.region]?.value;
    }

    const fetchCountries = async () => {
      const countriesRes = await countries();
      const parsedCountriesRes = countriesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        countryOptions: parsedCountriesRes,
      }));
    };

    const fetchProvinces = async () => {
      const provincesRes = await allProvinces({ country_id: countryId });
      const parsedProvincesRes = provincesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        regionOptions: parsedProvincesRes,
      }));
    };

    const fetchCities = async () => {
      const citiesRes = await cities({
        country_id: countryId,
        province_id: provinceId,
      });
      const parsedCitiesRes = citiesRes.data.data.map((d) => ({
        label: d.label,
        value: d.id,
      }));

      setOptionsData((prevState) => ({
        ...prevState,
        cityOptions: parsedCitiesRes,
      }));
    };

    fetchCountries();

    if (countryId) fetchProvinces();
    if (provinceId) fetchCities();
  }, [formData.country, formData.region]);

  useEffect(() => {
    if (localStorage.getItem('pageSource') === 'create-preview-page') {
      getFormDataFromLocalStorage();
      localStorage.removeItem('pageSource');
    }

    const fetchOptions = async () => {
      const fetchJobFunctionsOptions = async () => {
        const jobFunctionsRes = await jobFunctions();
        const options = jobFunctionsRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchJobLevelsOptions = async () => {
        const jobLevelsRes = await jobLevels();
        const options = jobLevelsRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchEmploymentTypesOptions = async () => {
        const employmentTypesRes = await employmentTypes();
        const options = employmentTypesRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchEducationDegreeOptions = async () => {
        const educationDegreeRes = await educationDegree();
        const options = educationDegreeRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchSkillsOptions = async () => {
        const skillsRes = await skills();
        const options = skillsRes.data.data.map(({ id, label }) => ({
          label,
          value: id,
        }));

        return options;
      };

      const fetchCompanyBenefits = async () => {
        const companyBenefitsRes = await companyBenefits();
        const parsedCompanyBenefitsRes = companyBenefitsRes.data.data.map(
          ({ id, label }) => ({
            id,
            name: label,
          }),
        );

        return parsedCompanyBenefitsRes;
      };

      const optionsPromises = [
        fetchJobFunctionsOptions(),
        fetchJobLevelsOptions(),
        fetchEmploymentTypesOptions(),
        fetchEducationDegreeOptions(),
        fetchSkillsOptions(),
        fetchCompanyBenefits(),
      ];
      const [
        jobFunctionsOptions,
        jobLevelsOptions,
        employmentTypesOptions,
        minimumEducationOptions,
        requiredSkillsOptions,
        companyBenefitsOptions,
      ] = await Promise.all(optionsPromises);

      setOptionsData((prevState) => ({
        ...prevState,
        jobFunctionsOptions,
        jobLevelsOptions,
        employmentTypesOptions,
        minimumEducationOptions,
        requiredSkillsOptions,
        companyBenefitsOptions,
      }));
    };

    fetchOptions();
  }, []);

  return (
    <Layout isPaddingActive={false}>
      <CreateJob
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formData={formData}
        setFormData={setFormData}
        optionsData={optionsData}
        setOptionsData={setOptionsData}
        handleDataToLocalStorage={handleDataToLocalStorage}
        handleSaveAsDraft={handleSaveAsDraft}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </Layout>
  );
};

export default CreateJobPage;
