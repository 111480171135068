/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as css from './JobDetailsForm.styles';
import formValidator from '../formValidator';
import AddNewPerksAndBenefitModal from './AddNewPerksAndBenefitModal/AddNewPerksAndBenefitModal';
import {
  Container,
  Button,
  Input,
  Select as CustomSelect,
  ShortText,
  Text,
  TextEditor,
  Checkbox,
} from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { SnackbarContext } from '../../../../contexts/snackbarContext';
import { isShow, isNotShow } from '../../../../reducers/snackbarReducers';
import { currencies } from '../../../../data';
import { millionsSeparator } from '../../../../helpers/parser';
import { companyBenefit } from '../../../../api/v1/post/job';

const JobDetailsForm = ({
  setCurrentStep,
  formData,
  setFormData,
  optionsData,
  setOptionsData,
  handleDataToLocalStorage,
  handleSaveAsDraft,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const { dispatch } = useContext(SnackbarContext);
  const [isModalShown, setModalStatus] = useState(false);

  const requiredSkillsValueParser = () => {
    const parsedValue = formData.requiredSkills.map((d) => ({
      label: d,
      value: d,
    }));

    return parsedValue;
  };

  const handleChange = (e) => {
    if (
      e.target.name === 'isWorkFromHome'
      || e.target.name === 'isWillingToRelocate'
    ) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleRequiredSkillsChange = (d) => {
    setFormData((prevState) => {
      if (d) {
        const parsedArr = d.map(({ value }) => value);

        return {
          ...prevState,
          requiredSkills: parsedArr,
        };
      }

      return {
        ...prevState,
        requiredSkills: [],
      };
    });
  };

  const handleJobDescriptionChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDescription: d,
    }));
  };

  const handleQualificationAndRequirementsChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      qualificationAndRequirements: d,
    }));
  };

  const currencyOptions = currencies.map((currency) => ({
    label: currency,
    value: currency,
  }));

  const handlePerksAndBenefitCheckboxChange = (e) => {
    const arr = optionsData.companyBenefitsOptions;
    const index = optionsData.companyBenefitsOptions.findIndex(
      ({ name }) => name === e.target.name,
    );
    const isValueExist = formData.perksAndBenefits.find(
      (d) => d.name === e.target.name,
    );

    if (isValueExist) {
      setFormData((prevState) => {
        const filteredArr = formData.perksAndBenefits.filter(
          (d) => d.name !== e.target.name,
        );

        return {
          ...prevState,
          perksAndBenefits: filteredArr,
        };
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        perksAndBenefits: [...prevState.perksAndBenefits, arr[index]],
      }));
    }
  };

  const handlePerksAndBenefitCheckboxValue = (id) => {
    const selectedCheckboxData = formData.perksAndBenefits.find(
      (d) => d.id === id,
    );

    if (selectedCheckboxData) return true;

    return false;
  };

  const handleOpenAddNewPerksAndBenefitModal = () => {
    setModalStatus(true);
  };

  const addNewPerksAndBenefitCheckbox = async (newPerksAndBenefit) => {
    const arr = optionsData.companyBenefitsOptions;
    const companyBenefitRes = await companyBenefit({
      name: newPerksAndBenefit,
    });

    arr.push({
      id: companyBenefitRes.data.data.benefit_id,
      name: newPerksAndBenefit,
    });
    setOptionsData((prevState) => ({
      ...prevState,
      companyBenefitsOptions: arr,
    }));
  };

  const handleRedirectToJobPage = () => {
    localStorage.removeItem('editJobFormData');
    localStorage.removeItem('editJobOptionsData');
    localStorage.removeItem('editJobLocationData');
    history.push(`/${locale}/job?filter=all`);
  };

  const handleNextStep = () => {
    const isFormEligibleToSubmit = formValidator(formData, dispatch, isShow);

    if (isFormEligibleToSubmit) {
      handleDataToLocalStorage();
      dispatch(isNotShow());
      setCurrentStep(2);
    }
  };

  return (
    <Container contentWidthType="NORMAL" contentStyles={css.contentStyles}>
      <div css={css.cardBodyStyles}>
        <form>
          <div css={css.formRowStyles}>
            <Input
              type="text"
              label="job title*"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              placeholder="job title"
            />
            <CustomSelect
              containerStyles={css.marginLeftStyles}
              label="job function*"
              name="jobFunction"
              options={optionsData.jobFunctionsOptions}
              value={formData.jobFunction}
              onChange={handleChange}
              placeholder="select job function"
            />
          </div>
          <div css={css.formRowStyles}>
            <CustomSelect
              label="job level*"
              name="jobLevel"
              options={optionsData.jobLevelsOptions}
              value={formData.jobLevel}
              onChange={handleChange}
              placeholder="select job level"
            />
            <CustomSelect
              containerStyles={css.marginLeftStyles}
              label="employment type*"
              name="employmentType"
              options={optionsData.employmentTypesOptions}
              value={formData.employmentType}
              onChange={handleChange}
              placeholder="select employment type"
            />
          </div>
          <div css={css.formRowStyles}>
            <Input
              type="number"
              label="number of people you hiring*"
              name="numberOfPeople"
              value={formData.numberOfPeople}
              onChange={handleChange}
              placeholder="number of people you hiring"
            />
            <Input
              type="date"
              containerStyles={css.marginLeftStyles}
              label="deadline of application*"
              name="applicationDeadline"
              value={formData.applicationDeadline}
              onChange={handleChange}
            />
            <CustomSelect
              containerStyles={css.marginLeftStyles}
              label="minimum education*"
              name="minimumEducation"
              options={optionsData.minimumEducationOptions}
              value={formData.minimumEducation}
              onChange={handleChange}
              placeholder="select education"
            />
          </div>
          <div css={css.formRowStyles}>
            <div css={css.reactSelectContainerStyles}>
              <ShortText styles={css.reactSelectLabelStyles}>
                required skills*
              </ShortText>
              <Text styles={css.reactSelectDescriptionStyles}>
                select up to 7 skills from different function that are relevant
                to your job
              </Text>
              <Select
                id="tech_stack"
                instanceId="tech_stack"
                css={css.reactSelectStyles}
                classNamePrefix="select"
                name="tech_stack"
                options={optionsData.requiredSkillsOptions}
                value={requiredSkillsValueParser()}
                onChange={handleRequiredSkillsChange}
                isSearchable
                placeholder="Technology Stack"
                isMulti
              />
            </div>
          </div>
          <div css={css.formRowStyles}>
            <TextEditor
              label="job description*"
              value={formData.jobDescription}
              onChange={handleJobDescriptionChange}
            />
          </div>
          <div css={css.formRowStyles}>
            <TextEditor
              label="qualification and requirements*"
              value={formData.qualificationAndRequirements}
              onChange={handleQualificationAndRequirementsChange}
            />
          </div>
          <div css={css.formRowStyles}>
            <CustomSelect
              label="country*"
              name="country"
              options={optionsData.countryOptions}
              value={formData.country}
              onChange={handleChange}
              placeholder="select country"
            />
            <CustomSelect
              containerStyles={css.marginLeftStyles}
              label="region*"
              name="region"
              options={optionsData.regionOptions}
              value={formData.region}
              onChange={handleChange}
              placeholder="select region"
            />
            <CustomSelect
              containerStyles={css.marginLeftStyles}
              label="city*"
              name="city"
              options={optionsData.cityOptions}
              value={formData.city}
              onChange={handleChange}
              placeholder="select city"
            />
          </div>
          <div css={css.availabilityStatusCheckboxContainerStyles}>
            <ShortText styles={css.availabilityStatusCheckboxLabelStyles}>
              availability
            </ShortText>
            <Checkbox
              containerStyles={css.availabilityStatusCheckboxStyles}
              checkboxLabel="this job can be done remotely (work from home)"
              name="isWorkFromHome"
              checked={formData.isWorkFromHome}
              onChange={handleChange}
            />
            <Checkbox
              containerStyles={css.availabilityStatusCheckboxStyles}
              checkboxLabel="accept candidates willing to relocate"
              name="isWillingToRelocate"
              checked={formData.isWillingToRelocate}
              onChange={handleChange}
            />
          </div>
          <div css={css.currencyAndSalaryContainerStyles}>
            <div css={css.salaryLabelContainerStyles}>
              <ShortText styles={css.salaryLabelStyles}>expected salaries</ShortText>
              <ShortText styles={css.salaryLabelDescriptionStyles}>
                &nbsp;(optional information)
              </ShortText>
            </div>
            <div css={css.currencyAndSalaryFieldContainerStyles}>
              <CustomSelect
                containerStyles={css.selectCurrencyStyles}
                name="currency"
                value={formData.currency}
                options={currencyOptions}
                onChange={handleChange}
                placeholder="Currency"
              />
              <Input
                containerStyles={css.currencyInputStyles}
                type="text"
                name="salaryRangeFrom"
                value={millionsSeparator(formData.salaryRangeFrom)}
                onChange={handleChange}
                placeholder="starting salary"
              />
              <Input
                containerStyles={css.currencyInputStyles}
                type="text"
                name="salaryRangeUntil"
                value={millionsSeparator(formData.salaryRangeUntil)}
                onChange={handleChange}
                placeholder="ending salary"
              />
            </div>
          </div>
          <div css={css.perksAndBenefitsContainerStyles}>
            <ShortText styles={css.perksAndBenefitsLabelStyles}>perks and benefit</ShortText>
            <div css={css.perksAndBenefitsCheckboxContainerStyles}>
              {optionsData.companyBenefitsOptions.map(({ id, name }) => (
                <Checkbox
                  key={id}
                  containerStyles={css.perksAndBenefitsCheckboxStyles}
                  checkboxLabel={name}
                  name={name}
                  onChange={handlePerksAndBenefitCheckboxChange}
                  checked={handlePerksAndBenefitCheckboxValue(id)}
                />
              ))}
              <button
                type="button"
                onClick={handleOpenAddNewPerksAndBenefitModal}
                css={css.addNewPerksAndBenefitsButtonStyles}
              >
                + Add new perks and benefit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div css={css.cardFooterStyles}>
        <Button
          type="button"
          size="S"
          color="LIGHT_BLUE"
          onClick={handleRedirectToJobPage}
          inverted
        >
          cancel
        </Button>
        <div>
          <Button type="button" size="M" onClick={handleSaveAsDraft} inverted>
            save as draft
          </Button>
          <Button
            type="button"
            styles={css.nextButtonStyles}
            size="M"
            color="LIGHT_BLUE"
            onClick={handleNextStep}
          >
            next
          </Button>
        </div>
      </div>
      <AddNewPerksAndBenefitModal
        isModalShown={isModalShown}
        setModalStatus={setModalStatus}
        addNewPerksAndBenefitCheckbox={addNewPerksAndBenefitCheckbox}
      />
    </Container>
  );
};

JobDetailsForm.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    numberOfPeople: PropTypes.string,
    applicationDeadline: PropTypes.string,
    minimumEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
    jobDescription: PropTypes.shape({}),
    qualificationAndRequirements: PropTypes.shape({}),
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
    currency: PropTypes.string,
    salaryRangeFrom: PropTypes.string,
    salaryRangeUntil: PropTypes.string,
    perksAndBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    countryLabel: PropTypes.string,
    regionLabel: PropTypes.string,
    cityLabel: PropTypes.string,
    formData: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  optionsData: PropTypes.shape({
    jobFunctionsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    jobLevelsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    employmentTypesOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    minimumEducationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    requiredSkillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    companyBenefitsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  setOptionsData: PropTypes.func.isRequired,
  handleDataToLocalStorage: PropTypes.func.isRequired,
  handleSaveAsDraft: PropTypes.func.isRequired,
};

export default JobDetailsForm;
