/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import modalImage from '../../../../assets/images/pages/job/success-draft.png';
import {
  Modal,
  Icon,
  Heading,
  Text,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { LocaleContext } from '../../../../contexts/localeContext';
import {
  cardStyles,
  closeButtonContainerStyles,
  closeButtonStyles,
  closeIconStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonStyles,
} from './ChangeStatusSuccessModal.styles';

const ChangeStatusSuccessModal = ({
  jobDetailsData,
  changeStatusSuccessModalData,
  setChangeStatusSuccessModalData,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);

  const handleCloseModal = () => {
    setChangeStatusSuccessModalData(false);
  };

  const handleContinueModal = () => {
    setChangeStatusSuccessModalData(false);
    history.push(`/${locale}/job`);
  };

  return (
    <Modal isModalShown={changeStatusSuccessModalData}>
      <PlainCard styles={cardStyles}>
        <div css={closeButtonContainerStyles}>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          job status has been changed
        </Heading>
        <Text styles={descriptionStyles}>
          {`Thank you, your existing ${jobDetailsData.name} Job has been changed. You can check the information details from jobs page`}
        </Text>
        <Button
          type="button"
          styles={buttonStyles}
          size="L"
          color="LIGHT_BLUE"
          onClick={handleContinueModal}
        >
          Go to Job Page
        </Button>
      </PlainCard>
    </Modal>
  );
};

ChangeStatusSuccessModal.propTypes = {
  jobDetailsData: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  changeStatusSuccessModalData: PropTypes.bool.isRequired,
  setChangeStatusSuccessModalData: PropTypes.func.isRequired,
};

export default ChangeStatusSuccessModal;
