/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import Navbar from './Navbar/Navbar';
import Details from './Details/Details';
import { containerStyles } from './EditJobPreview.styles';

const EditJobPreview = ({ data, optionsData, companyData }) => (
  <div css={containerStyles}>
    <Navbar data={data} />
    <Details data={data} optionsData={optionsData} companyData={companyData} />
  </div>
);

EditJobPreview.propTypes = {
  data: PropTypes.shape({
    jobTitle: PropTypes.string,
    jobFunction: PropTypes.string,
    jobLevel: PropTypes.string,
    employmentType: PropTypes.string,
    numberOfPeople: PropTypes.string,
    applicationDeadline: PropTypes.string,
    minimumEducation: PropTypes.string,
    requiredSkills: PropTypes.arrayOf(PropTypes.string),
    jobDescription: PropTypes.string,
    qualificationAndRequirements: PropTypes.string,
    country: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    isWorkFromHome: PropTypes.bool,
    isWillingToRelocate: PropTypes.bool,
    currency: PropTypes.string,
    salaries: PropTypes.string,
    perksAndBenefits: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        isChecked: PropTypes.bool,
      }),
    ),
  }).isRequired,
  optionsData: PropTypes.shape({
    jobFunctionsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    jobLevelsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    employmentTypesOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    minimumEducationOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    requiredSkillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    countryOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    regionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    cityOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    companyBenefitsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  companyData: PropTypes.shape({
    bannerUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    region: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
};

export default EditJobPreview;
