import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const labelAndValueStyles = css`
  display: flex;
  flex-direction: column;
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  color: ${colors.DARK_GREY};
`;

export const valueStyles = css`
  margin: 8px 0 0 0;
  font-size: 16px;
`;

export const htmlTagsStyles = css`
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};

  span,
  p,
  ul,
  ol {
    margin: 8px 0 0 0;
  }

  ul,
  ol {
    list-style-position: inside !important;
    list-style: auto;
  }
`;

export const linkStyles = css`
  display: inline-block;
  margin: 8px 0 0 0;
  font-size: 16px;
  color: ${colors.LIGHT_BLUE};
`;
