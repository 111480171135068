/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import modalImage from '../../../../assets/images/pages/job/success-create.png';
import {
  Modal,
  Icon,
  Heading,
  Text,
  Link,
  Button,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { LocaleContext } from '../../../../contexts/localeContext';
import { useQuery } from '../../../../hooks';
import {
  cardStyles,
  closeButtonContainerStyles,
  closeButtonStyles,
  closeIconStyles,
  imageStyles,
  titleStyles,
  descriptionStyles,
  buttonStyles,
} from './CreateSuccessModal.styles';

const CreateSuccessModal = ({
  isCreateSuccessModalShown,
  setCreateSuccessModalStatus,
}) => {
  const history = useHistory();
  const query = useQuery();
  const { locale } = useContext(LocaleContext);

  const handleCloseModal = () => {
    query.delete('activeModal');
    history.replace({
      search: query.toString(),
    });
    setCreateSuccessModalStatus(false);
  };

  return (
    <Modal isModalShown={isCreateSuccessModalShown}>
      <PlainCard styles={cardStyles}>
        <div css={closeButtonContainerStyles}>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <img src={modalImage} css={imageStyles} alt="success-modal" />
        <Heading type="h3" styles={titleStyles}>
          Job Information Published
        </Heading>
        <Text styles={descriptionStyles}>
          Thank you, job information has been published succesfully. You can
          check the list of published jobs through the jobs menu
        </Text>
        <Link to={`/${locale}/job?filter=open`}>
          <Button
            type="button"
            styles={buttonStyles}
            size="L"
            color="LIGHT_BLUE"
            onClick={handleCloseModal}
          >
            Go to Job Page
          </Button>
        </Link>
      </PlainCard>
    </Modal>
  );
};

CreateSuccessModal.propTypes = {
  isCreateSuccessModalShown: PropTypes.bool.isRequired,
  setCreateSuccessModalStatus: PropTypes.func.isRequired,
};

export default CreateSuccessModal;
