import moment from 'moment';

export const dateParser = (date) => {
  const parsedDate = moment(date).format('MMMM, Do YYYY');

  return parsedDate;
};

export const humanDateSlashesParser = (date) => {
  const parsedDate = moment(date).format('DD/MM/YYYY');

  return parsedDate;
};
/*
export {
  dateParser,
  humanDateSlashesParser,
};
*/
