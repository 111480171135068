/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import {
  Heading,
  LabelAndValue,
  Input,
  Button,
} from '../../../general';
import { LocaleContext } from '../../../../contexts/localeContext';
import { changeEmail } from '../../../../locale';
import { getIntrospection } from '../../../../helpers/localStorage';
import {
  containerStyles,
  headingStyles,
  formStyles,
  inputContainerStyles,
  submitButtonContainerStyles,
} from './RightCardContent.styles';

const RightCardContent = ({ formData, handleChange, handleSubmit }) => {
  const { locale } = useContext(LocaleContext);

  const handleSubmitButtonDisabledStatus = () => {
    if (!formData.newEmail) return true;

    return false;
  };

  return (
    <div css={containerStyles}>
      <Heading type="h3" styles={headingStyles}>
        {changeEmail.title[locale]}
      </Heading>
      <form css={formStyles}>
        <LabelAndValue
          label="current email address"
          value={getIntrospection().email}
        />
        <Input
          containerStyles={inputContainerStyles}
          label="new email address"
          type="email"
          name="newEmail"
          value={formData.newEmail}
          onChange={handleChange}
          placeholder="type your email address"
        />
        <div css={submitButtonContainerStyles}>
          <Button
            type="submit"
            size="L"
            color="LIGHT_BLUE"
            onClick={handleSubmit}
            disabled={handleSubmitButtonDisabledStatus()}
          >
            {changeEmail.submitButton[locale]}
          </Button>
        </div>
      </form>
    </div>
  );
};

RightCardContent.propTypes = {
  formData: PropTypes.shape({
    currentEmail: PropTypes.string,
    newEmail: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RightCardContent;
