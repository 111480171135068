import { css } from '@emotion/react';

import { colors } from '../../../configs';

export const customSelectContainerStyles = css`
  position: relative;
  width: 100%;
`;

export const labelStyles = css`
  width: 100%;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.BLACK};
`;

export const selectStyles = (label, downCaret) => css`
  position: relative;
  width: 100%;
  height: 40px;
  margin: ${label ? '8px' : 0} 0 0 0;
  padding: 0 12px;
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
  background-color: ${colors.BACKGROUND_WHITE};
  background-image: url(${downCaret});
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.PRIMARY_BLACK};
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;

  &:focus {
    border-color: ${colors.LIGHT_BLUE};
  }
`;
