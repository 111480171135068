import { css } from '@emotion/react';

import { contentWidth } from '../../../configs';

export const primaryContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const primaryContentStyles = (contentWidthType = 'FULL') => css`
  width: ${contentWidth[contentWidthType]}px;
`;
