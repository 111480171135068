import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

import { reducer } from '../reducers/localeReducer';
import { localeList } from '../configs';

export const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  let pathNameLocale = window.location.pathname.split('/')[1];

  if (!localeList.includes(pathNameLocale)) {
    pathNameLocale = 'en';
  }

  const [locale, dispatch] = useReducer(reducer, pathNameLocale);

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        dispatch,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
