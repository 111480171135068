/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  primaryContainerStyles,
  primaryContentStyles,
} from './Container.styles';

const Container = ({
  containerStyles,
  contentStyles,
  contentWidthType,
  children,
}) => (
  <div css={[primaryContainerStyles, containerStyles]}>
    <div css={[primaryContentStyles(contentWidthType), contentStyles]}>
      {children}
    </div>
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyles: PropTypes.shape({}),
  contentWidthType: PropTypes.oneOf(['FULL', 'NORMAL']).isRequired,
  contentStyles: PropTypes.shape({}),
};

Container.defaultProps = {
  containerStyles: css``,
  contentStyles: css``,
};

export default Container;
