import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { Job } from '../../components/pages';
import { LoadingContext } from '../../contexts/loadingContext';
import {
  isLoading,
  isNotLoading,
} from '../../reducers/loadingReducers';
import { useQuery, useDebounce, useIsFirstRender } from '../../hooks';
import { jobs } from '../../api/v1/get';
import { LocaleContext } from '../../contexts/localeContext';

const JobPage = () => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const isFirstRender = useIsFirstRender();
  const query = useQuery();
  const { dispatch } = useContext(LoadingContext);
  const [isRefetch, setRefetchStatus] = useState(false);
  const [sortValue, setSortValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  });
  const [jobData, setJobData] = useState([]);
  const [isCreateSuccessModalShown, setCreateSuccessModalStatus] = useState(false);
  const [isDraftSuccessModalShown, setDraftSuccessModalStatus] = useState(false);
  const [changeStatusConfirmationModalData, setChangeStatusConfirmationModalData] = useState({
    id: 0,
    type: '',
    name: '',
    status: false,
  });
  const [changeStatusSuccessModalData, setChangeStatusSuccessModalData] = useState({
    id: 0,
    name: '',
    status: false,
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        dispatch(isLoading());

        const res = await jobs({
          job_status:
            query.get('filter') === 'all' ? '' : query.get('filter'),
          q: debouncedSearchTerm?.trim() || '',
          limit: pageInfo.pageSize,
          page: pageInfo.currentPage,
          sort_key: sortValue ? 'created_at' : '',
          sort_value: sortValue,
        });

        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: res.data.data.total_data,
        }));
        setJobData(res.data.data.jobs);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    if (!isFirstRender && isRefetch) {
      fetchVacancies();
      setRefetchStatus(false);
    }
  }, [isRefetch]);

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        dispatch(isLoading());

        const res = await jobs({
          job_status:
            query.get('filter') === 'all' ? '' : query.get('filter'),
          q: debouncedSearchTerm?.trim() || '',
          limit: pageInfo.pageSize,
          page: pageInfo.currentPage,
          sort_key: 'created_at',
          sort_value: sortValue,
        });

        setPageInfo((prevState) => ({
          ...prevState,
          totalCount: res.data.data.total_data,
        }));
        setJobData(res.data.data.jobs);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          history.replace(`/${locale}/login`);
        }

        if (error.response.status >= 500) {
          history.replace(`/${locale}/error-500`);
        }
      } finally {
        dispatch(isNotLoading());
      }
    };

    fetchVacancies();
  }, [query.get('filter'), sortValue, debouncedSearchTerm, pageInfo.currentPage]);

  useEffect(() => {
    const activeModalName = query.get('activeModal');

    if (activeModalName === 'create') {
      setCreateSuccessModalStatus(true);
    }

    if (activeModalName === 'draft') {
      setDraftSuccessModalStatus(true);
    }
  }, [query.get('activeModal')]);

  return (
    <Layout isPaddingActive={false}>
      <Job
        jobData={jobData}
        sortValue={sortValue}
        setSortValue={setSortValue}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        isCreateSuccessModalShown={isCreateSuccessModalShown}
        setCreateSuccessModalStatus={setCreateSuccessModalStatus}
        isDraftSuccessModalShown={isDraftSuccessModalShown}
        setDraftSuccessModalStatus={setDraftSuccessModalStatus}
        changeStatusConfirmationModalData={changeStatusConfirmationModalData}
        setChangeStatusConfirmationModalData={setChangeStatusConfirmationModalData}
        changeStatusSuccessModalData={changeStatusSuccessModalData}
        setChangeStatusSuccessModalData={setChangeStatusSuccessModalData}
        setRefetchStatus={setRefetchStatus}
      />
    </Layout>
  );
};

export default JobPage;
