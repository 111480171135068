const roleEligibilityChecker = () => {
  const introspectionRes = localStorage.getItem('introspectionRes');
  const { role } = JSON.parse(introspectionRes);

  if (role !== 'admin') return false;

  return true;
};

export default roleEligibilityChecker;
