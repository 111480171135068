import moment from 'moment';

const dateDiff = (startDate, endDate) => {
  const s = moment(startDate);
  const e = moment(endDate);

  const durationInMonths = Math.floor(moment.duration(e.diff(s)).asMonths());
  let date = '';

  if (!endDate) {
    date = `${s.format('DD MMM YYYY')} - present`;
  } else if (durationInMonths < 0) {
    date = '-';
  } else if (durationInMonths >= 12 && durationInMonths % 12 !== 0) {
    date = `${Math.floor(durationInMonths / 12)} yrs ${
      durationInMonths % 12
    } mos`;
  } else if (durationInMonths >= 12 && durationInMonths % 12 === 0) {
    date = `${Math.floor(durationInMonths / 12)} yrs`;
  } else {
    date = `${durationInMonths % 12} mos`;
  }

  return date;
};

export default dateDiff;
