import axios from 'axios';

const allProvinces = (params) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/library/provinces/search/all`,
    {
      params,
    },
  ));

export default allProvinces;
