export default {
  title: {
    en: 'change password',
    id: 'ubah kata sandi',
  },
  currentPassword: {
    en: 'current password',
    id: 'kata sandi saat ini',
  },
  newPassword: {
    en: 'new password',
    id: 'kata sandi baru',
  },
  passwordStatus: {
    empty: {
      en: 'password status',
      id: 'status kata sandi',
    },
    tooShort: {
      en: 'too weak',
      id: 'terlalu lemah',
    },
    weak: {
      en: 'weak',
      id: 'lemah',
    },
    fair: {
      en: 'fair',
      id: 'cukup',
    },
    good: {
      en: 'good',
      id: 'baik',
    },
    strong: {
      en: 'strong',
      id: 'kuat',
    },
  },
  confirmationNewPassword: {
    en: 'confirm new password',
    id: 'konfirmasi kata sandi baru',
  },
  submitButton: {
    en: 'update password',
    id: 'perbarui kata sandi',
  },
  modal: {
    title: {
      en: 'change password',
      id: 'ubah kata sandi',
    },
    description: {
      en: 'your new password has been successfully set!',
      id: 'kata sandi baru anda telah diperbarui',
    },
    button: {
      en: 'done',
      id: 'selesai',
    },
  },
};
