export default {
  title: {
    en: 'change email',
    id: 'ubah email',
  },
  currentEmail: {
    en: 'current email address',
    id: 'alamat email saat ini',
  },
  newEmail: {
    en: 'new email address',
    id: 'alamat email baru baru',
  },
  submitButton: {
    en: 'update email',
    id: 'perbarui email',
  },
  modal: {
    title: {
      en: 'change email',
      id: 'ubah email',
    },
    description: {
      en: 'Your email change request has been sent. Please check your new email for more details.',
      id: 'Permintaan perubahan email anda telah dikirim. Mohon untuk mengecek email anda untuk detail yang lebih lanjut.',
    },
    button: {
      en: 'done',
      id: 'selesai',
    },
  },
};
