/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import ShortText from '../ShortText/ShortText';
import Text from '../Text/Text';
import {
  labelAndValueStyles,
  labelStyles,
  valueStyles,
  htmlTagsStyles,
  linkStyles,
} from './LabelAndValue.styles';

const LabelAndValue = ({
  styles,
  label,
  valueType,
  value,
}) => (
  <div css={[labelAndValueStyles, styles]}>
    <ShortText styles={labelStyles}>{label}</ShortText>
    {valueType === 'SHORT' && (
      <ShortText styles={valueStyles}>{value}</ShortText>
    )}
    {valueType === 'LONG' && <Text styles={valueStyles}>{value}</Text>}
    {valueType === 'HTML' && <div css={htmlTagsStyles}>{value}</div>}
    {valueType === 'URL' && (
      <a
        href={value}
        target="_blank"
        rel="noopener noreferrer"
        css={linkStyles}
      >
        {value}
      </a>
    )}
  </div>
);

LabelAndValue.propTypes = {
  styles: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  valueType: PropTypes.oneOf(['SHORT', 'LONG', 'HTML', 'URL']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
};

LabelAndValue.defaultProps = {
  styles: css``,
  valueType: 'SHORT',
};

export default LabelAndValue;
