/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useContext } from 'react';

import ForgotPasswordModal from './ForgotPasswordModal/ForgotPasswordModal';
import VerificationModal from './VerificationModal/VerificationModal';
import DeactivatedAccountModal from './DeactivatedAccountModal/DeactivatedAccountModal';
import {
  Heading,
  Text,
  InputWithIcon,
  InputPassword,
  Button,
  ShortText,
} from '../../general';
import { LocaleContext } from '../../../contexts/localeContext';
import { login as loginPageLocale } from '../../../locale';
import {
  containerStyles,
  backgroundImageStyles,
  formContainerStyles,
  titleStyles,
  descriptionStyles,
  formStyles,
  formTitleStyles,
  credentialInputContainerStyles,
  errorMessageAndForgotPasswordContainerStyles,
  errorMessageStyles,
  forgotPasswordLinkStyles,
  submitButtonStyles,
} from './Login.styles';
import backgroundImage from '../../../assets/images/pages/login/background.jpg';
import companyLogo from '../../../assets/svg/logo-dark.svg';

const Login = ({
  resetPasswordEmail,
  setResetPasswordEmail,
  forgotPasswordStatus,
  setForgotPasswordStatus,
  handleResetPasswordSubmit,
  formData,
  loginStatus,
  setLoginStatus,
  handleChange,
  handleSubmit,
}) => {
  const { locale } = useContext(LocaleContext);

  const handleOpenForgotPasswordModal = () => {
    setForgotPasswordStatus((prevState) => ({
      ...prevState,
      isModalShown: true,
    }));
  };

  return (
    <div css={containerStyles}>
      <div css={backgroundImageStyles(backgroundImage)} />
      <div css={formContainerStyles}>
        <img src={companyLogo} alt="hacktiv8-logo" />
        <Heading type="h1" styles={titleStyles}>
          login
        </Heading>
        <Text styles={descriptionStyles}>{loginPageLocale.title[locale]}</Text>
        <form css={formStyles} onSubmit={handleSubmit}>
          <Heading type="h3" styles={formTitleStyles}>
            {loginPageLocale.description[locale]}
          </Heading>
          <InputWithIcon
            type="email"
            containerStyles={credentialInputContainerStyles}
            iconName="mail-line"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="email"
            isError={loginStatus.isError.status}
          />
          <InputPassword
            containerStyles={credentialInputContainerStyles}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="password"
            isError={loginStatus.isError.status}
          />
          <div
            css={errorMessageAndForgotPasswordContainerStyles(
              loginStatus.isError.status,
            )}
          >
            {loginStatus.isError.status && (
              <ShortText styles={errorMessageStyles}>
                {loginStatus.isError.message}
              </ShortText>
            )}
            <button
              type="button"
              css={forgotPasswordLinkStyles}
              onClick={handleOpenForgotPasswordModal}
            >
              {loginPageLocale.forgotPassword[locale]}
            </button>
          </div>
          <Button
            type="submit"
            styles={submitButtonStyles}
            size="FULL"
            color="PRIMARY_BLUE"
          >
            {loginPageLocale.loginButton[locale]}
          </Button>
        </form>
      </div>
      <ForgotPasswordModal
        resetPasswordEmail={resetPasswordEmail}
        setResetPasswordEmail={setResetPasswordEmail}
        forgotPasswordStatus={forgotPasswordStatus}
        setForgotPasswordStatus={setForgotPasswordStatus}
        handleResetPasswordSubmit={handleResetPasswordSubmit}
      />
      <VerificationModal loginStatus={loginStatus} />
      <DeactivatedAccountModal
        loginStatus={loginStatus}
        setLoginStatus={setLoginStatus}
      />
    </div>
  );
};

Login.propTypes = {
  resetPasswordEmail: PropTypes.string.isRequired,
  setResetPasswordEmail: PropTypes.func.isRequired,
  forgotPasswordStatus: PropTypes.shape({
    isModalShown: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isSuccess: PropTypes.bool,
  }).isRequired,
  setForgotPasswordStatus: PropTypes.func.isRequired,
  handleResetPasswordSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  loginStatus: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isError: PropTypes.shape({
      status: PropTypes.bool,
      message: PropTypes.string,
    }),
    isSuccess: PropTypes.bool,
    isDeactivatedAccountModalShown: PropTypes.bool,
  }).isRequired,
  setLoginStatus: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Login;
