/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import ApplicantCard from './ApplicantCard/ApplicantCard';
import { Pagination } from '../../../general';
import {
  applicantContainerStyles,
  paginationContainerStyles,
  paginationStyles,
} from './ApplicantList.styles';

const ApplicantList = ({
  favoriteCandidatesData,
  setFavoriteCandidatesData,
  pageInfo,
  setPageInfo,
  setInviteUserModalStatus,
}) => {
  const handlePageChange = (page) => {
    setPageInfo((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  return (
    <Fragment>
      <div css={applicantContainerStyles}>
        {favoriteCandidatesData.map((d) => (
          <ApplicantCard
            key={d.id}
            data={d}
            setFavoriteCandidatesData={setFavoriteCandidatesData}
            setInviteUserModalStatus={setInviteUserModalStatus}
          />
        ))}
      </div>
      <div css={paginationContainerStyles}>
        <Pagination
          containerStyles={paginationStyles}
          pageSize={pageInfo.pageSize}
          totalCount={pageInfo.totalCount}
          currentPage={pageInfo.currentPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </Fragment>
  );
};

ApplicantList.propTypes = {
  favoriteCandidatesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      role_preference: PropTypes.shape({
        label: PropTypes.string,
      }),
    }),
  ).isRequired,
  setFavoriteCandidatesData: PropTypes.func.isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
  setInviteUserModalStatus: PropTypes.func.isRequired,
};

export default ApplicantList;
