import { convertToHTML } from 'draft-convert';

const formValidator = (formData, dispatch, isShow) => {
  if (!formData.jobTitle) {
    dispatch(isShow('error', 'job title field is required.'));

    return false;
  }

  if (!formData.jobFunction) {
    dispatch(isShow('error', 'job function field is required.'));

    return false;
  }

  if (!formData.jobLevel) {
    dispatch(isShow('error', 'job level field is required.'));

    return false;
  }

  if (!formData.employmentType) {
    dispatch(isShow('error', 'employment type field is required.'));

    return false;
  }

  if (!formData.numberOfPeople) {
    dispatch(isShow('error', 'number of people field is required.'));

    return false;
  }

  if (!formData.applicationDeadline) {
    dispatch(isShow('error', 'application deadline field is required.'));

    return false;
  }

  if (!formData.minimumEducation) {
    dispatch(isShow('error', 'minimum education field is required.'));

    return false;
  }
  if (convertToHTML(formData.jobDescription.getCurrentContent()) === '<p></p>') {
    dispatch(isShow('error', 'job description field is required.'));

    return false;
  }

  if (convertToHTML(formData.qualificationAndRequirements.getCurrentContent()) === '<p></p>') {
    dispatch(isShow('error', 'qualification and requirements field is required.'));

    return false;
  }

  if (!formData.country) {
    dispatch(isShow('error', 'country field is required.'));

    return false;
  }

  if (!formData.region) {
    dispatch(isShow('error', 'region field is required.'));

    return false;
  }

  if (!formData.city) {
    dispatch(isShow('error', 'city field is required.'));

    return false;
  }

  return true;
};

export default formValidator;
