/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Fragment,
  useContext,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import userDefaultImage from '../../../../../assets/images/pages/shared/user-default.png';
import recommendedImage from '../../../../../assets/images/pages/shared/recommended-by-h8.png';
import {
  Checkbox,
  Icon,
  ShortText,
  Button,
  Link,
  ExternalLink,
} from '../../../../general';
import { LocaleContext } from '../../../../../contexts/localeContext';
import { useDetectOutsideClick } from '../../../../../hooks';
import { badgeChecker, dateDiff, yearsToMonthsParser } from '../../../../../helpers/parser';
import {
  cardContainerStyles,
  cardStyles,
  checkboxStyles,
  imageStyles,
  rightCardStyles,
  nameLocationAndFinalScoreContainerStyles,
  nameAndLocationContainerStyles,
  nameStyles,
  iconAndLocationContainerStyles,
  iconStyles,
  locationStyles,
  locationAndJobExperienceSeparatorStyles,
  jobExperienceStyles,
  badgeAndFinalScoreContainerStyles,
  finalScoreContainerStyles,
  finalScoreStyles,
  badgeStyles,
  applicationStatusStyles,
  skillsContainerStyles,
  jobNameStyles,
  skillPillsContainerStyles,
  skillPillStyles,
  skillNameStyles,
  workExperienceAndButtonContainerStyles,
  educationlabelAndValueContainerStyles,
  workExperiencelabelAndValueContainerStyles,
  labelStyles,
  workExperienceContainerStyles,
  valueContainerStyles,
  workExperienceValueStyles,
  valueStyles,
  dotIconStyles,
  dateValueStyles,
  buttonContainerStyles,
  applicationButtonStyles,
  downArrowIconStyles,
  applicationDropdownContainerStyles,
  applicationDropdownStyles,
  buttonListStyles,
  dropdownDivider,
  rejectButtonStyles,
  mailButtonStyles,
  mailIconStyles,
  recommendedImageStyles,
} from './ApplicantCard.styles';

const ApplicantCard = ({
  data,
  setRefetchStatus,
  selectedApplicantIds,
  setSelectedApplicantIds,
  handleUpdateJobApplications,
}) => {
  const { jobId } = useParams();
  const { locale } = useContext(LocaleContext);
  const applicationDropdownRef = useRef();
  const [isApplicationDropdownShow, setApplicationDropdownStatus] = useState(false);

  const applicationOptions = [
    {
      label: 'applicant',
      value: 'applicant',
    },
    {
      label: 'shortlisted',
      value: 'shortlisted',
    },
    {
      label: 'HR Interview',
      value: 'hr-interview',
    },
    {
      label: 'technical stage',
      value: 'technical-stage',
    },
    {
      label: 'offer',
      value: 'offer',
    },
    {
      label: 'hired',
      value: 'hired',
    },
  ];

  const applicationRejectedOptions = [
    {
      label: 'rejected - education',
      value: 'rejected-education',
    },
    {
      label: 'rejected - tech stack',
      value: 'rejected-tech-stack',
    },
    {
      label: 'rejected - candidate withdraw',
      value: 'rejected-candidate-withdraw',
    },
    {
      label: 'rejected - experience',
      value: 'rejected-experience',
    },
    {
      label: 'rejected - aptitude',
      value: 'rejected-aptitude',
    },
    {
      label: 'rejected - technical',
      value: 'rejected-technical',
    },
    {
      label: 'rejected - did not submit',
      value: 'rejected-did-not-submit',
    },
    {
      label: 'rejected - communication',
      value: 'rejected-communication',
    },
    {
      label: 'rejected - culture/personality',
      value: 'rejected-culture/personality',
    },
    {
      label: 'rejected - no response',
      value: 'rejected-no-response',
    },
    {
      label: 'rejected - salary',
      value: 'rejected-salary',
    },
  ];

  useDetectOutsideClick(applicationDropdownRef, () => {
    if (isApplicationDropdownShow) setApplicationDropdownStatus(false);
  });

  const handleSelectedApplicantId = (e, applicantId) => {
    if (e.target.checked) {
      setSelectedApplicantIds((prevState) => [...prevState, applicantId]);
    } else {
      setSelectedApplicantIds((prevState) => {
        const filteredSelectedApplicantId = prevState.filter((d) => d !== applicantId);

        return filteredSelectedApplicantId;
      });
    }
  };

  const handleApplicationDropdownToggle = () => {
    setApplicationDropdownStatus((prevState) => !prevState);
  };

  const handleUpdateJobApplication = (seekerId, status) => {
    handleUpdateJobApplications(seekerId, status);
    setRefetchStatus(true);
    setApplicationDropdownStatus(false);
  };

  const applicationStatusParser = () => {
    if (data.application_status === 'shortlisted-pending') {
      return {
        status: true,
        text: 'pending invitation',
        color: 'PENDING_YELLOW',
      };
    }

    if (data.application_status === 'shortlisted-accepted') {
      return {
        status: true,
        text: 'accepted invitation',
        color: 'GREEN',
      };
    }

    return {
      status: false,
      text: 'accepted invitation',
      color: 'GREEN',
    };
  };

  const handleContactValue = (type) => {
    let val = '';
    if (data.contacts.length) {
      val = data.contacts.find(({ channel }) => channel === type).value;
    }
    return val;
  };

  return (
    <div css={cardContainerStyles}>
      <div css={cardStyles}>
        <Checkbox
          containerStyles={checkboxStyles}
          name={`applicant-${data.id}`}
          onChange={(e) => handleSelectedApplicantId(e, data.id)}
          checked={selectedApplicantIds.includes(data.id)}
        />
        {
          data.is_recommended && (
            <img src={recommendedImage} alt="recommended" css={recommendedImageStyles} />
          )
        }
        <img
          src={data.profile_image_url || userDefaultImage}
          css={imageStyles}
          alt="avatar"
        />
        <div css={rightCardStyles}>
          <div css={nameLocationAndFinalScoreContainerStyles}>
            <div css={nameAndLocationContainerStyles}>
              <ShortText styles={nameStyles}>{data.name}</ShortText>
              <div css={iconAndLocationContainerStyles}>
                {
                  data.city?.label && (
                    <>
                      <Icon name="map-pin-line" styles={iconStyles} />
                      <ShortText styles={locationStyles}>
                        {`${data.city?.label}, ${data.country?.label}`}
                      </ShortText>
                    </>
                  )
                }
                {
                  data.years_of_work_experience_track_records > 0.5 && (
                    <>
                      <div css={locationAndJobExperienceSeparatorStyles} />
                      <ShortText styles={jobExperienceStyles}>
                        {yearsToMonthsParser(data.years_of_work_experience_track_records || 0)}
                      </ShortText>
                    </>
                  )
                }
              </div>
            </div>
            <div css={badgeAndFinalScoreContainerStyles}>
              {data.last_hacktiv_course_track_record !== null
                && data.last_hacktiv_course_track_record.score >= 65 && (
                  <Fragment>
                    <div
                      css={finalScoreContainerStyles(
                        badgeChecker(
                          data.last_hacktiv_course_track_record.score,
                        ).color,
                      )}
                    >
                      <ShortText styles={finalScoreStyles} fontSize={24}>
                        {Math.floor(
                          data.last_hacktiv_course_track_record.score,
                        )}
                      </ShortText>
                    </div>
                    <ShortText
                      styles={badgeStyles(
                        badgeChecker(
                          data.last_hacktiv_course_track_record.score,
                        ).color,
                      )}
                      fontSize={18}
                    >
                      {
                        badgeChecker(
                          data.last_hacktiv_course_track_record.score,
                        ).badge
                      }
                    </ShortText>
                  </Fragment>
              )}
            </div>
          </div>
          {applicationStatusParser().status && (
            <ShortText styles={applicationStatusStyles(applicationStatusParser().color)}>
              {applicationStatusParser().text}
            </ShortText>
          )}
          <div css={skillsContainerStyles}>
            <ShortText styles={jobNameStyles}>
              {data.track_records.work_experience.length !== 0
                ? data.track_records.work_experience[
                  data.track_records.work_experience.length - 1
                ].title : '-'}
            </ShortText>
            <div css={skillPillsContainerStyles}>
              {data.skills.slice(0, 4).map(({ label }) => (
                <div key={label} css={skillPillStyles}>
                  <ShortText styles={skillNameStyles}>{label}</ShortText>
                </div>
              ))}
              {data.skills.length > 4 && (
                <div css={skillPillStyles}>
                  <ShortText styles={skillNameStyles}>
                    {`+${data.skills.length - 4}`}
                  </ShortText>
                </div>
              )}
            </div>
          </div>
          <div css={educationlabelAndValueContainerStyles}>
            <ShortText styles={labelStyles}>education</ShortText>
            <div css={valueContainerStyles}>
              <ShortText styles={valueStyles}>
                {data.track_records.formal_education.length !== 0
                  ? data.track_records.formal_education[
                    data.track_records.formal_education.length - 1
                  ].institution_name : '-'}
              </ShortText>
              <Icon name="checkbox-blank-circle-fill" styles={dotIconStyles} />
              <ShortText styles={dateValueStyles}>
                {data.track_records.formal_education.length !== 0
                  && data.track_records.formal_education[
                    data.track_records.formal_education.length - 1
                  ].date_until
                  ? `Graduated in ${moment(data.track_records.formal_education[
                    data.track_records.formal_education.length - 1
                  ].date_until).format('YYYY')}` : ''}
              </ShortText>
            </div>
          </div>
          <div css={workExperienceAndButtonContainerStyles}>
            <div css={workExperiencelabelAndValueContainerStyles}>
              <ShortText styles={labelStyles}>work</ShortText>
              <div css={workExperienceContainerStyles}>
                {data.track_records.work_experience.map((d) => (
                  <div key={d.id} css={[valueContainerStyles, workExperienceValueStyles]}>
                    <ShortText styles={valueStyles}>
                      {d.institution_name}
                    </ShortText>
                    <Icon
                      name="checkbox-blank-circle-fill"
                      styles={dotIconStyles}
                    />
                    <ShortText styles={dateValueStyles}>
                      {dateDiff(d.date_from, d.date_until)}
                    </ShortText>
                  </div>
                ))}
              </div>
            </div>
            <div css={buttonContainerStyles}>
              <Link to={`/${locale}/job/${jobId}/applicant/${data.id}`}>
                <Button type="button" size="S" color="LIGHT_BLUE" inverted>
                  details
                </Button>
              </Link>
              <div css={applicationDropdownContainerStyles}>
                <Button
                  type="button"
                  styles={applicationButtonStyles}
                  size="M"
                  color="LIGHT_BLUE"
                  onClick={handleApplicationDropdownToggle}
                >
                  application
                  <Icon name="arrow-down-s-line" styles={downArrowIconStyles} />
                </Button>
                <nav
                  ref={applicationDropdownRef}
                  css={applicationDropdownStyles(isApplicationDropdownShow)}
                >
                  <ul>
                    {applicationOptions.map(({ label, value }) => (
                      <li key={label}>
                        <button
                          type="button"
                          css={buttonListStyles}
                          onClick={() => handleUpdateJobApplication(data.id, value)}
                        >
                          {label}
                        </button>
                      </li>
                    ))}
                    <div css={dropdownDivider} />
                    {applicationRejectedOptions.map(({ label, value }) => (
                      <button
                        key={value}
                        type="button"
                        css={rejectButtonStyles}
                        onClick={() => handleUpdateJobApplication(data.id, value)}
                      >
                        {label}
                      </button>
                    ))}
                  </ul>
                </nav>
              </div>
              <ExternalLink
                href={`mailto:${handleContactValue('email')}`}
                styles={mailButtonStyles}
              >
                <Icon name="mail-line" styles={mailIconStyles} />
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicantCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    is_recommended: PropTypes.bool,
    profile_image_url: PropTypes.string,
    name: PropTypes.string,
    last_hacktiv_course_track_record: PropTypes.shape({
      score: PropTypes.number,
    }),
    city: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    years_of_work_experience_track_records: PropTypes.number,
    application_status: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    track_records: PropTypes.shape({
      formal_education: PropTypes.arrayOf(PropTypes.shape({
        institution_name: PropTypes.string,
        date_until: PropTypes.string,
      })),
      work_experience: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
      })),
    }),
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        channel: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  setRefetchStatus: PropTypes.func.isRequired,
  selectedApplicantIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedApplicantIds: PropTypes.func.isRequired,
  handleUpdateJobApplications: PropTypes.func.isRequired,
};

export default ApplicantCard;
