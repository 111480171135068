import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LocaleContext } from '../contexts/localeContext';

function ProtectedRoute({ component: Component, ...rest }) {
  const { locale } = useContext(LocaleContext);

  const authChecker = (props) => {
    const loginRes = JSON.parse(localStorage.getItem('loginRes'));
    const isAccessTokenExist = loginRes?.access_token;
    const accessTokenExpiryDate = moment(loginRes?.time_expires_in);
    const currentDate = moment();
    const diffDate = accessTokenExpiryDate.diff(currentDate, 'seconds');

    if (isAccessTokenExist && diffDate > 0) {
      return <Component {...props} />;
    }

    localStorage.clear();

    return <Redirect to={`/${locale}/login`} />;
  };

  return <Route {...rest} render={(props) => authChecker(props)} />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
