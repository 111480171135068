import axios from 'axios';

import { getAccessToken } from '../../../helpers/localStorage';

const jobDetails = (jobId) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/companies/jobs/${jobId}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  ));

export default jobDetails;
