import { css } from '@emotion/react';

import { colors } from '../../../../configs';

export const contentStyles = css`
  border-radius: 0 0 8px 8px;
  background-color: ${colors.WHITE};
`;

export const bodyStyles = css`
  padding: 36px 72px 32px 72px;
  border-bottom: 1px solid rgba(29, 61, 113, 0.05);
`;

export const rowStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 28px 0 0 0;

  &:first-of-type {
    margin: 0;
  }
`;

export const labelAndValueStyles = css`
  width: 210px;
  margin: 0 0 0 36px;

  &:first-of-type {
    margin: 0;
  }
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  color: ${colors.DARK_GREY};
`;

export const perksAndBenefitsContainerStyles = css`
  margin: 28px 0 0 0;
`;

export const perksAndBenefitsStyles = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 160px;
  margin: 8px 0 0 0;
  list-style: none;
`;

export const perksAndBenefitsListStyles = css`
  margin: 0 0 14px 0;
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.PRIMARY_BLACK};

  &:last-of-type {
    margin: 0;
  }

  &:before {
    content: '\\2022';
    margin: 0 11px 0 0;
    font-weight: bold;
    color: ${colors.LIGHT_BLUE};
  }
`;

export const footerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 72px;
`;
