/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Navbar from './Navbar/Navbar';
import NavbarFilter from './NavbarFilter/NavbarFilter';
import SearchAndFilter from './SearchAndFilter/SearchAndFilter';
import SortAndOptions from './SortAndOptions/SortAndOptions';
import ApplicantList from './ApplicantList/ApplicantList';
import ChangeStatusConfirmationModal from './ChangeStatusConfirmationModal/ChangeStatusConfirmationModal';
import ChangeStatusSuccessModal from './ChangeStatusSuccessModal/ChangeStatusSuccessModal';
import { Container } from '../../general';
import {
  containerStyles,
  contentStyles,
  sortAndApplicantListContainerStyles,
} from './Applicants.styles';

const Applicants = ({
  setRefetchStatus,
  jobDetailsData,
  applicantSummaryData,
  applicantTotalCount,
  applicantListData,
  selectedApplicantIds,
  setSelectedApplicantIds,
  sort,
  setSort,
  searchTerm,
  setSearchTerm,
  filterData,
  setFilterData,
  selectOptionsData,
  handleBulkUpdateJobApplications,
  handleUpdateJobApplications,
  pageInfo,
  setPageInfo,
  changeStatusConfirmationModalData,
  setChangeStatusConfirmationModalData,
  changeStatusSuccessModalData,
  setChangeStatusSuccessModalData,
}) => (
  <Fragment>
    <Navbar
      jobDetailsData={jobDetailsData}
      setChangeStatusConfirmationModalData={setChangeStatusConfirmationModalData}
    />
    <NavbarFilter applicantSummaryData={applicantSummaryData} />
    <Container
      contentWidthType="FULL"
      containerStyles={containerStyles}
      contentStyles={contentStyles}
    >
      <SearchAndFilter
        filterData={filterData}
        setFilterData={setFilterData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectOptionsData={selectOptionsData}
      />
      <div css={sortAndApplicantListContainerStyles}>
        <SortAndOptions
          setRefetchStatus={setRefetchStatus}
          applicantTotalCount={applicantTotalCount}
          applicantListData={applicantListData}
          selectedApplicantId={selectedApplicantIds}
          setSelectedApplicantId={setSelectedApplicantIds}
          handleBulkUpdateJobApplications={handleBulkUpdateJobApplications}
          sort={sort}
          setSort={setSort}
        />
        <ApplicantList
          setRefetchStatus={setRefetchStatus}
          applicantListData={applicantListData}
          selectedApplicantIds={selectedApplicantIds}
          setSelectedApplicantIds={setSelectedApplicantIds}
          handleUpdateJobApplications={handleUpdateJobApplications}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
        />
      </div>
      <ChangeStatusConfirmationModal
        changeStatusConfirmationModalData={changeStatusConfirmationModalData}
        setChangeStatusConfirmationModalData={
          setChangeStatusConfirmationModalData
        }
        setChangeStatusSuccessModalData={setChangeStatusSuccessModalData}
      />
      <ChangeStatusSuccessModal
        jobDetailsData={jobDetailsData}
        changeStatusSuccessModalData={changeStatusSuccessModalData}
        setChangeStatusSuccessModalData={setChangeStatusSuccessModalData}
      />
    </Container>
  </Fragment>
);

Applicants.propTypes = {
  setRefetchStatus: PropTypes.func.isRequired,
  jobDetailsData: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  applicantSummaryData: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      total: PropTypes.number,
    }),
  ).isRequired,
  applicantTotalCount: PropTypes.number.isRequired,
  applicantListData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile_image_url: PropTypes.string,
      name: PropTypes.string,
      last_hacktiv_course_track_record: PropTypes.shape({
        score: PropTypes.number,
      }),
      city: PropTypes.shape({
        label: PropTypes.string,
      }),
      country: PropTypes.shape({
        label: PropTypes.string,
      }),
      skills: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
      track_records: PropTypes.shape({
        formal_education: PropTypes.arrayOf(
          PropTypes.shape({
            institution_name: PropTypes.string,
            date_until: PropTypes.string,
          }),
        ),
        work_experience: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      contacts: PropTypes.arrayOf(
        PropTypes.shape({
          channel: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  selectedApplicantIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedApplicantIds: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOf(['ASC', 'DESC']),
  }).isRequired,
  setSort: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  filterData: PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    minWorkExperienceInYears: PropTypes.number,
    minWorkExperienceInMonths: PropTypes.number,
    maxWorkExperienceInYears: PropTypes.number,
    maxWorkExperienceInMonths: PropTypes.number,
    educationLevel: PropTypes.arrayOf(PropTypes.string),
    lastEducationMinYears: PropTypes.number,
    lastEducationMaxYears: PropTypes.number,
    provinces: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFilterData: PropTypes.func.isRequired,
  selectOptionsData: PropTypes.shape({
    skillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    rolePreferenceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    educationLevelList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    provinceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }).isRequired,
  handleBulkUpdateJobApplications: PropTypes.func.isRequired,
  handleUpdateJobApplications: PropTypes.func.isRequired,
  pageInfo: PropTypes.PropTypes.shape({
    pageSize: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  setPageInfo: PropTypes.func.isRequired,
  changeStatusConfirmationModalData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setChangeStatusConfirmationModalData: PropTypes.func.isRequired,
  changeStatusSuccessModalData: PropTypes.bool.isRequired,
  setChangeStatusSuccessModalData: PropTypes.func.isRequired,
};

export default Applicants;
