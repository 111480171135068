/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import RightCardContent from './RightCardContent/RightCardContent';
import SuccessModal from './SuccessModal/SuccessModal';
import { Container } from '../../general';
import { ProfileAndSettingsCard } from '../../cards';

const ChangePasswordSettings = ({
  formData,
  changePasswordStatus,
  setChangePasswordStatus,
  handleChange,
  handleSubmit,
  handleRedirectToLogout,
}) => (
  <Container contentWidthType="NORMAL">
    <ProfileAndSettingsCard
      rightCardContent={(
        <RightCardContent
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}
    />
    <SuccessModal
      changePasswordStatus={changePasswordStatus}
      setChangePasswordStatus={setChangePasswordStatus}
      handleRedirectToLogout={handleRedirectToLogout}
    />
  </Container>
);

ChangePasswordSettings.propTypes = {
  formData: PropTypes.shape({
    currentPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmationNewPassword: PropTypes.string,
  }).isRequired,
  changePasswordStatus: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setChangePasswordStatus: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRedirectToLogout: PropTypes.func.isRequired,
};

export default ChangePasswordSettings;
