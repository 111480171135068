/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import RightCardContent from './RightCardContent/RightCardContent';
import AddMemberModal from './AddMemberModal/AddMemberModal';
import EditMemberModal from './EditMemberModal/EditMemberModal';
import ManageMemberModal from './ManageMemberModal/ManageMemberModal';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { Container } from '../../general';
import { ProfileAndSettingsCard } from '../../cards';
import { manageMembers } from '../../../locale';

const ManageMembersSettings = ({
  data,
  setData,
  isAddMemberModalShown,
  setAddMemberModalStatus,
  editMemberModalData,
  setEditMemberModalData,
  manageMemberModalData,
  setManageMemberModalData,
  currentUserIdToBeActivated,
  setCurrentUserIdToBeActivated,
  activeConfirmationModalKey,
  setActiveConfirmationModalKey,
  isConfirmationModalShown,
  setConfirmationModalStatus,
}) => (
  <Container contentWidthType="NORMAL">
    <ProfileAndSettingsCard
      rightCardContent={(
        <RightCardContent
          data={data}
          setAddMemberModalStatus={setAddMemberModalStatus}
          setEditMemberModalData={setEditMemberModalData}
          setManageMemberModalData={setManageMemberModalData}
          setCurrentUserIdToBeActivated={setCurrentUserIdToBeActivated}
          setActiveConfirmationModalKey={setActiveConfirmationModalKey}
          setConfirmationModalStatus={setConfirmationModalStatus}
        />
      )}
    />
    <AddMemberModal
      setData={setData}
      isModalShown={isAddMemberModalShown}
      setModalStatus={setAddMemberModalStatus}
    />
    <EditMemberModal
      setData={setData}
      editMemberModalData={editMemberModalData}
      setEditMemberModalData={setEditMemberModalData}
    />
    <ManageMemberModal
      manageMemberModalData={manageMemberModalData}
      setManageMemberModalData={setManageMemberModalData}
      setActiveConfirmationModalKey={setActiveConfirmationModalKey}
      setConfirmationModalStatus={setConfirmationModalStatus}
    />
    <ConfirmationModal
      setData={setData}
      manageMemberModalData={manageMemberModalData}
      currentUserIdToBeActivated={currentUserIdToBeActivated}
      activeConfirmationModalKey={activeConfirmationModalKey}
      isConfirmationModalShown={isConfirmationModalShown}
      setConfirmationModalStatus={setConfirmationModalStatus}
      text={manageMembers}
    />
  </Container>
);

ManageMembersSettings.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company_name: PropTypes.string,
      company_type: PropTypes.string,
      department: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
      status: PropTypes.oneOf(['active', 'inactive', 'pending']),
    }),
  ).isRequired,
  setData: PropTypes.func.isRequired,
  isAddMemberModalShown: PropTypes.bool.isRequired,
  setAddMemberModalStatus: PropTypes.func.isRequired,
  editMemberModalData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setEditMemberModalData: PropTypes.func.isRequired,
  manageMemberModalData: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.bool,
  }).isRequired,
  setManageMemberModalData: PropTypes.func.isRequired,
  currentUserIdToBeActivated: PropTypes.number.isRequired,
  setCurrentUserIdToBeActivated: PropTypes.func.isRequired,
  activeConfirmationModalKey: PropTypes.string.isRequired,
  setActiveConfirmationModalKey: PropTypes.func.isRequired,
  isConfirmationModalShown: PropTypes.shape({
    activateMemberModal: PropTypes.bool,
    deactivateMemberModal: PropTypes.bool,
    deleteMemberModal: PropTypes.bool,
  }).isRequired,
  setConfirmationModalStatus: PropTypes.func.isRequired,
};

export default ManageMembersSettings;
