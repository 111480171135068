import getAccessToken from '../localStorage/getAccessToken';
import { introspection } from '../../api/v1/post/auth';

const updateIntrospection = async () => {
  const accessToken = getAccessToken();
  const introspectionRes = await introspection(accessToken);

  localStorage.setItem(
    'introspectionRes',
    JSON.stringify(introspectionRes.data.data),
  );
};

export default updateIntrospection;
