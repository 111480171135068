/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  InputWithIcon,
  ShortText,
  Checkbox,
  Button,
  Icon,
  Input,
} from '../../../general';
import {
  cardStyles,
  sectionStyles,
  sectionTitleStyles,
  searchInputStyles,
  inputStyles,
  advancedFilterSectionStyles,
  advancedFiltersTitleContainerStyles,
  hideButtonStyles,
  reactSelectContainerStyles,
  reactSelectStyles,
  accordionStyles,
  accordionButtonStyles,
  accordionIconStyles,
  accordionContentStyles,
  checkboxContainerStyles,
  checkboxStyles,
  minimumAndMaximumContainerStyles,
  labelStyles,
  inputAndLabelContainerStyles,
  minimumAndMaximumInputStyles,
  minimumAndMaximumLabelStyles,
  applyAndResetButtonContainerStyles,
  resetButtonStyles,
} from './SearchAndFilter.styles';
import { LocaleContext } from '../../../../contexts/localeContext';

const SearchAndFilter = ({
  filterData,
  setFilterData,
  searchTerm,
  setSearchTerm,
  setSearchTermResetStatus,
  selectOptionsData,
  setFilterApplicationStatus,
  setPaginationResetStatus,
  setPageInfo,
  setSearchFilterStatus,
  isSearchAndFilterReset,
}) => {
  const history = useHistory();
  const { locale } = useContext(LocaleContext);
  const [isAdvancedFilterShown, setAdvancedFilterStatus] = useState(true);
  const [accordionData, setAccordionData] = useState({
    jobRole: true,
    workExperience: true,
    educationLevel: true,
    location: true,
    programs: true,
  });
  const [checkboxSearchData, setCheckboxSearchData] = useState({
    jobRole: '',
    province: '',
  });

  const handleSearchTerm = (e) => {
    if (isSearchAndFilterReset) setSearchFilterStatus(false);
    setSearchTerm(e.target.value);
  };

  const handleAdvancedFilterToggle = () => {
    setAdvancedFilterStatus((prevState) => !prevState);
  };

  const skillsValueParser = () => {
    let parsedValue = [];
    if (typeof (filterData.skills) === 'object') {
      parsedValue = filterData.skills.map((d) => ({
        label: d,
        value: d,
      }));
    } else if (typeof (filterData.skills) === 'string') {
      parsedValue = [{ label: filterData.skills, value: filterData.skills }];
    }
    return parsedValue;
  };

  const handleSkillsChange = (d) => {
    setFilterData((prevState) => {
      if (d) {
        const parsedArr = d.map(({ value }) => value);

        return {
          ...prevState,
          skills: parsedArr,
        };
      }

      return {
        ...prevState,
        skills: [],
      };
    });
  };

  const handleAccordion = (accordionName) => {
    setAccordionData((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  const handleCheckboxSearch = (e) => {
    setCheckboxSearchData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeCheckbox = (e, type) => {
    if (e.target.checked) {
      setFilterData((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], e.target.name],
      }));
    } else {
      setFilterData((prevState) => {
        const newArr = prevState[type].filter(
          (d) => d !== e.target.name,
        );

        return {
          ...prevState,
          [type]: newArr,
        };
      });
    }
  };

  const handleMinAndMaxValueChange = (e) => {
    setFilterData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleApplyFilter = () => {
    setFilterApplicationStatus(true);
  };

  const handleResetFilter = () => {
    setSearchTerm('');
    setSearchTermResetStatus(true);
    setPaginationResetStatus(true);
    setSearchFilterStatus(true);
    setFilterData({
      skills: [],
      roles: [],
      educationLevel: [],
      minWorkExperienceInYears: 0,
      minWorkExperienceInMonths: 0,
      maxWorkExperienceInYears: 0,
      maxWorkExperienceInMonths: 0,
      lastEducationMinYears: 0,
      lastEducationMaxYears: 0,
      provinces: [],
      programs: [],
      distinctions: [],
      source: '',
    });
    setPageInfo((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    history.push({
      pathname: `/${locale}/resume`,
      search: '',
    });
    setFilterApplicationStatus(true);
  };

  return (
    <div css={cardStyles}>
      <form>
        <div css={sectionStyles}>
          <ShortText styles={sectionTitleStyles}>
            Search
          </ShortText>
          <InputWithIcon
            type="text"
            containerStyles={searchInputStyles}
            inputStyles={inputStyles}
            name="searchTerm"
            iconName="search-line"
            value={searchTerm}
            onChange={handleSearchTerm}
            placeholder="search"
          />
        </div>
        <div css={sectionStyles}>
          <div css={advancedFiltersTitleContainerStyles}>
            <ShortText styles={sectionTitleStyles}>advanced filters</ShortText>
            <button
              type="button"
              css={hideButtonStyles}
              onClick={handleAdvancedFilterToggle}
            >
              {isAdvancedFilterShown ? 'hide' : 'show'}
            </button>
          </div>
          <div css={reactSelectContainerStyles}>
            <Select
              id="skills"
              instanceId="skills"
              css={reactSelectStyles}
              classNamePrefix="select"
              name="skills"
              options={selectOptionsData.skillsOptions}
              value={skillsValueParser()}
              onChange={handleSkillsChange}
              isSearchable
              placeholder="skills"
              isMulti
            />
          </div>
          <div css={advancedFilterSectionStyles(isAdvancedFilterShown)}>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('jobRole')}
              >
                {!filterData.source ? 'Job Role' : 'Internship Program'}
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.jobRole)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.jobRole)}>
                <InputWithIcon
                  type="text"
                  inputStyles={inputStyles}
                  name="jobRole"
                  value={checkboxSearchData.jobRole}
                  onChange={handleCheckboxSearch}
                  iconName="search-line"
                  placeholder="Job Role"
                />
                <div css={checkboxContainerStyles}>
                  {selectOptionsData.rolePreferenceList
                    .filter(({ label }) => label
                      .toLowerCase()
                      .includes(checkboxSearchData.jobRole))
                    .map(({ label, value }) => (
                      <Checkbox
                        key={label}
                        containerStyles={checkboxStyles}
                        checkboxLabel={label}
                        name={value}
                        onChange={(e) => handleChangeCheckbox(e, 'roles')}
                        checked={filterData.roles.includes(value)}
                      />
                    ))}
                </div>
              </div>
            </div>
            {
              !filterData.source && (
                <div css={accordionStyles}>
                  <button
                    type="button"
                    css={accordionButtonStyles}
                    onClick={() => handleAccordion('workExperience')}
                  >
                    Years of Work Experience
                    <Icon
                      name="arrow-down-s-line"
                      styles={accordionIconStyles(accordionData.workExperience)}
                    />
                  </button>
                  <div css={accordionContentStyles(accordionData.workExperience)}>
                    <div css={minimumAndMaximumContainerStyles}>
                      <ShortText styles={labelStyles}>minimum</ShortText>
                      <div css={inputAndLabelContainerStyles}>
                        <Input
                          type="number"
                          containerStyles={minimumAndMaximumInputStyles}
                          name="minWorkExperienceInYears"
                          value={filterData.minWorkExperienceInYears}
                          onChange={handleMinAndMaxValueChange}
                          placeholder="Number of"
                          min="1"
                        />
                        <ShortText styles={minimumAndMaximumLabelStyles}>
                          years
                        </ShortText>
                      </div>
                      <div css={inputAndLabelContainerStyles}>
                        <Input
                          type="number"
                          containerStyles={minimumAndMaximumInputStyles}
                          name="minWorkExperienceInMonths"
                          value={filterData.minWorkExperienceInMonths}
                          onChange={handleMinAndMaxValueChange}
                          placeholder="Number of"
                          min="1"
                        />
                        <ShortText styles={minimumAndMaximumLabelStyles}>
                          months
                        </ShortText>
                      </div>
                    </div>
                    <div css={minimumAndMaximumContainerStyles}>
                      <ShortText styles={labelStyles}>maximum</ShortText>
                      <div css={inputAndLabelContainerStyles}>
                        <Input
                          type="number"
                          containerStyles={minimumAndMaximumInputStyles}
                          name="maxWorkExperienceInYears"
                          value={filterData.maxWorkExperienceInYears}
                          onChange={handleMinAndMaxValueChange}
                          placeholder="Number of"
                          min="1"
                        />
                        <ShortText styles={minimumAndMaximumLabelStyles}>
                          years
                        </ShortText>
                      </div>
                      <div css={inputAndLabelContainerStyles}>
                        <Input
                          type="number"
                          containerStyles={minimumAndMaximumInputStyles}
                          name="maxWorkExperienceInMonths"
                          value={filterData.maxWorkExperienceInMonths}
                          onChange={handleMinAndMaxValueChange}
                          placeholder="Number of"
                          min="1"
                        />
                        <ShortText styles={minimumAndMaximumLabelStyles}>
                          months
                        </ShortText>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('educationLevel')}
              >
                Education Level
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.educationLevel)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.educationLevel)}>
                <div>
                  {selectOptionsData.educationLevelList.map(
                    ({ label, value }) => (
                      <Checkbox
                        key={label}
                        containerStyles={checkboxStyles}
                        checkboxLabel={label}
                        name={value}
                        onChange={(e) => handleChangeCheckbox(e, 'educationLevel')}
                        checked={filterData.educationLevel.includes(value)}
                      />
                    ),
                  )}
                </div>
              </div>
            </div>
            <div css={accordionStyles}>
              <button
                type="button"
                css={accordionButtonStyles}
                onClick={() => handleAccordion('location')}
              >
                Location
                <Icon
                  name="arrow-down-s-line"
                  styles={accordionIconStyles(accordionData.location)}
                />
              </button>
              <div css={accordionContentStyles(accordionData.location)}>
                <InputWithIcon
                  type="text"
                  inputStyles={inputStyles}
                  name="province"
                  value={checkboxSearchData.province}
                  onChange={handleCheckboxSearch}
                  iconName="search-line"
                  placeholder="location"
                />
                <div css={checkboxContainerStyles}>
                  {selectOptionsData.provinceList
                    .filter(({ label }) => label
                      .toLowerCase()
                      .includes(checkboxSearchData.province.toLowerCase()))
                    .map(({ label, value }) => (
                      <Checkbox
                        key={label}
                        containerStyles={checkboxStyles}
                        checkboxLabel={label}
                        name={value.toString()}
                        onChange={(e) => handleChangeCheckbox(e, 'provinces')}
                        checked={filterData.provinces.includes(value.toString())}
                      />
                    ))}
                </div>
              </div>
            </div>
            {
              !filterData.source && (
                <div css={accordionStyles}>
                  <button
                    type="button"
                    css={accordionButtonStyles}
                    onClick={() => handleAccordion('programs')}
                  >
                    Programs
                    <Icon
                      name="arrow-down-s-line"
                      styles={accordionIconStyles(accordionData.programs)}
                    />
                  </button>
                  <div css={accordionContentStyles(accordionData.programs)}>
                    <div>
                      {selectOptionsData.programList.map(
                        ({ label, value }) => (
                          <Checkbox
                            key={label}
                            containerStyles={checkboxStyles}
                            checkboxLabel={label}
                            name={value}
                            onChange={(e) => handleChangeCheckbox(e, 'programs')}
                            checked={filterData.programs.includes(value)}
                          />
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )
            }
            {/* {
              !filterData.source && (
                <div css={accordionStyles}>
                  <button
                    type="button"
                    css={accordionButtonStyles}
                    onClick={() => handleAccordion('distinctions')}
                  >
                    Distinctions
                    <Icon
                      name="arrow-down-s-line"
                      styles={accordionIconStyles(accordionData.distinctions)}
                    />
                  </button>
                  <div css={accordionContentStyles(accordionData.distinctions)}>
                    <div>
                      {selectOptionsData.distinctionsList.map(
                        ({ label, value }) => (
                          <Checkbox
                            key={label}
                            containerStyles={checkboxStyles}
                            checkboxLabel={label}
                            name={value}
                            onChange={(e) => handleChangeCheckbox(e, 'distinctions')}
                            checked={filterData.distinctions.includes(value)}
                          />
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )
            } */}
          </div>
        </div>
        <div css={applyAndResetButtonContainerStyles}>
          <Button
            type="button"
            styles={resetButtonStyles}
            size="FULL"
            color="LIGHT_BLUE"
            onClick={handleApplyFilter}
          >
            apply filter
          </Button>
          <Button
            type="button"
            styles={resetButtonStyles}
            size="FULL"
            color="LIGHT_BLUE"
            onClick={handleResetFilter}
            inverted
          >
            reset filter
          </Button>
        </div>
      </form>
    </div>
  );
};

SearchAndFilter.propTypes = {
  filterData: PropTypes.shape({
    skills: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    minWorkExperienceInYears: PropTypes.number,
    minWorkExperienceInMonths: PropTypes.number,
    maxWorkExperienceInYears: PropTypes.number,
    maxWorkExperienceInMonths: PropTypes.number,
    educationLevel: PropTypes.arrayOf(PropTypes.string),
    lastEducationMinYears: PropTypes.number,
    lastEducationMaxYears: PropTypes.number,
    provinces: PropTypes.arrayOf(PropTypes.string),
    programs: PropTypes.arrayOf(PropTypes.string),
    distinctions: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string,
  }).isRequired,
  setFilterData: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSearchTermResetStatus: PropTypes.func.isRequired,
  selectOptionsData: PropTypes.shape({
    skillsOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    rolePreferenceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    educationLevelList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    provinceList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    programList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    distinctionsList: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
  }).isRequired,
  setFilterApplicationStatus: PropTypes.func.isRequired,
  setPageInfo: PropTypes.func.isRequired,
  setPaginationResetStatus: PropTypes.func.isRequired,
  setSearchFilterStatus: PropTypes.func.isRequired,
  isSearchAndFilterReset: PropTypes.bool.isRequired,
};

export default SearchAndFilter;
