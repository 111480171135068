import axios from 'axios';

const provinces = (params) => (
  axios.get(
    `${process.env.REACT_APP_CAREER_API_ENDPOINT}/library/provinces/search`,
    {
      params,
    },
  ));

export default provinces;
