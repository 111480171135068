import { css } from '@emotion/react';

import { colors, contentWidth } from '../../../../configs';

export const navbarStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  background-color: ${colors.WHITE};
`;

export const navbarContentStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${contentWidth.FULL}px;
  height: 100%;
`;

export const titleStyles = css`
  font-size: 20px;
  color: ${colors.PRIMARY_BLUE};
`;

export const breadcrumbsContainerStyles = css`
  margin: 10px 0 0 0;
`;

export const linkStyles = css`
  font-weight: 600;
  color: ${colors.LIGHT_BLUE};
`;

export const currentPageNameStyles = css`
  text-transform: capitalize;
  font-weight: 600;
`;
