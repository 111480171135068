import Layout from '../../components/layout/Layout';
import { Error500 } from '../../components/pages';

const Error500Page = () => (
  <Layout conte backgroundColor="LIGHT_GREY">
    <Error500 />
  </Layout>
);

export default Error500Page;
